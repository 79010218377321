import React from 'react';
import {
  Box,
  HStack,
  Image,
  Text,
  IconButton,
  VStack,
  useColorModeValue,
  Badge,
  Divider,
} from '@chakra-ui/react';
import { 
  FaQrcode, 
  FaToggleOn, 
  FaToggleOff, 
  FaTrash,
  FaEdit,
} from 'react-icons/fa';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const BadgeCard = ({ badge, onToggle, onDelete, onShowQR, onEdit }) => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const buttonBgColor = useColorModeValue('gray.50', 'gray.700');

  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Box
        bg={bgColor}
        p={4}
        borderRadius="lg"
        borderWidth="1px"
        borderColor={borderColor}
      >
        <VStack spacing={4} align="stretch">
          {/* Badge Content */}
          <HStack spacing={4}>
            <Image
              src={badge.imageUrl}
              alt={badge.name}
              boxSize="50px"
              objectFit="cover"
              borderRadius="md"
            />
            <Box flex={1}>
              <Text fontWeight="bold">{badge.name}</Text>
              <Badge colorScheme={badge.status === 'active' ? 'green' : 'gray'}>
                {badge.status}
              </Badge>
              {badge.description && (
                <Text fontSize="sm" color="gray.500" mt={1} noOfLines={1}>
                  {badge.description}
                </Text>
              )}
            </Box>
          </HStack>

          <Divider />

          {/* Action Buttons */}
          <HStack spacing={2} justify="flex-end">
            <IconButton
              icon={<FaEdit />}
              onClick={() => onEdit(badge)}
              colorScheme="blue"
              variant="ghost"
              size="sm"
              aria-label="Edit badge"
              bg={buttonBgColor}
            />
            <IconButton
              icon={<FaQrcode />}
              onClick={() => onShowQR(badge)}
              colorScheme="green"
              variant="ghost"
              size="sm"
              aria-label="Show QR code"
              bg={buttonBgColor}
            />
            <IconButton
              icon={badge.status === 'active' ? <FaToggleOn /> : <FaToggleOff />}
              onClick={() => onToggle(badge.id, badge.status)}
              colorScheme={badge.status === 'active' ? 'green' : 'gray'}
              variant="ghost"
              size="sm"
              aria-label="Toggle status"
              bg={buttonBgColor}
            />
            <IconButton
              icon={<FaTrash />}
              onClick={() => onDelete(badge)}
              colorScheme="red"
              variant="ghost"
              size="sm"
              aria-label="Delete badge"
              bg={buttonBgColor}
            />
          </HStack>
        </VStack>
      </Box>
    </MotionBox>
  );
};

export default BadgeCard;