import React, { useEffect, useState } from 'react';
import { HashRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { 
  ChakraProvider, 
  Box, 
  useColorModeValue, 
  ColorModeScript,
  Center,
  VStack,
  Text,
  Spinner
} from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from './contexts/ThemeContext';
import { AuthProvider } from './contexts/AuthContext';
import { ProfileProvider } from './contexts/ProfileContext';
import { SidebarProvider } from './contexts/SidebarContext';
import ErrorBoundary from './components/ErrorBoundary';
import theme from './theme/theme';
import { isMapsLoaded } from './utils/googleMaps';
import { App as CapacitorApp } from '@capacitor/app';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';

// Components
import Layout from './components/Layout';
import Home from './pages/Home';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Search from './components/Search';
import People from './components/People';
import Profile from './components/profiles/Profile';
import EditProfile from './components/EditProfile';
import BadgeManagement from './components/BadgeManagement';
import BrewCheck from './components/BrewCheck';
import CreatePost from './components/CreatePost';
import CoffeeVariants from './components/CoffeeVariants';
import Collections from './components/Collections';
import Meetups from './components/Meetups';
import MeetupDetails from './components/MeetupDetails';
import Reviews from './components/Reviews';
import NCA from './components/NCA';
import AllTopics from './components/AllTopics';
import Database from './components/Database';
import BadgeClaim from './components/BadgeClaim';
import Messages from './pages/Messages';
import ChatWindow from './components/ChatWindow';
import BeanInventory from './components/BeanInventory';
import EquipmentInventory from './components/EquipmentInventory';
import News from './components/News';
import CoffeePlaces from './components/CoffeePlaces';
import Polls from './components/Polls';
import ProtectedRoute from './components/auth/ProtectedRoute';
import ProtectedProfileRoute from './components/auth/ProtectedProfileRoute';
import About from './pages/info/About';
import Contact from './pages/info/Contact';
import CodeOfConduct from './pages/info/CodeOfConduct';
import Privacy from './pages/info/Privacy';
import Terms from './pages/info/Terms';
import CoffeePage from './components/CoffeePage';
import CoffeeTasting from './components/CoffeeTasting';
import EspressoJournal from './components/EspressoJournal';
import BrewLedger from './components/BrewLedger';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

function AppContent() {
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const [mapsReady, setMapsReady] = useState(false);

  useEffect(() => {
    // Check if Maps is loaded
    const checkMapsLoaded = () => {
      if (isMapsLoaded()) {
        setMapsReady(true);
      } else {
        setTimeout(checkMapsLoaded, 100);
      }
    };

    checkMapsLoaded();
  }, []);

  if (!mapsReady) {
    return (
      <Center height="100vh">
        <VStack spacing={4}>
          <Spinner size="xl" />
          <Text>Initializing Maps...</Text>
        </VStack>
      </Center>
    );
  }

  return (
    <Box
      minH="100vh"
      pt={{ 
        base: "calc(env(safe-area-inset-top))",
        md: "72px"
      }}
      pb={{ 
        base: "calc(env(safe-area-inset-bottom) + 40px)",
        md: "0"
      }}
      bg={bgColor}
    >
      <Routes>
        <Route element={<Layout><Outlet /></Layout>}>
          <Route index element={<CoffeePage />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="brewcheck" element={<BrewCheck />} />
          <Route path="profile/:userId" element={<Profile />} />
          <Route path="profile" element={<Profile />} />
          <Route path="edit-profile" element={<EditProfile />} />
          <Route path="search" element={<Search />} />
          <Route path="people" element={<People />} />
          <Route path="/badge-management" element={<BadgeManagement />} />
          <Route path="coffee-variants" element={<CoffeeVariants />} />
          <Route path="collections" element={<Collections />} />
          <Route path="meetups" element={<Meetups />} />
          <Route path="/meetup/:meetupId" element={<MeetupDetails />} />
          <Route path="reviews" element={<Reviews />} />
          <Route path="nca" element={<NCA />} />
          <Route path="topics" element={<AllTopics />} />
          <Route path="database" element={<Database />} />
          <Route path="/claim-badge/:badgeId" element={<BadgeClaim />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/messages/:userId" element={<Messages />} />
          <Route path="/chat/:chatId" element={<ChatWindow />} />
          <Route path="/inventory" element={<BeanInventory />} />
          <Route path="/equipment" element={<EquipmentInventory />} />
          <Route path="news" element={<News />} />
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/code-of-conduct" element={<CodeOfConduct />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/brew-journal" element={<BrewLedger />} />
          <Route path="/espresso-journal" element={<EspressoJournal />} />

          {/* Add other routes here */}
          <Route path="/places" element={
            <ErrorBoundary>
              <CoffeePlaces />
            </ErrorBoundary>
          } />
          <Route path="/badge/claim/:badgeId" element={<BadgeClaim />} />
          <Route path="/feed" element={<Home />} />
          <Route path="/tasting" element={<CoffeeTasting />} />
          <Route path="/espresso-journal" element={<EspressoJournal />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </Box>
  );
}

function App() {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const setupApp = async () => {
      try {
        if (Capacitor.isNativePlatform()) {
          // Set status bar style
          await StatusBar.setStyle({ style: Style.Dark });
          
          // Handle hardware back button
          CapacitorApp.addListener('backButton', ({ canGoBack }) => {
            if (!canGoBack) {
              CapacitorApp.exitApp();
            } else {
              window.history.back();
            }
          });
        }
        setIsInitialized(true);
      } catch (err) {
        console.error('Error setting up app:', err);
        setIsInitialized(true); // Still set to true so app renders
      }
    };

    setupApp();
  }, []);

  if (!isInitialized) {
    return null; // Or a loading spinner
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <ChakraProvider theme={theme}>
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <ThemeProvider>
            <AuthProvider>
              <ProfileProvider>
                <SidebarProvider>
                  <HashRouter>
                    <AppContent />
                  </HashRouter>
                </SidebarProvider>
              </ProfileProvider>
            </AuthProvider>
          </ThemeProvider>
        </ChakraProvider>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}

export default App;
