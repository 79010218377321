import React, { memo } from 'react';
import {
  Box,
  Image,
  Text,
  Badge,
  VStack,
  HStack,
  useColorModeValue,
  Icon,
  Tooltip,
} from '@chakra-ui/react';
import StarRating from '../shared/StarRating';
import { FaGoogle, FaCoffee } from 'react-icons/fa';

const PlaceCard = memo(({ place }) => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const isOpenNow = () => {
    if (!place.openingHours?.periods) return true; // Default to open if no data
    
    const now = new Date();
    const day = now.getDay();
    const currentPeriod = place.openingHours.periods.find(period => period.open.day === day);
    
    if (!currentPeriod) return false;
    
    const currentTime = now.getHours() * 100 + now.getMinutes();
    const openTime = parseInt(currentPeriod.open.time);
    const closeTime = parseInt(currentPeriod.close.time);
    
    return currentTime >= openTime && currentTime < closeTime;
  };

  const shouldShowOpenStatus = () => {
    return place.openingHours?.periods || place.openingHours?.weekday_text;
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      bg={bgColor}
      borderColor={borderColor}
      _hover={{ transform: 'scale(1.02)', transition: 'all 0.2s' }}
      cursor="pointer"
      height="100%"
    >
      <Image
        src={place.photos?.[0]?.url || '/default-coffee-shop.jpg'}
        alt={place.name}
        height="200px"
        width="100%"
        objectFit="cover"
      />
      
      <VStack p={4} spacing={2} align="stretch">
        <Text fontSize="xl" fontWeight="semibold" noOfLines={1}>
          {place.name}
        </Text>
        
        <VStack spacing={1} align="stretch">
          {/* Google Rating */}
          {place.rating > 0 && (
            <HStack spacing={2} align="center">
              <Icon as={FaGoogle} color="red.500" boxSize={4} />
              <StarRating rating={place.rating} size="sm" isReadOnly />
              <Text fontSize="sm" color="gray.500">
                ({place.userRatingsTotal || 0})
              </Text>
            </HStack>
          )}
          
          {/* Community Rating - only show if there are reviews */}
          {place.communityRating > 0 && place.communityReviewsCount > 0 && (
            <HStack spacing={2} align="center">
              <Icon as={FaCoffee} color="brown.500" boxSize={4} />
              <StarRating rating={place.communityRating} size="sm" isReadOnly />
              <Text fontSize="sm" color="gray.500">
                ({place.communityReviewsCount})
              </Text>
            </HStack>
          )}
        </VStack>

        <Text fontSize="sm" color="gray.500" noOfLines={2}>
          {place.vicinity || place.address}
        </Text>

        <HStack spacing={2} mt={2}>
          {shouldShowOpenStatus() && (
            <Badge colorScheme={isOpenNow() ? 'green' : 'red'}>
              {isOpenNow() ? 'Open' : 'Closed'}
            </Badge>
          )}
          {place.distance !== undefined && (
            <Badge colorScheme="purple">
              {place.distance < 1 
                ? `${(place.distance * 1000).toFixed(0)}m` 
                : `${place.distance.toFixed(1)}km`}
            </Badge>
          )}
        </HStack>
      </VStack>
    </Box>
  );
});

PlaceCard.displayName = 'PlaceCard';
export default PlaceCard;
