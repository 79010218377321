import React, { useState, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Box,
  Text,
  Input,
  useToast,
  Image,
  Center,
  Spinner,
  IconButton,
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaImage, FaTimes } from 'react-icons/fa';
import { useAuth } from '../../contexts/AuthContext';
import { addStory } from '../../utils/firebaseFunctions';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebase';

const MotionBox = motion(Box);

const CreateStoryModal = ({ isOpen, onClose }) => {
  const { user } = useAuth();
  const toast = useToast();
  const fileInputRef = useRef();
  
  const [caption, setCaption] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      toast({
        title: 'No media selected',
        description: 'Please select an image or video to share',
        status: 'error',
        duration: 3000,
      });
      return;
    }

    setIsLoading(true);
    try {
      // Upload media
      const fileRef = ref(storage, `stories/${user.uid}/${Date.now()}_${selectedFile.name}`);
      await uploadBytes(fileRef, selectedFile);
      const mediaUrl = await getDownloadURL(fileRef);

      // Create story
      await addStory(user.uid, {
        mediaUrl,
        caption,
        mediaType: selectedFile.type.startsWith('video') ? 'video' : 'image',
        user: {
          displayName: user.displayName,
          photoURL: user.photoURL,
          uid: user.uid
        }
      });

      toast({
        title: 'Story created!',
        status: 'success',
        duration: 3000,
      });
      onClose();
    } catch (error) {
      console.error('Error creating story:', error);
      toast({
        title: 'Error creating story',
        description: error.message,
        status: 'error',
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
      setSelectedFile(null);
      setPreview(null);
      setCaption('');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay backdropFilter="blur(5px)" />
      <ModalContent
        as={motion.div}
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -50, opacity: 0 }}
      >
        <ModalHeader>Create Story</ModalHeader>
        <ModalBody>
          <VStack spacing={4}>
            <AnimatePresence mode="wait">
              {preview ? (
                <MotionBox
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  position="relative"
                  w="full"
                >
                  <Image
                    src={preview}
                    alt="Preview"
                    borderRadius="lg"
                    objectFit="cover"
                    w="full"
                    maxH="400px"
                  />
                  <IconButton
                    icon={<FaTimes />}
                    position="absolute"
                    top={2}
                    right={2}
                    onClick={() => {
                      setSelectedFile(null);
                      setPreview(null);
                    }}
                    isRound
                    size="sm"
                  />
                </MotionBox>
              ) : (
                <MotionBox
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  w="full"
                >
                  <Center
                    h="200px"
                    borderRadius="lg"
                    borderWidth={2}
                    borderStyle="dashed"
                    borderColor="gray.300"
                    cursor="pointer"
                    onClick={() => fileInputRef.current?.click()}
                    _hover={{ bg: 'gray.50' }}
                  >
                    <VStack spacing={2}>
                      <FaImage size={24} />
                      <Text>Click to select media</Text>
                    </VStack>
                  </Center>
                </MotionBox>
              )}
            </AnimatePresence>

            <Input
              type="file"
              accept="image/*,video/*"
              hidden
              ref={fileInputRef}
              onChange={handleFileSelect}
            />

            <Input
              placeholder="Add a caption..."
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
            />
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isLoading={isLoading}
            loadingText="Creating..."
          >
            Share Story
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateStoryModal; 