import React, { useState } from 'react';
import { 
  signInWithEmailAndPassword, 
  signInWithPopup, 
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  updateProfile 
} from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Text,
  VStack,
  HStack,
  useColorModeValue,
  Container,
  Divider,
  IconButton,
  InputGroup,
  InputRightElement,
  useToast,
  Link,
} from '@chakra-ui/react';
import { FaGoogle, FaEye, FaEyeSlash } from 'react-icons/fa';

const Login = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    username: '',
    displayName: '',
  });
  
  const navigate = useNavigate();
  const toast = useToast();
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (isLogin) {
        await signInWithEmailAndPassword(auth, formData.email, formData.password);
      } else {
        // Register new user
        const { user } = await createUserWithEmailAndPassword(
          auth, 
          formData.email, 
          formData.password
        );

        // Update profile
        await updateProfile(user, {
          displayName: formData.displayName
        });

        // Create user document
        await setDoc(doc(db, 'users', user.uid), {
          uid: user.uid,
          email: formData.email,
          username: formData.username,
          displayName: formData.displayName,
          photoURL: user.photoURL || null,
          createdAt: new Date(),
          followers: [],
          following: [],
          bio: '',
        });
      }

      navigate('/');
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const { user } = await signInWithPopup(auth, provider);
      
      // Check if user document exists, if not create it
      const userDoc = doc(db, 'users', user.uid);
      await setDoc(userDoc, {
        uid: user.uid,
        email: user.email,
        username: user.email.split('@')[0],
        displayName: user.displayName,
        photoURL: user.photoURL,
        createdAt: new Date(),
        followers: [],
        following: [],
        bio: '',
      }, { merge: true });

      navigate('/');
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Container maxW="md" py={12}>
      <Box
        bg={bgColor}
        p={8}
        borderWidth="1px"
        borderRadius="xl"
        borderColor={borderColor}
        boxShadow="xl"
      >
        <VStack spacing={8}>
          <Heading size="lg">
            {isLogin ? 'Welcome Back' : 'Create Account'}
          </Heading>

          <Button
            leftIcon={<FaGoogle />}
            onClick={handleGoogleSignIn}
            width="full"
            colorScheme="red"
            variant="outline"
          >
            Continue with Google
          </Button>

          <HStack width="full">
            <Divider />
            <Text fontSize="sm" color="gray.500" whiteSpace="nowrap">
              or continue with email
            </Text>
            <Divider />
          </HStack>

          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <VStack spacing={4}>
              {!isLogin && (
                <>
                  <FormControl isRequired>
                    <FormLabel>Display Name</FormLabel>
                    <Input
                      value={formData.displayName}
                      onChange={(e) => setFormData({
                        ...formData,
                        displayName: e.target.value
                      })}
                      placeholder="Your name"
                    />
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel>Username</FormLabel>
                    <Input
                      value={formData.username}
                      onChange={(e) => setFormData({
                        ...formData,
                        username: e.target.value.toLowerCase()
                      })}
                      placeholder="Choose a username"
                    />
                  </FormControl>
                </>
              )}

              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({
                    ...formData,
                    email: e.target.value
                  })}
                  placeholder="your@email.com"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input
                    type="password"
                    placeholder="Enter password"
                    required
                    autoComplete="current-password"
                    value={formData.password}
                    onChange={(e) => setFormData({
                      ...formData,
                      password: e.target.value
                    })}
                  />
                  <InputRightElement>
                    <IconButton
                      icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                      variant="ghost"
                      onClick={() => setShowPassword(!showPassword)}
                      aria-label={showPassword ? 'Hide password' : 'Show password'}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <Button
                type="submit"
                colorScheme="blue"
                width="full"
                isLoading={loading}
                loadingText={isLogin ? 'Signing in...' : 'Creating account...'}
              >
                {isLogin ? 'Sign In' : 'Create Account'}
              </Button>
            </VStack>
          </form>

          <HStack justify="center" width="full" pt={2}>
            <Text>
              {isLogin ? "Don't have an account?" : "Already have an account?"}
            </Text>
            <Link
              color="blue.500"
              onClick={() => setIsLogin(!isLogin)}
              _hover={{ textDecoration: 'underline' }}
            >
              {isLogin ? 'Sign up' : 'Sign in'}
            </Link>
          </HStack>
        </VStack>
      </Box>
    </Container>
  );
};

export default Login;
