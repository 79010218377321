import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useColorModeValue,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
} from '@chakra-ui/react';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { PlaceSearch } from '../shared/PlaceSearch';

const EventForm = ({ isOpen, onClose, user, onSuccess }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [maxAttendees, setMaxAttendees] = useState(10);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [eventType, setEventType] = useState('meetup');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const bgColor = useColorModeValue('white', 'gray.800');

  const handleSubmit = async () => {
    if (!user || !selectedPlace || !title || !date || !time) return;

    setIsSubmitting(true);
    try {
      const eventData = {
        title,
        description,
        date,
        time,
        maxAttendees,
        placeId: selectedPlace.id,
        placeName: selectedPlace.name,
        placeAddress: selectedPlace.address,
        type: eventType,
        organizer: {
          id: user.uid,
          name: user.displayName,
          photo: user.photoURL,
        },
        attendees: [{
          id: user.uid,
          name: user.displayName,
          photo: user.photoURL,
          role: 'organizer'
        }],
        status: 'upcoming',
        timestamp: serverTimestamp(),
      };

      await addDoc(collection(db, 'events'), eventData);
      onSuccess?.();
      
      // Reset form
      setTitle('');
      setDescription('');
      setDate('');
      setTime('');
      setMaxAttendees(10);
      setSelectedPlace(null);
      setEventType('meetup');
    } catch (error) {
      console.error('Error creating event:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent bg={bgColor}>
        <ModalHeader>Create Coffee Event</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} pb={4}>
            <FormControl isRequired>
              <FormLabel>Event Title</FormLabel>
              <Input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Give your event a catchy title"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Location</FormLabel>
              <PlaceSearch
                selectedPlace={selectedPlace}
                onPlaceSelect={setSelectedPlace}
                placeholder="Select a coffee place"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Event Type</FormLabel>
              <Select
                value={eventType}
                onChange={(e) => setEventType(e.target.value)}
              >
                <option value="meetup">Coffee Meetup</option>
                <option value="tasting">Coffee Tasting</option>
                <option value="workshop">Coffee Workshop</option>
                <option value="other">Other</option>
              </Select>
            </FormControl>

            <HStack w="100%" spacing={4}>
              <FormControl isRequired>
                <FormLabel>Date</FormLabel>
                <Input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  min={new Date().toISOString().split('T')[0]}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Time</FormLabel>
                <Input
                  type="time"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                />
              </FormControl>
            </HStack>

            <FormControl>
              <FormLabel>Maximum Attendees</FormLabel>
              <NumberInput
                value={maxAttendees}
                onChange={(value) => setMaxAttendees(parseInt(value))}
                min={2}
                max={50}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>

            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Tell people what to expect..."
                resize="none"
                rows={4}
              />
            </FormControl>

            <HStack spacing={4} alignSelf="flex-end">
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                onClick={handleSubmit}
                isLoading={isSubmitting}
                loadingText="Creating..."
                isDisabled={!title || !selectedPlace || !date || !time}
              >
                Create Event
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EventForm;
