import React from 'react';
import {
  Box,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import ProfileHeader from '../sections/ProfileHeader';
import ProfileBio from '../sections/ProfileBio';
import ProfileTabs from '../sections/ProfileTabs';

const MobileLayout = ({
  user,
  currentUser,
  isOwnProfile,
  posts,
  stats,
  isEditing,
  onEdit,
  onUpdate,
  onImageUpdate,
  onClose,
}) => {
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const contentBg = useColorModeValue('white', 'gray.800');

  return (
    <Box bg={bgColor} minH="calc(100vh - 64px)">
      <VStack spacing={0} align="stretch">
        <Box bg={contentBg}>
          <ProfileHeader
            user={user}
            currentUser={currentUser}
            isOwnProfile={isOwnProfile}
            onImageUpdate={onImageUpdate}
            onEdit={onEdit}
          />
          <Box px={4} pb={4}>
            <ProfileBio
              user={user}
              isOwnProfile={isOwnProfile}
              isEditing={isEditing}
              onEdit={onEdit}
              onUpdate={onUpdate}
              onClose={onClose}
            />
          </Box>
        </Box>

        <Box p={4}>
          <ProfileTabs
            user={user}
            posts={posts}
            isOwnProfile={isOwnProfile}
          />
        </Box>
      </VStack>
    </Box>
  );
};

export default MobileLayout; 