import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  HStack,
  IconButton,
  Box,
  Textarea,
  Button,
  Avatar,
  Image,
  useToast,
} from '@chakra-ui/react';
import { FaCamera, FaTimes } from 'react-icons/fa';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../../contexts/AuthContext';

const QuickPostForm = ({ isOpen, onClose }) => {
  const { user } = useAuth();
  const toast = useToast();
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async () => {
    if (!content.trim() && !image) {
      toast({
        title: 'Please add some content',
        status: 'warning',
        duration: 3000,
      });
      return;
    }

    setIsLoading(true);
    try {
      let photoURL = null;
      if (image) {
        const imageRef = ref(storage, `posts/${user.uid}/${Date.now()}`);
        await uploadBytes(imageRef, image);
        photoURL = await getDownloadURL(imageRef);
      }

      await addDoc(collection(db, 'posts'), {
        content: content.trim(),
        photoURL,
        userId: user.uid,
        userName: user.displayName,
        userPhotoURL: user.photoURL,
        timestamp: serverTimestamp(),
        type: 'post',
        likes: 0,
        comments: 0,
        likedBy: [],
        shares: 0,
      });

      toast({
        title: 'Posted!',
        status: 'success',
        duration: 2000,
      });
      
      setContent('');
      setImage(null);
      setImagePreview(null);
      onClose();
    } catch (error) {
      toast({
        title: 'Error creating post',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      size="md"
      motionPreset="slideInBottom"
    >
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent 
        bg="gray.900" 
        m={4}
        borderRadius="xl"
      >
        <ModalHeader 
          borderBottom="1px" 
          borderColor="gray.700"
          p={4}
        >
          <HStack justify="space-between" align="center">
            <IconButton
              icon={<FaTimes />}
              variant="ghost"
              onClick={onClose}
              aria-label="Close"
              size="sm"
            />
            <Button
              colorScheme="blue"
              size="sm"
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              Post
            </Button>
          </HStack>
        </ModalHeader>
        <ModalBody p={4}>
          <HStack align="start" spacing={3}>
            <Avatar size="sm" src={user?.photoURL} name={user?.displayName} />
            <Box flex={1}>
              <Textarea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                placeholder="What's on your mind?"
                minH="100px"
                border="none"
                _focus={{ border: 'none' }}
                resize="none"
                color="white"
                fontSize="md"
                p={0}
              />
              {imagePreview && (
                <Box position="relative" mt={2}>
                  <Image
                    src={imagePreview}
                    alt="Preview"
                    borderRadius="md"
                    maxH="200px"
                    objectFit="cover"
                    w="100%"
                  />
                  <IconButton
                    icon={<FaTimes />}
                    position="absolute"
                    top={2}
                    right={2}
                    onClick={() => {
                      setImage(null);
                      setImagePreview(null);
                    }}
                    size="sm"
                    colorScheme="blackAlpha"
                    isRound
                  />
                </Box>
              )}
            </Box>
          </HStack>
          <HStack mt={4} justify="space-between">
            <input
              type="file"
              accept="image/*"
              id="quick-image-upload"
              style={{ display: 'none' }}
              onChange={handleImageChange}
            />
            <IconButton
              as="label"
              htmlFor="quick-image-upload"
              icon={<FaCamera />}
              variant="ghost"
              colorScheme="blue"
              cursor="pointer"
              aria-label="Add image"
              size="sm"
            />
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default QuickPostForm; 