import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  VStack,
  Text,
  useToast,
  Spinner,
  Center
} from '@chakra-ui/react';
import PlacesList from './PlacesList';
import PlaceDetailsModal from './PlaceDetailsModal';
import LocationSearch from './LocationSearch';
import PlacesFilters from './PlacesFilters';
import { searchNearbyPlaces } from '../../utils/googlePlaces';

const CoffeePlaces = () => {
  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [location, setLocation] = useState(null);
  const [filter, setFilter] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const toast = useToast();

  const fetchPlaces = useCallback(async () => {
    if (!location?.lat || !location?.lng) return;

    try {
      setLoading(true);
      setError(null);

      const results = await searchNearbyPlaces({
        lat: location.lat,
        lng: location.lng,
        radius: 2000
      });

      console.log('Fetched places:', results);
      setPlaces(results);
      setHasMore(false); // Google Places handles pagination differently
    } catch (err) {
      console.error('Error fetching places:', err);
      setError(err.message || 'Failed to fetch places');
      toast({
        title: 'Error',
        description: 'Failed to fetch coffee places. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [location, toast]);

  useEffect(() => {
    if (location) {
      setPage(1);
      fetchPlaces();
    }
  }, [location, filter, fetchPlaces]);

  const handleLocationSelect = useCallback((newLocation) => {
    console.log('Location selected:', newLocation);
    setLocation(newLocation);
  }, []);

  const handlePlaceSelect = (place) => {
    setSelectedPlace(place);
  };

  const handleCloseModal = () => {
    setSelectedPlace(null);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setPage(1);
  };

  const loadMore = async () => {
    if (!hasMore || loading) return;
    setPage(prev => prev + 1);
  };

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={6} align="stretch">
        <LocationSearch onLocationSelect={handleLocationSelect} />
        <PlacesFilters onFilterChange={handleFilterChange} />
        
        {error ? (
          <Text color="red.500" textAlign="center">{error}</Text>
        ) : loading ? (
          <Center py={8}>
            <Spinner size="xl" />
          </Center>
        ) : places.length > 0 ? (
          <PlacesList
            places={places}
            onPlaceSelect={handlePlaceSelect}
            hasMore={hasMore}
            loadMore={loadMore}
            loading={loading}
            filter={filter}
          />
        ) : location ? (
          <Text textAlign="center">No coffee places found nearby</Text>
        ) : (
          <Center py={8}>
            <Spinner size="xl" />
          </Center>
        )}

        {selectedPlace && (
          <PlaceDetailsModal
            place={selectedPlace}
            isOpen={!!selectedPlace}
            onClose={handleCloseModal}
          />
        )}
      </VStack>
    </Container>
  );
};

export default CoffeePlaces;
