import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Select,
  VStack,
  HStack,
  useColorMode,
  useBreakpointValue,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  useToast,
  Card,
  CardBody,
  Badge,
  IconButton,
  Flex,
  useColorModeValue,
  SimpleGrid,
} from '@chakra-ui/react';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { FaPlus, FaFilter, FaSort, FaSearch } from 'react-icons/fa';
import { Capacitor } from '@capacitor/core';

const Database = () => {
  const [coffees, setCoffees] = useState([]);
  const [filteredCoffees, setFilteredCoffees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({ name: '', origin: '', roast: '' });
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [newCoffee, setNewCoffee] = useState({
    name: '',
    origin: '',
    roast: '',
    rating: 0,
    roaster: '',
    priceUSD100g: 0
  });
  
  const { colorMode } = useColorMode();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const toast = useToast();
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  useEffect(() => {
    fetchCoffees();
  }, []);

  useEffect(() => {
    filterCoffees();
  }, [filters, coffees]);

  const fetchCoffees = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'coffees'));
      const fetchedCoffees = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCoffees(fetchedCoffees);
      setFilteredCoffees(fetchedCoffees);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching coffees:", error);
      setLoading(false);
    }
  };

  const filterCoffees = () => {
    const filtered = coffees.filter(coffee => 
      coffee.name.toLowerCase().includes(filters.name.toLowerCase()) &&
      coffee.origin.toLowerCase().includes(filters.origin.toLowerCase()) &&
      (filters.roast === '' || coffee.roast === filters.roast)
    );
    setFilteredCoffees(filtered);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleAddCoffee = async () => {
    try {
      await addDoc(collection(db, 'coffees'), {
        ...newCoffee,
        timestamp: new Date()
      });
      
      toast({
        title: 'Coffee added',
        status: 'success',
        duration: 3000,
        isClosable: true
      });
      
      setIsAddModalOpen(false);
      setNewCoffee({
        name: '',
        origin: '',
        roast: '',
        rating: 0,
        roaster: '',
        priceUSD100g: 0
      });
      fetchCoffees();
    } catch (error) {
      toast({
        title: 'Error adding coffee',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    }
  };

  const CoffeeCard = ({ coffee }) => (
    <Card 
      bg={bgColor} 
      borderWidth="1px" 
      borderColor={borderColor}
      height="200px"
      width="100%"
    >
      <CardBody>
        <VStack 
          align="stretch" 
          spacing={3} 
          height="100%"
          justify="space-between"
        >
          <Box>
            <Flex justify="space-between" align="center" mb={2}>
              <Heading 
                size="md" 
                noOfLines={1} 
                title={coffee.name}
              >
                {coffee.name}
              </Heading>
              <Badge 
                colorScheme={
                  coffee.roast === 'Light' 
                    ? 'green' 
                    : coffee.roast === 'Medium' 
                      ? 'yellow' 
                      : 'red'
                }
                ml={2}
                flexShrink={0}
              >
                {coffee.roast}
              </Badge>
            </Flex>
            <Text 
              color="gray.500" 
              fontSize="sm" 
              noOfLines={1}
              title={coffee.roaster}
            >
              {coffee.roaster}
            </Text>
            <Text 
              noOfLines={1}
              title={`Origin: ${coffee.origin}`}
            >
              Origin: {coffee.origin}
            </Text>
          </Box>

          <Flex 
            justify="space-between" 
            align="center"
            mt="auto"
          >
            <HStack spacing={2}>
              <Text fontWeight="medium">Rating:</Text>
              <Badge 
                colorScheme={
                  coffee.rating >= 90 ? 'green' : 
                  coffee.rating >= 80 ? 'blue' : 
                  coffee.rating >= 70 ? 'yellow' : 'red'
                }
              >
                {coffee.rating}/100
              </Badge>
            </HStack>
            <Text 
              fontWeight="bold"
              fontSize="lg"
            >
              ${coffee.priceUSD100g.toFixed(2)}
              <Text as="span" fontSize="sm" color="gray.500">/100g</Text>
            </Text>
          </Flex>
        </VStack>
      </CardBody>
    </Card>
  );

  if (loading) {
    return <Box textAlign="center" mt={8}>Loading...</Box>;
  }

  return (
    <Box maxW="6xl" mx="auto" mt={8} px={4}>
      <VStack spacing={6} align="stretch">
        <Flex justify="space-between" align="center">
          <Heading>Coffee Database</Heading>
          <Button
            leftIcon={<FaPlus />}
            colorScheme="blue"
            onClick={() => setIsAddModalOpen(true)}
          >
            Add Coffee
          </Button>
        </Flex>

        <VStack spacing={4} align="stretch">
          <HStack spacing={4} flexWrap="wrap">
            <Input
              placeholder="Filter by name"
              name="name"
              value={filters.name}
              onChange={handleFilterChange}
              flex="1"
              minW={{ base: "100%", md: "200px" }}
            />
            <Input
              placeholder="Filter by origin"
              name="origin"
              value={filters.origin}
              onChange={handleFilterChange}
              flex="1"
              minW={{ base: "100%", md: "200px" }}
            />
            <Select
              placeholder="Select roast"
              name="roast"
              value={filters.roast}
              onChange={handleFilterChange}
              flex="1"
              minW={{ base: "100%", md: "200px" }}
            >
              <option value="Light">Light</option>
              <option value="Medium">Medium</option>
              <option value="Dark">Dark</option>
            </Select>
          </HStack>
        </VStack>

        {isMobile ? (
          <SimpleGrid 
            columns={{ base: 1, sm: 2 }} 
            spacing={4}
            width="100%"
          >
            {filteredCoffees.map(coffee => (
              <CoffeeCard key={coffee.id} coffee={coffee} />
            ))}
          </SimpleGrid>
        ) : (
          <Box overflowX="auto">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Origin</Th>
                  <Th>Roast</Th>
                  <Th>Rating</Th>
                  <Th>Roaster</Th>
                  <Th>Price (per 100g)</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredCoffees.map(coffee => (
                  <Tr key={coffee.id}>
                    <Td>{coffee.name}</Td>
                    <Td>{coffee.origin}</Td>
                    <Td>{coffee.roast}</Td>
                    <Td>{coffee.rating}/100</Td>
                    <Td>{coffee.roaster}</Td>
                    <Td>${coffee.priceUSD100g.toFixed(2)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
      </VStack>

      <Modal isOpen={isAddModalOpen} onClose={() => setIsAddModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Coffee</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  value={newCoffee.name}
                  onChange={(e) => setNewCoffee(prev => ({ ...prev, name: e.target.value }))}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Origin</FormLabel>
                <Input
                  value={newCoffee.origin}
                  onChange={(e) => setNewCoffee(prev => ({ ...prev, origin: e.target.value }))}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Roast Level</FormLabel>
                <Select
                  value={newCoffee.roast}
                  onChange={(e) => setNewCoffee(prev => ({ ...prev, roast: e.target.value }))}
                >
                  <option value="Light">Light</option>
                  <option value="Medium">Medium</option>
                  <option value="Dark">Dark</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Rating (0-100)</FormLabel>
                <NumberInput
                  max={100}
                  min={0}
                  value={newCoffee.rating}
                  onChange={(value) => setNewCoffee(prev => ({ ...prev, rating: parseInt(value) }))}
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>

              <FormControl>
                <FormLabel>Roaster</FormLabel>
                <Input
                  value={newCoffee.roaster}
                  onChange={(e) => setNewCoffee(prev => ({ ...prev, roaster: e.target.value }))}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Price (USD per 100g)</FormLabel>
                <NumberInput
                  min={0}
                  value={newCoffee.priceUSD100g}
                  onChange={(value) => setNewCoffee(prev => ({ ...prev, priceUSD100g: parseFloat(value) }))}
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>

              <Button colorScheme="blue" onClick={handleAddCoffee} width="full">
                Add Coffee
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Database;
