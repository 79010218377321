import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  VStack,
  Input,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useColorModeValue,
  HStack,
  Box,
  Text,
  Textarea,
  IconButton,
  useBreakpointValue,
  Flex,
  Image,
  Grid,
  CloseButton,
  useToast,
  FormControl,
  FormLabel,
  Switch,
  Select,
  Tag,
  TagLabel,
  TagCloseButton,
  Input as ChakraInput,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Portal,
  SlideFade,
} from '@chakra-ui/react';
import { 
  FaImage, 
  FaTags,
  FaTimes,
  FaPlus,
  FaStar,
  FaRegStar,
  FaChevronDown,
  FaArrowLeft,
} from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { useDropzone } from 'react-dropzone';
import { useAuth } from '../../contexts/AuthContext';

const MotionBox = motion(Box);
const MotionModalContent = motion(ModalContent);

// Group definitions for form fields
const FIELD_GROUPS = {
  basic: {
    title: 'Basic Information',
    icon: FaPlus,
    fields: ['name', 'title', 'description', 'content']
  },
  details: {
    title: 'Details',
    icon: FaImage,
    fields: ['price', 'date', 'location', 'category']
  },
  media: {
    title: 'Media & Files',
    icon: FaImage,
    fields: ['photo', 'images', 'files']
  },
  specs: {
    title: 'Specifications',
    icon: FaTags,
    fields: ['specifications', 'features', 'dimensions']
  },
  additional: {
    title: 'Additional Information',
    icon: FaPlus,
    fields: ['notes', 'tags', 'rating']
  }
};

const RatingInput = ({ value, onChange, max = 5 }) => {
  return (
    <MotionBox
      as={HStack}
      spacing={1}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      {[...Array(max)].map((_, index) => (
        <IconButton
          key={index}
          icon={index < value ? <FaStar /> : <FaRegStar />}
          variant="ghost"
          color={index < value ? "yellow.400" : "gray.300"}
          onClick={() => onChange(index + 1)}
          aria-label={`Rate ${index + 1} stars`}
          _hover={{ transform: 'scale(1.2)' }}
          transition="all 0.2s"
        />
      ))}
    </MotionBox>
  );
};

const TagsInput = ({ value = [], onChange }) => {
  const [inputValue, setInputValue] = useState('');
  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue.trim()) {
      e.preventDefault();
      const newTags = [...value, inputValue.trim()];
      onChange(newTags);
      setInputValue('');
    }
  };

  const removeTag = (tagToRemove) => {
    const newTags = value.filter(tag => tag !== tagToRemove);
    onChange(newTags);
  };

  return (
    <Box>
      <Input
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Type and press Enter to add tags"
        mb={2}
      />
      <Flex wrap="wrap" gap={2}>
        <AnimatePresence>
          {value.map((tag, index) => (
            <MotionBox
              key={tag}
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0, opacity: 0 }}
            >
              <Tag
                size="md"
                borderRadius="full"
                variant="solid"
                colorScheme="blue"
              >
                <TagLabel>{tag}</TagLabel>
                <TagCloseButton onClick={() => removeTag(tag)} />
              </Tag>
            </MotionBox>
          ))}
        </AnimatePresence>
      </Flex>
    </Box>
  );
};

const UnifiedPostForm = ({
  title,
  fields = [],
  onSubmit,
  initialData = {},
  isOpen,
  onClose,
  submitLabel = "Submit"
}) => {
  const { user } = useAuth();
  const toast = useToast();
  const [formData, setFormData] = useState(() => ({
    name: '',
    roaster: '',
    origin: '',
    roastLevel: '',
    description: '',
    tasting_notes: [],
    rating: 0,
    isFavorite: false,
    price: '',
    purchaseDate: '',
    weight: '',
    processingMethod: '',
    ...initialData
  }));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeGroup, setActiveGroup] = useState(null);
  const modalRef = useRef(null);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const modalSize = useBreakpointValue({ base: 'full', md: 'xl' });

  // Group fields by their categories
  const groupedFields = fields.reduce((acc, field) => {
    const group = Object.entries(FIELD_GROUPS).find(([_, groupData]) => 
      groupData.fields.includes(field.name)
    )?.[0] || 'additional';
    
    if (!acc[group]) acc[group] = [];
    acc[group].push(field);
    return acc;
  }, {});

  const handleChange = (name, value) => {
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.gif']
    },
    maxFiles: 4,
    onDrop: (acceptedFiles) => {
      handleChange('photo', acceptedFiles[0]);
    }
  });

  const handleSubmit = async (e) => {
    e?.preventDefault();
    if (!user) {
      toast({
        title: "Authentication required",
        description: "Please sign in to continue.",
        status: "error",
        duration: 3000,
      });
      return;
    }

    setIsSubmitting(true);
    try {
      await onSubmit(formData);
      toast({
        title: "Success!",
        status: "success",
        duration: 2000,
      });
      onClose();
    } catch (error) {
      console.error('Error submitting form:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to submit form. Please try again.",
        status: "error",
        duration: 3000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderField = (field) => {
    const { name, label, type, required, options, placeholder, min, max, accept, description } = field;
    
    const commonProps = {
      value: formData[name] || '',
      onChange: (e) => handleChange(name, e.target.value),
      placeholder,
      required,
      _focus: { 
        transform: 'scale(1.01)',
        transition: 'all 0.2s'
      }
    };
    
    switch (type) {
      case 'file':
        return (
          <Box>
            <Input
              type="file"
              accept={accept}
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  handleChange(name, file);
                  // Create a preview URL for the image
                  const previewUrl = URL.createObjectURL(file);
                  handleChange('photoPreview', previewUrl);
                }
              }}
              display="none"
              id={`file-${name}`}
            />
            <Box
              border="2px dashed"
              borderColor={borderColor}
              borderRadius="md"
              p={4}
              textAlign="center"
              cursor="pointer"
              onClick={() => document.getElementById(`file-${name}`).click()}
              position="relative"
              transition="all 0.2s"
              _hover={{ borderColor: 'blue.500' }}
            >
              {formData.photoPreview ? (
                <Box position="relative">
                  <Image
                    src={formData.photoPreview}
                    alt="Preview"
                    maxH="200px"
                    mx="auto"
                    borderRadius="md"
                  />
                  <IconButton
                    icon={<FaTimes />}
                    position="absolute"
                    top={2}
                    right={2}
                    size="sm"
                    colorScheme="red"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleChange(name, null);
                      handleChange('photoPreview', null);
                    }}
                    aria-label="Remove image"
                  />
                </Box>
              ) : formData.photoURL ? (
                <Box position="relative">
                  <Image
                    src={formData.photoURL}
                    alt="Current photo"
                    maxH="200px"
                    mx="auto"
                    borderRadius="md"
                  />
                  <IconButton
                    icon={<FaTimes />}
                    position="absolute"
                    top={2}
                    right={2}
                    size="sm"
                    colorScheme="red"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleChange('photoURL', null);
                    }}
                    aria-label="Remove image"
                  />
                </Box>
              ) : (
                <VStack spacing={2}>
                  <Icon as={FaImage} boxSize={8} color="gray.400" />
                  <Text>Click to upload a photo</Text>
                  {description && (
                    <Text fontSize="sm" color="gray.500">
                      {description}
                    </Text>
                  )}
                </VStack>
              )}
            </Box>
          </Box>
        );
      
      case 'text':
        return <Input {...commonProps} />;
      
      case 'number':
        return (
          <NumberInput
            value={formData[name] || ''}
            onChange={(value) => handleChange(name, value)}
            min={min}
            max={max}
          >
            <NumberInputField placeholder={placeholder} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        );
      
      case 'textarea':
        return <Textarea {...commonProps} />;
      
      case 'select':
        return (
          <Select
            {...commonProps}
            value={formData[name] || ''}
            onChange={(e) => handleChange(name, e.target.value)}
          >
            <option value="">Select {label}</option>
            {options.map((option, index) => (
              <option 
                key={index} 
                value={typeof option === 'object' ? option.value : option}
              >
                {typeof option === 'object' ? option.label : option}
              </option>
            ))}
          </Select>
        );
      
      case 'switch':
        return (
          <Switch
            isChecked={formData[name] || false}
            onChange={(e) => handleChange(name, e.target.checked)}
          />
        );
      
      case 'date':
        return <Input {...commonProps} type="date" />;
      
      case 'tags':
        return (
          <TagsInput
            value={formData[name] || []}
            onChange={(value) => handleChange(name, value)}
          />
        );
      
      case 'rating':
        return (
          <RatingInput
            value={formData[name] || 0}
            onChange={(value) => handleChange(name, value)}
            max={max || 5}
          />
        );
      
      default:
        return null;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={modalSize}>
      <ModalOverlay />
      <ModalContent
        ref={modalRef}
        bg={bgColor}
        borderRadius="xl"
        overflow="hidden"
      >
        <ModalHeader>
          <Flex justify="space-between" align="center">
            <Text>{title}</Text>
            <IconButton
              icon={formData.isFavorite ? <FaStar /> : <FaRegStar />}
              aria-label="Toggle favorite"
              variant="ghost"
              size="lg"
              color={formData.isFavorite ? "yellow.400" : "gray.400"}
              onClick={() => handleChange('isFavorite', !formData.isFavorite)}
              _hover={{
                color: formData.isFavorite ? "yellow.500" : "yellow.400",
                transform: "scale(1.1)"
              }}
              transition="all 0.2s"
            />
          </Flex>
        </ModalHeader>

        <ModalBody>
          <VStack spacing={6} as="form" onSubmit={handleSubmit}>
            <FormControl isRequired>
              <FormLabel>Coffee Name</FormLabel>
              <Input
                name="name"
                value={formData.name || ''}
                onChange={(e) => handleChange('name', e.target.value)}
                placeholder="Enter coffee name"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Roaster</FormLabel>
              <Input
                name="roaster"
                value={formData.roaster || ''}
                onChange={(e) => handleChange('roaster', e.target.value)}
                placeholder="Enter roaster name"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Origin</FormLabel>
              <Input
                name="origin"
                value={formData.origin || ''}
                onChange={(e) => handleChange('origin', e.target.value)}
                placeholder="Country or region of origin"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Roast Level</FormLabel>
              <Select
                name="roastLevel"
                value={formData.roastLevel || ''}
                onChange={(e) => handleChange('roastLevel', e.target.value)}
              >
                <option value="">Select roast level</option>
                <option value="Light">Light</option>
                <option value="Medium-Light">Medium-Light</option>
                <option value="Medium">Medium</option>
                <option value="Medium-Dark">Medium-Dark</option>
                <option value="Dark">Dark</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Processing Method</FormLabel>
              <Select
                name="processingMethod"
                value={formData.processingMethod || ''}
                onChange={(e) => handleChange('processingMethod', e.target.value)}
              >
                <option value="">Select processing method</option>
                <option value="Washed">Washed</option>
                <option value="Natural">Natural</option>
                <option value="Honey">Honey</option>
                <option value="Anaerobic">Anaerobic</option>
                <option value="Other">Other</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Price</FormLabel>
              <Input
                name="price"
                type="number"
                value={formData.price || ''}
                onChange={(e) => handleChange('price', e.target.value)}
                placeholder="Enter price"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Weight (g)</FormLabel>
              <Input
                name="weight"
                type="number"
                value={formData.weight || ''}
                onChange={(e) => handleChange('weight', e.target.value)}
                placeholder="Enter weight in grams"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Purchase Date</FormLabel>
              <Input
                name="purchaseDate"
                type="date"
                value={formData.purchaseDate || ''}
                onChange={(e) => handleChange('purchaseDate', e.target.value)}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                name="description"
                value={formData.description || ''}
                onChange={(e) => handleChange('description', e.target.value)}
                placeholder="Enter coffee description"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Tasting Notes</FormLabel>
              <TagsInput
                value={formData.tasting_notes || []}
                onChange={(value) => handleChange('tasting_notes', value)}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Rating</FormLabel>
              <RatingInput
                value={formData.rating || 0}
                onChange={(value) => handleChange('rating', value)}
              />
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isLoading={isSubmitting}
          >
            {submitLabel}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UnifiedPostForm;