import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { collection, query, where, orderBy, getDocs, addDoc, serverTimestamp, doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../firebase';
import { useToast } from '@chakra-ui/react';

export const useComments = (postId) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { data: comments = [], isLoading: isLoadingComments } = useQuery({
    queryKey: ['comments', postId],
    queryFn: async () => {
      if (!postId) return [];
      
      const commentsRef = collection(db, `posts/${postId}/comments`);
      const q = query(commentsRef, orderBy('timestamp', 'desc'));
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate()
      }));
    },
    enabled: !!postId
  });

  const addCommentMutation = useMutation({
    mutationFn: async (commentData) => {
      const { text, user } = commentData;
      
      if (!user) {
        throw new Error('User must be logged in to comment');
      }

      const newComment = {
        text,
        userId: user.uid,
        username: user.displayName || 'Anonymous',
        userPhotoURL: user.photoURL,
        timestamp: serverTimestamp(),
        likedBy: [],
        likes: 0
      };
      
      const docRef = await addDoc(collection(db, `posts/${postId}/comments`), newComment);
      
      queryClient.setQueryData(['comments', postId], (old = []) => {
        return [{
          id: docRef.id,
          ...newComment,
          timestamp: new Date()
        }, ...old];
      });

      return { id: docRef.id, ...newComment };
    },
    onSuccess: () => {
      toast({
        title: "Comment added",
        status: "success",
        duration: 2000,
      });
    },
    onError: (error) => {
      queryClient.invalidateQueries(['comments', postId]);
      toast({
        title: "Error adding comment",
        description: error.message,
        status: "error",
        duration: 3000,
      });
    }
  });

  const handleAddComment = async (text, user) => {
    if (!user) {
      toast({
        title: "Please login to comment",
        status: "warning",
        duration: 3000,
      });
      return;
    }

    try {
      await addCommentMutation.mutateAsync({ text, user });
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const likeCommentMutation = useMutation({
    mutationFn: async ({ commentId, userId }) => {
      const commentRef = doc(db, `posts/${postId}/comments/${commentId}`);
      const comment = comments.find(c => c.id === commentId);
      const hasLiked = comment.likedBy?.includes(userId);

      await updateDoc(commentRef, {
        likedBy: hasLiked ? arrayRemove(userId) : arrayUnion(userId),
        likes: hasLiked ? comment.likes - 1 : (comment.likes || 0) + 1
      });

      return { commentId, hasLiked };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['comments', postId]);
    }
  });

  const handleLikeComment = async (commentId, user) => {
    if (!user) {
      toast({
        title: "Please login to like comments",
        status: "warning",
        duration: 3000,
      });
      return;
    }

    try {
      await likeCommentMutation.mutateAsync({ commentId, userId: user.uid });
    } catch (error) {
      console.error('Error liking comment:', error);
      toast({
        title: "Error updating like",
        status: "error",
        duration: 2000,
      });
    }
  };

  return {
    comments: comments || [],
    isLoadingComments,
    handleAddComment,
    handleLikeComment,
    isAddingComment: addCommentMutation.isLoading,
    isLikingComment: likeCommentMutation.isLoading
  };
}; 