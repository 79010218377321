import React, { memo } from 'react';
import { HStack, IconButton, Text } from '@chakra-ui/react';
import { FaHeart, FaRegHeart, FaComment, FaBookmark, FaRegBookmark } from 'react-icons/fa';
import { motion } from 'framer-motion';

const PostActions = memo(({ 
  hasLiked, 
  likesCount, 
  commentsCount, 
  isBookmarked, 
  onLike, 
  onComment, 
  onBookmark, 
  themeColors 
}) => {
  const MotionIconButton = motion(IconButton);

  return (
    <HStack spacing={4} pt={2}>
      <HStack>
        <MotionIconButton
          icon={hasLiked ? <FaHeart /> : <FaRegHeart />}
          variant="ghost"
          size="sm"
          color={hasLiked ? "red.400" : themeColors.subtext}
          onClick={onLike}
          _hover={{ bg: themeColors.hover }}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          aria-label={hasLiked ? "Unlike post" : "Like post"}
        />
        <Text color={themeColors.subtext}>{likesCount}</Text>
      </HStack>

      <HStack>
        <MotionIconButton
          icon={<FaComment />}
          variant="ghost"
          size="sm"
          color={themeColors.subtext}
          onClick={onComment}
          _hover={{ bg: themeColors.hover }}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          aria-label="Comment on post"
        />
        <Text color={themeColors.subtext}>{commentsCount}</Text>
      </HStack>

      <MotionIconButton
        icon={isBookmarked ? <FaBookmark /> : <FaRegBookmark />}
        variant="ghost"
        size="sm"
        color={isBookmarked ? "purple.400" : themeColors.subtext}
        onClick={onBookmark}
        _hover={{ bg: themeColors.hover }}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        ml="auto"
        aria-label={isBookmarked ? "Remove bookmark" : "Bookmark post"}
      />
    </HStack>
  );
});

PostActions.displayName = 'PostActions';
export default PostActions; 