import React from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useQuery } from '@tanstack/react-query';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import ProfileSkeleton from './shared/ProfileSkeleton';
import DesktopLayout from './layouts/DesktopLayout';
import MobileLayout from './layouts/MobileLayout';
import { useProfileData } from '../../hooks/useProfileData';

const Profile = () => {
  const { userId } = useParams();
  const { user: currentUser } = useAuth();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isOwnProfile = !userId || userId === currentUser?.uid;

  const { data: profileUser, isLoading: isUserLoading } = useQuery({
    queryKey: ['user', userId || currentUser?.uid],
    queryFn: async () => {
      const docRef = doc(db, 'users', userId || currentUser?.uid);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) throw new Error('User not found');
      return { id: docSnap.id, ...docSnap.data() };
    },
    enabled: !!userId || !!currentUser?.uid,
  });

  const {
    posts,
    stats,
    isEditing,
    setIsEditing,
    handleProfileUpdate,
    handleImageUpdate,
    isLoading: isDataLoading,
  } = useProfileData(profileUser?.id);

  // Calculate stats
  const profileStats = {
    posts: posts?.filter(p => p.type === 'post').length || 0,
    reviews: posts?.filter(p => p.type === 'review').length || 0,
    following: profileUser?.following?.length || 0,
  };

  if (isUserLoading || isDataLoading) {
    return <ProfileSkeleton />;
  }

  const LayoutComponent = isMobile ? MobileLayout : DesktopLayout;

  return (
    <LayoutComponent
      user={profileUser}
      currentUser={currentUser}
      isOwnProfile={isOwnProfile}
      posts={posts}
      stats={profileStats} // Pass the calculated stats
      isEditing={isEditing}
      onEdit={() => setIsEditing(true)}
      onUpdate={handleProfileUpdate}
      onImageUpdate={handleImageUpdate}
      onClose={() => setIsEditing(false)}
    />
  );
};

export default Profile; 