import React, { useState, useEffect } from 'react';
import { 
  Box, 
  VStack, 
  useColorModeValue, 
  Flex, 
  Show, 
  Spinner, 
  Center,
  Container,
} from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import StoriesBar from '../components/Stories/StoriesBar';
import TrendingSection from '../components/TrendingSection';
import CoffeePost from '../components/CoffeePost';
import { collection, query, orderBy, limit, getDocs, startAfter } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';

const POSTS_PER_PAGE = 10;

const Home = () => {
  const [posts, setPosts] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();

  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.600', 'gray.400');

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchPosts = async (isInitial = true) => {
    try {
      let baseQuery = collection(db, 'posts');
      let constraints = [
        orderBy('timestamp', 'desc'),
        limit(POSTS_PER_PAGE)
      ];

      if (!isInitial && lastDoc) {
        constraints.push(startAfter(lastDoc));
      }

      const q = query(baseQuery, ...constraints);
      const snapshot = await getDocs(q);
      const fetchedPosts = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      if (isInitial) {
        setPosts(fetchedPosts);
      } else {
        setPosts(prev => [...prev, ...fetchedPosts]);
      }

      setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      setHasMore(snapshot.docs.length === POSTS_PER_PAGE);
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []); 

  const fetchMorePosts = () => {
    if (!hasMore) return;
    fetchPosts(false);
  };

  return (
    <Container maxW="container.xl" px={{ base: 2, md: 4 }}>
      <Flex direction={{ base: "column", lg: "row" }} gap={6}>
        <Box width={{ base: "100%", lg: "75%" }}>
          <StoriesBar />
          
          {isLoading ? (
            <VStack spacing={4} mt={4}>
              {[...Array(3)].map((_, i) => (
                <Box 
                  key={i} 
                  w="full" 
                  h="200px" 
                  bg={bgColor}
                  borderRadius="xl"
                  animation="pulse 2s infinite"
                />
              ))}
            </VStack>
          ) : (
            <Box mt={4}>
              <InfiniteScroll
                dataLength={posts.length}
                next={fetchMorePosts}
                hasMore={hasMore}
                loader={
                  <Center py={4}>
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="lg"
                    />
                  </Center>
                }
              >
                {posts.map(post => (
                  <CoffeePost key={post.id} post={post} />
                ))}
              </InfiniteScroll>
            </Box>
          )}
        </Box>

        <Show above="lg">
          <Box width="25%" position="sticky" top="90px" alignSelf="start">
            <TrendingSection />
          </Box>
        </Show>
      </Flex>
    </Container>
  );
};

export default Home;
