import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  VStack,
  Input,
  Button,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { FaMugHot } from 'react-icons/fa';

const QuickAddModal = ({ isOpen, onClose, onAdd }) => {
  const [name, setName] = useState('Coffee');
  const [location, setLocation] = useState('Café');

  const handleFocus = (e) => {
    e.target.select(); // Select all text when focused
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm">
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent>
        <ModalHeader>Quick Add</ModalHeader>
        <ModalBody pb={6}>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Coffee Name</FormLabel>
              <Input
                placeholder="e.g., Flat White"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onFocus={handleFocus}
                size="lg"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Location</FormLabel>
              <Input
                placeholder="e.g., Starbucks"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                onFocus={handleFocus}
                size="lg"
              />
            </FormControl>

            <Button
              leftIcon={<FaMugHot />}
              colorScheme="purple"
              size="lg"
              width="100%"
              onClick={() => {
                onAdd({ name, location });
                setName('Coffee');
                setLocation('Café');
              }}
              isDisabled={!name || name.trim() === ''}
            >
              Add Drink
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default QuickAddModal; 