import React from 'react';
import UnifiedPostForm from './UnifiedPostForm';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { useToast } from '@chakra-ui/react';

const PostForm = ({ isOpen, onClose }) => {
  const { user } = useAuth();
  const toast = useToast();

  const fields = [
    { 
      name: 'content', 
      label: 'Content', 
      type: 'textarea', 
      placeholder: "What's on your mind?",
      required: true 
    },
    { 
      name: 'photo', 
      label: 'Photo', 
      type: 'file',
      accept: 'image/*',
      description: 'Add a photo to your post (optional)'
    }
  ];

  const handleSubmit = async (data) => {
    try {
      await addDoc(collection(db, 'posts'), {
        ...data,
        userId: user.uid,
        userName: user.displayName,
        userPhotoURL: user.photoURL,
        timestamp: serverTimestamp(),
        type: 'post',
        likes: 0,
        comments: 0,
        likedBy: [],
        shares: 0,
        visibility: 'public' // default visibility
      });

      toast({
        title: 'Post created successfully',
        status: 'success',
        duration: 3000,
      });
      onClose();
    } catch (error) {
      console.error('Error creating post:', error);
      toast({
        title: 'Error creating post',
        description: error.message,
        status: 'error',
        duration: 3000,
      });
    }
  };

  return (
    <UnifiedPostForm
      title="Create Post"
      fields={fields}
      onSubmit={handleSubmit}
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton={true} // Add this prop to show close button
      submitButtonText="Post" // Optional: customize submit button text
    />
  );
};

export default PostForm;
