import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Heading, 
  Text, 
  VStack, 
  HStack, 
  Input, 
  Button, 
  Tag, 
  useColorModeValue,
  Container,
  useToast,
  Avatar,
  Icon,
  Flex,
  Badge,
  Divider,
  Tooltip,
  Skeleton
} from '@chakra-ui/react';
import { collection, query, orderBy, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { FaPlus, FaSearch, FaClock } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { formatTimeAgo } from '../utils/timeAgo';

const MotionBox = motion(Box);

const AllTopics = () => {
  const { user } = useAuth();
  const [topics, setTopics] = useState([]);
  const [newTopic, setNewTopic] = useState({ title: '', tags: '' });
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('');
  const toast = useToast();

  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  useEffect(() => {
    fetchTopics();
  }, []);

  const fetchTopics = async () => {
    try {
      const q = query(collection(db, 'topics'), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const fetchedTopics = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setTopics(fetchedTopics);
    } catch (error) {
      console.error('Error fetching topics:', error);
      toast({
        title: 'Error fetching topics',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitTopic = async (e) => {
    e.preventDefault();
    if (!user) {
      toast({
        title: 'Login Required',
        description: 'Please login to create a topic',
        status: 'warning',
        duration: 3000,
      });
      return;
    }

    if (newTopic.title && newTopic.tags) {
      try {
        const topicData = {
          ...newTopic,
          tags: newTopic.tags.split(',').map(tag => tag.trim()),
          userId: user.uid,
          userName: user.displayName || user.email,
          userPhotoURL: user.photoURL,
          timestamp: serverTimestamp(),
          likes: 0,
          comments: 0
        };
        await addDoc(collection(db, 'topics'), topicData);
        setNewTopic({ title: '', tags: '' });
        fetchTopics();
        toast({
          title: 'Topic created successfully',
          status: 'success',
          duration: 2000,
        });
      } catch (error) {
        toast({
          title: 'Error creating topic',
          status: 'error',
          duration: 3000,
        });
      }
    }
  };

  const filteredTopics = topics.filter(topic => 
    topic.title.toLowerCase().includes(filter.toLowerCase()) ||
    topic.tags.some(tag => tag.toLowerCase().includes(filter.toLowerCase()))
  );

  if (loading) {
    return (
      <Container maxW="4xl" py={8}>
        <VStack spacing={6}>
          {[...Array(3)].map((_, i) => (
            <Skeleton key={i} height="200px" width="100%" borderRadius="xl" />
          ))}
        </VStack>
      </Container>
    );
  }

  return (
    <Container maxW="4xl" py={8}>
      <VStack spacing={8} align="stretch">
        <Flex justify="space-between" align="center">
          <Heading size="lg">Coffee Topics</Heading>
          {user ? (
            <Button
              leftIcon={<Icon as={FaPlus} />}
              colorScheme="blue"
              onClick={() => setNewTopic({ title: '', tags: '' })}
            >
              New Topic
            </Button>
          ) : (
            <Button
              colorScheme="blue"
              variant="outline"
              onClick={() => {
                toast({
                  title: "Login Required",
                  description: "Please login to create topics",
                  status: "info",
                  duration: 3000,
                  isClosable: true,
                });
              }}
            >
              Login to Create
            </Button>
          )}
        </Flex>

        <Box position="relative">
          <Input
            placeholder="Search topics..."
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            pl={10}
          />
          <Icon
            as={FaSearch}
            position="absolute"
            left={3}
            top="50%"
            transform="translateY(-50%)"
            color="gray.500"
          />
        </Box>

        <VStack spacing={4} align="stretch">
          {filteredTopics.map(topic => (
            <MotionBox
              key={topic.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              p={6}
              borderWidth="1px"
              borderRadius="xl"
              bg={bgColor}
              borderColor={borderColor}
              shadow="sm"
            >
              <VStack align="stretch" spacing={4}>
                <Flex justify="space-between" align="center">
                  <HStack>
                    <Avatar size="sm" src={topic.userPhotoURL} name={topic.userName} />
                    <VStack align="start" spacing={0}>
                      <Text fontWeight="bold">{topic.userName}</Text>
                      <Text fontSize="xs" color="gray.500">
                        {formatTimeAgo(topic.timestamp?.toDate())}
                      </Text>
                    </VStack>
                  </HStack>
                  <Badge colorScheme="green">Topic</Badge>
                </Flex>

                <Box>
                  <Heading size="md" mb={2}>{topic.title}</Heading>
                  <HStack spacing={2} wrap="wrap">
                    {topic.tags.map((tag, index) => (
                      <Tag key={index} colorScheme="blue" size="sm">
                        {tag}
                      </Tag>
                    ))}
                  </HStack>
                </Box>

                <Divider />

                <HStack justify="space-between" color="gray.500" fontSize="sm">
                  <HStack>
                    <Icon as={FaClock} />
                    <Text>{formatTimeAgo(topic.timestamp?.toDate())}</Text>
                  </HStack>
                  <Text>{topic.comments || 0} comments</Text>
                </HStack>
              </VStack>
            </MotionBox>
          ))}
        </VStack>
      </VStack>
    </Container>
  );
};

export default AllTopics;
