import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Image,
  useColorModeValue,
  UnorderedList,
  ListItem,
  Link,
} from '@chakra-ui/react';

const Terms = () => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <Container maxW="container.md" py={8}>
      <VStack spacing={8} align="stretch">
        <Box 
          bg={bgColor} 
          p={8} 
          borderRadius="xl" 
          boxShadow="sm"
        >
          <VStack spacing={6} align="stretch">
            <Image
              src="/beanscenelogo.png"
              alt="BeanScene Logo"
              height="60px"
              objectFit="contain"
              mx="auto"
              mb={4}
            />
            
            <Heading size="lg" textAlign="center">Terms of Use</Heading>
            
            <Text color={textColor}>
              Last updated: {new Date().toLocaleDateString()}
            </Text>

            <Heading size="md">1. Acceptance of Terms</Heading>
            <Text color={textColor}>
              By accessing and using BeanScene, you accept and agree to be bound by the terms and 
              provisions of this agreement. If you do not agree to these terms, do not use BeanScene.
            </Text>

            <Heading size="md">2. User Account</Heading>
            <UnorderedList spacing={2} color={textColor}>
              <ListItem>You must be 13 years or older to use this service</ListItem>
              <ListItem>You are responsible for maintaining the security of your account</ListItem>
              <ListItem>You are responsible for all activities that occur under your account</ListItem>
              <ListItem>You must not abuse, harass, threaten, or impersonate others</ListItem>
            </UnorderedList>

            <Heading size="md">3. Content Guidelines</Heading>
            <Text color={textColor}>
              Users are responsible for all content they post on BeanScene. Content must:
            </Text>
            <UnorderedList spacing={2} color={textColor}>
              <ListItem>Be accurate and truthful</ListItem>
              <ListItem>Respect intellectual property rights</ListItem>
              <ListItem>Not be harmful, offensive, or inappropriate</ListItem>
              <ListItem>Not contain spam or commercial solicitation</ListItem>
              <ListItem>Comply with our Community Guidelines</ListItem>
            </UnorderedList>

            <Heading size="md">4. Intellectual Property</Heading>
            <Text color={textColor}>
              BeanScene and its original content, features, and functionality are owned by 
              BeanScene and are protected by international copyright, trademark, patent, trade 
              secret, and other intellectual property laws.
            </Text>

            <Heading size="md">5. User Content License</Heading>
            <Text color={textColor}>
              By posting content on BeanScene, you grant us a non-exclusive, worldwide, royalty-free 
              license to use, modify, publicly perform, publicly display, reproduce, and distribute 
              such content on and through BeanScene.
            </Text>

            <Heading size="md">6. Termination</Heading>
            <Text color={textColor}>
              We may terminate or suspend your account and bar access to BeanScene immediately, 
              without prior notice or liability, under our sole discretion, for any reason 
              whatsoever and without limitation, including but not limited to a breach of the Terms.
            </Text>

            <Heading size="md">7. Limitation of Liability</Heading>
            <Text color={textColor}>
              In no event shall BeanScene, nor its directors, employees, partners, agents, suppliers, 
              or affiliates, be liable for any indirect, incidental, special, consequential or 
              punitive damages, including without limitation, loss of profits, data, use, goodwill, 
              or other intangible losses.
            </Text>

            <Heading size="md">8. Changes to Terms</Heading>
            <Text color={textColor}>
              We reserve the right to modify or replace these Terms at any time. We will provide 
              notice of any changes by posting the new Terms on this page. Your continued use of 
              BeanScene after any changes constitutes acceptance of the new Terms.
            </Text>

            <Heading size="md">9. Contact</Heading>
            <Text color={textColor}>
              If you have any questions about these Terms, please contact us at:{' '}
              <Link href="mailto:legal@beanscene.de" color="blue.500">
                legal@beanscene.de
              </Link>
            </Text>

            <Text fontSize="sm" color={textColor} fontStyle="italic">
              By using BeanScene, you acknowledge that you have read, understood, and agree to 
              be bound by these Terms of Use.
            </Text>
          </VStack>
        </Box>
      </VStack>
    </Container>
  );
};

export default Terms; 