import React, { memo } from 'react';
import {
  VStack,
  SimpleGrid,
  Box,
  Text,
  Button,
  Spinner,
  Center,
  useBreakpointValue,
  useColorModeValue,
  HStack,
  Badge,
  Icon,
  Image,
  AspectRatio,
} from '@chakra-ui/react';
import { 
  FaCoffee, 
  FaMapMarkerAlt, 
  FaStar, 
  FaDollarSign, 
  FaClock, 
  FaGoogle,
  FaUsers 
} from 'react-icons/fa';
import StarRating from '../shared/StarRating';

const PlacesList = memo(({ 
  places = [], 
  onPlaceSelect, 
  hasMore, 
  loadMore, 
  loading,
  filter,
}) => {
  const columns = useBreakpointValue({ base: 1, md: 2, lg: 3 });
  const bgColor = useColorModeValue('gray.900', 'gray.900');
  const borderColor = useColorModeValue('gray.700', 'gray.700');
  const textColor = useColorModeValue('gray.100', 'gray.100');
  const secondaryTextColor = useColorModeValue('gray.400', 'gray.400');

  const isOpenNow = (place) => {
    if (!place.openingHours?.periods) return true;
    
    const now = new Date();
    const day = now.getDay();
    const currentPeriod = place.openingHours.periods.find(period => period.open.day === day);
    
    if (!currentPeriod) return false;
    
    const currentTime = now.getHours() * 100 + now.getMinutes();
    const openTime = parseInt(currentPeriod.open.time);
    const closeTime = parseInt(currentPeriod.close.time);
    
    return currentTime >= openTime && currentTime < closeTime;
  };

  const shouldShowOpenStatus = (place) => {
    return place.openingHours?.length > 0;
  };

  if (!places?.length && !loading) {
    return (
      <Box 
        textAlign="center" 
        py={12}
        px={4}
        borderRadius="lg"
        bg={bgColor}
        borderWidth="1px"
        borderColor={borderColor}
        color={textColor}
      >
        <Icon as={FaCoffee} boxSize={10} color={secondaryTextColor} mb={4} />
        <Text fontSize="lg" mb={2}>No coffee places found nearby</Text>
        <Text fontSize="sm" color={secondaryTextColor}>
          {filter ? 'Try adjusting your filters' : 'Try searching in a different area'}
        </Text>
      </Box>
    );
  }

  return (
    <VStack spacing={6} width="100%" px={4}>
      <SimpleGrid columns={columns} spacing={4} width="100%">
        {places.map((place) => (
          <Box
            key={place.uniqueId || place.placeId}
            onClick={() => onPlaceSelect(place)}
            cursor="pointer"
            transition="all 0.2s"
            _hover={{ transform: 'translateY(-4px)', shadow: 'lg' }}
          >
            <Box
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              bg={bgColor}
              borderColor={borderColor}
              height="100%"
            >
              <AspectRatio ratio={16/9}>
                <Box position="relative">
                  {place.image || (place.photos && place.photos.length > 0) ? (
                    <Image
                      src={place.image || place.photos[0]}
                      alt={place.name}
                      objectFit="cover"
                      width="100%"
                      height="100%"
                      fallback={
                        <Center height="100%" bg="gray.800">
                          <Icon as={FaCoffee} boxSize={8} color="gray.600" />
                        </Center>
                      }
                    />
                  ) : (
                    <Center height="100%" bg="gray.800">
                      <Icon as={FaCoffee} boxSize={8} color="gray.600" />
                    </Center>
                  )}
                  {shouldShowOpenStatus(place) && (
                    <Badge
                      position="absolute"
                      top={2}
                      right={2}
                      colorScheme={isOpenNow(place) ? "green" : "red"}
                      display="flex"
                      alignItems="center"
                      gap={1}
                      bg={isOpenNow(place) ? "green.500" : "red.500"}
                      color="white"
                      px={2}
                      py={1}
                      borderRadius="full"
                      fontSize="sm"
                    >
                      <Icon as={FaClock} boxSize={3} />
                      {isOpenNow(place) ? "Open" : "Closed"}
                    </Badge>
                  )}
                </Box>
              </AspectRatio>

              <Box p={4}>
                <Text 
                  fontSize="lg" 
                  fontWeight="semibold" 
                  noOfLines={1}
                  color={textColor}
                >
                  {place.name}
                </Text>

                <HStack spacing={2} mt={2}>
                  <Icon as={FaMapMarkerAlt} color={secondaryTextColor} boxSize={3} />
                  <Text 
                    fontSize="sm" 
                    color={secondaryTextColor} 
                    noOfLines={1}
                  >
                    {place.address}
                  </Text>
                </HStack>

                <VStack mt={3} spacing={2} align="stretch">
                  {/* Google Rating */}
                  {place.userRatingsTotal > 0 && (
                    <HStack spacing={2} align="center">
                      <Icon as={FaGoogle} color="red.500" boxSize={3} />
                      <StarRating rating={place.rating} size="sm" isReadOnly />
                      <Text fontSize="xs" color={secondaryTextColor}>
                        ({place.userRatingsTotal})
                      </Text>
                    </HStack>
                  )}
                  
                  {/* Community Rating */}
                  {place.reviewCount > 0 && (
                    <HStack spacing={2} align="center">
                      <Icon as={FaCoffee} color="brown.500" boxSize={3} />
                      <StarRating rating={place.rating} size="sm" isReadOnly />
                      <Text fontSize="xs" color={secondaryTextColor}>
                        ({place.reviewCount})
                      </Text>
                    </HStack>
                  )}

                  <HStack spacing={2} flexWrap="wrap">
                    {place.checkInCount > 0 && (
                      <Badge 
                        colorScheme="purple"
                        display="flex"
                        alignItems="center"
                        gap={1}
                      >
                        <Icon as={FaUsers} boxSize={3} />
                        {place.checkInCount}
                      </Badge>
                    )}
                    {place.priceLevel && (
                      <Badge colorScheme="green">
                        {Array(place.priceLevel).fill('$').join('')}
                      </Badge>
                    )}
                    {place.distance && (
                      <Badge colorScheme="blue">
                        {typeof place.distance === 'number' 
                          ? `${(place.distance / 1000).toFixed(1)}km`
                          : place.distance}
                      </Badge>
                    )}
                  </HStack>
                </VStack>
              </Box>
            </Box>
          </Box>
        ))}
      </SimpleGrid>

      {loading && (
        <Center py={8}>
          <Spinner size="lg" color={textColor} />
        </Center>
      )}

      {hasMore && !loading && places.length > 0 && (
        <Button
          onClick={loadMore}
          colorScheme="blue"
          variant="outline"
          size="lg"
          width={{ base: "full", md: "auto" }}
          _hover={{
            transform: 'translateY(-2px)',
            shadow: 'md'
          }}
          transition="all 0.2s"
        >
          Load More Places
        </Button>
      )}
    </VStack>
  );
});

PlacesList.displayName = 'PlacesList';
export default PlacesList;
