import React, { useState } from 'react';
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  useToast,
  Box,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
} from '@chakra-ui/react';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useQuillStyles } from '../../utils/editorStyles';

const NEWS_CATEGORIES = [
  { value: 'industry', label: 'Industry News' },
  { value: 'events', label: 'Events' },
  { value: 'science', label: 'Science & Research' },
  { value: 'sustainability', label: 'Sustainability' },
  { value: 'market', label: 'Market Updates' }
];

const NewsForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    category: '',
    type: 'news'
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useAuth();
  const toast = useToast();
  const quillStyles = useQuillStyles();

  const handleSubmit = async () => {
    if (!formData.title || !formData.content || !formData.category) {
      toast({
        title: 'Error',
        description: 'Please fill in all required fields',
        status: 'error',
        duration: 3000,
      });
      return;
    }

    setIsSubmitting(true);
    try {
      const newsData = {
        ...formData,
        userId: user.uid,
        userName: user.displayName,
        userPhotoURL: user.photoURL,
        timestamp: serverTimestamp(),
        likes: 0,
        comments: 0,
        likedBy: [],
      };

      await addDoc(collection(db, 'posts'), newsData);
      toast({
        title: 'Success',
        description: 'News article published successfully',
        status: 'success',
        duration: 3000,
      });
      onClose();
    } catch (error) {
      console.error('Error publishing news:', error);
      toast({
        title: 'Error',
        description: 'Failed to publish news article',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal 
      isOpen={true} 
      onClose={onClose} 
      size="full"
      motionPreset="slideInBottom"
    >
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent 
        margin={0} 
        rounded="none"
        height="100vh"
        overflow="auto"
        position="fixed"
        top={0}
        right={0}
        bottom={0}
        left={0}
      >
        <ModalHeader
          borderBottom="1px solid"
          borderColor="whiteAlpha.200"
          py={4}
          position="sticky"
          top={0}
          bg={useColorModeValue('white', 'gray.800')}
          zIndex={10}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Text fontWeight="semibold">Write News</Text>
          <Button 
            colorScheme="blue" 
            onClick={handleSubmit}
            isLoading={isSubmitting}
          >
            Publish
          </Button>
        </ModalHeader>

        <ModalBody p={0}>
          <VStack spacing={6} w="full" p={4} pb={20}>
            <FormControl isRequired>
              <FormLabel>Title</FormLabel>
              <Input
                value={formData.title}
                onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                placeholder="Enter a compelling title for your news article"
                size="lg"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Category</FormLabel>
              <Select
                value={formData.category}
                onChange={(e) => setFormData(prev => ({ ...prev, category: e.target.value }))}
                placeholder="Select category"
                size="lg"
              >
                {NEWS_CATEGORIES.map(category => (
                  <option key={category.value} value={category.value}>
                    {category.label}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Content</FormLabel>
              <Box
                className="quill-wrapper"
                sx={{
                  '& .quill': {
                    borderRadius: 'md',
                    overflow: 'hidden',
                    bg: useColorModeValue('white', 'gray.700'),
                  }
                }}
              >
                <style>{quillStyles}</style>
                <ReactQuill
                  value={formData.content}
                  onChange={(content) => setFormData(prev => ({ ...prev, content }))}
                  placeholder="Write your article..."
                  modules={{
                    toolbar: [
                      ['bold', 'italic', 'underline'],
                      [{ 'header': [1, 2, false] }],
                      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                      ['link', 'blockquote'],
                      ['clean']
                    ],
                  }}
                  theme="snow"
                  style={{ height: '300px', marginBottom: '50px' }}
                />
              </Box>
            </FormControl>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NewsForm;
