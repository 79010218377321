import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  SimpleGrid,
  VStack,
  HStack,
  Text,
  Image,
  Button,
  Icon,
  Avatar,
  useColorModeValue,
  Badge,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { FaHeart, FaBookmark, FaShare, FaPlus } from 'react-icons/fa';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import CollectionDetails from './CoffeePlaces/CollectionDetails';
import { motion } from 'framer-motion';
import { useTheme } from '../contexts/ThemeContext';

const containerVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 }
};

const Collections = () => {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const bgColor = useColorModeValue('white', 'gray.800');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useAuth();
  const { theme } = useTheme();

  useEffect(() => {
    fetchCollections();
  }, []);

  const fetchCollections = async () => {
    try {
      const q = query(collection(db, 'coffeeLists'), orderBy('createdAt', 'desc'));
      const snapshot = await getDocs(q);
      const fetchedCollections = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCollections(fetchedCollections);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching collections:', error);
      setLoading(false);
    }
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="show"
      className="collections"
      style={{
        padding: '1rem',
        gap: '1rem',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))'
      }}
    >
      {collections.map(collection => (
        <motion.div 
          key={collection.id}
          variants={itemVariants}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className="collection-card"
          style={{
            backgroundColor: useColorModeValue('white', 'gray.800'),
            borderRadius: '0.5rem',
            padding: '1rem',
            boxShadow: 'sm',
            cursor: 'pointer'
          }}
          onClick={() => {
            setSelectedCollection(collection);
            onOpen();
          }}
        >
          <Box position="relative" height="200px">
            <Image
              src={collection.coverImage || 'default-collection.jpg'}
              alt={collection.title}
              objectFit="cover"
              w="100%"
              h="100%"
            />
            <Avatar
              src={collection.curator?.photoURL}
              name={collection.curator?.name}
              size="sm"
              position="absolute"
              bottom={-4}
              right={4}
              border="2px solid white"
            />
          </Box>

          <VStack p={4} align="stretch" spacing={3}>
            <Text fontSize="lg" fontWeight="bold" noOfLines={1}>
              {collection.title}
            </Text>
            
            <Text fontSize="sm" color="gray.500" noOfLines={2}>
              {collection.description}
            </Text>

            <HStack>
              {collection.tags?.map((tag, index) => (
                <Badge key={index} colorScheme="blue">
                  {tag}
                </Badge>
              ))}
            </HStack>

            <HStack justify="space-between">
              <HStack spacing={4}>
                <HStack>
                  <Icon as={FaHeart} color="red.400" />
                  <Text fontSize="sm">{collection.likes || 0}</Text>
                </HStack>
                <Text fontSize="sm" color="gray.500">
                  {collection.places?.length || 0} places
                </Text>
              </HStack>

              <HStack>
                <IconButton
                  icon={<FaBookmark />}
                  variant="ghost"
                  size="sm"
                  aria-label="Save collection"
                />
                <IconButton
                  icon={<FaShare />}
                  variant="ghost"
                  size="sm"
                  aria-label="Share collection"
                />
              </HStack>
            </HStack>
          </VStack>
        </motion.div>
      ))}

      {selectedCollection && (
        <CollectionDetails
          collection={selectedCollection}
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setSelectedCollection(null);
          }}
          onUpdate={fetchCollections}
        />
      )}
    </motion.div>
  );
};

export default Collections;
