import React, { useState } from 'react';
import {
  Box,
  HStack,
  Button,
  Icon,
  useColorModeValue,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverCloseButton,
  VStack,
  Checkbox,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { FaFilter, FaWifi, FaPlug, FaUmbrella, FaStar, FaDollarSign } from 'react-icons/fa';

const QUICK_FILTERS = [
  { id: 'wifi', label: 'WiFi', icon: FaWifi },
  { id: 'powerOutlets', label: 'Power', icon: FaPlug },
  { id: 'outdoorSeating', label: 'Outdoor', icon: FaUmbrella },
];

const PlacesFilters = ({ onFilterChange }) => {
  const [filters, setFilters] = useState({
    quickFilters: [],
    minRating: 0,
    priceRange: [1, 4],
    maxDistance: 5
  });

  const bgColor = useColorModeValue('gray.800', 'gray.800');
  const borderColor = useColorModeValue('gray.700', 'gray.700');
  const textColor = useColorModeValue('gray.100', 'gray.100');

  const handleFilterChange = (newFilters) => {
    const updatedFilters = { ...filters, ...newFilters };
    setFilters(updatedFilters);
    onFilterChange?.(updatedFilters);
  };

  const toggleQuickFilter = (filterId) => {
    const newQuickFilters = filters.quickFilters.includes(filterId)
      ? filters.quickFilters.filter(id => id !== filterId)
      : [...filters.quickFilters, filterId];
    
    handleFilterChange({ quickFilters: newQuickFilters });
  };

  return (
    <HStack spacing={2} py={2}>
      <Popover placement="bottom-start">
        <PopoverTrigger>
          <Button
            leftIcon={<Icon as={FaFilter} />}
            variant="outline"
            borderColor={borderColor}
            color={textColor}
            _hover={{ bg: 'gray.700' }}
          >
            Filters
            {filters.quickFilters.length > 0 && (
              <Badge ml={2} colorScheme="blue" borderRadius="full">
                {filters.quickFilters.length}
              </Badge>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent bg={bgColor} borderColor={borderColor}>
          <PopoverHeader borderColor={borderColor} color={textColor}>Filters</PopoverHeader>
          <PopoverCloseButton color={textColor} />
          <PopoverBody>
            <VStack align="stretch" spacing={4}>
              <Box>
                <Text color={textColor} mb={2}>Quick Filters</Text>
                <Wrap>
                  {QUICK_FILTERS.map(filter => (
                    <WrapItem key={filter.id}>
                      <Button
                        size="sm"
                        variant={filters.quickFilters.includes(filter.id) ? "solid" : "outline"}
                        colorScheme="blue"
                        leftIcon={<Icon as={filter.icon} />}
                        onClick={() => toggleQuickFilter(filter.id)}
                      >
                        {filter.label}
                      </Button>
                    </WrapItem>
                  ))}
                </Wrap>
              </Box>

              <Box>
                <Text color={textColor} mb={2}>Minimum Rating</Text>
                <HStack spacing={2}>
                  {[1, 2, 3, 4, 5].map(rating => (
                    <Button
                      key={rating}
                      size="sm"
                      variant={filters.minRating >= rating ? "solid" : "outline"}
                      colorScheme="yellow"
                      onClick={() => handleFilterChange({ minRating: rating })}
                    >
                      {rating} <Icon as={FaStar} ml={1} />
                    </Button>
                  ))}
                </HStack>
              </Box>

              <Box>
                <Text color={textColor} mb={2}>Price Range</Text>
                <RangeSlider
                  min={1}
                  max={4}
                  step={1}
                  value={filters.priceRange}
                  onChange={(value) => handleFilterChange({ priceRange: value })}
                >
                  <RangeSliderTrack>
                    <RangeSliderFilledTrack />
                  </RangeSliderTrack>
                  <RangeSliderThumb index={0} />
                  <RangeSliderThumb index={1} />
                </RangeSlider>
                <HStack justify="space-between" color={textColor}>
                  <Text>{Array(filters.priceRange[0]).fill('$').join('')}</Text>
                  <Text>{Array(filters.priceRange[1]).fill('$').join('')}</Text>
                </HStack>
              </Box>
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>

      <Menu>
        <MenuButton
          as={Button}
          variant="outline"
          borderColor={borderColor}
          color={textColor}
          _hover={{ bg: 'gray.700' }}
        >
          Sort By
        </MenuButton>
        <MenuList bg={bgColor} borderColor={borderColor}>
          <MenuItem
            _hover={{ bg: 'gray.700' }}
            color={textColor}
            onClick={() => onFilterChange?.({ sortBy: 'distance' })}
          >
            Distance
          </MenuItem>
          <MenuItem
            _hover={{ bg: 'gray.700' }}
            color={textColor}
            onClick={() => onFilterChange?.({ sortBy: 'rating' })}
          >
            Rating
          </MenuItem>
          <MenuItem
            _hover={{ bg: 'gray.700' }}
            color={textColor}
            onClick={() => onFilterChange?.({ sortBy: 'price' })}
          >
            Price
          </MenuItem>
        </MenuList>
      </Menu>
    </HStack>
  );
};

export default PlacesFilters;
