import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Image,
  useColorModeValue,
} from '@chakra-ui/react';

const About = () => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <Container maxW="container.md" py={8}>
      <VStack spacing={8} align="stretch">
        <Box 
          bg={bgColor} 
          p={8} 
          borderRadius="xl" 
          boxShadow="sm"
        >
          <VStack spacing={6} align="stretch">
            <Image
              src="/beanscenelogo.png"
              alt="BeanScene Logo"
              height="60px"
              objectFit="contain"
              mx="auto"
              mb={4}
            />
            
            <Heading size="lg" textAlign="center">About BeanScene</Heading>
            
            <Text color={textColor}>
              BeanScene is a vibrant community platform dedicated to coffee enthusiasts, 
              professionals, and anyone passionate about the world of coffee. Our mission 
              is to connect coffee lovers worldwide and provide a space where they can share 
              experiences, knowledge, and their love for coffee.
            </Text>

            <Heading size="md">Our Mission</Heading>
            <Text color={textColor}>
              We aim to create the most comprehensive and engaging coffee community platform 
              where members can:
              <br /><br />
              • Share their coffee experiences and discoveries
              <br />
              • Connect with fellow coffee enthusiasts
              <br />
              • Learn about different brewing methods and equipment
              <br />
              • Stay updated with the latest coffee industry news
              <br />
              • Organize and participate in coffee-related meetups
            </Text>

            <Heading size="md">The Team</Heading>
            <Text color={textColor}>
              BeanScene was created by a team of coffee enthusiasts who saw the need for 
              a dedicated platform that brings together all aspects of coffee culture. 
              Our team combines expertise in coffee, technology, and community building 
              to create the best possible experience for our users.
            </Text>

            <Heading size="md">Contact Us</Heading>
            <Text color={textColor}>
              We love hearing from our community! Whether you have suggestions, 
              questions, or just want to say hello, feel free to reach out to us at:
              <br /><br />
              Email: hello@beanscene.de
              <br />
              Instagram: @beanscene.de
            </Text>
          </VStack>
        </Box>
      </VStack>
    </Container>
  );
};

export default About; 