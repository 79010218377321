import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { Box, Heading, Text, SimpleGrid, useColorModeValue } from '@chakra-ui/react';

const CoffeeVariants = () => {
  const [coffees, setCoffees] = useState([]);
  const [loading, setLoading] = useState(true);

  const bgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const secondaryTextColor = useColorModeValue('gray.600', 'gray.300');

  useEffect(() => {
    const fetchCoffees = async () => {
      const querySnapshot = await getDocs(collection(db, 'coffees'));
      const coffeeList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCoffees(coffeeList);
      setLoading(false);
    };

    fetchCoffees();
  }, []);

  if (loading) {
    return <Text textAlign="center" mt={8}>Loading...</Text>;
  }

  return (
    <Box maxW="4xl" mx="auto" mt={8}>
      <Heading as="h2" size="xl" mb={6} color={textColor}>Coffee Variants</Heading>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
        {coffees.map(coffee => (
          <Box key={coffee.id} bg={bgColor} p={6} borderRadius="md" boxShadow="md">
            <Heading as="h3" size="md" mb={2} color={textColor}>{coffee.name}</Heading>
            <Text color={secondaryTextColor}><strong>Roaster:</strong> {coffee.roaster}</Text>
            <Text color={secondaryTextColor}><strong>Roast:</strong> {coffee.roast}</Text>
            <Text color={secondaryTextColor}><strong>Origin:</strong> {coffee.origin}</Text>
            <Text color={secondaryTextColor}><strong>Price:</strong> ${coffee.priceUSD100g.toFixed(2)} per 100g</Text>
            <Text color={secondaryTextColor}><strong>Rating:</strong> {coffee.rating}/5</Text>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default CoffeeVariants;
