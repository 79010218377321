import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useColorModeValue,
  SimpleGrid,
  Checkbox,
  useToast,
  Select,
} from '@chakra-ui/react';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { PlaceSearch } from '../shared/PlaceSearch';

const AMENITIES = [
  { id: 'wifi', label: 'Free WiFi' },
  { id: 'power', label: 'Power Outlets' },
  { id: 'food', label: 'Food Available' },
  { id: 'outdoor', label: 'Outdoor Seating' },
  { id: 'workspace', label: 'Work-Friendly' },
  { id: 'parking', label: 'Parking Available' },
  { id: 'accessibility', label: 'Wheelchair Accessible' },
  { id: 'petFriendly', label: 'Pet Friendly' },
];

const AddPlaceForm = ({ isOpen, onClose, user, onSuccess }) => {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [description, setDescription] = useState('');
  const [website, setWebsite] = useState('');
  const [phone, setPhone] = useState('');
  const [placeType, setPlaceType] = useState('cafe');
  const [selectedAmenities, setSelectedAmenities] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const bgColor = useColorModeValue('white', 'gray.800');
  const toast = useToast();

  const handleSubmit = async () => {
    if (!user || !name || !address) return;

    setIsSubmitting(true);
    try {
      const placeData = {
        name,
        address,
        description,
        website,
        phone,
        type: placeType,
        amenities: Object.entries(selectedAmenities)
          .filter(([_, value]) => value)
          .map(([key]) => key),
        addedBy: {
          id: user.uid,
          name: user.displayName,
          photo: user.photoURL,
        },
        status: 'pending',
        timestamp: serverTimestamp(),
        ratings: {
          overall: 0,
          coffee: 0,
          atmosphere: 0,
          service: 0,
          value: 0,
        },
        reviewCount: 0,
      };

      await addDoc(collection(db, 'coffeePlaces'), placeData);
      
      toast({
        title: 'Place Added',
        description: 'Your submission will be reviewed by our team.',
        status: 'success',
        duration: 5000,
      });

      onSuccess?.();
      
      // Reset form
      setName('');
      setAddress('');
      setDescription('');
      setWebsite('');
      setPhone('');
      setPlaceType('cafe');
      setSelectedAmenities({});
    } catch (error) {
      console.error('Error adding place:', error);
      toast({
        title: 'Error',
        description: 'Failed to add place. Please try again.',
        status: 'error',
        duration: 5000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent bg={bgColor}>
        <ModalHeader>Add Coffee Place</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} pb={4}>
            <FormControl isRequired>
              <FormLabel>Place Name</FormLabel>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name of the coffee place"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Address</FormLabel>
              <PlaceSearch
                onPlaceSelect={(place) => {
                  setAddress(place.description);
                }}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Type</FormLabel>
              <Select
                value={placeType}
                onChange={(e) => setPlaceType(e.target.value)}
              >
                <option value="cafe">Café</option>
                <option value="roastery">Roastery</option>
                <option value="coffeeShop">Coffee Shop</option>
                <option value="restaurant">Restaurant</option>
                <option value="other">Other</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Tell us about this place..."
                resize="none"
                rows={4}
              />
            </FormControl>

            <SimpleGrid columns={2} spacing={4} w="100%">
              <FormControl>
                <FormLabel>Website</FormLabel>
                <Input
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  placeholder="https://..."
                  type="url"
                />
              </FormControl>

              <FormControl>
                <FormLabel>Phone</FormLabel>
                <Input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Phone number"
                  type="tel"
                />
              </FormControl>
            </SimpleGrid>

            <FormControl>
              <FormLabel>Amenities</FormLabel>
              <SimpleGrid columns={{ base: 2, md: 3 }} spacing={3}>
                {AMENITIES.map(({ id, label }) => (
                  <Checkbox
                    key={id}
                    isChecked={selectedAmenities[id]}
                    onChange={(e) =>
                      setSelectedAmenities(prev => ({
                        ...prev,
                        [id]: e.target.checked
                      }))
                    }
                  >
                    {label}
                  </Checkbox>
                ))}
              </SimpleGrid>
            </FormControl>

            <HStack spacing={4} alignSelf="flex-end">
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                onClick={handleSubmit}
                isLoading={isSubmitting}
                loadingText="Adding..."
                isDisabled={!name || !address}
              >
                Add Place
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddPlaceForm;
