import React, { useRef, useEffect } from 'react';
import {
  Box,
  Flex,
  IconButton,
  Avatar,
  useColorModeValue,
  useDisclosure,
  Portal,
  useBreakpointValue,
  Badge,
  Tooltip,
} from '@chakra-ui/react';
import { 
  FaHome, 
  FaPlus, 
  FaSignInAlt, 
  FaCoffee,
  FaCompass,
  FaUser,
  FaStream,
  FaSearch,
  FaCog
} from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import CreatePost from './CreatePost';
import { motion, AnimatePresence } from 'framer-motion';

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);
const MotionIconButton = motion(IconButton);

const MobileNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const createButtonRef = useRef();
  const isScrolled = useBreakpointValue({ base: true, md: false });

  // Glass effect colors
  const glassEffect = {
    bg: useColorModeValue(
      'rgba(255, 255, 255, 0.25)',
      'rgba(26, 32, 44, 0.25)'
    ),
    border: useColorModeValue(
      'rgba(255, 255, 255, 0.12)',
      'rgba(255, 255, 255, 0.05)'
    ),
    shadow: useColorModeValue(
      '0 4px 30px rgba(0, 0, 0, 0.1)',
      '0 4px 30px rgba(0, 0, 0, 0.2)'
    ),
  };

  const buttonStyles = {
    bg: 'transparent',
    _hover: { 
      bg: useColorModeValue('whiteAlpha.300', 'blackAlpha.300'),
      transform: 'scale(1.1)'
    },
    _active: {
      bg: useColorModeValue('whiteAlpha.400', 'blackAlpha.400'),
      transform: 'scale(0.95)',
    },
    transition: 'all 0.2s',
  };

  const isActive = (path) => {
    if (path === '/') return location.pathname === '/feed';
    if (path === '/coffee') return location.pathname === '/' || location.pathname === '/coffee';
    return location.pathname.includes(path);
  };

  const NavButton = ({ icon: Icon, path, label, showBadge }) => (
    <Tooltip 
      label={label} 
      placement="top" 
      hasArrow 
      openDelay={500}
      display={{ base: 'none', sm: 'block' }}
    >
      <MotionIconButton
        icon={
          <Box position="relative">
            <Icon size={22} />
            {showBadge && (
              <Badge
                position="absolute"
                top="-2px"
                right="-4px"
                colorScheme="red"
                borderRadius="full"
                size="xs"
                p={0.5}
              />
            )}
          </Box>
        }
        variant="ghost"
        onClick={() => navigate(path)}
        color={isActive(path) ? 'blue.400' : 'gray.500'}
        aria-label={label}
        {...buttonStyles}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
      />
    </Tooltip>
  );

  const navItems = [
    { path: '/coffee', icon: FaCoffee, label: 'Coffee' },
    { path: '/feed', icon: FaStream, label: 'Feed' },
    { type: 'plus' },  // Placeholder for the plus button
    { path: '/search', icon: FaSearch, label: 'Search' },
  ];

  return (
    <AnimatePresence>
      <MotionBox
        position="fixed"
        bottom="20px"
        left="50%"
        initial={{ y: 100, x: '-50%' }}
        animate={{ y: 0, x: '-50%' }}
        exit={{ y: 100, x: '-50%' }}
        transition={{ type: 'spring', stiffness: 260, damping: 20 }}
        display={{ base: 'block', md: 'none' }}
        zIndex={1000}
        width="90%"
        maxWidth="400px"
        className="pb-safe"
      >
        {/* Glass background */}
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg={glassEffect.bg}
          backdropFilter="blur(20px) saturate(180%)"
          borderRadius="full"
          border="1px solid"
          borderColor={glassEffect.border}
          boxShadow={glassEffect.shadow}
        />

        {/* Content */}
        <MotionFlex
          position="relative"
          justify="space-around"
          align="center"
          height="60px"
          px={6}
        >
          {navItems.map((item, index) => (
            item.type === 'plus' ? (
              <MotionIconButton
                key="create"
                ref={createButtonRef}
                icon={<FaPlus size={22} />}
                colorScheme="blue"
                rounded="full"
                onClick={onOpen}
                aria-label="Create Post"
                size="lg"
                position="relative"
                top="-10px"
                boxShadow="lg"
                whileHover={{ scale: 1.1, boxShadow: "0 8px 15px rgba(0,0,0,0.2)" }}
                whileTap={{ scale: 0.95 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
              />
            ) : (
              <NavButton 
                key={item.path} 
                icon={item.icon} 
                path={item.path} 
                label={item.label} 
              />
            )
          ))}

          {user ? (
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
              <Avatar
                size="sm"
                src={user?.photoURL}
                name={user?.displayName}
                cursor="pointer"
                onClick={() => navigate(`/profile/${user?.uid}`)}
                border={isActive('/profile') ? '2px solid' : 'none'}
                borderColor="blue.400"
                transition="all 0.2s"
              />
            </motion.div>
          ) : (
            <NavButton icon={FaSignInAlt} path="/login" label="Login" />
          )}
        </MotionFlex>

        <CreatePost 
          isOpen={isOpen} 
          onClose={onClose}
          anchorEl={createButtonRef.current}
        />
      </MotionBox>
    </AnimatePresence>
  );
};

export default MobileNav;
