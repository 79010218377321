import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  Avatar,
  Text,
  Box,
  Button,
  Input,
  Flex,
  useColorModeValue,
  IconButton,
  Divider,
  Container,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { formatTimeAgo } from '../utils/timeAgo';
import { Link as RouterLink } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { isPlatform } from '../utils/platformUtils';

const MotionModalContent = motion(ModalContent);

const CommentItem = ({ comment, onLikeComment, currentUser }) => {
  if (!comment) return null;

  const isLiked = comment.likedBy?.includes(currentUser?.uid);

  return (
    <Box py={2}>
      <Flex gap={2}>
        <RouterLink to={`/profile/${comment.userId}`}>
          <Avatar 
            size="sm" 
            src={comment.userPhotoURL} 
            name={comment.username || 'Anonymous'}
          />
        </RouterLink>
        <Box flex={1}>
          <Flex justify="space-between" align="center">
            <RouterLink to={`/profile/${comment.userId}`}>
              <Text 
                fontWeight="medium" 
                fontSize="sm"
                _hover={{ color: 'blue.500' }}
              >
                {comment.username || 'Anonymous'}
              </Text>
            </RouterLink>
            <IconButton
              icon={isLiked ? <FaHeart /> : <FaRegHeart />}
              onClick={() => onLikeComment(comment.id, currentUser)}
              variant="ghost"
              size="sm"
              aria-label="Like comment"
              isDisabled={!currentUser}
              color={isLiked ? "red.400" : undefined}
            />
          </Flex>
          <Text fontSize="sm" mt={1}>{comment.text}</Text>
          <Text fontSize="xs" color="gray.500" mt={1}>
            {comment.timestamp ? formatTimeAgo(comment.timestamp) : 'Just now'}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

const CommentsModal = ({ 
  isOpen = false, 
  onClose, 
  comments = [], 
  onAddComment, 
  onLikeComment, 
  currentUser 
}) => {
  const [newComment, setNewComment] = useState('');
  const toast = useToast();
  
  const inputBg = useColorModeValue('gray.50', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const modalBg = useColorModeValue('white', 'gray.800');
  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleSubmitComment = () => {
    if (!currentUser) {
      toast({
        title: "Please login to comment",
        status: "warning",
        duration: 3000,
      });
      return;
    }

    if (newComment.trim()) {
      onAddComment(newComment.trim(), currentUser);
      setNewComment('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmitComment();
    }
  };

  const commentsList = (
    <VStack 
      spacing={2} 
      align="stretch" 
      maxH={isMobile ? "calc(90vh - 200px)" : "calc(80vh - 200px)"}
      overflowY="auto"
      css={{
        '&::-webkit-scrollbar': { width: '4px' },
        '&::-webkit-scrollbar-track': { width: '6px' },
        '&::-webkit-scrollbar-thumb': {
          background: borderColor,
          borderRadius: '24px',
        },
      }}
    >
      {Array.isArray(comments) && comments.map((comment) => (
        <CommentItem
          key={comment.id}
          comment={comment}
          onLikeComment={onLikeComment}
          currentUser={currentUser}
        />
      ))}
      {(!comments || comments.length === 0) && (
        <Text color="gray.500" textAlign="center" py={4}>
          No comments yet. Be the first to comment!
        </Text>
      )}
    </VStack>
  );

  const commentInput = (
    <Flex gap={3}>
      <Input
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder="Add a comment..."
        bg={inputBg}
        borderRadius="full"
        _placeholder={{ color: 'gray.500' }}
        disabled={!currentUser}
      />
      <Button 
        onClick={handleSubmitComment}
        colorScheme="blue"
        borderRadius="full"
        px={6}
        isDisabled={!currentUser || !newComment.trim()}
      >
        Post
      </Button>
    </Flex>
  );

  // Mobile version
  if (isMobile) {
    return (
      <AnimatePresence>
        {isOpen && (
          <Modal 
            isOpen={true} 
            onClose={onClose}
            motionPreset="none"
          >
            <ModalOverlay 
              bg="blackAlpha.600" 
              backdropFilter="blur(5px)"
            />
            <MotionModalContent
              position="fixed"
              bottom={0}
              left={0}
              right={0}
              margin={0}
              initial={{ y: '100%' }}
              animate={{ y: 0 }}
              exit={{ y: '100%' }}
              transition={{ type: "spring", damping: 25, stiffness: 300 }}
              maxH="90vh"
              borderBottomRadius={0}
              borderTopRadius="2xl"
              bg={modalBg}
              overflow="hidden"
            >
              <Box 
                w="40px" 
                h="4px" 
                bg="gray.300" 
                borderRadius="full" 
                mx="auto" 
                mt={3}
                mb={2}
              />

              <Container maxW="container.md" px={4}>
                <ModalHeader textAlign="center" fontSize="md" py={2}>
                  Comments
                </ModalHeader>

                <ModalBody p={0} mb={20}>
                  {commentsList}
                </ModalBody>

                <Box 
                  position="fixed" 
                  bottom={0} 
                  left={0} 
                  right={0} 
                  p={4} 
                  bg={modalBg}
                  borderTop="1px solid"
                  borderColor={borderColor}
                >
                  <Container maxW="container.md">
                    {commentInput}
                  </Container>
                </Box>
              </Container>
            </MotionModalContent>
          </Modal>
        )}
      </AnimatePresence>
    );
  }

  // Desktop version
  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      size="xl"
      isCentered
    >
      <ModalOverlay bg="blackAlpha.600" backdropFilter="blur(5px)" />
      <ModalContent bg={modalBg} maxH="80vh">
        <ModalHeader borderBottomWidth="1px">Comments</ModalHeader>
        <ModalCloseButton />
        
        <ModalBody p={4}>
          <VStack spacing={4} align="stretch">
            {commentInput}
            <Divider />
            {commentsList}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CommentsModal;
