import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useToast } from '@chakra-ui/react';

export const useProfileData = (userId) => {
  const [posts, setPosts] = useState([]);
  const [stats, setStats] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!userId) return;

      try {
        const postsQuery = query(
          collection(db, 'posts'),
          where('userId', '==', userId)
        );
        const postsSnapshot = await getDocs(postsQuery);
        const postsData = postsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setPosts(postsData);

        const stats = {
          totalPosts: postsData.length,
          reviews: postsData.filter(p => p.type === 'review').length,
          brews: postsData.filter(p => p.type === 'brew').length,
          meetups: postsData.filter(p => p.type === 'meetup').length,
          totalLikes: postsData.reduce((acc, post) => acc + (post.likes?.length || 0), 0),
          totalComments: postsData.reduce((acc, post) => acc + (post.comments?.length || 0), 0),
          followers: 0,
          following: 0,
        };
        setStats(stats);
      } catch (error) {
        console.error('Error fetching profile data:', error);
        toast({
          title: 'Error loading profile',
          status: 'error',
          duration: 3000,
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfileData();
  }, [userId, toast]);

  const handleProfileUpdate = async (updates) => {
    if (!userId) {
      console.error('No user ID provided');
      return;
    }

    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, updates);
      toast({
        title: 'Profile updated',
        status: 'success',
        duration: 2000,
      });
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating profile:', error);
      toast({
        title: 'Error updating profile',
        status: 'error',
        duration: 3000,
      });
      throw error; // Re-throw to handle in the component
    }
  };

  const handleImageUpdate = async (file, type = 'avatar') => {
    if (!userId || !file) {
      console.error('Missing userId or file');
      return;
    }

    try {
      // First upload the file
      const storageRef = ref(storage, `users/${userId}/${type}/${Date.now()}_${file.name}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      
      // Then update the profile
      const update = type === 'avatar' 
        ? { photoURL: url }
        : { coverPhotoURL: url };
      
      await handleProfileUpdate(update);
      return url;
    } catch (error) {
      console.error('Error uploading image:', error);
      toast({
        title: 'Error uploading image',
        status: 'error',
        duration: 3000,
      });
      throw error; // Re-throw to handle in the component
    }
  };

  return {
    posts,
    stats,
    isEditing,
    setIsEditing,
    handleProfileUpdate,
    handleImageUpdate,
    isLoading,
  };
}; 