import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useTheme } from '../contexts/ThemeContext';
import { useColorMode, useColorModeValue } from '@chakra-ui/react';
import {
  Box,
  VStack,
  Text,
  Skeleton,
  SkeletonText,
  Link,
  Icon,
  HStack,
  Avatar,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { FaFire, FaUser } from 'react-icons/fa';
import { stripHtml } from '../utils/textUtils';

const TrendingPostSkeleton = () => (
  <Box p={4} borderWidth="1px" borderRadius="lg">
    <HStack spacing={3}>
      <Skeleton borderRadius="full" boxSize="40px" />
      <VStack align="start" flex={1} spacing={2}>
        <Skeleton height="20px" width="140px" />
        <Skeleton height="16px" width="100px" />
      </VStack>
    </HStack>
    <SkeletonText mt={4} noOfLines={2} spacing={2} />
  </Box>
);

const TrendingSection = () => {
  // 1. All context hooks first (in consistent order)
  const { user } = useAuth();
  const { mode, colors } = useTheme();
  const { colorMode } = useColorMode();

  // 2. All useState hooks
  const [posts, setPosts] = useState([]);
  const [trendingUsers, setTrendingUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // 3. All color mode hooks
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const hoverBg = useColorModeValue('gray.50', 'gray.700');

  // 4. useEffect hooks
  useEffect(() => {
    Promise.all([
      fetchTrendingPosts(),
      fetchTrendingUsers()
    ]).finally(() => setLoading(false));
  }, []);

  const fetchTrendingPosts = async () => {
    try {
      const postsQuery = query(
        collection(db, 'posts'),
        orderBy('likes', 'desc'),
        limit(5)
      );
      const postsSnapshot = await getDocs(postsQuery);
      const posts = postsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPosts(posts);
    } catch (error) {
      console.error('Error fetching trending posts:', error);
      setError(error);
    }
  };

  const fetchTrendingUsers = async () => {
    try {
      const usersQuery = query(
        collection(db, 'users'),
        orderBy('followers', 'desc'),
        limit(5)
      );
      const usersSnapshot = await getDocs(usersQuery);
      const users = usersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setTrendingUsers(users);
    } catch (error) {
      console.error('Error fetching trending users:', error);
      setError(error);
    }
  };

  return (
    <Box 
      position="sticky"
      top="20px"
      p={4}
      bg={bgColor}
      borderRadius="lg"
      borderWidth="1px"
      borderColor={borderColor}
    >
      <VStack spacing={6} align="stretch">
        {/* Trending Posts */}
        <Box>
          <HStack mb={4}>
            <Icon as={FaFire} color="orange.500" />
            <Text fontWeight="bold" color={textColor}>
              Trending Posts
            </Text>
          </HStack>

          <VStack spacing={4} align="stretch">
            {loading ? (
              Array(3).fill(0).map((_, i) => (
                <TrendingPostSkeleton key={i} />
              ))
            ) : (
              posts.map(post => (
                <Link
                  key={post.id}
                  as={RouterLink}
                  to={`/post/${post.id}`}
                  _hover={{ textDecoration: 'none' }}
                >
                  <Box 
                    p={4} 
                    borderWidth="1px" 
                    borderRadius="lg"
                    transition="all 0.2s"
                    _hover={{ bg: hoverBg }}
                  >
                    <HStack spacing={3}>
                      <Avatar 
                        size="sm" 
                        src={post.userPhotoURL} 
                        name={post.userName} 
                      />
                      <VStack align="start" spacing={0}>
                        <Text fontWeight="bold" fontSize="sm">
                          {post.userName}
                        </Text>
                        <Text fontSize="xs" color="gray.500">
                          {post.likes?.length || 0} likes
                        </Text>
                      </VStack>
                    </HStack>
                    <Text 
                      fontSize="sm" 
                      mt={2} 
                      noOfLines={2}
                      color={useColorModeValue('gray.600', 'gray.300')}
                    >
                      {stripHtml(post.content)}
                    </Text>
                  </Box>
                </Link>
              ))
            )}
          </VStack>
        </Box>

        {/* Trending Users */}
        <Box>
          <HStack mb={4}>
            <Icon as={FaUser} color="blue.500" />
            <Text fontWeight="bold" color={textColor}>
              Popular Users
            </Text>
          </HStack>

          <VStack spacing={3} align="stretch">
            {loading ? (
              Array(3).fill(0).map((_, i) => (
                <HStack key={i} spacing={3}>
                  <Skeleton borderRadius="full" boxSize="32px" />
                  <Skeleton height="20px" width="120px" />
                </HStack>
              ))
            ) : (
              trendingUsers.map(user => (
                <Link
                  key={user.id}
                  as={RouterLink}
                  to={`/profile/${user.id}`}
                  _hover={{ textDecoration: 'none' }}
                >
                  <HStack 
                    spacing={3}
                    p={2}
                    borderRadius="lg"
                    transition="all 0.2s"
                    _hover={{ bg: useColorModeValue('gray.50', 'gray.700') }}
                  >
                    <Avatar 
                      size="sm" 
                      src={user.photoURL} 
                      name={user.displayName} 
                    />
                    <VStack align="start" spacing={0}>
                      <Text fontWeight="bold" fontSize="sm">
                        {user.displayName}
                      </Text>
                      <Text fontSize="xs" color="gray.500">
                        {user.followers?.length || 0} followers
                      </Text>
                    </VStack>
                  </HStack>
                </Link>
              ))
            )}
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
};

export default TrendingSection;
