import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Container,
  Progress,
  Flex,
  Badge,
  Avatar,
  HStack,
  Button,
  SimpleGrid,
  Icon,
  Tooltip,
  Skeleton,
  Link,
  useToast,
  Divider
} from '@chakra-ui/react';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { FaVoteYea, FaClock, FaUser, FaChartBar } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { motion } from 'framer-motion';
import { formatTimeAgo } from '../utils/timeAgo';
import { useNavigate } from 'react-router-dom';

const MotionBox = motion(Box);

const PollOption = ({ option, totalVotes, onVote, hasVoted, isSelected }) => {
  const percentage = totalVotes > 0 ? (option.votes / totalVotes) * 100 : 0;
  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const hoverBg = useColorModeValue('gray.100', 'gray.600');
  const selectedBg = useColorModeValue('blue.50', 'blue.900');

  return (
    <MotionBox
      whileHover={!hasVoted ? { scale: 1.01 } : {}}
      onClick={() => !hasVoted && onVote()}
      cursor={hasVoted ? 'default' : 'pointer'}
      p={4}
      borderRadius="lg"
      bg={isSelected ? selectedBg : bgColor}
      _hover={!hasVoted ? { bg: hoverBg } : {}}
      position="relative"
      overflow="hidden"
    >
      <Flex justify="space-between" align="center" mb={2}>
        <Text fontWeight="medium">{option.text}</Text>
        {hasVoted && (
          <Badge colorScheme="blue">
            {percentage.toFixed(1)}%
          </Badge>
        )}
      </Flex>

      {hasVoted && (
        <Progress
          value={percentage}
          size="sm"
          colorScheme="blue"
          borderRadius="full"
          hasStripe
          isAnimated
        />
      )}

      <Text fontSize="sm" color="gray.500" mt={2}>
        {option.votes} {option.votes === 1 ? 'vote' : 'votes'}
      </Text>

      {isSelected && (
        <Icon
          as={FaVoteYea}
          position="absolute"
          top={2}
          right={2}
          color="green.500"
        />
      )}
    </MotionBox>
  );
};

const PollCard = ({ poll }) => {
  const { user } = useAuth();
  const [hasVoted, setHasVoted] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [localPoll, setLocalPoll] = useState(poll);
  const toast = useToast();
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  useEffect(() => {
    if (user && poll.voters && poll.voters.includes(user.uid)) {
      setHasVoted(true);
      const userVote = poll.votes?.find(vote => vote.userId === user.uid);
      if (userVote) {
        setSelectedOption(userVote.optionIndex);
      }
    }
  }, [user, poll]);

  const totalVotes = Array.isArray(localPoll?.options) 
    ? localPoll.options.reduce((sum, option) => sum + (option.votes || 0), 0) 
    : 0;

  const handleVote = async (optionIndex) => {
    if (!user) {
      toast({
        title: "Login Required",
        description: "Please login to vote in polls",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const pollRef = doc(db, 'polls', poll.id);
      const updatedOptions = [...localPoll.options];
      updatedOptions[optionIndex].votes = (updatedOptions[optionIndex].votes || 0) + 1;

      await updateDoc(pollRef, {
        options: updatedOptions,
        voters: [...(poll.voters || []), user.uid],
        votes: [...(poll.votes || []), { userId: user.uid, optionIndex }]
      });

      setLocalPoll(prev => ({
        ...prev,
        options: updatedOptions
      }));
      setHasVoted(true);
      setSelectedOption(optionIndex);
    } catch (error) {
      console.error('Error voting:', error);
    }
  };

  if (!Array.isArray(localPoll?.options)) {
    return (
      <Box p={4} borderWidth="1px" borderRadius="lg" bg={bgColor}>
        <Text>Error loading poll options</Text>
      </Box>
    );
  }

  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      p={6}
      borderWidth="1px"
      borderRadius="xl"
      bg={bgColor}
      borderColor={borderColor}
      shadow="sm"
    >
      <VStack align="stretch" spacing={4}>
        <Flex justify="space-between" align="center">
          <HStack>
            <Link as={RouterLink} to={`/profile/${poll.userId}`}>
              <Avatar size="sm" src={poll.userPhotoURL} name={poll.userName} />
            </Link>
            <VStack align="start" spacing={0}>
              <Link as={RouterLink} to={`/profile/${poll.userId}`}>
                <Text fontWeight="medium">{poll.userName}</Text>
              </Link>
              <Text fontSize="sm" color="gray.500">
                {formatTimeAgo(poll.timestamp?.toDate())}
              </Text>
            </VStack>
          </HStack>
          <Badge colorScheme="purple">Poll</Badge>
        </Flex>

        <Box>
          <Heading size="md" mb={2}>{poll.question}</Heading>
          {poll.description && (
            <Text color="gray.500" fontSize="sm">{poll.description}</Text>
          )}
        </Box>

        <SimpleGrid columns={1} spacing={3}>
          {localPoll.options.map((option, index) => (
            <PollOption
              key={index}
              option={option}
              totalVotes={totalVotes}
              onVote={() => handleVote(index)}
              hasVoted={hasVoted}
              isSelected={selectedOption === index}
            />
          ))}
        </SimpleGrid>

        <Flex justify="space-between" align="center" fontSize="sm" color="gray.500">
          <HStack>
            <Icon as={FaChartBar} />
            <Text>{totalVotes} total votes</Text>
          </HStack>
          {poll.endDate && (
            <Tooltip label={new Date(poll.endDate).toLocaleDateString()}>
              <HStack>
                <Icon as={FaClock} />
                <Text>Ends {formatTimeAgo(new Date(poll.endDate))}</Text>
              </HStack>
            </Tooltip>
          )}
        </Flex>
      </VStack>
    </MotionBox>
  );
};

const Polls = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [polls, setPolls] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  useEffect(() => {
    const fetchPolls = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'polls'));
        const fetchedPolls = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setPolls(fetchedPolls);
      } catch (error) {
        console.error("Error fetching polls:", error);
        toast({
          title: "Error loading polls",
          status: "error",
          duration: 3000,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchPolls();
  }, []);

  const handleCreatePoll = () => {
    if (!user) {
      toast({
        title: "Login Required",
        description: "Please login to create polls",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    navigate('/create-post?type=poll');
  };

  return (
    <Container maxW="4xl" py={8}>
      <VStack spacing={8} align="stretch">
        <Flex justify="space-between" align="center">
          <Heading size="lg">Coffee Polls</Heading>
          <Button
            leftIcon={<Icon as={FaVoteYea} />}
            colorScheme="blue"
            onClick={handleCreatePoll}
          >
            {user ? 'Create Poll' : 'Login to Create'}
          </Button>
        </Flex>

        {loading ? (
          <VStack spacing={4}>
            {[...Array(3)].map((_, i) => (
              <Skeleton key={i} height="200px" width="100%" borderRadius="xl" />
            ))}
          </VStack>
        ) : polls.length === 0 ? (
          <Box 
            textAlign="center" 
            py={10}
            bg={useColorModeValue('gray.50', 'gray.700')}
            borderRadius="xl"
          >
            <Icon as={FaChartBar} boxSize={10} color="gray.400" mb={4} />
            <Text fontSize="lg" color="gray.500">No polls available at the moment</Text>
            <Text fontSize="sm" color="gray.400" mt={2}>
              {user ? 'Be the first to create a poll!' : 'Login to create the first poll!'}
            </Text>
          </Box>
        ) : (
          <VStack spacing={6} align="stretch">
            {polls.map(poll => (
              <PollCard key={poll.id} poll={poll} />
            ))}
          </VStack>
        )}
      </VStack>
    </Container>
  );
};

export default Polls;
