import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, Button, VStack, HStack, Avatar, Tabs, TabList, TabPanels, Tab, TabPanel, Image } from '@chakra-ui/react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const MeetupDetails = () => {
  const [meetup, setMeetup] = useState({
    title: "NOVA Watches & Coffee meetup 10/27/2024",
    location: "McLean, VA, US",
    link: "https://www.meetup.com/nova-watches-coffee/",
    host: {
      name: "ImNevix",
      avatar: "path/to/avatar.jpg",
      pastMeetups: 12
    },
    date: "Sun, October 27 at 11:00 AM EDT",
    mapCenter: { lat: 38.9339, lng: -77.1773 },
    attendees: ["User1", "User2"],
    comments: [
      {
        author: "ImNevix",
        text: "Looking forward to it!",
        hashtags: ["#meetups", "#dmv", "#nova", "#watchesandcoffee", "#collecting"],
        reactions: ["🔥", "👏"]
      }
    ]
  });

  return (
    <Box maxW="4xl" mx="auto" mt={8} p={6}>
      <VStack align="start" spacing={4}>
        <Image src="path/to/meetup-image.jpg" alt="Meetup" />
        <Heading as="h1" size="xl">{meetup.title}</Heading>
        <Text>{meetup.location} - <a href={meetup.link}>{meetup.link}</a></Text>
        <HStack>
          <Avatar src={meetup.host.avatar} />
          <Text>Hosted by {meetup.host.name} - {meetup.host.pastMeetups} past meetups</Text>
        </HStack>
        <Text>{meetup.date}</Text>
        <LoadScript googleMapsApiKey="AIzaSyD_nEcml7BKJrV25hk_FXs4-oHoAvbQ6Jg">
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '200px' }}
            center={meetup.mapCenter}
            zoom={15}
          >
            <Marker position={meetup.mapCenter} />
          </GoogleMap>
        </LoadScript>
        <HStack spacing={4}>
          <Button colorScheme="green">Attend</Button>
          <Button>Interested</Button>
          <Button>Invite</Button>
          <Button>Share</Button>
        </HStack>
        <Tabs variant="enclosed" mt={4}>
          <TabList>
            <Tab>About</Tab>
            <Tab>Going {meetup.attendees.length}</Tab>
            <Tab>Interested</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Text>About the meetup...</Text>
            </TabPanel>
            <TabPanel>
              <VStack align="start">
                {meetup.attendees.map((attendee, index) => (
                  <HStack key={index}>
                    <Avatar size="sm" />
                    <Text>{attendee}</Text>
                  </HStack>
                ))}
              </VStack>
            </TabPanel>
            <TabPanel>
              <Text>Interested attendees...</Text>
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Heading as="h2" size="lg" mt={8}>Comments</Heading>
        <VStack align="start" spacing={4}>
          {meetup.comments.map((comment, index) => (
            <Box key={index} p={4} borderWidth="1px" borderRadius="lg">
              <HStack>
                <Avatar size="sm" />
                <Text fontWeight="bold">{comment.author}</Text>
              </HStack>
              <Text>{comment.text}</Text>
              <HStack>
                {comment.hashtags.map((tag, index) => (
                  <Text key={index} color="blue.500">{tag}</Text>
                ))}
              </HStack>
              <HStack>
                {comment.reactions.map((reaction, index) => (
                  <Text key={index}>{reaction}</Text>
                ))}
              </HStack>
            </Box>
          ))}
        </VStack>
      </VStack>
    </Box>
  );
};

export default MeetupDetails;