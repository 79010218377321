import React from 'react';
import { Box, Container, useBreakpointValue } from '@chakra-ui/react';
import { useSidebar } from '../contexts/SidebarContext';
import Sidebar from './Sidebar';
import MobileNav from './MobileNav';
import Header from './Header';

function Layout({ children }) {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { isSidebarOpen } = useSidebar();

  return (
    <Box minH="100vh" display="flex" flexDirection="column">
      {/* Header */}
      <Header />

      {/* Main Layout */}
      <Box 
        pt={{ base: "60px", md: "72px" }}
        display="flex"
        flex="1"
      >
        {/* Desktop Sidebar */}
        {!isMobile && <Sidebar />}

        {/* Main Content */}
        <Box
          flex="1"
          ml={{ base: 0, md: "240px" }}
          transition="margin-left 0.3s ease"
          width="100%"
        >
          <Container 
            maxW="container.xl" 
            px={{ base: 4, md: 6 }}
            pb={{ base: "80px", md: 6 }} // Account for mobile nav
            height="100%"
          >
            {children}
          </Container>
        </Box>

        {/* Mobile Navigation */}
        {isMobile && <MobileNav />}
      </Box>
    </Box>
  );
}

export default Layout;
