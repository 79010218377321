import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Image,
  useColorModeValue,
  Button,
  Link,
  HStack,
  Icon,
} from '@chakra-ui/react';
import { FaInstagram, FaEnvelope } from 'react-icons/fa';

const Contact = () => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <Container maxW="container.md" py={8}>
      <VStack spacing={8} align="stretch">
        <Box 
          bg={bgColor} 
          p={8} 
          borderRadius="xl" 
          boxShadow="sm"
        >
          <VStack spacing={6} align="stretch">
            <Image
              src="/beanscenelogo.png"
              alt="BeanScene Logo"
              height="60px"
              objectFit="contain"
              mx="auto"
              mb={4}
            />
            
            <Heading size="lg" textAlign="center">Contact Us</Heading>
            
            <Text color={textColor}>
              We'd love to hear from you! Whether you have questions, suggestions, 
              or just want to share your coffee experience, feel free to reach out 
              through any of these channels:
            </Text>

            <VStack spacing={4} align="stretch" pt={4}>
              <Link href="mailto:hello@beanscene.de" isExternal>
                <Button
                  leftIcon={<FaEnvelope />}
                  width="full"
                  size="lg"
                  colorScheme="blue"
                  variant="outline"
                >
                  hello@beanscene.de
                </Button>
              </Link>

              <Link href="https://instagram.com/beanscene.de" isExternal>
                <Button
                  leftIcon={<FaInstagram />}
                  width="full"
                  size="lg"
                  colorScheme="pink"
                  variant="outline"
                >
                  @beanscene.de
                </Button>
              </Link>
            </VStack>

            <Box pt={6}>
              <Heading size="md" mb={4}>Response Time</Heading>
              <Text color={textColor}>
                We aim to respond to all inquiries within 24-48 hours during business days. 
                For urgent matters, email is the fastest way to reach us.
              </Text>
            </Box>

            <Box>
              <Heading size="md" mb={4}>Feedback</Heading>
              <Text color={textColor}>
                Your feedback helps us improve BeanScene. We're constantly working to make 
                the platform better for our coffee community, and your input is invaluable 
                in this process.
              </Text>
            </Box>

            <Box>
              <Heading size="md" mb={4}>Business Inquiries</Heading>
              <Text color={textColor}>
                For business partnerships, collaborations, or press inquiries, 
                please email us at partnerships@beanscene.de
              </Text>
            </Box>
          </VStack>
        </Box>
      </VStack>
    </Container>
  );
};

export default Contact; 