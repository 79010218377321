export const brewMethods = [
  'Pour Over',
  'French Press',
  'Espresso',
  'AeroPress',
  'Chemex',
  'Drip',
  'Cold Brew',
  'Moka Pot',
  'Siphon',
  'Turkish',
];

export const addBrewMethod = async (newMethod) => {
  // In a real application, you would add this to your database
  brewMethods.push(newMethod);
};
