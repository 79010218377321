import React from 'react';
import UnifiedPostForm from './UnifiedPostForm';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { useToast } from '@chakra-ui/react';

const ReviewForm = ({ isOpen, onClose }) => {
  const { user } = useAuth();
  const toast = useToast();

  const fields = [
    { name: 'title', label: 'Title', type: 'text', required: true },
    { name: 'company', label: 'Company/Brand', type: 'text', required: true },
    { name: 'productName', label: 'Product Name', type: 'text', required: true },
    { name: 'photo', label: 'Photo', type: 'file' },
    { name: 'ratings', label: 'Ratings', type: 'ratingGroup', fields: [
      { id: 'overall', label: 'Overall Rating', description: 'Your overall impression' },
      { id: 'value', label: 'Value for Money', description: 'Was it worth the price?' },
      { id: 'quality', label: 'Quality', description: 'Build quality and materials' },
      { id: 'usability', label: 'Usability', description: 'How easy is it to use?' }
    ]},
    { name: 'pros', label: 'Pros', type: 'dynamicList', placeholder: 'Add a positive point' },
    { name: 'cons', label: 'Cons', type: 'dynamicList', placeholder: 'Add an improvement point' },
    { name: 'body', label: 'Detailed Review', type: 'richText' },
    { name: 'recommend', label: 'Would you recommend this product?', type: 'select', options: [
      { value: 'yes', label: 'Yes, I recommend it' },
      { value: 'no', label: "No, I don't recommend it" }
    ]},
    { name: 'tags', label: 'Tags', type: 'tags' }
  ];

  const handleSubmit = async (data) => {
    try {
      await addDoc(collection(db, 'posts'), {
        ...data,
        userId: user.uid,
        userName: user.displayName,
        userPhotoURL: user.photoURL,
        timestamp: serverTimestamp(),
        type: 'review',
        likes: 0,
        comments: 0,
        likedBy: [],
      });

      toast({
        title: 'Review posted successfully',
        status: 'success',
        duration: 3000,
      });
      onClose();
    } catch (error) {
      console.error('Error submitting review:', error);
      toast({
        title: 'Error submitting review',
        description: error.message,
        status: 'error',
        duration: 3000,
      });
    }
  };

  return (
    <UnifiedPostForm
      title="Write Review"
      fields={fields}
      onSubmit={handleSubmit}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

export default ReviewForm;
