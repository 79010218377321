import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider } from './contexts/ThemeContext';
import { initGoogleMaps } from './utils/googleMaps';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import './index.css';

console.log('🚀 main.jsx - Starting initialization');

// Performance monitoring
const perfMetrics = {
  firstPaint: 0,
  firstContentfulPaint: 0,
  domLoaded: 0,
};

// Add performance marks
performance.mark('appInit');

// Add more detailed error tracking
const errorLogs = [];

window.onerror = function(msg, url, line, col, error) {
  const errorDetails = {
    message: msg,
    url: url || 'unknown',
    line: line || 0,
    col: col || 0,
    error: error,
    stack: error?.stack || 'no stack available',
    timestamp: new Date().toISOString(),
    platform: Capacitor.getPlatform()
  };
  
  console.error('🔴 main.jsx - Global error:', errorDetails);
  errorLogs.push(errorDetails);
  
  return true;
};

// Initialize platform-specific features
const initializePlatform = async () => {
  performance.mark('platformInit');
  if (Capacitor.isNativePlatform()) {
    try {
      if (Capacitor.getPlatform() === 'ios') {
        await StatusBar.setStyle({ style: Style.Dark });
        // Enable iOS swipe back gesture
        document.body.style.overscrollBehavior = 'auto';
        // Add iOS-specific optimizations
        document.documentElement.style.webkitTapHighlightColor = 'transparent';
        document.documentElement.style.webkitTouchCallout = 'none';
        document.documentElement.style.webkitOverflowScrolling = 'touch';
      }
    } catch (error) {
      console.warn('Platform initialization error:', error);
    }
  }
  performance.mark('platformInitEnd');
  performance.measure('Platform Initialization', 'platformInit', 'platformInitEnd');
};

// Initialize Google Maps before rendering
const initializeApp = async () => {
  try {
    // Initialize platform features first
    await initializePlatform();
    
    console.log('🗺️ main.jsx - Initializing Google Maps');
    await initGoogleMaps().catch(error => {
      console.error('Failed to initialize Google Maps:', error);
    });
    console.log('✅ main.jsx - Google Maps initialized');

    // Record performance metrics
    perfMetrics.firstPaint = performance.getEntriesByType('paint')
      .find(entry => entry.name === 'first-paint')?.startTime;
    perfMetrics.firstContentfulPaint = performance.getEntriesByType('paint')
      .find(entry => entry.name === 'first-contentful-paint')?.startTime;
  } catch (error) {
    console.error('❌ main.jsx - Error during initialization:', error);
  }
  
  // Continue with app rendering regardless of Maps status
  await renderApp();
};

// Separate the rendering logic
const renderApp = async () => {
  try {
    console.log('🎨 main.jsx - Creating React root');
    const root = ReactDOM.createRoot(document.getElementById('root'));
    
    root.render(
      <React.StrictMode>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </React.StrictMode>
    );
    
    console.log('✅ main.jsx - Render complete');

    // Record DOM loaded time
    perfMetrics.domLoaded = performance.now();

    // Hide splash screen after render with fade
    if (Capacitor.isNativePlatform()) {
      await SplashScreen.hide({
        fadeOutDuration: 500
      });
    }

    // Log performance metrics
    performance.mark('appLoaded');
    performance.measure('App Initialization', 'appInit', 'appLoaded');
    
    console.log('📊 Performance Metrics:', {
      ...perfMetrics,
      measures: performance.getEntriesByType('measure')
    });
  } catch (error) {
    console.error('❌ main.jsx - Error during render:', error);
  }
};

// Start initialization
initializeApp().catch(error => {
  console.error('Failed to initialize app:', error);
});

// Debug logs
setTimeout(() => {
  console.log('📊 main.jsx - Error logs:', errorLogs);
}, 1000);
