import React from 'react';
import { Badge } from '@chakra-ui/react';

export const PostTypeBadge = ({ type }) => {
  const badgeColors = {
    post: 'blue',
    review: 'green',
    brew: 'orange',
    meetup: 'purple'
  };

  return (
    <Badge 
      colorScheme={badgeColors[type] || 'gray'}
      borderRadius="full"
      px={2}
      fontSize="xs"
      textTransform="capitalize"
    >
      {type}
    </Badge>
  );
};

export default PostTypeBadge; 