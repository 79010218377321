import React from 'react';
import { 
  Box, 
  VStack, 
  Text, 
  Icon, 
  useColorModeValue,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  useBreakpointValue,
  Flex
} from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaCheck, FaUsers, FaFolder, FaCalendar, FaStar, FaPoll, FaCoffee, FaTags, FaDatabase, FaMapMarkerAlt } from 'react-icons/fa';
import { useSidebar } from '../contexts/SidebarContext';
import { motion } from 'framer-motion';

const SidebarItem = ({ icon: Icon, text, to }) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  const activeBg = useColorModeValue('blue.500', 'blue.200');
  const activeColor = useColorModeValue('white', 'gray.800');
  const hoverBg = useColorModeValue('gray.100', 'gray.700');

  // Function to strip HTML tags
  const stripHtml = (html) => {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  return (
    <Link to={to}>
      <motion.div
        whileHover={{ x: 4 }}
        transition={{ duration: 0.2 }}
      >
        <Flex
          align="center"
          p={3}
          mx={3}
          borderRadius="lg"
          role="group"
          cursor="pointer"
          bg={isActive ? activeBg : 'transparent'}
          color={isActive ? activeColor : 'inherit'}
          _hover={{
            bg: isActive ? activeBg : hoverBg,
          }}
          transition="all 0.2s"
        >
          <Icon size={20} />
          <Text ml={4} fontWeight="medium">
            {typeof text === 'string' ? stripHtml(text) : text}
          </Text>
        </Flex>
      </motion.div>
    </Link>
  );
};

const SidebarContent = ({ onClick }) => {
  const location = useLocation();
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  
  const items = [
    { icon: FaHome, text: "Home", to: "/" },
    { icon: FaCoffee, text: "Coffee of the Day", to: "/brewcheck" },
    { icon: FaUsers, text: "People", to: "/people" },
    { icon: FaMapMarkerAlt, text: "Coffee Places", to: "/places" },
    { icon: FaFolder, text: "Collections", to: "/collections" },
    { icon: FaCalendar, text: "Meetups", to: "/meetups" },
    { icon: FaStar, text: "Reviews", to: "/reviews" },
    { icon: FaPoll, text: "Polls", to: "/polls" },
    { icon: FaCoffee, text: "NCA", to: "/nca" },
    { icon: FaTags, text: "All topics", to: "/topics" },
    { icon: FaDatabase, text: "Database", to: "/database" }
  ];

  return (
    <VStack align="stretch" spacing={1} bg={bgColor} h="full" py={4}>
      {items.map((item) => (
        <SidebarItem
          key={item.to}
          icon={item.icon}
          text={item.text}
          to={item.to}
          isActive={location.pathname === item.to}
          onClick={onClick}
        />
      ))}
    </VStack>
  );
};

const Sidebar = () => {
  const { isSidebarOpen, setIsSidebarOpen } = useSidebar();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const scrollbarColor = useColorModeValue('gray.300', 'gray.600');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const onClose = () => setIsSidebarOpen(false);

  if (!isMobile) {
    return (
      <Box
        position="fixed"
        left={0}
        width="240px"
        height="100vh"
        top="72px"
        bg={bgColor}
        borderRight="1px solid"
        borderColor={borderColor}
        display={{ base: 'none', md: 'block' }}
        overflowY="auto"
        pb={8}
        css={{
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: scrollbarColor,
            borderRadius: '24px',
          },
        }}
      >
        <SidebarContent />
      </Box>
    );
  }

  return (
    <Drawer
      isOpen={isSidebarOpen}
      placement="left"
      onClose={onClose}
      size="full"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">Menu</DrawerHeader>
        <DrawerBody p={0}>
          <SidebarContent onClick={onClose} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default Sidebar;
