import { useState } from 'react';
import { doc, updateDoc, increment, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../firebase';
import { useToast } from '@chakra-ui/react';

export const useBrewVotes = (refetchPosts) => {
  const [votingPost, setVotingPost] = useState(null);
  const toast = useToast();

  const handleVote = async (postId, userId, currentVotes = [], onSuccess) => {
    if (!userId) {
      toast({
        title: "Login Required",
        description: "Please login to vote for your favorite coffee of the day",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top"
      });
      return;
    }

    try {
      setVotingPost(postId);
      const postRef = doc(db, 'posts', postId);
      const hasVoted = currentVotes.includes(userId);

      await updateDoc(postRef, {
        votes: increment(hasVoted ? -1 : 1),
        votedBy: hasVoted ? arrayRemove(userId) : arrayUnion(userId)
      });

      if (onSuccess) onSuccess(!hasVoted);
      
      toast({
        title: hasVoted ? 'Vote removed' : 'Vote recorded',
        status: 'success',
        duration: 2000,
      });

      // Refetch posts to update the UI
      await refetchPosts();
    } catch (error) {
      console.error('Error voting:', error);
      toast({
        title: 'Error recording vote',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setVotingPost(null);
    }
  };

  return {
    handleVote,
    votingPost
  };
}; 