import React, { useState, useEffect } from 'react';
import { 
  Box, 
  HStack, 
  Avatar, 
  Text, 
  VStack,
  useColorModeValue,
  useColorMode,
  IconButton,
  Center,
  Skeleton,
  SkeletonCircle
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaPlus } from 'react-icons/fa';
import { useAuth } from '../../contexts/AuthContext';
import { getStoriesForTimeline } from '../../utils/firebaseFunctions';
import StoryViewer from './StoryViewer';
import CreateStoryModal from './CreateStoryModal';
import { useTheme } from '../../contexts/ThemeContext';
import { formatTimeAgo } from '../../utils/timeAgo';

const MotionBox = motion(Box);

const AddStoryButton = () => (
  <VStack spacing={0.5} w="64px">
    <Center
      w="40px"
      h="40px"
      borderRadius="full"
      cursor="pointer"
      as={motion.div}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      color="blue.400"
      _hover={{ color: 'blue.500' }}
    >
      <FaPlus size="16px" />
    </Center>
    <Text 
      fontSize="xs" 
      fontWeight="medium"
      color="gray.500"
    >
      Add Story
    </Text>
  </VStack>
);

const StoryBubble = ({ story, user, onClick, hasUnviewed, timeAgo }) => {
  const borderColor = useColorModeValue('blue.500', 'blue.300');
  
  return (
    <VStack spacing={0.5} w="64px">
      <MotionBox
        position="relative"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        cursor="pointer"
        onClick={onClick}
      >
        <Avatar
          size="sm"
          src={user.photoURL}
          name={user.displayName}
          border="2px solid"
          borderColor={hasUnviewed ? borderColor : 'gray.400'}
          padding="1px"
          bg={useColorModeValue('white', 'gray.800')}
        />
        {hasUnviewed && (
          <Box
            position="absolute"
            top="-1px"
            right="-1px"
            w="3px"
            h="3px"
            borderRadius="full"
            bg={borderColor}
          />
        )}
      </MotionBox>
      <Text 
        fontSize="xs" 
        fontWeight="medium"
        noOfLines={1}
        textAlign="center"
      >
        {user.displayName}
      </Text>
      <Text
        fontSize="10px"
        color="gray.500"
        noOfLines={1}
      >
        {timeAgo}
      </Text>
    </VStack>
  );
};

const StoriesBar = () => {
  const { user } = useAuth();
  const { colorMode } = useColorMode();
  const [stories, setStories] = useState([]);
  const [selectedStory, setSelectedStory] = useState(null);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Updated color values
  const bgColor = useColorModeValue('white', 'gray.900');
  const textColor = useColorModeValue('gray.800', 'white');
  const subTextColor = useColorModeValue('gray.600', 'gray.400');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const handleStoryClick = (story) => {
    setSelectedStory(story);
    setIsViewerOpen(true);
  };

  useEffect(() => {
    const fetchStories = async () => {
      try {
        setIsLoading(true);
        let userStories = [];
        let publicStories = [];

        if (user) {
          // First fetch stories from followed users
          const followedIds = [user.uid, ...(user.following || [])];
          if (followedIds.length > 0) {
            userStories = await getStoriesForTimeline(followedIds);
          }

          // Then fetch other public stories
          publicStories = await getStoriesForTimeline(null);
          // Filter out stories we already have from followed users
          publicStories = publicStories.filter(
            story => !followedIds.includes(story.userId)
          );
        } else {
          // For non-logged in users, only fetch public stories
          publicStories = await getStoriesForTimeline(null);
        }

        const now = new Date();
        const filterValidStories = (stories) => stories.filter(story => {
          const expiryTime = story.createdAt.toDate();
          expiryTime.setHours(expiryTime.getHours() + 24);
          return expiryTime > now;
        });

        // Combine and sort stories
        const validUserStories = filterValidStories(userStories);
        const validPublicStories = filterValidStories(publicStories);
        
        setStories([...validUserStories, ...validPublicStories]);
      } catch (error) {
        console.error('Error fetching stories:', error);
        setStories([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStories();
  }, [user]);

  // Don't render anything for non-logged in users if there are no stories
  if (!user && !isLoading && stories.length === 0) {
    return null;
  }

  const handleReaction = async (storyId, reaction) => {
    if (!user) {
      toast({
        title: "Login Required",
        description: "Please login to react to stories",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      await addReactionToStory(storyId, {
        userId: user.uid,
        userName: user.displayName,
        userPhoto: user.photoURL,
        reaction,
        timestamp: new Date()
      });

      // Update local state
      setStories(prevStories => 
        prevStories.map(story => 
          story.id === storyId 
            ? {
                ...story,
                reactions: [...(story.reactions || []), {
                  userId: user.uid,
                  reaction,
                  timestamp: new Date()
                }]
              }
            : story
        )
      );
    } catch (error) {
      console.error('Error adding reaction:', error);
      toast({
        title: "Error",
        description: "Could not add reaction",
        status: "error",
        duration: 3000,
      });
    }
  };

  return (
    <Box 
      w="full" 
      maxW="100%" 
      overflow="hidden"
      mb={3}
      py={2}
      px={3}
      bg={bgColor}
      borderRadius="xl"
      borderWidth="1px"
      borderColor={borderColor}
    >
      <HStack 
        spacing={3} 
        overflowX="auto" 
        css={{
          '&::-webkit-scrollbar': { display: 'none' },
          'scrollbarWidth': 'none',
          'msOverflowStyle': 'none'
        }}
      >
        {user && (
          <Box 
            onClick={() => setIsCreateModalOpen(true)}
            as={motion.div}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <AddStoryButton />
          </Box>
        )}

        {isLoading ? (
          [...Array(5)].map((_, i) => (
            <VStack key={i} spacing={0.5} w="64px">
              <SkeletonCircle size="40px" />
              <Skeleton height="10px" width="40px" />
            </VStack>
          ))
        ) : (
          <AnimatePresence>
            {stories.map((story, index) => (
              <motion.div
                key={story.id}
                initial={{ opacity: 0, x: 20 }}
                animate={{ 
                  opacity: 1, 
                  x: 0,
                  transition: { delay: index * 0.1 }
                }}
                exit={{ opacity: 0, x: -20 }}
              >
                <StoryBubble
                  story={story}
                  user={story.user}
                  onClick={() => handleStoryClick(story)}
                  hasUnviewed={user ? !story.views?.some(view => view.userId === user?.uid) : true}
                  timeAgo={formatTimeAgo(story.createdAt.toDate())}
                  isFollowed={user?.following?.includes(story.userId)}
                />
              </motion.div>
            ))}
          </AnimatePresence>
        )}
      </HStack>

      <StoryViewer
        isOpen={isViewerOpen}
        onClose={() => setIsViewerOpen(false)}
        story={selectedStory}
        currentUser={user}
        onReaction={handleReaction}
      />

      <CreateStoryModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
      />
    </Box>
  );
};

export default StoriesBar;