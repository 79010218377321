import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  Container,
  Heading,
  Textarea,
  Select,
  useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { brewMethods } from '../data/brewMethods';
import { roastLevels } from '../data/roastLevels';

const EditProfile = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const bgColor = useColorModeValue('white', 'gray.800');
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    displayName: '',
    username: '',
    bio: '',
    favoriteCoffee: '',
    preferredRoast: '',
    brewingMethod: '',
    interests: '',
  });

  // Fetch current user data
  useEffect(() => {
    const fetchUserData = async () => {
      if (!user?.uid) return;

      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setFormData({
            displayName: userData.displayName || '',
            username: userData.username || '',
            bio: userData.bio || '',
            favoriteCoffee: userData.favoriteCoffee || '',
            preferredRoast: userData.preferredRoast || '',
            brewingMethod: userData.brewingMethod || '',
            interests: userData.interests?.join(', ') || '',
          });
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        toast({
          title: 'Error loading profile data',
          status: 'error',
          duration: 3000,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [user, toast]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        ...formData,
        interests: formData.interests.split(',').map(i => i.trim()).filter(i => i),
      });

      toast({
        title: 'Profile updated successfully',
        status: 'success',
        duration: 3000,
      });

      navigate(`/profile/${user.uid}`);
    } catch (error) {
      console.error('Error updating profile:', error);
      toast({
        title: 'Error updating profile',
        description: error.message,
        status: 'error',
        duration: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Container maxW="container.sm" py={8}>
        <Box bg={bgColor} p={6} borderRadius="xl" shadow="sm">
          <Heading size="lg" mb={6}>Loading...</Heading>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxW="container.sm" py={8}>
      <Box bg={bgColor} p={6} borderRadius="xl" shadow="sm">
        <Heading size="lg" mb={6}>Edit Profile</Heading>
        <form onSubmit={handleSubmit}>
          <VStack spacing={4} align="stretch">
            <FormControl>
              <FormLabel>Display Name</FormLabel>
              <Input
                name="displayName"
                value={formData.displayName}
                onChange={handleChange}
                placeholder="Your display name"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Username</FormLabel>
              <Input
                name="username"
                value={formData.username}
                onChange={handleChange}
                placeholder="Your username"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Bio</FormLabel>
              <Textarea
                name="bio"
                value={formData.bio}
                onChange={handleChange}
                placeholder="Tell us about yourself"
                rows={4}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Favorite Coffee</FormLabel>
              <Input
                name="favoriteCoffee"
                value={formData.favoriteCoffee}
                onChange={handleChange}
                placeholder="Your favorite coffee"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Preferred Roast</FormLabel>
              <Select
                name="preferredRoast"
                value={formData.preferredRoast}
                onChange={handleChange}
                placeholder="Select roast level"
              >
                {roastLevels.map(roast => (
                  <option key={roast} value={roast}>{roast}</option>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Brewing Method</FormLabel>
              <Select
                name="brewingMethod"
                value={formData.brewingMethod}
                onChange={handleChange}
                placeholder="Select brewing method"
              >
                {brewMethods.map(method => (
                  <option key={method} value={method}>{method}</option>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Interests (comma-separated)</FormLabel>
              <Input
                name="interests"
                value={formData.interests}
                onChange={handleChange}
                placeholder="Coffee, Espresso, Latte Art"
              />
            </FormControl>

            <Button 
              type="submit" 
              colorScheme="blue" 
              mt={4}
              isLoading={loading}
            >
              Save Changes
            </Button>
          </VStack>
        </form>
      </Box>
    </Container>
  );
};

export default EditProfile;
