import React, { useState, useEffect } from 'react';
import {
  Box, VStack, HStack, Avatar, Text, Button, Spinner, Link,
  useColorModeValue, Input, InputGroup, InputLeftElement,
  SimpleGrid, Badge, Icon, Flex, Stat, StatLabel, StatNumber,
  Menu, MenuButton, MenuList, MenuItem, IconButton,
  Image, Tooltip, Divider, Spacer
} from '@chakra-ui/react';
import {
  collection, getDocs, updateDoc, doc, arrayUnion, arrayRemove, 
  getDoc, query, where, orderBy
} from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { Link as RouterLink } from 'react-router-dom';
import { FaSearch, FaSortAmountDown, FaFilter, FaCoffee, 
         FaHeart, FaComment, FaStar, FaUserFriends } from 'react-icons/fa';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const People = () => {
  const { user } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('contributions');
  const [userStats, setUserStats] = useState({});
  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  useEffect(() => {
    const fetchUsersAndStats = async () => {
      try {
        // Fetch users
        const usersSnapshot = await getDocs(collection(db, 'users'));
        const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Fetch posts to calculate stats
        const postsSnapshot = await getDocs(collection(db, 'posts'));
        const stats = {};

        postsSnapshot.docs.forEach(post => {
          const data = post.data();
          if (!stats[data.userId]) {
            stats[data.userId] = {
              posts: 0,
              reviews: 0,
              likes: 0,
              comments: 0,
              totalRating: 0,
              ratingCount: 0
            };
          }

          stats[data.userId].posts++;
          if (data.type === 'review') stats[data.userId].reviews++;
          if (data.likes) stats[data.userId].likes += data.likes;
          if (data.comments) stats[data.userId].comments += data.comments.length;
          if (data.rating) {
            stats[data.userId].totalRating += data.rating;
            stats[data.userId].ratingCount++;
          }
        });

        setUserStats(stats);
        setUsers(usersData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchUsersAndStats();
  }, []);

  const handleFollow = async (userId) => {
    if (!user) return;
    try {
      const userRef = doc(db, 'users', userId);
      const currentUserRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      const currentUserDoc = await getDoc(currentUserRef);

      if (userDoc.exists() && currentUserDoc.exists()) {
        const isFollowing = userDoc.data().followers?.includes(user.uid);
        
        if (isFollowing) {
          await updateDoc(userRef, {
            followers: arrayRemove(user.uid)
          });
          await updateDoc(currentUserRef, {
            following: arrayRemove(userId)
          });
        } else {
          await updateDoc(userRef, {
            followers: arrayUnion(user.uid)
          });
          await updateDoc(currentUserRef, {
            following: arrayUnion(userId)
          });
        }

        // Update local state
        setUsers(users.map(u => 
          u.id === userId 
            ? {...u, followers: isFollowing 
                ? (u.followers || []).filter(id => id !== user.uid)
                : [...(u.followers || []), user.uid]}
            : u
        ));
      }
    } catch (error) {
      console.error('Error updating follow status:', error);
    }
  };

  const filteredAndSortedUsers = users
    .filter(user => 
      user.displayName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      const statsA = userStats[a.id] || {};
      const statsB = userStats[b.id] || {};
      
      switch (sortBy) {
        case 'contributions':
          return (statsB.posts || 0) - (statsA.posts || 0);
        case 'followers':
          return (b.followers?.length || 0) - (a.followers?.length || 0);
        case 'rating':
          const avgA = statsA.ratingCount ? statsA.totalRating / statsA.ratingCount : 0;
          const avgB = statsB.ratingCount ? statsB.totalRating / statsB.ratingCount : 0;
          return avgB - avgA;
        default:
          return 0;
      }
    });

  const UserCard = ({ userProfile }) => {
    const stats = userStats[userProfile.id] || {};
    const avgRating = stats.ratingCount ? (stats.totalRating / stats.ratingCount).toFixed(1) : 'N/A';

    return (
      <MotionBox
        whileHover={{ y: -3 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        bg={bgColor}
        borderRadius="lg"
        overflow="hidden"
        border="1px solid"
        borderColor={borderColor}
        height="300px"
        display="flex"
        flexDirection="column"
      >
        <Box flex="0 0 auto">
          {userProfile.bannerImage && (
            <Box position="relative" height="100px">
              <Image
                src={userProfile.bannerImage}
                alt="Banner"
                objectFit="cover"
                w="100%"
                h="100%"
              />
              <Box
                position="absolute"
                bottom={0}
                left={0}
                right={0}
                h="20px"
                bgGradient={`linear(to-t, ${bgColor}, transparent)`}
              />
            </Box>
          )}
        </Box>
        
        <Box p={5} flex="1 1 auto" display="flex" flexDirection="column">
          <VStack spacing={4} height="100%">
            <HStack spacing={4} width="100%">
              <Link as={RouterLink} to={
                user?.uid === userProfile.id ? 
                `/profile` : 
                `/user/${userProfile.id}`
              }>
                <Avatar 
                  size="lg" 
                  src={userProfile.photoURL} 
                  name={userProfile.displayName}
                  border="3px solid"
                  borderColor={bgColor}
                  mt={userProfile.bannerImage ? "-30px" : 0}
                  position="relative"
                />
              </Link>
              
              <VStack align="start" spacing={1} flex={1}>
                <Link as={RouterLink} to={
                  user?.uid === userProfile.id ? 
                  `/profile` : 
                  `/user/${userProfile.id}`
                }>
                  <Text fontSize="lg" fontWeight="bold">{userProfile.displayName}</Text>
                </Link>
                <HStack spacing={2} flexWrap="wrap">
                  <Badge colorScheme="green">{userProfile.favoriteBrewMethod}</Badge>
                  <Badge colorScheme="purple">{userProfile.favoriteRoast}</Badge>
                </HStack>
                <Text fontSize="sm" color="gray.500" noOfLines={1}>
                  Favorite: {userProfile.favoriteCoffeeType}
                </Text>
              </VStack>

              {user && user.uid !== userProfile.id && (
                <Button
                  onClick={() => handleFollow(userProfile.id)}
                  colorScheme={userProfile.followers?.includes(user.uid) ? 'red' : 'blue'}
                  variant={userProfile.followers?.includes(user.uid) ? 'outline' : 'solid'}
                >
                  {userProfile.followers?.includes(user.uid) ? 'Unfollow' : 'Follow'}
                </Button>
              )}
            </HStack>
            
            <Spacer />
            
            <SimpleGrid columns={4} gap={4} width="100%">
              <Tooltip label="Total Posts">
                <VStack spacing={1} align="center">
                  <Icon as={FaCoffee} color="gray.500" boxSize={4} />
                  <Text fontSize="sm" fontWeight="semibold">{stats.posts || 0}</Text>
                  <Text fontSize="xs" color="gray.500">Posts</Text>
                </VStack>
              </Tooltip>
              
              <Tooltip label="Reviews">
                <VStack spacing={1} align="center">
                  <Icon as={FaStar} color="gray.500" boxSize={4} />
                  <Text fontSize="sm" fontWeight="semibold">{stats.reviews || 0}</Text>
                  <Text fontSize="xs" color="gray.500">Reviews</Text>
                </VStack>
              </Tooltip>
              
              <Tooltip label="Followers">
                <VStack spacing={1} align="center">
                  <Icon as={FaUserFriends} color="gray.500" boxSize={4} />
                  <Text fontSize="sm" fontWeight="semibold">{userProfile.followers?.length || 0}</Text>
                  <Text fontSize="xs" color="gray.500">Followers</Text>
                </VStack>
              </Tooltip>
              
              <Tooltip label="Average Rating">
                <VStack spacing={1} align="center">
                  <Icon as={FaHeart} color="gray.500" boxSize={4} />
                  <Text fontSize="sm" fontWeight="semibold">{avgRating}</Text>
                  <Text fontSize="xs" color="gray.500">Rating</Text>
                </VStack>
              </Tooltip>
            </SimpleGrid>
          </VStack>
        </Box>
      </MotionBox>
    );
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" minH="50vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box maxW="1200px" mx="auto" px={4} py={6}>
      <VStack spacing={4} align="stretch">
        <HStack spacing={4}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Icon as={FaSearch} color="gray.500" />
            </InputLeftElement>
            <Input
              placeholder="Search users..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
          
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              leftIcon={<Icon as={FaSortAmountDown} />}
            >
              Sort
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => setSortBy('contributions')}>Contributions</MenuItem>
              <MenuItem onClick={() => setSortBy('followers')}>Followers</MenuItem>
              <MenuItem onClick={() => setSortBy('rating')}>Rating</MenuItem>
            </MenuList>
          </Menu>
        </HStack>

        <SimpleGrid 
          columns={{ base: 1, sm: 2, lg: 3 }} 
          spacing={{ base: 3, md: 4 }}
        >
          {filteredAndSortedUsers.map((userProfile) => (
            <UserCard key={userProfile.id} userProfile={userProfile} />
          ))}
        </SimpleGrid>
      </VStack>
    </Box>
  );
};

export default People;
