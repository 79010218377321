import React from 'react';
import Stories from 'react-insta-stories';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  useColorModeValue,
  IconButton,
  HStack,
  Avatar,
  Text,
  VStack,
} from '@chakra-ui/react';
import { viewStory } from '../../utils/firebaseFunctions';
import { FaTimes } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { formatTimeAgo } from '../../utils/timeAgo';

const StoryViewer = ({ isOpen, onClose, story, currentUser, onReaction }) => {
  const handleStoryEnd = async () => {
    if (story && currentUser) {
      await viewStory(story.id, currentUser.uid);
    }
    onClose();
  };

  const getRemainingTime = (createdAt) => {
    const expiryTime = new Date(createdAt.toDate());
    expiryTime.setHours(expiryTime.getHours() + 24);
    const now = new Date();
    const remaining = expiryTime - now;
    
    const hours = Math.floor(remaining / (1000 * 60 * 60));
    const minutes = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60));
    
    return `${hours}h ${minutes}m remaining`;
  };

  const reactions = ['❤️', '👍', '😮', '😢', '😂'];

  if (!story) return null;

  const stories = [
    {
      url: story.mediaUrl,
      type: story.mediaType,
      duration: 5000,
      header: {
        heading: story.user.displayName,
        subheading: (
          <HStack spacing={2} fontSize="xs" color="whiteAlpha.800">
            <Text>{formatTimeAgo(story.createdAt.toDate())}</Text>
          </HStack>
        ),
        profileImage: story.user.photoURL
      }
    }
  ];

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      size="full"
      motionPreset="slideInBottom"
      isCentered
    >
      <ModalOverlay bg="blackAlpha.900" backdropFilter="blur(10px)" />
      <ModalContent 
        bg="transparent" 
        boxShadow="none"
        position="relative"
        maxW="100vw"
        h="100vh"
        m={0}
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          transition={{ duration: 0.2 }}
          style={{ height: '100%' }}
        >
          <Box 
            height="100%" 
            display="flex" 
            alignItems="center" 
            justifyContent="center"
            position="relative"
          >
            {/* Close button */}
            <IconButton
              icon={<FaTimes />}
              position="absolute"
              top={4}
              right={4}
              zIndex={2}
              onClick={onClose}
              variant="ghost"
              color="white"
              _hover={{ bg: 'whiteAlpha.200' }}
              aria-label="Close story"
            />

            {/* Story content */}
            <Box 
              maxW="md" 
              w="full" 
              h="90vh"
              position="relative"
              borderRadius="2xl"
              overflow="hidden"
              boxShadow="2xl"
              onClick={(e) => e.stopPropagation()}
            >
              <Stories
                stories={stories}
                defaultInterval={5000}
                width="100%"
                height="100%"
                onAllStoriesEnd={handleStoryEnd}
                keyboardNavigation
                preventDefault
                storyStyles={{
                  borderRadius: '16px',
                  overflow: 'hidden'
                }}
              />
            </Box>
          </Box>
        </motion.div>
      </ModalContent>

      {currentUser && (
        <HStack 
          position="absolute" 
          bottom={4} 
          left="50%" 
          transform="translateX(-50%)"
          spacing={2}
          bg="blackAlpha.600"
          p={2}
          borderRadius="full"
        >
          {reactions.map((reaction) => (
            <IconButton
              key={reaction}
              icon={<Text fontSize="xl">{reaction}</Text>}
              variant="ghost"
              colorScheme="whiteAlpha"
              onClick={() => onReaction(story.id, reaction)}
              size="sm"
              isRound
            />
          ))}
        </HStack>
      )}
    </Modal>
  );
};

export default StoryViewer;