import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, VStack, HStack, Image, Button, Input, Textarea, useColorModeValue } from '@chakra-ui/react';
import { collection, query, orderBy, limit, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { db, auth, storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const NCA = () => {
  const [newArrivals, setNewArrivals] = useState([]);
  const [newArrival, setNewArrival] = useState({ name: '', description: '', image: null });
  const [loading, setLoading] = useState(true);

  const bgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');

  useEffect(() => {
    fetchNewArrivals();
  }, []);

  const fetchNewArrivals = async () => {
    const q = query(collection(db, 'newArrivals'), orderBy('timestamp', 'desc'), limit(10));
    const querySnapshot = await getDocs(q);
    const fetchedArrivals = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setNewArrivals(fetchedArrivals);
    setLoading(false);
  };

  const handleSubmitNewArrival = async (e) => {
    e.preventDefault();
    if (!auth.currentUser) {
      alert('You must be logged in to submit a new arrival.');
      return;
    }
    if (newArrival.name && newArrival.description && newArrival.image) {
      const imageRef = ref(storage, `newArrivals/${newArrival.image.name}`);
      await uploadBytes(imageRef, newArrival.image);
      const imageUrl = await getDownloadURL(imageRef);

      await addDoc(collection(db, 'newArrivals'), {
        ...newArrival,
        imageUrl,
        userId: auth.currentUser.uid,
        userName: auth.currentUser.displayName || auth.currentUser.email,
        timestamp: serverTimestamp()
      });
      setNewArrival({ name: '', description: '', image: null });
      fetchNewArrivals();
    }
  };

  if (loading) {
    return <Box textAlign="center" mt={8}>Loading...</Box>;
  }

  return (
    <Box maxW="4xl" mx="auto" mt={8}>
      <Heading as="h1" size="xl" mb={6} color={textColor}>New Coffee Arrivals</Heading>
      
      <Box as="form" onSubmit={handleSubmitNewArrival} mb={8} bg={bgColor} p={4} borderRadius="md" boxShadow="md">
        <Heading as="h2" size="lg" mb={4} color={textColor}>Submit a New Arrival</Heading>
        <VStack spacing={4} align="stretch">
          <Input
            placeholder="Coffee Name"
            value={newArrival.name}
            onChange={(e) => setNewArrival({...newArrival, name: e.target.value})}
            required
          />
          <Textarea
            placeholder="Description"
            value={newArrival.description}
            onChange={(e) => setNewArrival({...newArrival, description: e.target.value})}
            required
          />
          <Input
            type="file"
            accept="image/*"
            onChange={(e) => setNewArrival({...newArrival, image: e.target.files[0]})}
            required
          />
          <Button type="submit" colorScheme="blue">Submit New Arrival</Button>
        </VStack>
      </Box>

      <VStack spacing={4} align="stretch">
        {newArrivals.map(arrival => (
          <Box key={arrival.id} p={4} borderWidth={1} borderRadius="md" bg={bgColor}>
            <HStack spacing={4}>
              <Image src={arrival.imageUrl} alt={arrival.name} boxSize="100px" objectFit="cover" />
              <VStack align="start" spacing={2}>
                <Heading as="h3" size="md" color={textColor}>{arrival.name}</Heading>
                <Text color={textColor}>{arrival.description}</Text>
                <Text fontSize="sm" color={useColorModeValue('gray.600', 'gray.400')}>
                  Submitted by {arrival.userName} on {arrival.timestamp?.toDate().toLocaleString()}
                </Text>
              </VStack>
            </HStack>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default NCA;
