import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  VStack,
  Text,
  HStack,
  Icon,
  Badge,
  useColorModeValue,
  Image,
  Divider,
  Link,
  Avatar,
  Spinner,
  Center,
  Button,
  Textarea,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast,
  SimpleGrid,
  useBreakpointValue,
  IconButton
} from '@chakra-ui/react';
import {
  FaStar,
  FaPhone,
  FaGlobe,
  FaClock,
  FaDollarSign,
  FaCoffee,
  FaChevronDown,
  FaChevronUp,
  FaMapMarkerAlt,
  FaCheck,
  FaComment,
  FaImages,
  FaUtensils,
  FaDirections,
  FaGoogle,
  FaUsers,
  FaHeart,
  FaRegHeart
} from 'react-icons/fa';
import { format } from 'date-fns';
import { useAuth } from '../../contexts/AuthContext';
import { addPlace, addCheckIn, addRating, addReview, toggleFavorite } from '../../utils/firebaseFunctions';
import StarRating from '../shared/StarRating';

const PlaceDetailsView = ({ place, isModal = false, onClose }) => {
  const { user } = useAuth();
  const [showAllHours, setShowAllHours] = useState(false);
  const [isCheckedIn, setIsCheckedIn] = useState(false);
  const [userRating, setUserRating] = useState(0);
  const [reviewText, setReviewText] = useState('');
  const [isSubmittingReview, setIsSubmittingReview] = useState(false);
  const [isCheckingIn, setIsCheckingIn] = useState(false);
  const [isFavorited, setIsFavorited] = useState(false);
  const [isTogglingFavorite, setIsTogglingFavorite] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const modalRef = useRef(null);
  const contentRef = useRef(null);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const toast = useToast();

  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const secondaryTextColor = useColorModeValue('gray.600', 'gray.400');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  useEffect(() => {
    if (isModal && modalRef.current) {
      let startY = 0;
      let currentY = 0;

      const handleTouchStart = (e) => {
        startY = e.touches[0].clientY;
      };

      const handleTouchMove = (e) => {
        currentY = e.touches[0].clientY;
        const deltaY = currentY - startY;
        if (deltaY > 0) {
          modalRef.current.style.transform = `translateY(${deltaY}px)`;
          modalRef.current.style.transition = 'none';
        }
      };

      const handleTouchEnd = () => {
        const deltaY = currentY - startY;
        modalRef.current.style.transition = 'transform 0.3s ease-out';
        if (deltaY > 150) {
          modalRef.current.style.transform = 'translateY(100%)';
          setTimeout(() => {
            onClose?.();
          }, 300);
        } else {
          modalRef.current.style.transform = '';
        }
      };

      const element = modalRef.current;
      element.addEventListener('touchstart', handleTouchStart);
      element.addEventListener('touchmove', handleTouchMove);
      element.addEventListener('touchend', handleTouchEnd);

      return () => {
        element.removeEventListener('touchstart', handleTouchStart);
        element.removeEventListener('touchmove', handleTouchMove);
        element.removeEventListener('touchend', handleTouchEnd);
      };
    }
  }, [isModal, onClose]);

  useEffect(() => {
    if (user && place) {
      // Check if user has favorited this place
      setIsFavorited(place.likedBy?.includes(user.uid));
    }
  }, [user, place]);

  const handleCheckIn = async () => {
    if (!user) {
      toast({
        title: 'Login Required',
        description: 'Please login to check in to this place.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setIsCheckingIn(true);
      // First create/update the place
      await addPlace(place);
      // Then add the check-in
      await addCheckIn(place.placeId, user.uid);
      setIsCheckedIn(true);
      toast({
        title: 'Checked In!',
        description: 'Successfully checked in to ' + place.name,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error checking in:', error);
      toast({
        title: 'Error',
        description: error.message || 'Failed to check in. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsCheckingIn(false);
    }
  };

  const handleReviewSubmit = async () => {
    if (!user) {
      toast({
        title: 'Login Required',
        description: 'Please login to submit a review.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!reviewText.trim()) {
      toast({
        title: 'Review Required',
        description: 'Please write a review before submitting.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!userRating) {
      toast({
        title: 'Rating Required',
        description: 'Please add a star rating to your review.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setIsSubmittingReview(true);
      await addPlace(place);
      await addReview(place.placeId, {
        text: reviewText,
        rating: userRating
      }, user.uid);
      setReviewText('');
      toast({
        title: 'Review Submitted',
        description: 'Thank you for reviewing ' + place.name,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error submitting review:', error);
      toast({
        title: 'Error',
        description: 'Failed to submit review. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmittingReview(false);
    }
  };

  const isOpenNow = () => {
    if (!place.openingHours?.periods) return true;
    
    const now = new Date();
    const day = now.getDay();
    const currentPeriod = place.openingHours.periods.find(period => period.open.day === day);
    
    if (!currentPeriod) return false;
    
    const currentTime = now.getHours() * 100 + now.getMinutes();
    const openTime = parseInt(currentPeriod.open.time);
    const closeTime = parseInt(currentPeriod.close.time);
    
    return currentTime >= openTime && currentTime < closeTime;
  };

  const shouldShowOpenStatus = () => {
    return place.openingHours?.length > 0;
  };

  const handleNavigate = () => {
    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(place.name)}&query_place_id=${place.placeId}`;
    window.open(url, '_blank');
  };

  const calculateCommunityRating = (place) => {
    if (!place?.reviews?.length) return 0;
    return place.reviewCount > 0 ? place.rating : 0;
  };

  const communityRating = calculateCommunityRating(place);

  const handleFavoriteToggle = async () => {
    if (!user) {
      toast({
        title: 'Login Required',
        description: 'Please login to favorite places.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setIsTogglingFavorite(true);
      await toggleFavorite(place.placeId, user.uid);
      setIsFavorited(!isFavorited);
      toast({
        title: isFavorited ? 'Removed from favorites' : 'Added to favorites',
        status: 'success',
        duration: 2000,
      });
    } catch (error) {
      console.error('Error toggling favorite:', error);
      toast({
        title: 'Error',
        description: 'Failed to update favorites. Please try again.',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setIsTogglingFavorite(false);
    }
  };

  const content = (
    <VStack spacing={4} align="stretch" bg={bgColor} p={6} borderRadius="lg" borderWidth="1px" borderColor={borderColor} width="100%">
      <Tabs isFitted variant="enclosed" index={activeTab} onChange={setActiveTab}>
        <TabList mb="1em">
          <Tab>Details</Tab>
          <Tab>Reviews</Tab>
          <Tab>Photos</Tab>
          {place.menu && <Tab>Menu</Tab>}
        </TabList>

        <TabPanels>
          <TabPanel>
            {/* Main Image */}
            {place.image && (
              <Box borderRadius="lg" overflow="hidden" maxHeight="300px">
                <Image
                  src={place.image}
                  alt={place.name}
                  width="100%"
                  height="300px"
                  objectFit="cover"
                  fallback={
                    <Center height="300px" bg="gray.100">
                      <Icon as={FaCoffee} boxSize={8} color="gray.400" />
                    </Center>
                  }
                />
              </Box>
            )}

            {/* Header Section */}
            <VStack align="stretch" spacing={2} mt={4}>
              {/* Title and Favorite Row */}
              <HStack justify="space-between" align="center">
                <Text fontSize="2xl" fontWeight="bold" color={textColor}>
                  {place.name}
                </Text>
                <IconButton
                  icon={isFavorited ? <FaHeart /> : <FaRegHeart />}
                  aria-label={isFavorited ? "Remove from favorites" : "Add to favorites"}
                  colorScheme={isFavorited ? "red" : "gray"}
                  variant="ghost"
                  isLoading={isTogglingFavorite}
                  onClick={handleFavoriteToggle}
                />
              </HStack>

              {/* Address and Open Status */}
              <HStack justify="space-between" align="center">
                <VStack align="start" spacing={1}>
                  {place.address && (
                    <HStack spacing={2}>
                      <Icon as={FaMapMarkerAlt} color={secondaryTextColor} boxSize={4} />
                      <Text color={secondaryTextColor} fontSize="md">
                        {place.address}
                      </Text>
                    </HStack>
                  )}
                </VStack>
                {shouldShowOpenStatus() && (
                  <Badge
                    colorScheme={isOpenNow() ? "green" : "red"}
                    fontSize="sm"
                    px={3}
                    py={1}
                    borderRadius="full"
                  >
                    {isOpenNow() ? "OPEN" : "CLOSED"}
                  </Badge>
                )}
              </HStack>

              {/* Favorite Count */}
              {place.likedBy?.length > 0 && (
                <HStack spacing={2} color={secondaryTextColor}>
                  <Icon as={FaHeart} color="red.500" />
                  <Text fontSize="sm">
                    {place.likedBy.length} {place.likedBy.length === 1 ? 'person' : 'people'} favorited this place
                  </Text>
                </HStack>
              )}
            </VStack>

            {/* Ratings Section */}
            <VStack spacing={4} mt={6} align="stretch">
              {/* Google Rating */}
              {place.userRatingsTotal > 0 && (
                <VStack align="start" spacing={1}>
                  <HStack spacing={2}>
                    <Icon as={FaGoogle} color="red.500" boxSize={5} />
                    <Text fontSize="sm" fontWeight="medium" color={textColor}>
                      Google Rating
                    </Text>
                    <Text fontSize="sm" color={secondaryTextColor}>
                      ({place.userRatingsTotal})
                    </Text>
                  </HStack>
                  <StarRating rating={place.rating} size="lg" isReadOnly />
                </VStack>
              )}

              {/* Community Rating - only show if we have app reviews */}
              {place.reviewCount > 0 && (
                <VStack align="start" spacing={1}>
                  <HStack spacing={2}>
                    <Icon as={FaCoffee} color="brown.500" boxSize={5} />
                    <Text fontSize="sm" fontWeight="medium" color={textColor}>
                      Community Rating
                    </Text>
                    <Text fontSize="sm" color={secondaryTextColor}>
                      ({place.reviewCount})
                    </Text>
                  </HStack>
                  <StarRating 
                    rating={place.rating} 
                    size="lg" 
                    isReadOnly 
                  />
                </VStack>
              )}
            </VStack>

            {/* Action Buttons */}
            <HStack spacing={4} mt={6}>
              <Button
                leftIcon={isCheckedIn ? <FaCheck /> : <FaCoffee />}
                colorScheme={isCheckedIn ? "green" : "blue"}
                onClick={handleCheckIn}
                isLoading={isCheckingIn}
                flex="1"
              >
                {isCheckedIn ? "Checked In!" : "Check In"}
              </Button>
              <Button
                leftIcon={<FaDirections />}
                colorScheme="teal"
                onClick={handleNavigate}
                flex="1"
              >
                Navigate
              </Button>
            </HStack>

            {/* Opening Hours */}
            {place.openingHours?.length > 0 && (
              <Box mt={6}>
                <HStack 
                  justify="space-between" 
                  onClick={() => setShowAllHours(!showAllHours)}
                  cursor="pointer"
                  _hover={{ color: 'blue.500' }}
                  pb={2}
                  borderBottomWidth={1}
                  borderColor={borderColor}
                >
                  <HStack>
                    <Icon as={FaClock} color={secondaryTextColor} />
                    <Text color={textColor}>Opening Hours</Text>
                  </HStack>
                  <Icon 
                    as={showAllHours ? FaChevronUp : FaChevronDown} 
                    color={secondaryTextColor}
                  />
                </HStack>
                <VStack 
                  align="stretch" 
                  mt={2} 
                  spacing={2}
                  display={showAllHours ? "block" : "none"}
                >
                  {place.openingHours.map((hours, index) => (
                    <Text key={index} color={secondaryTextColor} fontSize="sm">
                      {hours}
                    </Text>
                  ))}
                </VStack>
              </Box>
            )}

            {/* Contact Info */}
            {(place.phone || place.website) && (
              <VStack align="stretch" spacing={2} mt={6}>
                {place.phone && (
                  <HStack>
                    <Icon as={FaPhone} color={secondaryTextColor} />
                    <Link href={`tel:${place.phone}`} color={textColor}>
                      {place.phone}
                    </Link>
                  </HStack>
                )}
                {place.website && (
                  <HStack>
                    <Icon as={FaGlobe} color={secondaryTextColor} />
                    <Link href={place.website} isExternal color={textColor}>
                      Website
                    </Link>
                  </HStack>
                )}
              </VStack>
            )}
          </TabPanel>

          <TabPanel>
            <VStack spacing={6} align="stretch">
              {/* Write Review Section */}
              <Box
                p={6}
                borderWidth="1px"
                borderRadius="md"
                borderColor={borderColor}
              >
                <Text fontSize="lg" fontWeight="bold" mb={6}>
                  Write a Review
                </Text>
                <VStack spacing={4} align="start" width="100%">
                  <Box width="100%">
                    <Text mb={2} color={textColor}>Your Rating</Text>
                    <StarRating
                      rating={userRating}
                      onRate={setUserRating}
                      size="xl"
                    />
                  </Box>
                  <Box width="100%">
                    <Text mb={2} color={textColor}>Your Review</Text>
                    <Textarea
                      placeholder="Share your experience..."
                      value={reviewText}
                      onChange={(e) => setReviewText(e.target.value)}
                      size="md"
                      resize="vertical"
                      minHeight="120px"
                    />
                  </Box>
                  <Button
                    leftIcon={<Icon as={FaComment} />}
                    colorScheme="blue"
                    onClick={handleReviewSubmit}
                    isLoading={isSubmittingReview}
                    width="full"
                    mt={2}
                  >
                    Submit Review
                  </Button>
                </VStack>
              </Box>

              <Divider />

              {/* Reviews Sections */}
              <VStack spacing={6} align="stretch">
                {/* Community Reviews - from our app */}
                <VStack spacing={4} align="stretch">
                  <Text fontSize="lg" fontWeight="bold">
                    Community Reviews {place.reviewCount > 0 && `(${place.reviewCount})`}
                  </Text>
                  
                  {place.reviewCount > 0 ? (
                    <VStack align="stretch" spacing={4}>
                      {place.reviews?.filter(review => review.userId).map((review, index) => (
                        <Box
                          key={index}
                          p={4}
                          borderWidth="1px"
                          borderRadius="md"
                          borderColor={borderColor}
                        >
                          <HStack spacing={4} mb={2}>
                            <Avatar size="sm" name={review.userId} />
                            <VStack align="start" spacing={0}>
                              <Text fontWeight="medium" color={textColor}>
                                User
                              </Text>
                              <HStack spacing={2}>
                                <StarRating 
                                  rating={review.rating} 
                                  size="sm" 
                                  isReadOnly 
                                />
                                {review.timestamp && (
                                  <Text fontSize="sm" color={secondaryTextColor}>
                                    {format(review.timestamp.toDate(), 'MMM d, yyyy')}
                                  </Text>
                                )}
                              </HStack>
                            </VStack>
                          </HStack>
                          {review.text && (
                            <Text color={secondaryTextColor} fontSize="md">
                              {review.text}
                            </Text>
                          )}
                          {review.likes > 0 && (
                            <HStack mt={2} spacing={1} color={secondaryTextColor}>
                              <Icon as={FaHeart} color="red.500" />
                              <Text fontSize="sm">{review.likes}</Text>
                            </HStack>
                          )}
                        </Box>
                      ))}
                    </VStack>
                  ) : (
                    <Text textAlign="center" color={secondaryTextColor}>
                      No community reviews yet. Be the first to review!
                    </Text>
                  )}
                </VStack>

                {/* Google Reviews */}
                {place.reviews?.some(review => review.author_name) && (
                  <VStack spacing={4} align="stretch">
                    <Divider />
                    <Text fontSize="lg" fontWeight="bold">
                      Google Reviews ({place.userRatingsTotal})
                    </Text>
                    <VStack align="stretch" spacing={4}>
                      {place.reviews
                        .filter(review => review.author_name)
                        .map((review, index) => (
                          <Box
                            key={index}
                            p={4}
                            borderWidth="1px"
                            borderRadius="md"
                            borderColor={borderColor}
                          >
                            <HStack spacing={4} mb={2}>
                              {review.profile_photo_url && (
                                <Avatar size="sm" src={review.profile_photo_url} name={review.author_name} />
                              )}
                              <VStack align="start" spacing={0}>
                                <Text fontWeight="medium" color={textColor}>
                                  {review.author_name}
                                </Text>
                                <HStack spacing={2}>
                                  <StarRating 
                                    rating={review.rating} 
                                    size="sm" 
                                    isReadOnly 
                                  />
                                  {review.time && (
                                    <Text fontSize="sm" color={secondaryTextColor}>
                                      {format(new Date(review.time * 1000), 'MMM d, yyyy')}
                                    </Text>
                                  )}
                                </HStack>
                              </VStack>
                            </HStack>
                            {review.text && (
                              <Text color={secondaryTextColor} fontSize="md">
                                {review.text}
                              </Text>
                            )}
                          </Box>
                        ))}
                    </VStack>
                  </VStack>
                )}
              </VStack>
            </VStack>
          </TabPanel>

          <TabPanel>
            <SimpleGrid columns={2} spacing={4}>
              {place.photos?.map((photo, index) => (
                <Box
                  key={index}
                  borderRadius="lg"
                  overflow="hidden"
                  cursor="pointer"
                  onClick={() => window.open(photo.url, '_blank')}
                >
                  <Image
                    src={photo.url}
                    alt={`${place.name} photo ${index + 1}`}
                    width="100%"
                    height="200px"
                    objectFit="cover"
                    fallback={
                      <Center height="200px" bg="gray.100">
                        <Icon as={FaImages} boxSize={8} color="gray.400" />
                      </Center>
                    }
                  />
                </Box>
              ))}
            </SimpleGrid>
          </TabPanel>

          {place.menu && (
            <TabPanel>
              <VStack spacing={4} align="stretch">
                <Text fontSize="lg" fontWeight="bold">
                  Menu
                </Text>
                {/* Add menu content here when available */}
                <Text color={secondaryTextColor}>
                  Menu information coming soon...
                </Text>
              </VStack>
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </VStack>
  );

  if (isModal) {
    return (
      <Box 
        ref={modalRef} 
        position="relative" 
        height="100%" 
        overflowY="auto"
        style={{ touchAction: 'pan-y' }}
      >
        <Box 
          position="sticky" 
          top={0} 
          left={0} 
          right={0} 
          height="20px" 
          zIndex={1}
          bg={bgColor}
          borderTopRadius="lg"
        >
          <Box
            width="50px"
            height="4px"
            bg="gray.300"
            borderRadius="full"
            mx="auto"
            mt={2}
          />
        </Box>
        {content}
      </Box>
    );
  }

  return content;
};

export default PlaceDetailsView;
