import React, { memo } from 'react';
import { Box, Image } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const PostImage = memo(({ imageUrl, onImageClick }) => {
  if (!imageUrl) return null;

  return (
    <MotionBox
      mb={4}
      borderRadius="lg"
      overflow="hidden"
      maxH="500px"
      whileHover={{ scale: 1.01 }}
      transition={{ duration: 0.2 }}
    >
      <Image
        src={imageUrl}
        alt="Post image"
        width="100%"
        height="100%"
        objectFit="cover"
        cursor="pointer"
        onClick={() => onImageClick(imageUrl)}
        loading="lazy"
        onError={(e) => {
          e.target.src = '/fallback-image.jpg'; // Add a fallback image
        }}
      />
    </MotionBox>
  );
});

PostImage.displayName = 'PostImage';
export default PostImage; 