import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  VStack,
  Text,
  useToast,
  Box,
  Image,
  useColorModeValue,
  Input,
  FormControl,
  FormLabel,
  Divider,
  HStack,
  InputGroup,
  InputRightElement,
  IconButton,
} from '@chakra-ui/react';
import { FaGoogle, FaEye, FaEyeSlash } from 'react-icons/fa';
import { 
  signInWithPopup, 
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword 
} from 'firebase/auth';
import { auth } from '../../firebase';

const LoginModal = ({ isOpen, onClose }) => {
  const toast = useToast();
  const bgColor = useColorModeValue('white', 'gray.800');
  const [isSignUp, setIsSignUp] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account'
      });
      await signInWithPopup(auth, provider);
      onClose();
      toast({
        title: "Welcome to BeanScene!",
        status: "success",
        duration: 3000,
      });
    } catch (error) {
      console.error('Error signing in:', error);
      toast({
        title: "Error signing in",
        description: error.message,
        status: "error",
        duration: 3000,
      });
    }
  };

  const handleEmailAuth = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      toast({
        title: "Error",
        description: "Please fill in all fields",
        status: "error",
        duration: 3000,
      });
      return;
    }

    setLoading(true);
    try {
      if (isSignUp) {
        await createUserWithEmailAndPassword(auth, email, password);
        toast({
          title: "Account created successfully!",
          status: "success",
          duration: 3000,
        });
      } else {
        await signInWithEmailAndPassword(auth, email, password);
        toast({
          title: "Welcome back!",
          status: "success",
          duration: 3000,
        });
      }
      onClose();
    } catch (error) {
      console.error('Auth error:', error);
      toast({
        title: "Authentication Error",
        description: error.message,
        status: "error",
        duration: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent 
        bg={bgColor}
        mx={4}
      >
        <ModalHeader textAlign="center">
          {isSignUp ? 'Create Account' : 'Welcome Back'}
        </ModalHeader>
        <ModalBody pb={6}>
          <VStack spacing={6}>
            <Box textAlign="center">
              <Image 
                src="/beanscenelogo.png" 
                alt="BeanScene Logo"
                height="60px"
                mx="auto"
                mb={4}
              />
              <Text fontSize="sm" color="gray.500">
                {isSignUp 
                  ? 'Join the coffee community and share your coffee journey'
                  : 'Sign in to continue your coffee journey'
                }
              </Text>
            </Box>

            <Button
              w="full"
              size="lg"
              leftIcon={<FaGoogle />}
              onClick={handleGoogleLogin}
              colorScheme="blue"
              variant="outline"
            >
              Continue with Google
            </Button>

            <HStack w="full">
              <Divider />
              <Text fontSize="sm" color="gray.500" whiteSpace="nowrap" px={2}>
                or use email
              </Text>
              <Divider />
            </HStack>

            <form onSubmit={handleEmailAuth} style={{ width: '100%' }}>
              <VStack spacing={4} w="full">
                <FormControl isRequired>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Password</FormLabel>
                  <InputGroup>
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter your password"
                    />
                    <InputRightElement>
                      <IconButton
                        variant="ghost"
                        icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                        onClick={() => setShowPassword(!showPassword)}
                        aria-label={showPassword ? "Hide password" : "Show password"}
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                <Button
                  type="submit"
                  colorScheme="blue"
                  size="lg"
                  width="full"
                  isLoading={loading}
                >
                  {isSignUp ? 'Sign Up' : 'Sign In'}
                </Button>
              </VStack>
            </form>

            <Button
              variant="link"
              onClick={() => setIsSignUp(!isSignUp)}
              size="sm"
            >
              {isSignUp 
                ? 'Already have an account? Sign In' 
                : 'Need an account? Sign Up'}
            </Button>

            <Text fontSize="xs" color="gray.500" textAlign="center">
              By continuing, you agree to our Terms of Service and Privacy Policy
            </Text>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LoginModal;