import React, { useState, useEffect } from 'react';
import { Box, Link, Icon, HStack, Text, Image, Skeleton, useColorModeValue, VStack } from '@chakra-ui/react';
import { FaExternalLinkAlt, FaLink, FaGlobe } from 'react-icons/fa';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const LinkPost = ({ url, title, description, themeColors }) => {
  const [metadata, setMetadata] = useState({
    image: null,
    favicon: null,
    isLoading: true,
    error: null
  });

  const bgHover = useColorModeValue('gray.50', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  
  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        // In a real app, you'd want to fetch this from your backend
        // to avoid CORS issues and to safely parse the metadata
        const response = await fetch(`/api/metadata?url=${encodeURIComponent(url)}`);
        const data = await response.json();
        setMetadata({
          image: data.image,
          favicon: data.favicon,
          isLoading: false,
          error: null
        });
      } catch (error) {
        setMetadata(prev => ({
          ...prev,
          isLoading: false,
          error: 'Failed to load preview'
        }));
      }
    };

    if (url) {
      fetchMetadata();
    }
  }, [url]);

  // Extract domain from URL
  const getDomain = (url) => {
    try {
      const domain = new URL(url).hostname;
      return domain.replace('www.', '');
    } catch {
      return url;
    }
  };

  const domain = getDomain(url);

  return (
    <MotionBox
      whileHover={{ scale: 1.01 }}
      transition={{ duration: 0.2 }}
      mb={4}
    >
      <Link
        href={url}
        isExternal
        _hover={{ textDecoration: 'none' }}
        display="block"
      >
        <Box
          borderWidth="1px"
          borderRadius="xl"
          borderColor={borderColor}
          overflow="hidden"
          bg={useColorModeValue('white', 'gray.800')}
          _hover={{ 
            borderColor: 'blue.400',
            shadow: 'md'
          }}
          transition="all 0.2s"
        >
          <HStack spacing={0} align="stretch">
            {/* Link Preview Image */}
            {metadata.image && !metadata.isLoading && (
              <Box 
                w={{ base: "120px", md: "200px" }}
                h={{ base: "120px", md: "150px" }}
                overflow="hidden"
                borderRightWidth="1px"
                borderColor={borderColor}
              >
                <Image
                  src={metadata.image}
                  alt={title || domain}
                  w="100%"
                  h="100%"
                  objectFit="cover"
                  fallback={
                    <Box 
                      w="100%" 
                      h="100%" 
                      bg="gray.100" 
                      display="flex" 
                      alignItems="center" 
                      justifyContent="center"
                    >
                      <Icon as={FaGlobe} boxSize={8} color="gray.400" />
                    </Box>
                  }
                />
              </Box>
            )}

            {/* Content */}
            <VStack 
              flex={1} 
              align="stretch" 
              p={4} 
              spacing={2}
              h="100%"
            >
              {/* Domain and Icon */}
              <HStack spacing={2} color={themeColors.subtext}>
                {metadata.favicon ? (
                  <Image 
                    src={metadata.favicon} 
                    alt={domain}
                    boxSize="16px"
                    fallback={<Icon as={FaGlobe} />}
                  />
                ) : (
                  <Icon as={FaGlobe} />
                )}
                <Text fontSize="sm" fontWeight="medium">
                  {domain}
                </Text>
                <Icon as={FaExternalLinkAlt} boxSize={3} />
              </HStack>

              {/* Title */}
              <Text 
                fontWeight="bold"
                fontSize="lg"
                color={themeColors.text}
                noOfLines={2}
              >
                {title || url}
              </Text>

              {/* Description */}
              {description && (
                <Text 
                  color={themeColors.subtext}
                  fontSize="sm"
                  noOfLines={2}
                >
                  {description}
                </Text>
              )}
            </VStack>
          </HStack>
        </Box>
      </Link>
    </MotionBox>
  );
};

export default LinkPost; 