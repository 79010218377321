import React from 'react';
import {
  VStack,
  Text,
  HStack,
  Icon,
  Flex,
  Spacer,
  SimpleGrid,
} from '@chakra-ui/react';
import { FaCoffee, FaFire, FaLeaf, FaMapMarkerAlt } from 'react-icons/fa';

const ProfileBio = ({ user }) => {
  const preferences = [
    { 
      value: user?.favoriteCoffee, 
      icon: FaCoffee,
    },
    { 
      value: user?.preferredRoast, 
      icon: FaFire,
    },
    { 
      value: user?.brewingMethod, 
      icon: FaLeaf,
    },
  ];

  return (
    <VStack 
      align="stretch" 
      spacing={4} 
      bg="rgba(22, 25, 34, 0.7)"
      p={4}
      borderRadius="xl"
      mt={4}
      mb={4}
    >
      <Flex align="center">
        <Text 
          fontSize="lg" 
          fontWeight="semibold" 
          color="white"
        >
          About
        </Text>
        <Spacer />
        <HStack spacing={2} color="gray.400">
          <Icon as={FaMapMarkerAlt} boxSize={3.5} />
          <Text fontSize="sm">
            {user?.checkedInPlaces?.length || 0} Check-ins
          </Text>
        </HStack>
      </Flex>
      
      <Text color="gray.400">
        {user?.bio || 'No bio yet'}
      </Text>

      <SimpleGrid 
        columns={{ base: 2, sm: 3 }}
        spacing={4} 
        width="100%"
      >
        {preferences.map(({ value, icon }, index) => (
          <HStack 
            key={index} 
            spacing={2}
            color="gray.400"
            bg="whiteAlpha.50"
            p={2}
            borderRadius="md"
            minW="120px"
          >
            <Icon as={icon} boxSize={3.5} />
            <Text fontSize="xs" noOfLines={1}>
              {value || 'Not set'}
            </Text>
          </HStack>
        ))}
      </SimpleGrid>
    </VStack>
  );
};

export default ProfileBio;