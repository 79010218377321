import React, { useState, memo, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Box,
  useColorModeValue,
  useToast,
  Text,
  Divider,
  useBreakpointValue,
  Avatar,
  Link,
  VStack,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  IconButton,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Button,
} from '@chakra-ui/react';
import { useAuth } from '../contexts/AuthContext';
import { useQuery } from '@tanstack/react-query';
import { doc, getDoc, deleteDoc, updateDoc, arrayUnion, collection, serverTimestamp, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useTheme } from '../contexts/ThemeContext';
import PostHeader from './posts/PostHeader';
import PostActions from './posts/PostActions';
import PostContent from './posts/PostContent';
import PostImage from './posts/PostImage';
import CommentsModal from './CommentsModal';
import ImageModal from './shared/ImageModal';
import { usePostActions } from '../hooks/usePostActions';
import { useComments } from '../hooks/useComments';
import LinkPost from './posts/LinkPost';
import { Link as RouterLink } from 'react-router-dom';
import { formatTimeAgo } from '../utils/formatTimeAgo';
import PostTypeBadge from './posts/PostTypeBadge';
import {
  FaEllipsisH,
  FaShare,
  FaTwitter,
  FaFacebook,
  FaWhatsapp,
  FaLink,
  FaBan,
  FaVolumeMute,
  FaFlag,
  FaTrash,
  FaPen,
} from 'react-icons/fa';
import UserActions from './shared/UserActions';
import ShareMenu from './shared/ShareMenu';
import PostForm from './forms/PostForm';

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);

const postVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { 
      duration: 0.3,
      ease: "easeOut"
    }
  },
  exit: { 
    opacity: 0,
    y: -20,
    transition: { 
      duration: 0.2 
    }
  }
};

const contentVariants = {
  hidden: { opacity: 0, scale: 0.95 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: { delay: 0.1, duration: 0.2 }
  }
};

const CoffeePost = memo(({ post = {}, isOptimistic = false, onDelete }) => {
  if (post.type === 'coffeeOfTheDay') return null;

  const toast = useToast();
  const { user } = useAuth();
  const { mode, colors } = useTheme();
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const { isOpen: isDeleteAlertOpen, onOpen: onDeleteAlertOpen, onClose: onDeleteAlertClose } = useDisclosure();
  const { isOpen: isReportModalOpen, onOpen: onReportModalOpen, onClose: onReportModalClose } = useDisclosure();
  const [reportReason, setReportReason] = useState('');
  const cancelRef = useRef();
  const [isQuickPostOpen, setIsQuickPostOpen] = useState(false);

  // Custom hooks for post actions and comments
  const { 
    isBookmarked, 
    hasLiked, 
    likes, 
    handleLike, 
    handleBookmark 
  } = usePostActions(post, user);

  const {
    comments,
    isLoadingComments,
    handleAddComment,
    handleLikeComment
  } = useComments(post.id);

  // Theme colors
  const themeColors = {
    card: useColorModeValue('white', 'gray.800'),
    text: useColorModeValue('gray.800', 'gray.100'),
    subtext: useColorModeValue('gray.600', 'gray.400'),
    border: useColorModeValue('gray.100', 'gray.700'),
    hover: useColorModeValue('gray.50', 'gray.700'),
    shadow: useColorModeValue(
      '0 4px 6px rgba(0, 0, 0, 0.1)',
      '0 4px 6px rgba(0, 0, 0, 0.3)'
    )
  };

  // User data query
  const { data: userData } = useQuery({
    queryKey: ['user', post.userId],
    queryFn: async () => {
      if (!post?.userId) return null;
      const userDoc = await getDoc(doc(db, 'users', post.userId));
      return userDoc.exists() ? userDoc.data() : null;
    },
    enabled: !isOptimistic && !!post?.userId && !post.userName,
  });

  const renderContent = () => {
    if (post.type === 'link') {
      return (
        <LinkPost 
          url={post.url}
          title={post.title}
          description={post.content}
          themeColors={themeColors}
        />
      );
    }

    return (
      <>
        {post.title && (
          <Text
            fontSize="lg"
            fontWeight="bold"
            mb={2}
            color={themeColors.text}
            _hover={{ color: 'blue.500' }}
            transition="color 0.2s"
          >
            {post.title}
          </Text>
        )}
        <PostContent 
          content={post.content} 
          themeColors={themeColors} 
        />
      </>
    );
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, 'posts', post.id));
      onDelete?.(post.id);
      onDeleteAlertClose();
      toast({
        title: "Post deleted",
        status: "success",
        duration: 3000,
      });
    } catch (error) {
      console.error('Error deleting post:', error);
      toast({
        title: "Error deleting post",
        status: "error",
        duration: 3000,
      });
    }
  };

  const handleMuteUser = async () => {
    try {
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        mutedUsers: arrayUnion(post.userId)
      });
      toast({
        title: "User muted",
        description: "You won't see posts from this user anymore",
        status: "success",
        duration: 3000,
      });
    } catch (error) {
      console.error('Error muting user:', error);
      toast({
        title: "Error muting user",
        status: "error",
        duration: 3000,
      });
    }
  };

  const handleBlockUser = async () => {
    try {
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        blockedUsers: arrayUnion(post.userId)
      });
      toast({
        title: "User blocked",
        status: "success",
        duration: 3000,
      });
    } catch (error) {
      console.error('Error blocking user:', error);
      toast({
        title: "Error blocking user",
        status: "error",
        duration: 3000,
      });
    }
  };

  const handleReport = async () => {
    try {
      await addDoc(collection(db, 'reports'), {
        postId: post.id,
        reportedBy: user.uid,
        reportedUser: post.userId,
        reason: reportReason,
        timestamp: serverTimestamp(),
        status: 'pending'
      });
      toast({
        title: "Report submitted",
        status: "success",
        duration: 3000,
      });
      onReportModalClose();
    } catch (error) {
      console.error('Error submitting report:', error);
      toast({
        title: "Error submitting report",
        status: "error",
        duration: 3000,
      });
    }
  };

  const handleShare = () => {
    const postUrl = `${window.location.origin}/post/${post.id}`;
    const postTitle = post.title || 'Check out this post';

    // Use ShareMenu component
    return (
      <ShareMenu 
        url={postUrl}
        title={postTitle}
        onCopy={() => {
          toast({
            title: "Link copied to clipboard",
            status: "success",
            duration: 2000,
          });
        }}
      />
    );
  };

  const isOwnPost = user?.uid === post.userId;

  return (
    <AnimatePresence>
      <MotionBox
        variants={postVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        layout
        w="100%"
        mb={4}
      >
        <Box
          bg={themeColors.card}
          borderRadius={{ base: 'lg', md: 'xl' }}
          overflow="hidden"
          boxShadow={themeColors.shadow}
          transition="all 0.2s"
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg'
          }}
        >
          <VStack spacing={0} align="stretch">
            {/* Post Header */}
            <Box px={{ base: 3, md: 6 }} pt={{ base: 3, md: 4 }}>
              <PostHeader
                userData={userData}
                post={{
                  ...post,
                  isOptimistic,
                  timestamp: post.createdAt || post.timestamp
                }}
                themeColors={themeColors}
              />
            </Box>

            {/* Post Content */}
            <MotionFlex
              variants={contentVariants}
              initial="hidden"
              animate="visible"
              direction="column"
              px={{ base: 3, md: 6 }}
              py={4}
            >
              {renderContent()}
              
              {/* Post Images */}
              {post.images && post.images.length > 0 && (
                <Box 
                  mt={4}
                  borderRadius="lg"
                  overflow="hidden"
                >
                  <PostImage
                    images={post.images}
                    onImageClick={(img) => {
                      setSelectedImage(img);
                      setIsModalOpen(true);
                    }}
                  />
                </Box>
              )}

              {/* Tags and Location */}
              {(post.tags?.length > 0 || post.location) && (
                <Flex 
                  mt={4} 
                  gap={2} 
                  flexWrap="wrap"
                  fontSize="sm"
                  color={themeColors.subtext}
                >
                  {post.tags?.map(tag => (
                    <Box
                      key={tag}
                      bg={useColorModeValue('gray.100', 'gray.700')}
                      color={themeColors.text}
                      px={2}
                      py={1}
                      borderRadius="full"
                      fontSize="xs"
                      _hover={{ bg: useColorModeValue('gray.200', 'gray.600') }}
                      cursor="pointer"
                      transition="all 0.2s"
                    >
                      #{tag}
                    </Box>
                  ))}
                  {post.location && (
                    <Text fontSize="xs" color={themeColors.subtext}>
                      📍 {post.location}
                    </Text>
                  )}
                </Flex>
              )}
            </MotionFlex>

            <Divider borderColor={themeColors.border} />

            {/* Post Actions */}
            <Box px={{ base: 3, md: 6 }} py={2}>
              <PostActions
                post={post}
                user={user}
                likes={likes}
                hasLiked={hasLiked}
                isBookmarked={isBookmarked}
                comments={comments}
                onLike={handleLike}
                onBookmark={handleBookmark}
                onComment={() => setIsModalOpen(true)}
                themeColors={themeColors}
              />
         
            </Box>
          </VStack>
        </Box>

        {/* Modals */}
        <CommentsModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          comments={comments}
          onAddComment={handleAddComment}
          onLikeComment={handleLikeComment}
          currentUser={user}
        />

        <ImageModal
          isOpen={!!selectedImage}
          onClose={() => setSelectedImage(null)}
          imageUrl={selectedImage}
        />

        {/* Delete Alert Dialog */}
        <AlertDialog
          isOpen={isDeleteAlertOpen}
          leastDestructiveRef={cancelRef}
          onClose={onDeleteAlertClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader>Delete Post</AlertDialogHeader>
              <AlertDialogBody>
                Are you sure? This action cannot be undone.
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onDeleteAlertClose}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={handleDelete} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>

        {/* Report Modal */}
        <Modal isOpen={isReportModalOpen} onClose={onReportModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Report Post</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <VStack spacing={4}>
                <FormControl>
                  <FormLabel>Reason for reporting</FormLabel>
                  <Select
                    value={reportReason}
                    onChange={(e) => setReportReason(e.target.value)}
                    placeholder="Select a reason"
                  >
                    <option value="spam">Spam</option>
                    <option value="harassment">Harassment</option>
                    <option value="inappropriate">Inappropriate Content</option>
                    <option value="misinformation">Misinformation</option>
                    <option value="other">Other</option>
                  </Select>
                </FormControl>
                <Button
                  colorScheme="red"
                  onClick={handleReport}
                  isDisabled={!reportReason}
                  width="full"
                >
                  Submit Report
                </Button>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>

        {/* Add PostForm Modal */}
        {isQuickPostOpen && (
          <PostForm
            isOpen={isQuickPostOpen}
            onClose={() => setIsQuickPostOpen(false)}
            isQuickPost={true}
          />
        )}
      </MotionBox>
    </AnimatePresence>
  );
});

CoffeePost.displayName = 'CoffeePost';
export default CoffeePost;
