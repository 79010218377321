import { useState, useEffect } from 'react';
import { doc, updateDoc, arrayUnion, arrayRemove, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useToast } from '@chakra-ui/react';

export const usePostActions = (post, user) => {
  const toast = useToast();
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [hasLiked, setHasLiked] = useState(false);
  const [likes, setLikes] = useState([]);

  useEffect(() => {
    if (post?.likes) {
      const likesArray = Array.isArray(post.likes) ? post.likes : [];
      setLikes(likesArray);
      setHasLiked(user ? likesArray.includes(user.uid) : false);
    }
  }, [post?.likes, user]);

  useEffect(() => {
    if (user && post?.id) {
      const checkBookmark = async () => {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          const bookmarks = userDoc.data()?.bookmarks || [];
          setIsBookmarked(bookmarks.includes(post.id));
        } catch (error) {
          console.error('Error checking bookmark status:', error);
        }
      };

      checkBookmark();
    } else {
      setIsBookmarked(false);
    }
  }, [user, post?.id]);

  const handleLike = async () => {
    if (!user) {
      toast({
        title: "Please login to like posts",
        status: "warning",
        duration: 3000,
      });
      return;
    }

    try {
      const postRef = doc(db, 'posts', post.id);
      await updateDoc(postRef, {
        likes: hasLiked ? arrayRemove(user.uid) : arrayUnion(user.uid)
      });
      setHasLiked(!hasLiked);
      setLikes(prev => 
        hasLiked 
          ? prev.filter(id => id !== user.uid)
          : [...prev, user.uid]
      );
    } catch (error) {
      console.error('Error updating like:', error);
      toast({
        title: "Error updating like",
        status: "error",
        duration: 2000,
      });
    }
  };

  const handleBookmark = async () => {
    if (!user) {
      toast({
        title: "Please login to bookmark posts",
        status: "warning",
        duration: 3000,
      });
      return;
    }

    try {
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        bookmarks: isBookmarked ? arrayRemove(post.id) : arrayUnion(post.id)
      });
      setIsBookmarked(!isBookmarked);
    } catch (error) {
      console.error('Error updating bookmark:', error);
      toast({
        title: "Error updating bookmark",
        status: "error",
        duration: 2000,
      });
    }
  };

  return {
    isBookmarked,
    hasLiked,
    likes,
    handleLike,
    handleBookmark
  };
}; 