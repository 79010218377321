import React, { useState } from 'react';
import {
  Input,
  Box,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

export const PlaceSearch = ({ onPlaceSelect }) => {
  const [searchValue, setSearchValue] = useState('');
  const [predictions, setPredictions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const bgColor = useColorModeValue('white', 'gray.800');
  const hoverBg = useColorModeValue('gray.100', 'gray.700');

  const handleSearch = async (value) => {
    setSearchValue(value);
    if (!value.trim()) {
      setPredictions([]);
      return;
    }

    setIsLoading(true);
    try {
      // Note: In a production environment, you would want to implement 
      // proper Google Places API integration here
      const mockPredictions = [
        {
          description: value + ' Coffee Shop, Main St',
          place_id: '1',
        },
        {
          description: value + ' Café, Oak Avenue',
          place_id: '2',
        },
      ];
      setPredictions(mockPredictions);
    } catch (error) {
      console.error('Error fetching place predictions:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelect = (prediction) => {
    setSearchValue(prediction.description);
    setPredictions([]);
    onPlaceSelect?.(prediction);
  };

  return (
    <Box position="relative" w="100%">
      <Input
        value={searchValue}
        onChange={(e) => handleSearch(e.target.value)}
        placeholder="Search for a place..."
        isLoading={isLoading}
      />
      {predictions.length > 0 && (
        <List
          position="absolute"
          top="100%"
          left={0}
          right={0}
          mt={1}
          maxH="200px"
          overflowY="auto"
          bg={bgColor}
          borderRadius="md"
          boxShadow="lg"
          zIndex={1000}
        >
          {predictions.map((prediction) => (
            <ListItem
              key={prediction.place_id}
              px={4}
              py={2}
              cursor="pointer"
              _hover={{ bg: hoverBg }}
              onClick={() => handleSelect(prediction)}
            >
              <Text fontSize="sm">{prediction.description}</Text>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};
