import React, { useState } from 'react';
import {
  Box,
  VStack,
  HStack,
  Text,
  Avatar,
  useColorModeValue,
  Container,
  Button,
  Icon,
  Flex,
  Badge,
  Skeleton,
  useToast,
  IconButton,
  Divider,
  Tooltip,
  Heading
} from '@chakra-ui/react';
import { FaStar, FaHeart, FaRegHeart, FaClock, FaEdit } from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext';
import { useInfiniteQuery } from '@tanstack/react-query';
import { collection, query, orderBy, limit, getDocs, startAfter } from 'firebase/firestore';
import { db } from '../firebase';
import { motion } from 'framer-motion';
import { formatTimeAgo } from '../utils/timeAgo';

const MotionBox = motion(Box);

const StarRating = ({ rating }) => (
  <HStack spacing={1}>
    {[...Array(5)].map((_, i) => (
      <Icon
        key={i}
        as={FaStar}
        color={i < rating ? "yellow.400" : "gray.300"}
        boxSize={{ base: 3, md: 4 }}
      />
    ))}
  </HStack>
);

const ReviewCard = ({ review, onLike }) => {
  const { user } = useAuth();
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const toast = useToast();

  const handleLikeClick = () => {
    if (!user) {
      toast({
        title: "Login Required",
        description: "Please login to like reviews",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    onLike(review.id);
  };

  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      p={{ base: 4, md: 6 }}
      borderWidth="1px"
      borderRadius="xl"
      bg={bgColor}
      borderColor={borderColor}
      shadow="sm"
      width="100%"
    >
      <VStack align="stretch" spacing={4}>
        <Flex justify="space-between" align="center">
          <HStack>
            <Avatar 
              size={{ base: "sm", md: "md" }} 
              src={review.userPhotoURL} 
              name={review.userName} 
            />
            <VStack align="start" spacing={0}>
              <Text fontWeight="bold" fontSize={{ base: "sm", md: "md" }}>
                {review.userName}
              </Text>
              <Text fontSize="xs" color="gray.500">
                {formatTimeAgo(review.timestamp?.toDate())}
              </Text>
            </VStack>
          </HStack>
          <Badge colorScheme="purple">Review</Badge>
        </Flex>

        <Box>
          <StarRating rating={review.rating} />
          <Text 
            mt={2} 
            fontSize={{ base: "sm", md: "md" }}
            noOfLines={{ base: 3, md: 4 }}
          >
            {review.content}
          </Text>
        </Box>

        <Divider />

        <HStack justify="space-between">
          <HStack>
            <IconButton
              icon={review.likedBy?.includes(user?.uid) ? <FaHeart /> : <FaRegHeart />}
              variant="ghost"
              colorScheme={review.likedBy?.includes(user?.uid) ? "red" : "gray"}
              onClick={handleLikeClick}
              size={{ base: "sm", md: "md" }}
              aria-label="Like review"
            />
            <Text fontSize={{ base: "sm", md: "md" }}>
              {review.likes?.length || 0}
            </Text>
          </HStack>
          
          <Tooltip label={new Date(review.timestamp?.toDate()).toLocaleString()}>
            <HStack color="gray.500" fontSize={{ base: "xs", md: "sm" }}>
              <Icon as={FaClock} />
              <Text>{formatTimeAgo(review.timestamp?.toDate())}</Text>
            </HStack>
          </Tooltip>
        </HStack>
      </VStack>
    </MotionBox>
  );
};

const Reviews = () => {
  const { user } = useAuth();
  const toast = useToast();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    error
  } = useInfiniteQuery({
    queryKey: ['reviews'],
    queryFn: async ({ pageParam = null }) => {
      const reviewsRef = collection(db, 'reviews');
      const q = query(
        reviewsRef,
        orderBy('timestamp', 'desc'),
        limit(10),
        ...(pageParam ? [startAfter(pageParam)] : [])
      );
      
      const snapshot = await getDocs(q);
      return {
        reviews: snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })),
        lastVisible: snapshot.docs[snapshot.docs.length - 1]
      };
    },
    getNextPageParam: (lastPage) => lastPage?.lastVisible
  });

  const handleCreateReview = () => {
    if (!user) {
      toast({
        title: "Login Required",
        description: "Please login to write reviews",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    // Navigate to create review form or open modal
  };

  return (
    <Container maxW="4xl" py={{ base: 4, md: 8 }}>
      <VStack spacing={{ base: 4, md: 8 }} align="stretch">
        <Flex 
          justify="space-between" 
          align="center"
          direction={{ base: "column", sm: "row" }}
          gap={{ base: 4, sm: 0 }}
        >
          <Heading size={{ base: "md", md: "lg" }}>Coffee Reviews</Heading>
          <Button
            leftIcon={<Icon as={FaEdit} />}
            colorScheme="blue"
            onClick={handleCreateReview}
            width={{ base: "full", sm: "auto" }}
          >
            {user ? 'Write Review' : 'Login to Review'}
          </Button>
        </Flex>

        {isLoading ? (
          <VStack spacing={4}>
            {[...Array(3)].map((_, i) => (
              <Skeleton 
                key={i} 
                height={{ base: "200px", md: "250px" }} 
                width="100%" 
                borderRadius="xl" 
              />
            ))}
          </VStack>
        ) : error ? (
          <Box 
            textAlign="center" 
            p={8} 
            bg={useColorModeValue('red.50', 'red.900')}
            borderRadius="xl"
          >
            <Text color="red.500">Error loading reviews</Text>
          </Box>
        ) : data?.pages[0].reviews.length === 0 ? (
          <Box 
            textAlign="center" 
            py={10}
            bg={useColorModeValue('gray.50', 'gray.700')}
            borderRadius="xl"
          >
            <Icon as={FaEdit} boxSize={10} color="gray.400" mb={4} />
            <Text fontSize="lg" color="gray.500">No reviews yet</Text>
            <Text fontSize="sm" color="gray.400" mt={2}>
              {user ? 'Be the first to write a review!' : 'Login to write the first review!'}
            </Text>
          </Box>
        ) : (
          <VStack spacing={4} align="stretch">
            {data.pages.map((page, i) => (
              <React.Fragment key={i}>
                {page.reviews.map(review => (
                  <ReviewCard 
                    key={review.id} 
                    review={review}
                    onLike={(reviewId) => {
                      // Handle like functionality
                    }}
                  />
                ))}
              </React.Fragment>
            ))}
            
            {hasNextPage && (
              <Button
                onClick={() => fetchNextPage()}
                isLoading={isFetchingNextPage}
                width="100%"
                variant="outline"
              >
                Load More
              </Button>
            )}
          </VStack>
        )}
      </VStack>
    </Container>
  );
};

export default Reviews;
