import React, { memo } from 'react';
import { HStack, Avatar, VStack, Text, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { formatTimeAgo } from '../../utils/timeAgo';
import { PostTypeBadge } from './PostTypeBadge';

const PostHeader = memo(({ 
  userData, 
  post, 
  themeColors 
}) => {
  const displayName = userData?.displayName || post.userName || 'Anonymous';
  const photoURL = userData?.photoURL || post.userPhotoURL;
  const userId = userData?.uid || post.userId;
  
  return (
    <HStack spacing={3} width="100%">
      <Link
        as={RouterLink}
        to={`/profile/${userId}`}
        _hover={{ textDecoration: 'none' }}
      >
        <Avatar
          size="md"
          src={photoURL}
          name={displayName}
          _hover={{ transform: 'scale(1.05)' }}
          transition="all 0.2s"
          border="2px solid"
          borderColor="blue.400"
        />
      </Link>
      
      <VStack align="start" spacing={0} flex={1}>
        <Link
          as={RouterLink}
          to={`/profile/${userId}`}
          _hover={{ color: 'blue.400', textDecoration: 'none' }}
        >
          <Text fontWeight="bold" fontSize="md" color={themeColors.text}>
            {displayName}
          </Text>
        </Link>
        <HStack spacing={2} align="center">
          <PostTypeBadge type={post.type} />
          <Text fontSize="sm" color={themeColors.subtext}>
            {post.isOptimistic ? 'Just now' : formatTimeAgo(post.timestamp?.toDate?.() || post.timestamp)}
          </Text>
        </HStack>
      </VStack>
    </HStack>
  );
});

PostHeader.displayName = 'PostHeader';
export default PostHeader;