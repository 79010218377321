import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  VStack,
  Input,
  Text,
  useColorMode,
  IconButton,
  Flex,
  InputGroup,
  InputLeftElement,
  Avatar,
} from '@chakra-ui/react';
import { FaSearch, FaArrowLeft } from 'react-icons/fa';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  orderBy, 
  onSnapshot,
  serverTimestamp 
} from 'firebase/firestore';
import { db, auth } from '../firebase';
import { createChat } from '../utils/firebaseFunctions';

const Messages = () => {
  const { colorMode } = useColorMode();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [recentChats, setRecentChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Search users
  useEffect(() => {
    const searchUsers = async () => {
      if (!searchTerm || searchTerm.length < 2) {
        setSearchResults([]);
        return;
      }

      const usersRef = collection(db, 'users');
      const q = query(
        usersRef,
        where('displayName', '>=', searchTerm.toLowerCase()),
        where('displayName', '<=', searchTerm.toLowerCase() + '\uf8ff')
      );

      try {
        const snapshot = await getDocs(q);
        const results = snapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(user => user.id !== auth.currentUser?.uid);
        setSearchResults(results);
      } catch (error) {
        console.error('Error searching users:', error);
      }
    };

    const debounceTimeout = setTimeout(searchUsers, 300);
    return () => clearTimeout(debounceTimeout);
  }, [searchTerm]);

  // Fetch recent chats
  useEffect(() => {
    if (!auth.currentUser) return;

    const chatsRef = collection(db, 'chats');
    const q = query(
      chatsRef,
      where('participants', 'array-contains', auth.currentUser.uid),
      orderBy('lastMessageTime', 'desc')
    );

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const chatsData = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const chatData = doc.data();
          const otherUserId = chatData.participants.find(
            id => id !== auth.currentUser.uid
          );
          
          return {
            id: doc.id,
            ...chatData,
            otherUser: chatData.participantsData[otherUserId]
          };
        })
      );
      
      setRecentChats(chatsData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleUserSelect = async (user) => {
    try {
      const chatId = await createChat(auth.currentUser, user);
      navigate(`/chat/${chatId}`);
    } catch (error) {
      console.error('Error creating chat:', error);
    }
  };

  const formatLastMessageTime = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate();
    const now = new Date();
    const diff = now - date;
    
    if (diff < 86400000) { // Less than 24 hours
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
    return date.toLocaleDateString();
  };

  return (
    <Box h="100vh" bg={colorMode === 'dark' ? 'gray.900' : 'white'}>
      {/* ... Header section remains the same ... */}

      {/* Search and Chat List */}
      <VStack p={4} spacing={4} align="stretch">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <FaSearch color="gray.300" />
          </InputLeftElement>
          <Input
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            bg={colorMode === 'dark' ? 'gray.800' : 'gray.50'}
            border="none"
            _focus={{
              border: 'none',
              boxShadow: 'none'
            }}
          />
        </InputGroup>

        {/* Search Results */}
        {searchResults.length > 0 && (
          <VStack align="stretch" spacing={2}>
            {searchResults.map(user => (
              <Flex
                key={user.id}
                p={3}
                alignItems="center"
                cursor="pointer"
                _hover={{ bg: colorMode === 'dark' ? 'gray.800' : 'gray.100' }}
                onClick={() => handleUserSelect(user)}
                borderRadius="md"
              >
                <Avatar size="sm" src={user.photoURL} name={user.displayName} />
                <Text ml={3}>{user.displayName}</Text>
              </Flex>
            ))}
          </VStack>
        )}

        {/* Recent Chats */}
        <Text fontWeight="semibold" color="gray.500" mt={4}>
          Recent Chats
        </Text>

        {loading ? (
          <Text color="gray.500">Loading...</Text>
        ) : recentChats.length === 0 ? (
          <Flex 
            direction="column" 
            align="center" 
            justify="center" 
            h="50vh"
            color="gray.500"
          >
            <Text>No recent chats</Text>
          </Flex>
        ) : (
          <VStack spacing={2} align="stretch">
            {recentChats.map(chat => (
              <Flex
                key={chat.id}
                p={3}
                alignItems="center"
                cursor="pointer"
                _hover={{ bg: colorMode === 'dark' ? 'gray.800' : 'gray.100' }}
                onClick={() => navigate(`/chat/${chat.id}`)}
                borderRadius="md"
              >
                <Avatar 
                  size="sm" 
                  src={chat.otherUser.photoURL} 
                  name={chat.otherUser.displayName} 
                />
                <Box ml={3} flex={1}>
                  <Flex justify="space-between" align="center">
                    <Text fontWeight="medium">{chat.otherUser.displayName}</Text>
                    {chat.lastMessageTime && (
                      <Text fontSize="xs" color="gray.500">
                        {formatLastMessageTime(chat.lastMessageTime)}
                      </Text>
                    )}
                  </Flex>
                  {chat.lastMessage && (
                    <Text fontSize="sm" color="gray.500" noOfLines={1}>
                      {chat.lastMessage.text}
                    </Text>
                  )}
                </Box>
              </Flex>
            ))}
          </VStack>
        )}
      </VStack>
    </Box>
  );
};

export default Messages;