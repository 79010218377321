import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  HStack,
  Text,
  Image,
  Badge,
  Button,
  IconButton,
  useToast,
  Box,
  SimpleGrid,
  Divider,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Switch,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { FaEdit, FaTrash, FaShare, FaBookmark } from 'react-icons/fa';
import { doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import PlaceCard from './PlaceCard';
import { ImageUpload } from '../shared/ImageUpload';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebase';

const CollectionDetails = ({ collection, isOpen, onClose, onUpdate }) => {
  const { user } = useAuth();
  const toast = useToast();
  const editModal = useDisclosure();
  const [editForm, setEditForm] = useState(collection);
  const isOwner = user?.uid === collection?.curator?.id;
  const [uploadingImage, setUploadingImage] = useState(false);

  const handleEdit = async () => {
    try {
      await updateDoc(doc(db, 'coffeeLists', collection.id), {
        title: editForm.title,
        description: editForm.description,
        tags: editForm.tags,
        public: editForm.public,
        updatedAt: new Date()
      });
      
      onUpdate();
      editModal.onClose();
      toast({
        title: 'Collection updated',
        status: 'success',
        duration: 2000,
      });
    } catch (error) {
      console.error('Error updating collection:', error);
      toast({
        title: 'Error',
        description: 'Could not update collection',
        status: 'error',
        duration: 3000,
      });
    }
  };

  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete this collection?')) return;
    
    try {
      await deleteDoc(doc(db, 'coffeeLists', collection.id));
      onClose();
      onUpdate();
      toast({
        title: 'Collection deleted',
        status: 'success',
        duration: 2000,
      });
    } catch (error) {
      console.error('Error deleting collection:', error);
      toast({
        title: 'Error',
        description: 'Could not delete collection',
        status: 'error',
        duration: 3000,
      });
    }
  };

  const handleImageUpload = async (file) => {
    if (!file) return;
    setUploadingImage(true);

    try {
      const storageRef = ref(storage, `collections/${collection.id}/${Date.now()}`);
      await uploadBytes(storageRef, file);
      const imageUrl = await getDownloadURL(storageRef);
      
      await updateDoc(doc(db, 'coffeeLists', collection.id), {
        coverImage: imageUrl,
        updatedAt: new Date()
      });

      toast({
        title: 'Image updated',
        status: 'success',
        duration: 2000,
      });
      onUpdate();
    } catch (error) {
      console.error('Error uploading image:', error);
      toast({
        title: 'Error uploading image',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setUploadingImage(false);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p={0}>
            <VStack spacing={0} align="stretch">
              <Box position="relative" height="200px">
                <ImageUpload
                  currentImage={collection?.coverImage}
                  onUpload={handleImageUpload}
                  height="200px"
                  isLoading={uploadingImage}
                  placeholderText="Click to update collection image"
                />
                <HStack
                  position="absolute"
                  bottom={4}
                  right={4}
                  spacing={2}
                >
                  {isOwner && (
                    <>
                      <IconButton
                        icon={<FaEdit />}
                        onClick={editModal.onOpen}
                        colorScheme="blue"
                      />
                      <IconButton
                        icon={<FaTrash />}
                        onClick={handleDelete}
                        colorScheme="red"
                      />
                    </>
                  )}
                  <IconButton
                    icon={<FaShare />}
                    onClick={() => {/* Share functionality */}}
                  />
                  <IconButton
                    icon={<FaBookmark />}
                    onClick={() => {/* Bookmark functionality */}}
                  />
                </HStack>
              </Box>

              <Box p={6}>
                <VStack align="stretch" spacing={4}>
                  <HStack justify="space-between">
                    <Text fontSize="2xl" fontWeight="bold">
                      {collection?.title}
                    </Text>
                    <Badge colorScheme={collection?.public ? 'green' : 'gray'}>
                      {collection?.public ? 'Public' : 'Private'}
                    </Badge>
                  </HStack>

                  <Text color="gray.500">
                    {collection?.description}
                  </Text>

                  <Wrap>
                    {collection?.tags.map((tag, index) => (
                      <Badge key={index} colorScheme="blue">
                        {tag}
                      </Badge>
                    ))}
                  </Wrap>

                  <Divider />

                  <Text fontWeight="bold">Places in this collection</Text>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                    {collection?.places.map(place => (
                      <PlaceCard
                        key={place.id}
                        place={place}
                        isCompact
                      />
                    ))}
                  </SimpleGrid>
                </VStack>
              </Box>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Edit Modal */}
      <Modal isOpen={editModal.isOpen} onClose={editModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Collection</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Title</FormLabel>
                <Input
                  value={editForm.title}
                  onChange={(e) => setEditForm(prev => ({
                    ...prev,
                    title: e.target.value
                  }))}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea
                  value={editForm.description}
                  onChange={(e) => setEditForm(prev => ({
                    ...prev,
                    description: e.target.value
                  }))}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Tags</FormLabel>
                <Input
                  placeholder="Add tags (press Enter)"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && e.target.value) {
                      setEditForm(prev => ({
                        ...prev,
                        tags: [...prev.tags, e.target.value]
                      }));
                      e.target.value = '';
                    }
                  }}
                />
              </FormControl>

              <FormControl display="flex" alignItems="center">
                <FormLabel mb="0">Public Collection</FormLabel>
                <Switch
                  isChecked={editForm.public}
                  onChange={(e) => setEditForm(prev => ({
                    ...prev,
                    public: e.target.checked
                  }))}
                />
              </FormControl>

              <Button colorScheme="blue" onClick={handleEdit}>
                Save Changes
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CollectionDetails;
