import { useColorModeValue } from '@chakra-ui/react';

export const useQuillStyles = () => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const placeholderColor = useColorModeValue('gray.400', 'gray.300'); // Lighter in dark mode
  const toolbarBg = useColorModeValue('gray.50', 'gray.700');
  const toolbarBtnColor = useColorModeValue('gray.600', 'white'); // White in dark mode
  const toolbarBtnHoverBg = useColorModeValue('gray.200', 'gray.600');
  const iconColor = useColorModeValue('gray.600', 'white'); // White in dark mode

  return `
    .ql-toolbar.ql-snow {
      border-color: ${borderColor};
      background-color: ${toolbarBg};
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    .ql-container.ql-snow {
      border-color: ${borderColor};
      background-color: ${bgColor};
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      min-height: 120px;
    }

    .ql-editor {
      color: ${textColor};
      font-size: 1rem;
      min-height: 120px;
    }

    .ql-editor.ql-blank::before {
      color: rgba(255, 255, 255, 0.6) !important;
      font-style: normal;
      font-size: 1rem;
      opacity: 1 !important;
    }

    /* Light mode placeholder */
    .chakra-ui-light .ql-editor.ql-blank::before {
      color: rgba(0, 0, 0, 0.4) !important;
    }

    /* Dark mode placeholder */
    .chakra-ui-dark .ql-editor.ql-blank::before {
      color: rgba(255, 255, 255, 0.6) !important;
    }

    .ql-toolbar.ql-snow .ql-picker-label {
      color: ${toolbarBtnColor};
    }

    .ql-toolbar.ql-snow button {
      color: ${toolbarBtnColor} !important;
    }

    .ql-toolbar.ql-snow button:hover,
    .ql-toolbar.ql-snow .ql-picker-label:hover {
      color: ${textColor} !important;
    }

    .ql-toolbar.ql-snow button:hover .ql-stroke,
    .ql-toolbar.ql-snow .ql-picker-label:hover .ql-stroke {
      stroke: ${textColor} !important;
    }

    .ql-toolbar.ql-snow .ql-picker-label .ql-stroke {
      stroke: ${iconColor} !important;
    }

    .ql-toolbar.ql-snow .ql-picker-options {
      background-color: ${bgColor};
      border-color: ${borderColor};
    }

    .ql-toolbar.ql-snow .ql-picker-options .ql-picker-item {
      color: ${textColor};
    }

    .ql-toolbar.ql-snow .ql-formats button.ql-active,
    .ql-toolbar.ql-snow .ql-formats button:focus,
    .ql-toolbar.ql-snow .ql-formats button:hover {
      background-color: ${toolbarBtnHoverBg};
      border-radius: 4px;
    }

    .ql-snow .ql-stroke {
      stroke: ${iconColor} !important;
    }

    .ql-snow .ql-fill {
      fill: ${iconColor} !important;
    }

    .ql-snow .ql-picker {
      color: ${textColor};
    }

    .ql-snow .ql-picker-options .ql-picker-item {
      color: ${textColor} !important;
    }

    .ql-snow .ql-picker-label {
      color: ${textColor} !important;
    }

    /* Force white icons in dark mode */
    .chakra-ui-dark .ql-snow .ql-stroke {
      stroke: white !important;
    }

    .chakra-ui-dark .ql-snow .ql-fill {
      fill: white !important;
    }

    .chakra-ui-dark .ql-snow button {
      color: white !important;
    }
  `;
};
