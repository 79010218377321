import React, { useState, useCallback } from 'react';
import {
  Box,
  VStack,
  Text,
  Icon,
  Image,
  IconButton,
  Progress,
  useColorModeValue,
  Flex,
  Button,
  useBreakpointValue,
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { useDropzone } from 'react-dropzone';
import { FaImage, FaTimes, FaUpload, FaCamera } from 'react-icons/fa';
import imageCompression from 'browser-image-compression';

const MotionBox = motion(Box);

const ImageUpload = ({ 
  onUpload, 
  maxSize = 2,
  ratio = 16/9,
  showCamera = false,
  isMobile = false
}) => {
  const [isCompressing, setIsCompressing] = useState(false);
  const [progress, setProgress] = useState(0);

  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.600', 'gray.200');

  const compressImage = async (file) => {
    setIsCompressing(true);
    try {
      const options = {
        maxSizeMB: maxSize,
        maxWidthOrHeight: Math.max(ratio * maxSize, maxSize),
        useWebWorker: true,
        fileType: 'image/jpeg',
        quality: 0.8,
        onProgress: (p) => setProgress(p),
      };

      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error('Error compressing image:', error);
      throw error;
    } finally {
      setIsCompressing(false);
      setProgress(0);
    }
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    try {
      const processedFiles = await Promise.all(
        acceptedFiles.map(async (file) => {
          const compressed = await compressImage(file);
          return {
            file: compressed,
            preview: URL.createObjectURL(compressed)
          };
        })
      );

      if (processedFiles.length > 0) {
        onUpload(processedFiles);
      }
    } catch (error) {
      console.error('Error processing files:', error);
    }
  }, [onUpload]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { 'image/*': ['.jpeg', '.jpg', '.png'] },
    maxFiles: 1,
    onDrop,
  });

  const removeImage = (index) => {
    onUpload(null);
  };

  const renderPreview = () => {
    if (!showCamera || !onUpload) return null;

    return (
      <PreviewImage
        src={onUpload.preview}
        onRemove={() => removeImage()}
        height="200px"
      />
    );
  };

  return (
    <AnimatePresence>
      <VStack spacing={4} width="100%">
        {isMobile ? (
          <Button
            leftIcon={<FaCamera />}
            onClick={() => document.getElementById('fileInput').click()}
            width="full"
            colorScheme="blue"
            size="md"
            height="40px"
          >
            Take Photo
            <input
              id="fileInput"
              type="file"
              accept="image/*"
              capture="environment"
              onChange={(e) => {
                if (e.target.files?.[0]) {
                  onDrop([e.target.files[0]]);
                }
              }}
              style={{ display: 'none' }}
            />
          </Button>
        ) : (
          <MotionBox
            {...getRootProps()}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            width="100%"
            height="200px"
            borderWidth={2}
            borderStyle="dashed"
            borderColor={isDragActive ? 'blue.400' : borderColor}
            borderRadius="xl"
            bg={bgColor}
            cursor="pointer"
            transition="all 0.2s"
            _hover={{
              borderColor: 'blue.400',
              transform: 'translateY(-2px)',
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <input {...getInputProps()} />
            <VStack spacing={2}>
              <Icon 
                as={FaImage} 
                boxSize={8} 
                color={isDragActive ? 'blue.400' : textColor}
              />
              <Text color={textColor} fontSize="sm">
                {isDragActive ? 'Drop here!' : 'Drag & drop or click to select'}
              </Text>
              <Text color="gray.500" fontSize="xs">
                Max size: {maxSize}MB
              </Text>
            </VStack>
          </MotionBox>
        )}

        {isCompressing && (
          <Box w="100%">
            <Progress 
              value={progress} 
              size="xs" 
              colorScheme="blue" 
              borderRadius="full"
              isAnimated
            />
          </Box>
        )}
      </VStack>
    </AnimatePresence>
  );
};

const PreviewImage = ({ src, onRemove, height }) => (
  <MotionBox
    initial={{ opacity: 0, scale: 0.9 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 0.9 }}
    position="relative"
    borderRadius="xl"
    overflow="hidden"
    width="100%"
    height={height}
  >
    <Image
      src={src}
      alt="Preview"
      width="100%"
      height="100%"
      objectFit="cover"
    />
    <IconButton
      icon={<FaTimes />}
      position="absolute"
      top={2}
      right={2}
      size="sm"
      colorScheme="red"
      isRound
      onClick={(e) => {
        e.stopPropagation();
        onRemove();
      }}
      opacity={0.8}
      _hover={{ opacity: 1 }}
    />
  </MotionBox>
);

export { ImageUpload };
export default ImageUpload;
