import React, { useState, useEffect } from 'react';
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  HStack,
  Icon,
  useToast,
  useColorModeValue
} from '@chakra-ui/react';
import { FaMapMarkerAlt, FaCrosshairs } from 'react-icons/fa';
import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';

const LocationSearch = ({ onLocationSelect }) => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const bgColor = useColorModeValue('gray.800', 'gray.800');
  const borderColor = useColorModeValue('gray.700', 'gray.700');
  const textColor = useColorModeValue('gray.100', 'gray.100');

  const getCurrentLocation = async () => {
    try {
      setLoading(true);
      let position;

      if (Capacitor.isNativePlatform()) {
        // Request permissions first on native platforms
        const permissionStatus = await Geolocation.checkPermissions();
        if (permissionStatus.location !== 'granted') {
          await Geolocation.requestPermissions();
        }
        position = await Geolocation.getCurrentPosition();
      } else {
        // Browser geolocation
        position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0
          });
        });
      }

      const location = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };

      console.log('Got current location:', location);
      onLocationSelect(location);

      toast({
        title: 'Location found',
        description: 'Using your current location',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error getting location:', error);
      
      // Default to Bremen city center if location access fails
      const defaultLocation = { lat: 53.0793, lng: 8.8017 };
      onLocationSelect(defaultLocation);
      
      toast({
        title: 'Location access denied',
        description: 'Using default location: Bremen city center',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  // Automatically get current location when component mounts
  useEffect(() => {
    getCurrentLocation();
  }, []);

  const handleLocationSearch = async (event) => {
    event.preventDefault();
    const searchQuery = event.target.elements.location.value;

    if (!searchQuery) {
      getCurrentLocation();
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(
        `https://us1.locationiq.com/v1/search.php?key=pk.e894d2148b5bf1f8642a9ef4de48b26f&q=${encodeURIComponent(
          searchQuery
        )}&format=json&limit=1`
      );

      if (!response.ok) {
        throw new Error('Location search failed');
      }

      const data = await response.json();
      if (data && data[0]) {
        const location = {
          lat: parseFloat(data[0].lat),
          lng: parseFloat(data[0].lon)
        };
        console.log('Found location:', location);
        onLocationSelect(location);
      }
    } catch (error) {
      console.error('Error searching location:', error);
      toast({
        title: 'Error',
        description: 'Could not find location. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box as="form" onSubmit={handleLocationSearch}>
      <HStack spacing={2}>
        <InputGroup>
          <InputLeftElement>
            <Icon as={FaMapMarkerAlt} color="gray.400" />
          </InputLeftElement>
          <Input
            name="location"
            placeholder="Enter location or use current location"
            bg={bgColor}
            borderColor={borderColor}
            color={textColor}
            _placeholder={{ color: 'gray.400' }}
          />
        </InputGroup>
        <Button
          type="submit"
          colorScheme="blue"
          isLoading={loading}
          loadingText="Searching..."
        >
          Search
        </Button>
        <Button
          onClick={getCurrentLocation}
          leftIcon={<Icon as={FaCrosshairs} />}
          variant="outline"
          isLoading={loading}
          loadingText="Getting location..."
          borderColor={borderColor}
          color={textColor}
          _hover={{ bg: 'gray.700' }}
        >
          Current
        </Button>
      </HStack>
    </Box>
  );
};

export default LocationSearch;
