import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  VStack,
  Select,
  Button,
  Text,
  HStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import { FaMugHot } from 'react-icons/fa';

const QuickDrinkModal = ({ isOpen, onClose, coffees, onDrink }) => {
  const [selectedCoffee, setSelectedCoffee] = useState(coffees[0]?.id);
  const [amount, setAmount] = useState(18);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm">
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent>
        <ModalHeader>Quick Drink</ModalHeader>
        <ModalBody pb={6}>
          <VStack spacing={4}>
            <Select
              value={selectedCoffee}
              onChange={(e) => setSelectedCoffee(e.target.value)}
              size="lg"
            >
              {coffees.map(coffee => (
                <option key={coffee.id} value={coffee.id}>
                  {coffee.name}
                </option>
              ))}
            </Select>

            <HStack width="100%">
              <Text>Amount:</Text>
              <NumberInput
                value={amount}
                onChange={(value) => setAmount(Number(value))}
                min={1}
                max={30}
                size="md"
                flex={1}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Text>g</Text>
            </HStack>

            <Button
              leftIcon={<FaMugHot />}
              colorScheme="blue"
              size="lg"
              width="100%"
              onClick={() => onDrink(selectedCoffee, amount)}
            >
              Drink
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default QuickDrinkModal; 