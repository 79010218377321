import React from 'react';
import {
  Box,
  Container,
  Grid,
  GridItem,
  useColorModeValue,
} from '@chakra-ui/react';
import ProfileHeader from '../sections/ProfileHeader';
import ProfileStats from '../sections/ProfileStats';
import ProfileBio from '../sections/ProfileBio';
import ProfileTabs from '../sections/ProfileTabs';

const DesktopLayout = ({
  user,
  currentUser,
  isOwnProfile,
  posts,
  stats,
  isEditing,
  onEdit,
  onUpdate,
  onImageUpdate,
  onClose,
}) => {
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const contentBg = useColorModeValue('white', 'gray.800');

  return (
    <Box bg={bgColor} minH="calc(100vh - 64px)" py={8}>
      <Container maxW="container.xl">
        <Grid templateColumns="300px 1fr" gap={8}>
          <GridItem>
            <Box position="sticky" top="80px">
              <ProfileBio
                user={user}
                isOwnProfile={isOwnProfile}
                isEditing={isEditing}
                onEdit={onEdit}
                onUpdate={onUpdate}
                onClose={onClose}
              />
              <ProfileStats stats={stats} />
            </Box>
          </GridItem>

          <GridItem>
            <Box bg={contentBg} borderRadius="xl" shadow="sm" overflow="hidden">
              <ProfileHeader
                user={user}
                currentUser={currentUser}
                isOwnProfile={isOwnProfile}
                onImageUpdate={onImageUpdate}
              />
              <Box p={6}>
                <ProfileTabs
                  user={user}
                  posts={posts}
                  isOwnProfile={isOwnProfile}
                />
              </Box>
            </Box>
          </GridItem>
        </Grid>
      </Container>
    </Box>
  );
};

export default DesktopLayout; 