import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  Text,
  useColorModeValue,
  Container,
  Heading,
  Avatar,
  Link,
  Flex,
  Button,
  IconButton,
  useToast,
  Divider,
  Skeleton,
  Badge,
  useBreakpointValue,
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  orderBy, 
  limit,
  getDoc,
  doc,
  addDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  Timestamp 
} from 'firebase/firestore';
import { db } from '../firebase';
import { Link as RouterLink } from 'react-router-dom';
import { formatTimeAgo } from '../utils/formatTimeAgo';
import { FaHeart, FaCamera, FaClock } from 'react-icons/fa';
import ImageUpload from './shared/ImageUpload';
import ImageModal from './shared/ImageModal';
import { useAuth } from '../contexts/AuthContext';
import { useBrewCheck } from '../hooks/useBrewCheck';
import { useBrewVotes } from '../hooks/useBrewVotes';
import PreviousWinners from './brew/PreviousWinners';

const MotionBox = motion(Box);

const TimeLeft = ({ endTime }) => {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const end = new Date(endTime);
      const diff = end - now;

      if (diff <= 0) {
        setTimeLeft('Voting ended');
        return;
      }

      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      setTimeLeft(`${hours}h ${minutes}m left`);
    };

    updateTime();
    const interval = setInterval(updateTime, 60000);
    return () => clearInterval(interval);
  }, [endTime]);

  return (
    <Badge colorScheme="purple" variant="subtle">
      <Flex align="center" gap={1}>
        <FaClock size={12} />
        {timeLeft}
      </Flex>
    </Badge>
  );
};

const WinnerCard = ({ date, winner, index }) => {
  const [userData, setUserData] = useState(null);
  const bgColor = useColorModeValue('gray.700', 'gray.800');
  const textColor = useColorModeValue('gray.300', 'gray.400');

  useEffect(() => {
    const fetchUserData = async () => {
      if (winner?.userId) {
        try {
          const userDoc = await getDoc(doc(db, 'users', winner.userId));
          if (userDoc.exists()) {
            setUserData(userDoc.data());
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [winner?.userId]);

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'short',
      day: 'numeric'
    }).toUpperCase();
  };

  return (
    <Box
      bg={bgColor}
      borderRadius="xl"
      overflow="hidden"
      mb={4}
    >
      <Box p={4}>
        <Flex 
          justify="space-between" 
          align="center" 
          mb={winner ? 4 : 0}
        >
          <Text
            color="gray.300"
            fontSize="sm"
            fontWeight="medium"
            bg="gray.600"
            px={3}
            py={1}
            borderRadius="full"
          >
            {formatDate(date)}
          </Text>
          {winner && (
            <Text color={textColor} fontSize="sm">
              {formatTimeAgo(winner.timestamp.toDate())}
            </Text>
          )}
        </Flex>

        {winner ? (
          <Box>
            <Flex align="center" gap={3} mb={4}>
              <Link
                as={RouterLink}
                to={`/profile/${winner.userId}`}
                _hover={{ textDecoration: 'none' }}
              >
                <Avatar
                  size="sm"
                  src={userData?.photoURL || winner.userPhotoURL}
                  name={userData?.displayName || winner.userName}
                />
              </Link>
              <Link
                as={RouterLink}
                to={`/profile/${winner.userId}`}
                color="white"
                fontWeight="medium"
                _hover={{ color: 'blue.300' }}
              >
                {userData?.displayName || winner.userName}
              </Link>
            </Flex>

            {winner.photoURL && (
              <Box
                position="relative"
                borderRadius="lg"
                overflow="hidden"
                cursor="pointer"
                transition="transform 0.2s"
                _hover={{ transform: 'scale(1.02)' }}
              >
                <Box
                  as="img"
                  src={winner.photoURL}
                  alt="Winning coffee"
                  width="100%"
                  height="auto"
                  objectFit="cover"
                  borderRadius="lg"
                />
              </Box>
            )}
          </Box>
        ) : (
          <Text color={textColor} fontStyle="italic">
            No winner for this day
          </Text>
        )}
      </Box>
    </Box>
  );
};

const TodayEntries = ({ entries = [], onVote, currentUser, selectedImage, setSelectedImage }) => {
  return (
    <Box mb={8}>
      <Flex justify="space-between" align="center" mb={4}>
        <Heading size="md" color="white">
          Today's Entries
        </Heading>
        <TimeLeft endTime={new Date().setHours(23, 59, 59, 999)} />
      </Flex>
      
      <VStack spacing={4} align="stretch">
        {entries.length === 0 ? (
          <Text color="gray.400">No entries yet today. Be the first!</Text>
        ) : (
          entries.map((entry) => (
            <MotionBox
              key={entry.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              whileHover={{ y: -2 }}
              bg="gray.700"
              borderRadius="xl"
              overflow="hidden"
              p={4}
            >
              <Flex align="center" justify="space-between" mb={4}>
                <Flex align="center" gap={3}>
                  <Link
                    as={RouterLink}
                    to={`/profile/${entry.userId}`}
                    _hover={{ textDecoration: 'none' }}
                  >
                    <Avatar size="sm" src={entry.userPhotoURL} name={entry.userName} />
                  </Link>
                  <Link
                    as={RouterLink}
                    to={`/profile/${entry.userId}`}
                    color="white"
                    fontWeight="medium"
                    _hover={{ color: 'blue.300' }}
                  >
                    {entry.userName}
                  </Link>
                </Flex>
                <IconButton
                  icon={<FaHeart />}
                  variant="ghost"
                  colorScheme={entry.votes?.includes(currentUser?.uid) ? "red" : "gray"}
                  onClick={() => onVote(entry.id)}
                  isDisabled={!currentUser}
                  aria-label="Vote"
                />
              </Flex>
              <Box
                position="relative"
                borderRadius="lg"
                overflow="hidden"
                cursor="pointer"
                onClick={() => setSelectedImage(entry.photoURL)}
                transition="transform 0.2s"
                _hover={{ transform: 'scale(1.02)' }}
              >
                <Box
                  as="img"
                  src={entry.photoURL}
                  alt="Coffee of the day entry"
                  width="100%"
                  height="auto"
                  objectFit="cover"
                  borderRadius="lg"
                />
              </Box>
              <Text color="gray.300" mt={2}>
                {entry.votes?.length || 0} votes
              </Text>
            </MotionBox>
          ))
        )}
      </VStack>

      <ImageModal
        isOpen={!!selectedImage}
        onClose={() => setSelectedImage(null)}
        imageUrl={selectedImage}
      />
    </Box>
  );
};

const BrewCheck = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [previousWinners, setPreviousWinners] = useState([]);
  const { user } = useAuth();
  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const {
    todaysPosts,
    yesterdayWinner,
    loading,
    hasPostedToday,
    timeLeft,
    refetchPosts
  } = useBrewCheck(user);

  const { handleVote, votingPost } = useBrewVotes(refetchPosts);

  // Fetch previous winners
  useEffect(() => {
    const fetchPreviousWinners = async () => {
      try {
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
        
        const q = query(
          collection(db, 'posts'),
          where('type', '==', 'coffeeOfTheDay'),
          where('isWinner', '==', true),
          where('timestamp', '>=', sevenDaysAgo),
          orderBy('timestamp', 'desc'),
          limit(7)
        );

        const snapshot = await getDocs(q);
        const winners = snapshot.docs.map(doc => ({
          id: doc.id,
          date: doc.data().timestamp.toDate(),
          winner: { id: doc.id, ...doc.data() }
        }));

        setPreviousWinners(winners);
      } catch (error) {
        console.error('Error fetching previous winners:', error);
      }
    };

    fetchPreviousWinners();
  }, []);

  const handleUpload = async (file) => {
    if (!user) {
      toast({
        title: "Please login to participate",
        status: "warning",
        duration: 3000,
      });
      return;
    }

    if (hasPostedToday) {
      toast({
        title: "You've already posted today",
        status: "warning",
        duration: 3000,
      });
      return;
    }

    try {
      setIsUploading(true);
      
      // Upload image logic here
      const photoURL = await uploadImage(file);
      
      const postData = {
        type: 'coffeeOfTheDay',
        photoURL,
        timestamp: new Date(),
        votes: [],
        isWinner: false
      };

      await createBrewCheck(postData, user);
      
      toast({
        title: "Entry submitted!",
        status: "success",
        duration: 3000,
      });
      
      refetchPosts();
    } catch (error) {
      console.error('Error uploading entry:', error);
      toast({
        title: "Error uploading entry",
        status: "error",
        duration: 3000,
      });
    } finally {
      setIsUploading(false);
    }
  };

  if (loading) {
    return (
      <Container maxW="container.sm" py={8}>
        <VStack spacing={4}>
          {[...Array(3)].map((_, i) => (
            <Skeleton key={i} height="200px" width="100%" borderRadius="xl" />
          ))}
        </VStack>
      </Container>
    );
  }

  return (
    <Container maxW="container.sm" py={8}>
      <VStack spacing={8} align="stretch">
        <Box>
          <Heading size="lg" color="white" mb={4}>
            Coffee of the Day
          </Heading>
          
          {!hasPostedToday && (
            <Box mb={6}>
              <ImageUpload
                onUpload={handleUpload}
                accept="image/*"
                maxSize={5}
                ratio={4/3}
                showCamera={true}
                isMobile={isMobile}
              />
            </Box>
          )}

          <TodayEntries
            entries={todaysPosts}
            onVote={handleVote}
            currentUser={user}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
          />
        </Box>

        <Divider />

        <PreviousWinners />
      </VStack>
    </Container>
  );
};

export default BrewCheck;
