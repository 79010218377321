import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  HStack,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Image,
  Icon,
  useToast,
  Grid,
  GridItem,
  Badge,
  Heading,
  Wrap,
  WrapItem,
  Progress,
  Tag,
  TagLabel,
  Divider,
  SimpleGrid,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb
} from '@chakra-ui/react';
import { FaCoffee, FaMugHot, FaLeaf, FaAppleAlt, FaIceCream } from 'react-icons/fa';
import { GiCoffeeBeans, GiChocolateBar } from 'react-icons/gi';
import { collection, addDoc, updateDoc, doc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const TASTING_SECTIONS = {
  aroma: {
    title: 'Aroma',
    description: 'First, smell your coffee.',
    options: {
      FLORAL: { icon: FaLeaf, color: 'green.400' },
      FRUITY: { icon: FaAppleAlt, color: 'red.400' },
      CHOCOLATE: { icon: GiChocolateBar, color: 'brown.400' }
    }
  },
  taste: {
    title: 'Taste',
    description: 'Take a sip of your coffee.',
    categories: {
      floral: {
        title: 'Floral',
        options: ['VANILLA', 'HONEY']
      },
      sweet: {
        title: 'Sweet',
        options: ['MAPLE SYRUP', 'CARAMEL', 'MARZIPAN', 'MALTY']
      },
      fruit: {
        title: 'Fruit',
        options: ['BERRIES', 'TROPICAL', 'CITRUS', 'STONE FRUIT']
      },
      chocolate: {
        title: 'Chocolate',
        options: ['DARK', 'MILK', 'COCOA']
      }
    }
  },
  acidityBitterness: {
    title: 'Acidity & Bitterness',
    description: 'Sour like lemons or bitter like grapefruit?',
    scales: {
      bitter: { label: 'BITTER', max: 10 },
      acidity: { label: 'ACIDITY', max: 10 }
    }
  },
  body: {
    title: 'Body',
    description: 'The texture and weight in your mouth.',
    categories: {
      light: {
        title: 'Light',
        options: ['WATERY', 'TEA-LIKE', 'SILKY', 'JUICY']
      },
      medium: {
        title: 'Medium',
        options: ['SMOOTH', 'SYRUPY', 'ROUND', 'CREAMY']
      },
      heavy: {
        title: 'Heavy',
        options: ['FULL', 'VELVETY', 'THICK', 'COATING']
      }
    }
  }
};

const TastingCard = ({ coffee, onSelect }) => (
  <MotionBox
    whileHover={{ y: -5 }}
    whileTap={{ scale: 0.95 }}
    cursor="pointer"
    borderWidth="1px"
    borderRadius="lg"
    overflow="hidden"
    onClick={() => onSelect(coffee)}
    bg="white"
    shadow="md"
  >
    <Box h="200px" position="relative">
      {coffee.photoURL ? (
        <Image src={coffee.photoURL} alt={coffee.name} objectFit="cover" w="full" h="full" />
      ) : (
        <Box bg="gray.100" w="full" h="full" display="flex" alignItems="center" justifyContent="center">
          <Icon as={FaCoffee} boxSize="40px" color="gray.400" />
        </Box>
      )}
    </Box>
    <Box p={4}>
      <Text fontWeight="bold" fontSize="lg">{coffee.name}</Text>
      <Text color="gray.600">{coffee.roaster}</Text>
    </Box>
  </MotionBox>
);

const TastingSection = ({ title, children, icon }) => (
  <Box mb={6} bg="gray.800" p={4} borderRadius="lg" shadow="dark-lg">
    <HStack mb={4}>
      <Icon as={icon} boxSize={6} color="orange.300" />
      <Heading size="md" color="white">{title}</Heading>
    </HStack>
    {children}
  </Box>
);

const FlavorTag = ({ label, isSelected, onClick }) => (
  <Tag
    size="lg"
    variant={isSelected ? "solid" : "outline"}
    colorScheme={isSelected ? "orange" : "gray"}
    cursor="pointer"
    onClick={onClick}
    m={1}
    bg={isSelected ? "orange.400" : "transparent"}
    color={isSelected ? "white" : "gray.400"}
    borderColor={isSelected ? "orange.400" : "gray.600"}
    _hover={{
      bg: isSelected ? "orange.500" : "gray.700",
      borderColor: isSelected ? "orange.500" : "gray.500"
    }}
  >
    <TagLabel>{label}</TagLabel>
  </Tag>
);

const FlavorWheel = ({ categories, selectedFlavors, onSelect }) => (
  <Box>
    <Text color="gray.400">Interactive Flavor Wheel Coming Soon!</Text>
    {/* Implement the flavor wheel logic here or integrate a package */}
  </Box>
);

const TastingModal = ({ isOpen, onClose, coffee, onSave, selectedAromas, handleAromaSelect, handleAcidityChange, handleBitternessChange, handleBodyChange }) => {
  const [selectedFlavors, setSelectedFlavors] = useState(new Set());
  const [scores, setScores] = useState({
    bitter: 5,
    acidity: 5
  });
  const [bodyType, setBodyType] = useState('');

  const handleFlavorToggle = (flavor) => {
    const newSelected = new Set(selectedFlavors);
    if (newSelected.has(flavor)) {
      newSelected.delete(flavor);
    } else {
      newSelected.add(flavor);
    }
    setSelectedFlavors(newSelected);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent margin={0} bg="gray.900">
        <ModalHeader bg="gray.800" color="white">
          <HStack>
            <Icon as={FaMugHot} color="orange.300" />
            <VStack align="start" spacing={0}>
              <Text>{coffee?.name}</Text>
              <Text fontSize="sm" fontWeight="normal" color="gray.400">{coffee?.roaster}</Text>
            </VStack>
          </HStack>
        </ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody p={4} pb={24}>
          <VStack spacing={6} align="stretch">
            {/* Aroma Section */}
            <TastingSection title="Aroma" icon={GiCoffeeBeans}>
              <Text fontSize="sm" mb={4} color="gray.400">Smell your coffee first:</Text>
              <Wrap justify="center">
                {Object.entries(TASTING_SECTIONS.aroma.options).map(([flavor, { icon }]) => (
                  <Button
                    key={flavor}
                    leftIcon={<Icon as={icon} />}
                    variant={selectedAromas.has(flavor) ? "solid" : "outline"}
                    colorScheme={selectedAromas.has(flavor) ? "orange" : "gray"}
                    onClick={() => handleAromaSelect(flavor)}
                    m={1}
                    borderRadius="full"
                    _hover={{
                      bg: selectedAromas.has(flavor) ? "orange.500" : "gray.700",
                    }}
                  >
                    {flavor}
                  </Button>
                ))}
                <Button variant="outline" colorScheme="gray" onClick={() => handleAromaSelect('Unsure')} m={1}>
                  Unsure
                </Button>
              </Wrap>
            </TastingSection>

            {/* Flavor Section */}
            <TastingSection title="Flavor" icon={GiCoffeeBeans}>
              <Text fontSize="sm" mb={4} color="gray.400">Take a sip of your coffee:</Text>
              <Wrap justify="center">
                {Object.entries(TASTING_SECTIONS.taste.categories).map(([category, { title, options }]) => (
                  <Box key={category} mb={4} textAlign="center">
                    <Text fontWeight="bold" mb={2}>{title}</Text>
                    <Wrap justify="center">
                      {options.map(flavor => (
                        <Button
                          key={flavor}
                          variant={selectedFlavors.has(flavor) ? "solid" : "outline"}
                          colorScheme={selectedFlavors.has(flavor) ? "orange" : "gray"}
                          onClick={() => handleFlavorToggle(flavor)}
                          m={1}
                          _hover={{
                            bg: selectedFlavors.has(flavor) ? "orange.500" : "gray.700",
                          }}
                        >
                          {flavor}
                        </Button>
                      ))}
                    </Wrap>
                  </Box>
                ))}
              </Wrap>
              <Button variant="outline" colorScheme="gray" onClick={() => setSelectedFlavors(new Set())} m={1}>
                Reset
              </Button>
            </TastingSection>

            {/* Acidity & Bitterness Section */}
            <TastingSection title="Acidity & Bitterness" icon={GiCoffeeBeans}>
              <Text fontSize="sm" mb={4} color="gray.400">Sour like lemons or bitter like grapefruit?</Text>
              <VStack spacing={4} align="stretch">
                <Box>
                  <Text color="gray.400">Acidity</Text>
                  <Slider defaultValue={5} min={0} max={10} step={1} onChange={handleAcidityChange}>
                    <SliderTrack bg="linear-gradient(to right, #ffff00, #008000)">
                      <SliderFilledTrack bg="orange.400" />
                    </SliderTrack>
                    <SliderThumb boxSize={6} bg="orange.400">
                      <Box color="gray.800" as={GiCoffeeBeans} />
                    </SliderThumb>
                  </Slider>
                </Box>
                <Box>
                  <Text color="gray.400">Bitterness</Text>
                  <Slider defaultValue={5} min={0} max={10} step={1} onChange={handleBitternessChange}>
                    <SliderTrack bg="linear-gradient(to right, #ffa500, #ff0000)">
                      <SliderFilledTrack bg="orange.400" />
                    </SliderTrack>
                    <SliderThumb boxSize={6} bg="orange.400">
                      <Box color="gray.800" as={GiCoffeeBeans} />
                    </SliderThumb>
                  </Slider>
                </Box>
              </VStack>
            </TastingSection>

            {/* Body Section */}
            <TastingSection title="Body" icon={GiCoffeeBeans}>
              <Text fontSize="sm" mb={4} color="gray.400">The texture of your coffee in the mouth:</Text>
              <Slider defaultValue={3} min={1} max={5} step={1} onChange={handleBodyChange} pb={4}>
                <SliderTrack bg="linear-gradient(to right, #add8e6, #ffa500, #ff4500)">
                  <SliderFilledTrack bg="orange.400" />
                </SliderTrack>
                <SliderThumb boxSize={6} bg="orange.400">
                  <Box color="gray.800" as={GiCoffeeBeans} />
                </SliderThumb>
              </Slider>
              <HStack justify="space-between">
                <Text color="blue.500">Watery</Text>
                <Text color="blue.500">Tea-like</Text>
                <Text color="blue.500">Silky</Text>
                <Text color="orange.500">Juicy</Text>
                <Text color="orange.500">Smooth</Text>
                <Text color="orange.500">Round</Text>
                <Text color="orange.500">Creamy</Text>
                <Text color="red.500">Full</Text>
                <Text color="red.500">Velvety</Text>
                <Text color="red.500">Sticky</Text>
                <Text color="red.500">Coating</Text>
              </HStack>
            </TastingSection>

            <Button
              colorScheme="orange"
              size="lg"
              position="fixed"
              bottom={4}
              left={4}
              right={4}
              onClick={onSave}
              bg="orange.400"
              _hover={{ bg: 'orange.500' }}
            >
              Save Tasting
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const CoffeeTasting = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCoffee, setSelectedCoffee] = useState(null);
  const [coffeeInventory, setCoffeeInventory] = useState([]);
  const [tastingScores, setTastingScores] = useState({
    bitter: 5,
    acidity: 5
  });
  const [selectedFlavors, setSelectedFlavors] = useState(new Set());
  const [bodyType, setBodyType] = useState('');
  const { user } = useAuth();
  const toast = useToast();
  const [selectedAromas, setSelectedAromas] = useState(new Set());

  useEffect(() => {
    if (user) {
      loadCoffeeInventory();
    }
  }, [user]);

  const loadCoffeeInventory = async () => {
    const q = query(collection(db, 'coffeeInventory'), where('userId', '==', user.uid));
    const querySnapshot = await getDocs(q);
    const coffees = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setCoffeeInventory(coffees);
  };

  const handleSaveTasting = async () => {
    if (!selectedCoffee) return;

    try {
      const tastingData = {
        coffeeId: selectedCoffee.id,
        userId: user.uid,
        scores: tastingScores,
        selectedFlavors: Array.from(selectedFlavors),
        bodyType,
        timestamp: new Date(),
      };

      // Add tasting to tastings collection
      await addDoc(collection(db, 'tastings'), tastingData);

      // Update coffee document with new tasting
      const coffeeRef = doc(db, 'coffeeInventory', selectedCoffee.id);
      const averageScore = (tastingScores.bitter + tastingScores.acidity) / 2;
      
      await updateDoc(coffeeRef, {
        tastings: [...(selectedCoffee.tastings || []), tastingData],
        averageRating: averageScore
      });

      toast({
        title: 'Tasting saved!',
        status: 'success',
        duration: 3000,
      });

      onClose();
      setSelectedCoffee(null);
      setTastingScores({ bitter: 5, acidity: 5 });
      setSelectedFlavors(new Set());
      setBodyType('');
    } catch (error) {
      toast({
        title: 'Error saving tasting',
        description: error.message,
        status: 'error',
        duration: 3000,
      });
    }
  };

  const handleAromaSelect = (flavor) => {
    const newSelected = new Set(selectedAromas);
    if (newSelected.has(flavor)) {
      newSelected.delete(flavor);
    } else {
      newSelected.add(flavor);
    }
    setSelectedAromas(newSelected);
  };

  const handleBodyChange = (value) => {
    setBodyType(value);
  };

  const handleAcidityChange = (value) => {
    setTastingScores((prevScores) => ({ ...prevScores, acidity: value }));
  };

  const handleBitternessChange = (value) => {
    setTastingScores((prevScores) => ({ ...prevScores, bitter: value }));
  };

  return (
    <Box p={4}>
      <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }} gap={6}>
        {coffeeInventory.map(coffee => (
          <TastingCard 
            key={coffee.id} 
            coffee={coffee} 
            onSelect={() => {
              setSelectedCoffee(coffee);
              onOpen();
            }}
          />
        ))}
      </Grid>

      {selectedCoffee && (
        <TastingModal
          isOpen={isOpen}
          onClose={onClose}
          coffee={selectedCoffee}
          onSave={handleSaveTasting}
          selectedAromas={selectedAromas}
          handleAromaSelect={handleAromaSelect}
          handleAcidityChange={handleAcidityChange}
          handleBitternessChange={handleBitternessChange}
          handleBodyChange={handleBodyChange}
        />
      )}
    </Box>
  );
};

export default CoffeeTasting; 