import React, { useState, useEffect } from 'react';
import {
  Box,
  SimpleGrid,
  VStack,
  Button,
  Text,
  useDisclosure,
  useToast,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Icon,
  Image,
  Badge,
  HStack,
  useBreakpointValue,
  useColorModeValue,
  Heading,
} from '@chakra-ui/react';
import { FaPlus, FaCoffee, FaBlender, FaWeight, FaThermometer } from 'react-icons/fa';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import AddEquipmentForm from './forms/AddEquipmentForm';
import EquipmentDetails from './EquipmentDetails';
import StarRating from './shared/StarRating';

const CATEGORIES = [
  { id: 'brewing', name: 'Brewing', icon: FaCoffee },
  { id: 'grinder', name: 'Grinders', icon: FaBlender },
  { id: 'scale', name: 'Scales', icon: FaWeight },
  { id: 'kettle', name: 'Kettles', icon: FaThermometer },
  { id: 'other', name: 'Other', icon: FaCoffee },
];

const EquipmentInventory = ({ userId }) => {
  const [equipment, setEquipment] = useState([]);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const detailsDisclosure = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const fetchEquipment = async () => {
    try {
      setLoading(true);
      const q = query(
        collection(db, 'equipmentInventory'),
        where('userId', '==', userId || user?.uid)
      );
      const querySnapshot = await getDocs(q);
      const fetchedEquipment = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setEquipment(fetchedEquipment);
    } catch (error) {
      console.error('Error fetching equipment:', error);
      toast({
        title: 'Error fetching equipment',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user || userId) {
      fetchEquipment();
    }
  }, [userId, user]);

  const handleEquipmentClick = (equipment) => {
    setSelectedEquipment(equipment);
    detailsDisclosure.onOpen();
  };

  const EquipmentCard = ({ equipment }) => (
    <Box
      p={4}
      borderWidth="1px"
      borderRadius="lg"
      cursor="pointer"
      onClick={() => handleEquipmentClick(equipment)}
      _hover={{ transform: 'translateY(-2px)', shadow: 'md' }}
      transition="all 0.2s"
      bg={bgColor}
      height="100%"
    >
      <VStack align="stretch" spacing={3}>
        <Box
          position="relative"
          borderRadius="md"
          overflow="hidden"
          height="200px"
        >
          {equipment.photoURL ? (
            <Image
              src={equipment.photoURL}
              alt={equipment.name}
              objectFit="cover"
              width="100%"
              height="100%"
              fallback={
                <Box
                  height="100%"
                  bg="gray.100"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon as={FaCoffee} boxSize="40px" color="gray.400" />
                </Box>
              }
            />
          ) : (
            <Box
              height="100%"
              bg="gray.100"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon as={FaCoffee} boxSize="40px" color="gray.400" />
            </Box>
          )}
          <Badge
            position="absolute"
            top={2}
            right={2}
            colorScheme={equipment.status === 'Active' ? 'green' : 'yellow'}
          >
            {equipment.status}
          </Badge>
        </Box>

        <VStack align="stretch" spacing={1}>
          <Text fontSize="lg" fontWeight="bold" noOfLines={1}>
            {equipment.name}
          </Text>
          <Text color="gray.500" fontSize="sm" noOfLines={1}>
            {equipment.manufacturer} {equipment.model}
          </Text>
          <HStack spacing={2}>
            <StarRating rating={equipment.rating || 0} size={14} />
            {equipment.rating > 0 && (
              <Text fontSize="sm" color="gray.500">
                ({equipment.rating.toFixed(1)})
              </Text>
            )}
          </HStack>
          <HStack spacing={2} flexWrap="wrap">
            {equipment.tags?.slice(0, 2).map((tag, index) => (
              <Badge key={index} colorScheme="blue" variant="subtle">
                {tag}
              </Badge>
            ))}
            {equipment.tags?.length > 2 && (
              <Badge colorScheme="gray" variant="subtle">
                +{equipment.tags.length - 2}
              </Badge>
            )}
          </HStack>
        </VStack>
      </VStack>
    </Box>
  );

  return (
    <Box>
      <HStack justify="space-between" mb={6}>
        <Heading size="lg">Equipment</Heading>
        <Button
          leftIcon={<Icon as={FaPlus} />}
          colorScheme="blue"
          onClick={onOpen}
          size={isMobile ? "md" : "sm"}
        >
          Add Equipment
        </Button>
      </HStack>

      <Tabs>
        <TabList overflowX="auto" overflowY="hidden" py={2}>
          {CATEGORIES.map(category => (
            <Tab
              key={category.id}
              px={4}
              py={2}
              whiteSpace="nowrap"
            >
              <HStack spacing={2}>
                <Icon as={category.icon} />
                <Text>{category.name}</Text>
                <Badge>
                  {equipment.filter(eq => 
                    eq.category.toLowerCase().includes(category.id)
                  ).length}
                </Badge>
              </HStack>
            </Tab>
          ))}
        </TabList>

        <TabPanels>
          {CATEGORIES.map(category => (
            <TabPanel key={category.id} px={0}>
              {loading ? (
                <VStack py={8} spacing={4}>
                  <Text>Loading equipment...</Text>
                </VStack>
              ) : (
                <SimpleGrid
                  columns={{ base: 1, md: 2, lg: 3 }}
                  spacing={6}
                  py={4}
                >
                  {equipment
                    .filter(eq => eq.category.toLowerCase().includes(category.id))
                    .map(eq => (
                      <EquipmentCard key={eq.id} equipment={eq} />
                    ))}
                </SimpleGrid>
              )}
              {!loading && 
                equipment.filter(eq => 
                  eq.category.toLowerCase().includes(category.id)
                ).length === 0 && (
                <VStack py={8} spacing={4}>
                  <Text color="gray.500">
                    No {category.name.toLowerCase()} equipment found
                  </Text>
                  <Button
                    leftIcon={<Icon as={FaPlus} />}
                    colorScheme="blue"
                    variant="outline"
                    onClick={onOpen}
                  >
                    Add {category.name}
                  </Button>
                </VStack>
              )}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>

      <AddEquipmentForm
        isOpen={isOpen}
        onClose={onClose}
        onSuccess={fetchEquipment}
      />

      {selectedEquipment && (
        <EquipmentDetails
          equipment={selectedEquipment}
          isOpen={detailsDisclosure.isOpen}
          onClose={() => {
            detailsDisclosure.onClose();
            setSelectedEquipment(null);
          }}
          onUpdate={fetchEquipment}
        />
      )}
    </Box>
  );
};

export default EquipmentInventory;
