import axios from 'axios';

const GOOGLE_MAPS_API_KEY = 'AIzaSyDuYwgDNDmrDbKIAYRNkkWoigiuTnF3d8o';

const DEFAULT_COFFEE_IMAGE = "https://images.unsplash.com/photo-1442512595331-e89e73853f31?auto=format&fit=crop&q=80&w=400";

const createMapElement = () => {
  const existingMap = document.getElementById('google-map-services');
  if (existingMap) return existingMap;
  
  const mapDiv = document.createElement('div');
  mapDiv.style.display = 'none';
  mapDiv.id = 'google-map-services';
  document.body.appendChild(mapDiv);
  return mapDiv;
};

// Helper function to calculate distance between two points in meters
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Earth's radius in km
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
    Math.sin(dLon/2) * Math.sin(dLon/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return R * c * 1000; // Convert to meters
};

export const searchNearbyPlaces = async ({ lat, lng, radius = 2000 }) => {
  try {
    console.log('Searching nearby places with:', { lat, lng, radius });
    const service = new window.google.maps.places.PlacesService(createMapElement());
    
    // First get basic place data
    const searchResults = await new Promise((resolve, reject) => {
      service.nearbySearch(
        {
          location: { lat, lng },
          radius: radius,
          type: ['cafe', 'restaurant'],
          keyword: 'coffee',
        },
        (results, status, pagination) => {
          console.log('Nearby search results:', results);
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            resolve({ results, pagination });
          } else {
            reject(new Error(`Google Places API returned status: ${status}`));
          }
        }
      );
    });

    // Get details for each place
    const detailedPlaces = await Promise.all(
      searchResults.results.map(async (place) => {
        try {
          console.log('Getting details for place:', place.name);
          const details = await new Promise((resolve, reject) => {
            service.getDetails(
              {
                placeId: place.place_id,
                fields: [
                  'name',
                  'formatted_address',
                  'geometry',
                  'photos',
                  'rating',
                  'user_ratings_total',
                  'price_level',
                  'opening_hours',
                  'website',
                  'formatted_phone_number',
                  'reviews',
                  'types',
                  'business_status'
                ]
              },
              (result, status) => {
                console.log('Place details result:', result);
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                  resolve(result);
                } else {
                  reject(new Error(`Failed to get place details: ${status}`));
                }
              }
            );
          });

          // Calculate distance
          const distance = calculateDistance(
            lat,
            lng,
            details.geometry.location.lat(),
            details.geometry.location.lng()
          );

          // Process photos
          const photos = details.photos?.slice(0, 5).map(photo => {
            const url = photo.getUrl({ maxWidth: 400, maxHeight: 400 });
            console.log('Generated photo URL:', url);
            return {
              url,
              attribution: photo.html_attributions?.[0] || null
            };
          }) || [];

          const placeData = {
            id: place.place_id,
            placeId: place.place_id,
            name: details.name,
            location: {
              lat: details.geometry.location.lat(),
              lng: details.geometry.location.lng()
            },
            address: details.formatted_address,
            distance,
            types: details.types || [],
            rating: details.rating,
            userRatingsTotal: details.user_ratings_total,
            priceLevel: details.price_level,
            openNow: details.opening_hours?.isOpen?.() || false,
            openingHours: details.opening_hours?.weekday_text || [],
            photos,
            image: photos[0]?.url || DEFAULT_COFFEE_IMAGE,
            website: details.website,
            phone: details.formatted_phone_number,
            reviews: details.reviews?.map(review => ({
              author_name: review.author_name,
              rating: review.rating,
              text: review.text,
              time: review.time,
              author_url: review.author_url,
              profile_photo_url: review.profile_photo_url
            })) || [],
            businessStatus: details.business_status
          };

          console.log('Processed place data:', placeData);
          return placeData;
        } catch (error) {
          console.error('Error getting place details:', error);
          return null;
        }
      })
    );

    // Filter out failed requests
    const places = detailedPlaces.filter(place => place !== null);
    console.log('Final places data:', places);
    return places;

  } catch (error) {
    console.error('Error searching nearby places:', error);
    return [];
  }
};

export const getPlacePhotos = async (placeId, maxPhotos = 5) => {
  try {
    const service = new window.google.maps.places.PlacesService(createMapElement());
    
    return new Promise((resolve, reject) => {
      service.getDetails({
        placeId: placeId,
        fields: ['photos']
      }, (place, status) => {
        console.log('getPlacePhotos result:', place);
        if (status === window.google.maps.places.PlacesServiceStatus.OK && place.photos) {
          const photos = place.photos
            .slice(0, maxPhotos)
            .map(photo => {
              const url = photo.getUrl({ maxWidth: 800, maxHeight: 600 });
              console.log('Generated photo URL:', url);
              return {
                url,
                attribution: photo.html_attributions?.[0] || null
              };
            });
          resolve(photos);
        } else {
          resolve([]);
        }
      });
    });
  } catch (error) {
    console.error('Error getting place photos:', error);
    return [];
  }
};



