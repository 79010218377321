import React from 'react';
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useColorModeValue,
  Icon,
  Badge,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { 
  FaCoffee, 
  FaStar, 
  FaCog,
  FaMedal,
  FaBoxOpen
} from 'react-icons/fa';
import PostsTab from '../shared/tabs/PostsTab';
import ReviewsTab from '../shared/tabs/ReviewsTab';
import { BadgesTab } from '../shared/tabs/BadgesTab';
import EquipmentInventory from '../../EquipmentInventory';
import BeanInventory from '../../BeanInventory';

const MotionBox = motion(Box);

const TabItem = ({ icon, label, count }) => (
  <Box display="flex" alignItems="center" gap={2}>
    <Icon as={icon} />
    <Box>{label}</Box>
    {count > 0 && (
      <Badge
        ml={1}
        fontSize="xs"
        colorScheme="blue"
        variant="subtle"
        borderRadius="full"
      >
        {count}
      </Badge>
    )}
  </Box>
);

const ProfileTabs = ({ user, posts = [], isOwnProfile }) => {
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const hoverBg = useColorModeValue('gray.50', 'gray.700');
  const selectedBg = useColorModeValue('blue.50', 'blue.900');
  const selectedColor = useColorModeValue('blue.600', 'blue.200');

  // Filter posts by type
  const filteredPosts = {
    posts: posts.filter(p => p.type === 'post' || p.type === 'link' || p.type === 'coffeeOfTheDay'),
    reviews: posts.filter(p => p.type === 'review'),
  };

  const tabs = [
    { 
      label: 'Posts', 
      icon: FaCoffee,
      count: filteredPosts.posts.length,
      component: <PostsTab posts={filteredPosts.posts} isOwnProfile={isOwnProfile} user={user} />
    },
    { 
      label: 'Reviews', 
      icon: FaStar,
      count: filteredPosts.reviews.length,
      component: <ReviewsTab posts={filteredPosts.reviews} isOwnProfile={isOwnProfile} user={user} />
    },
    { 
      label: 'Badges', 
      icon: FaMedal,
      count: user?.badges?.length || 0,
      component: <BadgesTab profileUser={user} />
    }
  ];

  // Add inventory tabs only for own profile
  if (isOwnProfile) {
    tabs.push(
      {
        label: 'Equipment',
        icon: FaCog,
        component: <EquipmentInventory userId={user?.id} />
      },
      {
        label: 'Bean Inventory',
        icon: FaBoxOpen,
        component: <BeanInventory userId={user?.id} />
      }
    );
  }

  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Tabs
        variant="soft-rounded"
        colorScheme="blue"
        isLazy
      >
        <TabList
          overflowX="auto"
          css={{
            scrollbarWidth: 'none',
            '::-webkit-scrollbar': { display: 'none' },
            '-webkit-overflow-scrolling': 'touch',
          }}
          py={2}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.label}
              _selected={{
                bg: selectedBg,
                color: selectedColor,
              }}
              _hover={{
                bg: hoverBg,
              }}
              borderRadius="full"
              px={4}
              py={2}
              mr={2}
              whiteSpace="nowrap"
            >
              <TabItem
                icon={tab.icon}
                label={tab.label}
                count={tab.count}
              />
            </Tab>
          ))}
        </TabList>

        <TabPanels mt={4}>
          {tabs.map((tab) => (
            <TabPanel key={tab.label} p={0}>
              {tab.component}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </MotionBox>
  );
};

export default ProfileTabs;