import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, auth, storage } from '../firebase';
import { Box, Heading, Text, SimpleGrid, Button, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel, Input, Textarea, NumberInput, NumberInputField, useColorModeValue, VStack, HStack, Avatar, Image, Select, Switch, Flex, Badge, Icon } from '@chakra-ui/react';
import { FaMapMarkerAlt, FaCamera, FaTimes, FaCalendar } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import imageCompression from 'browser-image-compression';
import { GoogleMap, LoadScript, Marker, useLoadScript } from '@react-google-maps/api';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { useToast } from '@chakra-ui/react';

const libraries = ['places'];

// Add this helper function for timezone
const getLocalTimeZone = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const offset = new Date().getTimezoneOffset();
  const hours = Math.abs(Math.floor(offset / 60));
  const minutes = Math.abs(offset % 60);
  const sign = offset < 0 ? '+' : '-';
  
  return {
    timeZone,
    formattedOffset: `GMT${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`,
    displayName: timeZone.replace(/_/g, ' ').split('/').pop()
  };
};

const Meetups = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyD_nEcml7BKJrV25hk_FXs4-oHoAvbQ6Jg",
    libraries,
  });

  const { user } = useAuth();
  const [meetups, setMeetups] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newMeetup, setNewMeetup] = useState({
    title: '',
    description: '',
    date: '',
    time: '',
    timeZone: '',
    address: '',
    venue: '',
    guestLimit: 0,
    tags: [],
    coverPhoto: null,
    lat: null,
    lng: null
  });
  const [isGuestLimitEnabled, setIsGuestLimitEnabled] = useState(false);
  const bgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.600', 'gray.200');
  const buttonHoverBg = useColorModeValue('gray.100', 'whiteAlpha.200');
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState('');
  const { timeZone, formattedOffset, displayName } = getLocalTimeZone();
  const [viewMode, setViewMode] = useState('upcoming');
  const toast = useToast();

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();

  useEffect(() => {
    const fetchMeetups = async () => {
      const q = query(
        collection(db, 'meetups'),
        where('date', '>=', new Date())
      );
      const querySnapshot = await getDocs(q);
      const fetchedMeetups = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setMeetups(fetchedMeetups.sort((a, b) => a.date.toDate() - b.date.toDate()));
      setLoading(false);
    };

    fetchMeetups();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMeetup({ ...newMeetup, [name]: value });
  };

  const handleFileChange = async (e) => {
    if (e.target.files[0]) {
      const imageFile = e.target.files[0];
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        setNewMeetup({ ...newMeetup, coverPhoto: compressedFile });
      } catch (error) {
        console.error("Error compressing image:", error);
      }
    }
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setNewMeetup({
        ...newMeetup,
        address,
        lat,
        lng
      });
      setMapCenter({ lat, lng });
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      alert('You must be logged in to create a meetup.');
      return;
    }
    try {
      let coverPhotoUrl = '';
      if (newMeetup.coverPhoto) {
        const storageRef = ref(storage, `meetupCovers/${Date.now()}_${newMeetup.coverPhoto.name}`);
        await uploadBytes(storageRef, newMeetup.coverPhoto);
        coverPhotoUrl = await getDownloadURL(storageRef);
      }

      await addDoc(collection(db, 'meetups'), {
        ...newMeetup,
        createdBy: user.uid,
        creatorName: user.displayName,
        creatorPhotoURL: user.photoURL,
        createdAt: serverTimestamp(),
        attendees: [user.uid],
        date: new Date(`${newMeetup.date}T${newMeetup.time}`),
        guestLimit: isGuestLimitEnabled ? newMeetup.guestLimit : null,
        coverPhotoUrl,
        tags: newMeetup.tags.split(',').map(tag => tag.trim())
      });
      onClose();
      fetchMeetups();
    } catch (error) {
      console.error("Error adding meetup: ", error);
    }
  };

  // Handle tag input
  const handleTagKeyDown = (e) => {
    if (e.key === 'Enter' && tagInput.trim()) {
      e.preventDefault();
      const newTag = tagInput.trim();
      if (!tags.includes(newTag)) {
        setTags([...tags, newTag]);
        setNewMeetup(prev => ({
          ...prev,
          tags: [...(prev.tags || []), newTag]
        }));
      }
      setTagInput('');
    }
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
    setNewMeetup(prev => ({
      ...prev,
      tags: prev.tags.filter(tag => tag !== tagToRemove)
    }));
  };

  if (!isLoaded) return <div>Loading...</div>;

  if (loading) {
    return <Box textAlign="center" mt={8}>Loading...</Box>;
  }

  return (
    <Box maxW="1200px" mx="auto" mt={8}>
      <HStack justify="space-between" mb={6} px={4}>
        <Heading as="h1" size="xl">Coffee Meetups</Heading>
        {user ? (
          <Button 
            colorScheme="green" 
            onClick={onOpen}
            leftIcon={<Icon as={FaCalendar} />}
          >
            Host meetup
          </Button>
        ) : (
          <Button
            colorScheme="blue"
            variant="outline"
            onClick={() => {
              toast({
                title: "Login Required",
                description: "Please login to host a meetup",
                status: "info",
                duration: 3000,
                isClosable: true,
              });
              // Optionally navigate to login
              // navigate('/login');
            }}
          >
            Login to Host
          </Button>
        )}
      </HStack>
      
      <VStack align="stretch" spacing={6} px={4}>
        <Text color={textColor}>
          {user 
            ? "Welcome to #meetups. Make sure to follow and join the discussion!"
            : "Join our community to host and participate in coffee meetups."
          }
        </Text>
        
        <HStack>
          <Text>{meetups.length} meetups</Text>
        </HStack>

        <HStack spacing={4}>
          <Button
            colorScheme="blue"
            variant={viewMode === 'upcoming' ? "solid" : "ghost"}
            onClick={() => setViewMode('upcoming')}
          >
            Upcoming
          </Button>
          <Button
            variant="ghost"
            onClick={() => setViewMode('past')}
            isDisabled={true}
            _disabled={{
              opacity: 0.5,
              cursor: "not-allowed",
              _hover: {}
            }}
            title="Past meetups coming soon"
          >
            Past meetups
          </Button>
        </HStack>
        
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
          {meetups.map(meetup => (
            <Box 
              key={meetup.id} 
              bg={bgColor} 
              shadow="md" 
              rounded="lg" 
              overflow="hidden"
              position="relative"
            >
              {!user && (
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  bg="blackAlpha.50"
                  zIndex={1}
                  backdropFilter="blur(2px)"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  gap={4}
                  p={4}
                >
                  <Text 
                    textAlign="center" 
                    fontWeight="medium"
                    color={textColor}
                  >
                    Login to view meetup details and RSVP
                  </Text>
                  <Button
                    as={RouterLink}
                    to="/login"
                    colorScheme="blue"
                    size="sm"
                  >
                    Login
                  </Button>
                </Box>
              )}

              <Image
                src={meetup.coverPhotoUrl || 'https://via.placeholder.com/400x200'}
                alt={meetup.title}
                objectFit="cover"
                h="200px"
                w="100%"
                filter={!user ? "blur(2px)" : "none"}
              />
              
              <Box p={4}>
                <HStack mb={2}>
                  <Avatar size="sm" name={meetup.creatorName} src={meetup.creatorPhotoURL} />
                  <Text fontWeight="bold">{meetup.creatorName}</Text>
                  <Text color={textColor} fontSize="sm">
                    {new Date(meetup.date.seconds * 1000).toLocaleDateString()}
                  </Text>
                </HStack>
                <Heading as="h2" size="md" mb={2}>{meetup.title}</Heading>
                <Text color={textColor} mb={2}>{meetup.address}</Text>
                <Text color={textColor} noOfLines={3} mb={4}>{meetup.description}</Text>
                
                <Box height="150px" width="100%" filter={!user ? "blur(2px)" : "none"}>
                  {meetup.lat && meetup.lng && (
                    <GoogleMap
                      mapContainerStyle={{ height: "100%", width: "100%" }}
                      center={{ lat: meetup.lat, lng: meetup.lng }}
                      zoom={12}
                      options={{ gestureHandling: user ? 'auto' : 'none' }}
                    >
                      <Marker position={{ lat: meetup.lat, lng: meetup.lng }} />
                    </GoogleMap>
                  )}
                </Box>

                <HStack mt={4} justify="space-between">
                  <Button
                    as={RouterLink}
                    to={user ? `/meetup/${meetup.id}` : '/login'}
                    colorScheme="blue"
                    size="sm"
                    onClick={(e) => {
                      if (!user) {
                        e.preventDefault();
                        toast({
                          title: "Login Required",
                          description: "Please login to view meetup details",
                          status: "info",
                          duration: 3000,
                          isClosable: true,
                        });
                      }
                    }}
                  >
                    {user ? 'View Details' : 'Login to View'}
                  </Button>
                  {meetup.guestLimit && (
                    <Badge colorScheme="green">
                      {meetup.attendees?.length || 0}/{meetup.guestLimit} spots
                    </Badge>
                  )}
                </HStack>
              </Box>
            </Box>
          ))}
        </SimpleGrid>
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Host New Meetup</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              {/* Cover Photo Upload */}
              <FormControl>
                <FormLabel>Cover Photo</FormLabel>
                <Box
                  borderWidth={2}
                  borderStyle="dashed"
                  borderRadius="md"
                  p={4}
                  textAlign="center"
                  cursor="pointer"
                  onClick={() => document.getElementById('photo-upload').click()}
                  position="relative"
                  height="200px"
                  overflow="hidden"
                  bg={useColorModeValue('gray.50', 'gray.700')}
                  _hover={{
                    borderColor: 'blue.500',
                    bg: useColorModeValue('gray.100', 'gray.600')
                  }}
                >
                  {newMeetup.coverPhoto ? (
                    <Image
                      src={URL.createObjectURL(newMeetup.coverPhoto)}
                      alt="Cover preview"
                      objectFit="cover"
                      w="100%"
                      h="100%"
                    />
                  ) : (
                    <VStack spacing={2} position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
                      <Icon as={FaCamera} boxSize={8} color="gray.400" />
                      <Text color="gray.500">Click to upload cover photo</Text>
                    </VStack>
                  )}
                  <input
                    id="photo-upload"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                </Box>
              </FormControl>

              <FormControl>
                <FormLabel>Title</FormLabel>
                <Input name="title" onChange={handleInputChange} placeholder="Name of your Meetup" />
              </FormControl>

              <FormControl>
                <FormLabel>Date & Time</FormLabel>
                <SimpleGrid columns={3} spacing={4}>
                  <FormControl>
                    <FormLabel fontSize="sm">Date</FormLabel>
                    <Input 
                      name="date" 
                      type="date" 
                      onChange={handleInputChange} 
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel fontSize="sm">Time</FormLabel>
                    <Input 
                      name="time" 
                      type="time" 
                      onChange={handleInputChange} 
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel fontSize="sm">Time Zone</FormLabel>
                    <Box
                      p={2}
                      borderWidth={1}
                      borderRadius="md"
                      fontSize="sm"
                      color="gray.500"
                      bg={useColorModeValue('gray.50', 'gray.700')}
                    >
                      <Box>{displayName}</Box>
                      <Box fontSize="xs" mt={1}>
                        {formattedOffset}
                      </Box>
                    </Box>
                  </FormControl>
                </SimpleGrid>
              </FormControl>

              <FormControl>
                <FormLabel>Location</FormLabel>
                <Input
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  disabled={!ready}
                  placeholder="Search an address"
                />
                {status === "OK" && (
                  <VStack align="stretch" mt={2}>
                    {data.map(({ place_id, description }) => (
                      <Button
                        key={place_id}
                        onClick={() => handleSelect(description)}
                        variant="outline"
                      >
                        {description}
                      </Button>
                    ))}
                  </VStack>
                )}
              </FormControl>

              {newMeetup.lat && newMeetup.lng && (
                <Box height="300px" width="100%">
                  <GoogleMap
                    mapContainerStyle={{ height: "100%", width: "100%" }}
                    center={{ lat: newMeetup.lat, lng: newMeetup.lng }}
                    zoom={15}
                  >
                    <Marker position={{ lat: newMeetup.lat, lng: newMeetup.lng }} />
                  </GoogleMap>
                </Box>
              )}

              <FormControl>
                <FormLabel>Venue</FormLabel>
                <Input name="venue" onChange={handleInputChange} placeholder="Name of venue (hotel, bar, café, etc...)" />
              </FormControl>

              <FormControl display="flex" alignItems="center">
                <FormLabel mb="0">Guest Limit</FormLabel>
                <Switch isChecked={isGuestLimitEnabled} onChange={(e) => setIsGuestLimitEnabled(e.target.checked)} />
              </FormControl>

              {isGuestLimitEnabled && (
                <FormControl>
                  <NumberInput min={0}>
                    <NumberInputField name="guestLimit" onChange={handleInputChange} />
                  </NumberInput>
                </FormControl>
              )}

              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea 
                  name="description" 
                  onChange={handleInputChange} 
                  placeholder="Describe your meetup so guests know what to expect."
                  minH="150px"
                />
              </FormControl>

              {/* Tags Input */}
              <FormControl>
                <FormLabel>Tags</FormLabel>
                <VStack align="stretch" spacing={2}>
                  <Input
                    value={tagInput}
                    onChange={(e) => setTagInput(e.target.value)}
                    onKeyDown={handleTagKeyDown}
                    placeholder="Type and press Enter to add tags"
                  />
                  <Flex wrap="wrap" gap={2}>
                    {tags.map((tag, index) => (
                      <Badge
                        key={index}
                        colorScheme="blue"
                        borderRadius="full"
                        px={3}
                        py={1}
                        display="flex"
                        alignItems="center"
                      >
                        {tag}
                        <Icon
                          as={FaTimes}
                          ml={2}
                          cursor="pointer"
                          onClick={() => removeTag(tag)}
                        />
                      </Badge>
                    ))}
                  </Flex>
                </VStack>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>Cancel</Button>
            <Button colorScheme="blue" onClick={handleSubmit}>Create Meetup</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Meetups;
