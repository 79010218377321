import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Heading,
  Text,
  HStack,
  Icon,
  useColorModeValue,
  VStack,
  SimpleGrid,
  Button,
  Badge,
  useBreakpointValue,
  useDisclosure,
  useToast,
  useMediaQuery,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  IconButton,
  Tooltip,
  Spinner,
  Divider,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { 
  FaCoffee, 
  FaChartBar, 
  FaHistory, 
  FaStar, 
  FaGlobeAmericas,
  FaLeaf,
  FaThermometerHalf,
  FaHeart,
  FaCalendarAlt,
  FaStore,
  FaChartPie,
  FaCheck,
  FaPlus,
  FaMugHot,
} from 'react-icons/fa';
import BeanInventory from './BeanInventory';
import { useAuth } from '../contexts/AuthContext';
import { collection, query, where, getDocs, updateDoc, addDoc, doc, getDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../firebase';
import { Line, Pie, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';
import StarRating from './shared/StarRating';
import { Capacitor } from '@capacitor/core';
import UnifiedPostForm from './forms/UnifiedPostForm';
import CoffeeCard from './shared/CoffeeCard';
import AddCoffeeForm from './forms/AddCoffeeForm';
import QuickDrinkModal from './modals/QuickDrinkModal';
import QuickAddModal from './modals/QuickAddModal';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  ChartTooltip,
  Legend
);

const MotionBox = motion(Box);

const CoffeePage = () => {
  const { user } = useAuth();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { 
    isOpen: isQuickDrinkOpen, 
    onOpen: onQuickDrinkOpen, 
    onClose: onQuickDrinkClose 
  } = useDisclosure();
  const { 
    isOpen: isQuickAddOpen, 
    onOpen: onQuickAddOpen, 
    onClose: onQuickAddClose 
  } = useDisclosure();
  const [selectedCoffee, setSelectedCoffee] = useState(null);
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const cardBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const textColor = useColorModeValue('gray.600', 'gray.400');
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [isNative] = useState(Capacitor.isNativePlatform());
  const [isSmallScreen] = useMediaQuery("(max-width: 30em)");

  const [stats, setStats] = useState({
    totalCoffees: 0,
    activeCoffees: [],
    averageRating: 0,
    favoriteRoaster: 'Loading...',
    topOrigin: 'Loading...',
    recentlyFinished: [],
    tastingNotes: {},
    roasters: {},
    origins: {},
    roastLevels: {},
    monthlyConsumption: [],
    favorites: [],
    cafeVisits: {},
    totalCafeVisits: 0,
    favoriteCafe: 'None yet'
  });

  const [selectedCoffeeId, setSelectedCoffeeId] = useState('');
  const [doseAmount, setDoseAmount] = useState(19); // Default 19g
  const [isLoading, setIsLoading] = useState(false);

  const [todayStats, setTodayStats] = useState({
    total: 0,
    espresso: 0,
    filter: 0,
    totalGrams: 0
  });

  const [isStatsLoading, setIsStatsLoading] = useState(true);

  const [drinkType, setDrinkType] = useState('espresso');

  useEffect(() => {
    fetchCoffeeStats();
  }, [user]);

  useEffect(() => {
    if (stats.activeCoffees.length > 0 && !selectedCoffeeId) {
      setSelectedCoffeeId(stats.activeCoffees[0].id);
    }
  }, [stats.activeCoffees]);

  const calculateTodayStats = (coffees) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    let todayStats = {
      total: 0,
      espresso: 0,
      filter: 0,
      totalGrams: 0
    };

    coffees.forEach(coffee => {
      (coffee.drinks || []).forEach(drink => {
        const drinkDate = new Date(drink.timestamp?.seconds * 1000 || drink.timestamp);
        if (drinkDate >= today) {
          todayStats.total++;
          todayStats[drink.type] = (todayStats[drink.type] || 0) + 1;
          todayStats.totalGrams += drink.amount || 0;
        }
      });
    });

    setTodayStats(todayStats);
  };

  const fetchCoffeeStats = async () => {
    if (!user) return;

    try {
      setIsStatsLoading(true);
      // Get coffee inventory
      const coffeeQuery = query(
        collection(db, 'coffeeInventory'),
        where('userId', '==', user.uid)
      );
      const coffeeSnapshot = await getDocs(coffeeQuery);
      const coffees = coffeeSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Process coffee data
      const tastingNotes = {};
      const roasters = {};
      const origins = {};
      const roastLevels = {};
      const monthlyData = {};
      let totalRating = 0;
      const activeCoffees = [];
      const finishedCoffees = [];

      coffees.forEach(coffee => {
        // Process tasting notes
        coffee.tasting_notes?.forEach(note => {
          tastingNotes[note] = (tastingNotes[note] || 0) + 1;
        });

        // Process roasters
        if (coffee.roaster) {
          roasters[coffee.roaster] = (roasters[coffee.roaster] || 0) + 1;
        }

        // Process origins
        if (coffee.origin) {
          origins[coffee.origin] = (origins[coffee.origin] || 0) + 1;
        }

        // Process roast levels
        if (coffee.roastLevel) {
          roastLevels[coffee.roastLevel] = (roastLevels[coffee.roastLevel] || 0) + 1;
        }

        // Process ratings
        if (coffee.rating) {
          totalRating += coffee.rating;
        }

        // Process monthly data
        if (coffee.createdAt) {
          const date = new Date(coffee.createdAt?.seconds * 1000 || coffee.createdAt);
          const monthYear = date.toLocaleString('default', { month: 'short', year: 'numeric' });
          monthlyData[monthYear] = (monthlyData[monthYear] || 0) + 1;
        }

        // Separate active and finished coffees
        if (coffee.status === 'active') {
          activeCoffees.push(coffee);
        } else if (coffee.status === 'finished') {
          finishedCoffees.push(coffee);
        }
      });

      // Sort favorites by rating
      const favorites = coffees
        .filter(coffee => coffee.rating >= 4)
        .sort((a, b) => b.rating - a.rating);

      // Find top roaster and origin
      const topRoaster = Object.entries(roasters)
        .sort(([,a], [,b]) => b - a)[0]?.[0] || 'None';
      const topOrigin = Object.entries(origins)
        .sort(([,a], [,b]) => b - a)[0]?.[0] || 'None';

      // Calculate average rating
      const averageRating = coffees.length ? totalRating / coffees.filter(c => c.rating).length : 0;

      setStats(prev => ({
        ...prev,
        totalCoffees: coffees.length,
        activeCoffees: activeCoffees,
        averageRating,
        favoriteRoaster: topRoaster,
        topOrigin: topOrigin,
        recentlyFinished: finishedCoffees
          .sort((a, b) => (b.finishedDate?.seconds || 0) - (a.finishedDate?.seconds || 0))
          .slice(0, 5),
        tastingNotes,
        roasters,
        origins,
        roastLevels,
        monthlyConsumption: Object.entries(monthlyData)
          .sort(([a], [b]) => new Date(a) - new Date(b))
          .slice(-12),
        favorites,
      }));

      // Calculate today's stats
      calculateTodayStats(coffees);

    } catch (error) {
      console.error('Error fetching coffee stats:', error);
      toast({
        title: 'Error fetching statistics',
        description: error.message,
        status: 'error',
        duration: 3000,
      });
    } finally {
      setIsStatsLoading(false);
    }
  };

  const handleCoffeeClick = (coffee) => {
    if (!coffee) return;
    setSelectedCoffee(coffee);
    onOpen();
  };

  const StatCard = ({ title, value, icon, color }) => (
    <MotionBox
      whileHover={{ y: -5 }}
      p={5}
      bg={cardBg}
      borderRadius="lg"
      borderWidth="1px"
      borderColor={borderColor}
      shadow="sm"
    >
      <HStack spacing={4}>
        <Icon as={icon} boxSize={6} color={color} />
        <VStack align="start" spacing={0}>
          <Text fontSize="sm" color={textColor}>
            {title}
          </Text>
          <Text fontSize="2xl" fontWeight="bold">
            {value}
          </Text>
        </VStack>
      </HStack>
    </MotionBox>
  );

  const TastingNotesChart = () => {
    const data = {
      labels: Object.keys(stats.tastingNotes).slice(0, 8),
      datasets: [{
        data: Object.values(stats.tastingNotes).slice(0, 8),
        backgroundColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0',
          '#9966FF', '#FF9F40', '#FF6384', '#36A2EB'
        ],
      }],
    };

    return (
      <Box p={4} bg={cardBg} borderRadius="lg" borderWidth="1px">
        <Heading size="md" mb={4}>Top Tasting Notes</Heading>
        <Box height="300px">
          <Pie data={data} options={{ maintainAspectRatio: false }} />
        </Box>
      </Box>
    );
  };

  const ConsumptionChart = () => {
    const data = {
      labels: stats.monthlyConsumption.map(([month]) => month),
      datasets: [{
        label: 'Coffees Added',
        data: stats.monthlyConsumption.map(([, count]) => count),
        borderColor: '#4299E1',
        tension: 0.4,
      }],
    };

    return (
      <Box p={4} bg={cardBg} borderRadius="lg" borderWidth="1px">
        <Heading size="md" mb={4}>Monthly Coffee Consumption</Heading>
        <Box height="300px">
          <Line data={data} options={{ maintainAspectRatio: false }} />
        </Box>
      </Box>
    );
  };

  const RoastLevelChart = () => {
    const data = {
      labels: Object.keys(stats.roastLevels),
      datasets: [{
        label: 'Coffees by Roast Level',
        data: Object.values(stats.roastLevels),
        backgroundColor: '#805AD5',
      }],
    };

    return (
      <Box p={4} bg={cardBg} borderRadius="lg" borderWidth="1px">
        <Heading size="md" mb={4}>Roast Level Distribution</Heading>
        <Box height="300px">
          <Bar data={data} options={{ maintainAspectRatio: false }} />
        </Box>
      </Box>
    );
  };

  const FavoriteCard = ({ coffee }) => (
    <Box
      p={4}
      bg={cardBg}
      borderRadius="lg"
      borderWidth="1px"
      mb={4}
    >
      <HStack spacing={4}>
        {coffee.photoURL ? (
          <Image
            src={coffee.photoURL}
            alt={coffee.name}
            boxSize="80px"
            objectFit="cover"
            borderRadius="md"
          />
        ) : (
          <Icon as={FaCoffee} boxSize="80px" color="gray.400" />
        )}
        <VStack align="start" spacing={2} flex={1}>
          <Heading size="md">{coffee.name}</Heading>
          <Text fontSize="sm" color={textColor}>{coffee.roaster}</Text>
          <HStack>
            <StarRating rating={coffee.rating || 0} size={16} />
            <Badge colorScheme="green">{coffee.roastLevel}</Badge>
            <Badge colorScheme="purple">{coffee.origin}</Badge>
          </HStack>
          <Flex wrap="wrap" gap={2}>
            {coffee.tasting_notes?.map((note, index) => (
              <Tag key={index} size="sm" colorScheme="blue" borderRadius="full">
                <TagLabel>{note}</TagLabel>
              </Tag>
            ))}
          </Flex>
        </VStack>
      </HStack>
    </Box>
  );

  // Also add the missing updateCoffee and addCoffee functions
  const updateCoffee = async (id, data) => {
    const coffeeRef = doc(db, 'coffeeInventory', id);
    await updateDoc(coffeeRef, {
      ...data,
      updatedAt: new Date(),
    });
  };

  const addCoffee = async (data) => {
    await addDoc(collection(db, 'coffeeInventory'), {
      ...data,
      createdAt: new Date(),
      updatedAt: new Date(),
      status: 'active',
    });
  };

  const handleMakeDrink = async (coffeeId, amount) => {
    if (!coffeeId) return;
    
    setIsLoading(true);
    try {
      const coffeeRef = doc(db, 'coffeeInventory', coffeeId);
      const coffeeDoc = await getDoc(coffeeRef);
      
      if (!coffeeDoc.exists()) {
        toast({
          title: "Coffee not found",
          status: "error",
          duration: 3000,
        });
        return;
      }

      const coffeeData = coffeeDoc.data();
      const remainingGrams = (coffeeData.remainingGrams || 0) - amount;

      if (remainingGrams < 0) {
        toast({
          title: "Not enough coffee left",
          status: "warning",
          duration: 3000,
        });
        return;
      }

      // Create brew record in brews collection
      const brewRef = await addDoc(collection(db, 'brews'), {
        userId: user.uid,
        coffeeId: coffeeId,
        coffeeName: coffeeData.name,
        dose: amount,
        timestamp: new Date(),
        notes: '',
        rating: 0,
        source: 'quick',
        brewDetails: {
          grindSize: null,
          waterTemp: null,
          ratio: null,
          time: null
        }
      });

      // Update coffee inventory with the drink
      await updateDoc(coffeeRef, {
        remainingGrams,
        drinks: arrayUnion({
          brewId: brewRef.id,
          amount: amount,
          timestamp: new Date(),
          userId: user.uid
        })
      });

      toast({
        title: "Drink made!",
        description: `${amount}g used. ${remainingGrams}g remaining`,
        status: "success",
        duration: 3000,
      });

      onQuickDrinkClose();
      fetchCoffeeStats();
    } catch (error) {
      console.error('Error making drink:', error);
      toast({
        title: "Error making drink",
        status: "error",
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const Stat = ({ label, value, color }) => (
    <VStack spacing={0} minW="70px">
      <Text fontSize="sm" color="gray.500">
        {label}
      </Text>
      <Text fontWeight="bold" fontSize="lg" color={color}>
        {value}
      </Text>
    </VStack>
  );

  const handleFormClose = () => {
    setSelectedCoffee(null);
    onClose();
  };

  const handleFormSuccess = () => {
    handleFormClose();
    fetchCoffeeStats();
  };

  const handleQuickAdd = async (data) => {
    try {
      const now = new Date();
      await addDoc(collection(db, 'coffeeInventory'), {
        userId: user.uid,
        name: data.name,
        location: data.location,
        type: 'quick',
        createdAt: now,
        updatedAt: now,
        status: 'finished',
        finishedDate: now,
        drinks: [{
          timestamp: now,
          amount: 0,
          type: 'quick'
        }]
      });

      toast({
        title: "Drink added!",
        status: "success",
        duration: 3000,
      });

      onQuickAddClose();
      fetchCoffeeStats();
    } catch (error) {
      console.error('Error adding quick drink:', error);
      toast({
        title: "Error adding drink",
        description: error.message,
        status: "error",
        duration: 3000,
      });
    }
  };

  return (
    <Box 
      bg={bgColor} 
      minH="100vh" 
      py={isNative ? 0 : 6}
      pb={isNative ? 20 : 6}
    >
      <Container maxW="container.xl" px={isNative ? 2 : 4}>
        <VStack spacing={isNative ? 4 : 8} align="stretch">
          {/* Header */}
          <HStack 
            justify="space-between" 
            wrap="wrap"
            pt={isNative ? 4 : 0}
          >
            <Heading size={isSmallScreen ? "md" : "lg"}>Coffee Collection</Heading>
            <HStack spacing={4}>
              <Button
                leftIcon={<FaPlus />}
                colorScheme="blue"
                size={isSmallScreen ? "sm" : "md"}
                onClick={() => {
                  setSelectedCoffee(null);
                  onOpen();
                }}
              >
                Add Coffee
              </Button>
              <Badge colorScheme="green" fontSize={isSmallScreen ? "sm" : "md"} px={3} py={1}>
                {stats.activeCoffees.length} Active
              </Badge>
            </HStack>
          </HStack>

          {/* Quick Drink Section - Mobile Friendly */}
          <Box
            p={4}
            bg={cardBg}
            borderRadius="lg"
            borderWidth="1px"
            borderColor={borderColor}
            mb={4}
          >
            <VStack spacing={4} align="stretch">
              {/* Stats Row */}
              <HStack justify="space-between" align="center">
                <HStack spacing={2}>
                  <Icon as={FaMugHot} color="blue.400" />
                  {isStatsLoading ? (
                    <Spinner size="sm" color="blue.400" />
                  ) : (
                    <Text fontSize="2xl" fontWeight="bold">
                      {todayStats.total}
                    </Text>
                  )}
                  <Text fontSize="xl" color="gray.500">
                    {todayStats.total === 1 ? 'Cup' : 'Cups'} today
                  </Text>
                </HStack>
              </HStack>

              {/* Quick Actions */}
              <SimpleGrid columns={2} spacing={4}>
                {/* From Collection */}
                <Button
                  leftIcon={<FaCoffee />}
                  variant="outline"
                  colorScheme="blue"
                  size="lg"
                  height="80px"
                  isLoading={isStatsLoading}
                  onClick={() => {
                    if (stats.activeCoffees.length > 0) {
                      setSelectedCoffee(stats.activeCoffees[0]);
                      onQuickDrinkOpen();
                    } else {
                      toast({
                        title: "No coffees in collection",
                        description: "Add some coffee to your collection first",
                        status: "info"
                      });
                    }
                  }}
                >
                  <VStack spacing={1}>
                    <Text>Collection</Text>
                    <Text fontSize="sm" color="gray.500">
                      {isStatsLoading ? "Loading..." : `${stats.activeCoffees.length} active`}
                    </Text>
                  </VStack>
                </Button>

                {/* Quick Add */}
                <Button
                  leftIcon={<FaStore />}
                  variant="outline"
                  colorScheme="purple"
                  size="lg"
                  height="80px"
                  isLoading={isStatsLoading}
                  onClick={onQuickAddOpen}
                >
                  <VStack spacing={1}>
                    <Text>Quick Add</Text>
                    <Text fontSize="sm" color="gray.500">
                      Café / Other
                    </Text>
                  </VStack>
                </Button>
              </SimpleGrid>
            </VStack>
          </Box>

          {/* Tabs - Fix the gap */}
          <Box 
            borderRadius="lg" 
            overflow="hidden"
            bg={cardBg}
            borderWidth="1px"
            borderColor={borderColor}
          >
            <Tabs 
              variant="soft-rounded" 
              colorScheme="blue" 
              size={isSmallScreen ? "sm" : "md"} 
              isLazy
            >
              <TabList p={4}>
                <Tab>Collection</Tab>
                <Tab>History</Tab>
                <Tab>Stats</Tab>
                <Tab>Favorites</Tab>
              </TabList>

              <TabPanels>
                <TabPanel p={4} pb={6}>
                  <BeanInventory 
                    userId={user?.uid} 
                    showAddButton={true}
                    onCoffeeClick={handleCoffeeClick}
                  />
                </TabPanel>

                <TabPanel p={4} pb={6}>
                  <VStack spacing={4} align="stretch">
                    {stats.recentlyFinished.length === 0 ? (
                      <Text color={textColor}>No finished coffees yet.</Text>
                    ) : (
                      stats.recentlyFinished.map((coffee) => (
                        <CoffeeCard key={coffee.id} coffee={coffee} />
                      ))
                    )}
                  </VStack>
                </TabPanel>

                <TabPanel p={4} pb={6}>
                  <VStack spacing={isNative ? 4 : 6} align="stretch">
                    <SimpleGrid 
                      columns={{ base: 1, lg: 2 }} 
                      spacing={isNative ? 3 : 6}
                    >
                      <TastingNotesChart />
                      <RoastLevelChart />
                    </SimpleGrid>
                    <ConsumptionChart />
                    
                    <SimpleGrid 
                      columns={{ base: 1, md: 2, lg: 3 }} 
                      spacing={isNative ? 3 : 4}
                    >
                      <Box p={4} bg={cardBg} borderRadius="lg" borderWidth="1px">
                        <Heading size={isSmallScreen ? "xs" : "sm"} mb={4}>Top Roasters</Heading>
                        <VStack align="stretch" spacing={2}>
                          {Object.entries(stats.roasters)
                            .sort(([,a], [,b]) => b - a)
                            .slice(0, 5)
                            .map(([roaster, count]) => (
                              <HStack key={roaster} justify="space-between">
                                <Text fontSize={isSmallScreen ? "sm" : "md"}>{roaster}</Text>
                                <Badge colorScheme="blue">{count}</Badge>
                              </HStack>
                            ))}
                        </VStack>
                      </Box>

                      <Box p={4} bg={cardBg} borderRadius="lg" borderWidth="1px">
                        <Heading size={isSmallScreen ? "xs" : "sm"} mb={4}>Top Origins</Heading>
                        <VStack align="stretch" spacing={2}>
                          {Object.entries(stats.origins)
                            .sort(([,a], [,b]) => b - a)
                            .slice(0, 5)
                            .map(([origin, count]) => (
                              <HStack key={origin} justify="space-between">
                                <Text fontSize={isSmallScreen ? "sm" : "md"}>{origin}</Text>
                                <Badge colorScheme="green">{count}</Badge>
                              </HStack>
                            ))}
                        </VStack>
                      </Box>

                      <Box p={4} bg={cardBg} borderRadius="lg" borderWidth="1px">
                        <Heading size={isSmallScreen ? "xs" : "sm"} mb={4}>Common Tasting Notes</Heading>
                        <VStack align="stretch" spacing={2}>
                          {Object.entries(stats.tastingNotes)
                            .sort(([,a], [,b]) => b - a)
                            .slice(0, 5)
                            .map(([note, count]) => (
                              <HStack key={note} justify="space-between">
                                <Text fontSize={isSmallScreen ? "sm" : "md"}>{note}</Text>
                                <Badge colorScheme="purple">{count}</Badge>
                              </HStack>
                            ))}
                        </VStack>
                      </Box>
                    </SimpleGrid>

                    <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={isNative ? 3 : 6}>
                      <Box p={4} bg={cardBg} borderRadius="lg" borderWidth="1px">
                        <Heading size={isSmallScreen ? "xs" : "sm"} mb={4}>Cafe Visits</Heading>
                        <VStack align="stretch" spacing={2}>
                          {Object.entries(stats.cafeVisits)
                            .sort(([,a], [,b]) => b - a)
                            .map(([cafe, count]) => (
                              <HStack key={cafe} justify="space-between">
                                <Text fontSize={isSmallScreen ? "sm" : "md"}>{cafe}</Text>
                                <Badge colorScheme="purple">{count}</Badge>
                              </HStack>
                            ))}
                        </VStack>
                      </Box>

                      <SimpleGrid columns={{ base: 2 }} spacing={4}>
                        <StatCard
                          title="Total Cafe Visits"
                          value={stats.totalCafeVisits}
                          icon={FaStore}
                          color="purple.400"
                        />
                        <StatCard
                          title="Favorite Cafe"
                          value={stats.favoriteCafe}
                          icon={FaHeart}
                          color="red.400"
                        />
                      </SimpleGrid>
                    </SimpleGrid>

                    <SimpleGrid 
                      columns={{ base: 2, md: 1, lg: 3 }} 
                      spacing={isNative ? 2 : 4}
                    >
                      <StatCard
                        title="Total Coffees"
                        value={stats.totalCoffees}
                        icon={FaCoffee}
                        color="brown.400"
                      />
                      <StatCard
                        title="Average Rating"
                        value={stats.averageRating.toFixed(1)}
                        icon={FaStar}
                        color="yellow.400"
                      />
                      <StatCard
                        title="Favorite Roaster"
                        value={stats.favoriteRoaster}
                        icon={FaStore}
                        color="blue.400"
                      />
                      <StatCard
                        title="Top Origin"
                        value={stats.topOrigin}
                        icon={FaGlobeAmericas}
                        color="green.400"
                      />
                    </SimpleGrid>
                  </VStack>
                </TabPanel>

                <TabPanel p={4} pb={6}>
                  <VStack spacing={4} align="stretch">
                    <Heading size={isSmallScreen ? "sm" : "md"} mb={4}>Favorite Coffees</Heading>
                    {stats.favorites.length === 0 ? (
                      <Text color={textColor}>
                        No favorite coffees yet. Rate your coffees 4 stars or higher to see them here!
                      </Text>
                    ) : (
                      stats.favorites.map((coffee) => (
                        <FavoriteCard key={coffee.id} coffee={coffee} />
                      ))
                    )}
                  </VStack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </VStack>
      </Container>

      {/* AddCoffeeForm */}
      <AddCoffeeForm
        isOpen={isOpen}
        onClose={handleFormClose}
        onSuccess={handleFormSuccess}
        editCoffee={selectedCoffee}
      />

      <QuickDrinkModal
        isOpen={isQuickDrinkOpen}
        onClose={onQuickDrinkClose}
        coffees={stats.activeCoffees}
        onDrink={handleMakeDrink}
      />

      <QuickAddModal
        isOpen={isQuickAddOpen}
        onClose={onQuickAddClose}
        onAdd={handleQuickAdd}
      />
    </Box>
  );
};

export default CoffeePage; 