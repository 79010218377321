import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import PlaceDetailsView from './PlaceDetailsView';

const PlaceDetailsModal = ({ place, isOpen, onClose }) => {
  console.log('PlaceDetailsModal - place:', place); // Debug log

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent bg="gray.900" color="white">
        <ModalHeader>{place?.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <PlaceDetailsView place={place} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PlaceDetailsModal;
