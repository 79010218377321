import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Box,
  useColorModeValue,
  Icon,
  useToast,
  VStack,
  HStack,
  Text,
  Button,
} from '@chakra-ui/react';
import { 
  FaPen, 
  FaStar, 
  FaCalendar, 
  FaCoffee, 
  FaCogs,
} from 'react-icons/fa';
import QuickPostForm from './forms/QuickPostForm';
import ReviewForm from './forms/ReviewForm';
import AddPlaceForm from './forms/AddPlaceForm';
import EventForm from './forms/EventForm';
import AddCoffeeForm from './forms/AddCoffeeForm';
import AddEquipmentForm from './forms/AddEquipmentForm';
import { useNavigate } from 'react-router-dom';

const CREATE_OPTIONS = [
  {
    id: 'share',
    label: 'Share',
    description: 'Share your coffee experiences',
    items: [
      {
        id: 'quickPost',
        icon: FaPen,
        label: 'Quick Post',
        description: 'Share a quick update',
        color: '#FF9D4D',
        gradient: 'linear-gradient(135deg, #FF9D4D 0%, #FF6B6B 100%)'
      },
      {
        id: 'review',
        icon: FaStar,
        label: 'Write Review',
        description: 'Review a coffee place',
        color: '#FF6B6B',
        gradient: 'linear-gradient(135deg, #FF6B6B 0%, #FFE66D 100%)'
      }
    ]
  },
  {
    id: 'discover',
    label: 'Discover',
    description: 'Add new places and events',
    items: [
      {
        id: 'addPlace',
        icon: FaCoffee,
        label: 'Add Coffee Place',
        description: 'Add a new coffee spot',
        color: '#845EC2',
        gradient: 'linear-gradient(135deg, #845EC2 0%, #6C63FF 100%)',
      },
      {
        id: 'event',
        icon: FaCalendar,
        label: 'Create Event',
        description: 'Organize a coffee meetup',
        color: '#FF8066',
        gradient: 'linear-gradient(135deg, #FF8066 0%, #FF4B2B 100%)'
      }
    ]
  },
  {
    id: 'inventory',
    label: 'Inventory',
    description: 'Manage your coffee and equipment',
    items: [
      {
        id: 'addCoffee',
        icon: FaCoffee,
        label: 'Add Coffee',
        description: 'Add coffee to your inventory',
        color: '#6C63FF',
        gradient: 'linear-gradient(135deg, #6C63FF 0%, #4834D4 100%)'
      },
      {
        id: 'addEquipment',
        icon: FaCogs,
        label: 'Add Equipment',
        description: 'Add coffee equipment',
        color: '#2BAE66',
        gradient: 'linear-gradient(135deg, #2BAE66 0%, #1E8449 100%)'
      }
    ]
  }
];

const CreatePost = ({ isOpen, onClose }) => {
  const [quickPostOpen, setQuickPostOpen] = useState(false);
  const [reviewFormOpen, setReviewFormOpen] = useState(false);
  const [addPlaceFormOpen, setAddPlaceFormOpen] = useState(false);
  const [eventFormOpen, setEventFormOpen] = useState(false);
  const [addCoffeeFormOpen, setAddCoffeeFormOpen] = useState(false);
  const [addEquipmentFormOpen, setAddEquipmentFormOpen] = useState(false);
  const navigate = useNavigate();
  const bgColor = useColorModeValue('gray.900', 'gray.900');

  const handleOptionSelect = (type) => {
    onClose(); // Close the menu first

    // Handle each option type
    switch (type) {
      case 'quickPost':
        setQuickPostOpen(true);
        break;
      case 'review':
        setReviewFormOpen(true);
        break;
      case 'addPlace':
        setAddPlaceFormOpen(true);
        break;
      case 'event':
        setEventFormOpen(true);
        break;
      case 'addCoffee':
        setAddCoffeeFormOpen(true);
        break;
      case 'addEquipment':
        setAddEquipmentFormOpen(true);
        break;
      default:
        break;
    }
  };

  // Add drag gesture handling
  const dragControls = {
    initial: { y: '100%' },
    animate: { y: 0 },
    exit: { y: '100%' },
    drag: 'y',
    dragConstraints: { top: 0, bottom: 0 },
    dragElastic: 0.2,
    onDragEnd: (event, info) => {
      if (info.offset.y > 200) {
        onClose();
      }
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'rgba(0, 0, 0, 0.7)',
              backdropFilter: 'blur(4px)',
              zIndex: 1300
            }}
          />

          {/* Menu */}
          <motion.div
            {...dragControls}
            initial={{ y: '100%' }}
            animate={{ y: 0 }}
            exit={{ y: '100%' }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            style={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1400,
            }}
          >
            <Box
              bg={bgColor}
              width="100%"
              borderTopRadius="24px"
              overflow="hidden"
              maxHeight="90vh"
              pb="env(safe-area-inset-bottom)"
            >
              {/* Drag Handle - Make it more prominent */}
              <Box 
                pt={4} 
                pb={2}
                cursor="grab" 
                _active={{ cursor: "grabbing" }}
              >
                <Box
                  width="32px"
                  height="4px"
                  bg="gray.600"
                  borderRadius="full"
                  mx="auto"
                />
              </Box>

              {/* Menu Content */}
              <VStack 
                spacing={0} 
                align="stretch"
                overflowY="auto"
                maxHeight="calc(90vh - 40px)"
                css={{
                  '&::-webkit-scrollbar': { display: 'none' },
                  scrollbarWidth: 'none'
                }}
              >
                {CREATE_OPTIONS.map((group) => (
                  <Box 
                    key={group.id} 
                    p={4}
                    borderBottom="1px solid"
                    borderColor="whiteAlpha.100"
                    _last={{ borderBottom: 'none' }}
                  >
                    <Text
                      fontSize="sm"
                      fontWeight="semibold"
                      color="gray.300"
                      mb={1}
                    >
                      {group.label}
                    </Text>
                    <Text fontSize="xs" color="gray.500" mb={3}>
                      {group.description}
                    </Text>
                    <VStack spacing={2}>
                      {group.items.map((item) => (
                        <Button
                          key={item.id}
                          onClick={() => handleOptionSelect(item.id)}
                          variant="ghost"
                          justifyContent="flex-start"
                          p={4}
                          h="auto"
                          w="100%"
                          bg="whiteAlpha.50"
                          _hover={{ bg: 'whiteAlpha.100' }}
                          _active={{ bg: 'whiteAlpha.200' }}
                          borderRadius="lg"
                        >
                          <HStack spacing={3} align="center">
                            <Box
                              p={2}
                              borderRadius="lg"
                              background={item.gradient}
                            >
                              <Icon as={item.icon} boxSize={5} color="white" />
                            </Box>
                            <VStack align="start" spacing={0}>
                              <Text 
                                fontWeight="medium" 
                                color="white"
                                fontSize="sm"
                              >
                                {item.label}
                              </Text>
                              <Text fontSize="xs" color="gray.400">
                                {item.description}
                              </Text>
                            </VStack>
                          </HStack>
                        </Button>
                      ))}
                    </VStack>
                  </Box>
                ))}
              </VStack>
            </Box>
          </motion.div>
        </>
      )}

      {/* Forms */}
      <QuickPostForm
        isOpen={quickPostOpen}
        onClose={() => setQuickPostOpen(false)}
      />
      
      {reviewFormOpen && (
        <ReviewForm
          isOpen={reviewFormOpen}
          onClose={() => setReviewFormOpen(false)}
        />
      )}

      {addPlaceFormOpen && (
        <AddPlaceForm
          isOpen={addPlaceFormOpen}
          onClose={() => setAddPlaceFormOpen(false)}
        />
      )}

      {eventFormOpen && (
        <EventForm
          isOpen={eventFormOpen}
          onClose={() => setEventFormOpen(false)}
        />
      )}

      {addCoffeeFormOpen && (
        <AddCoffeeForm
          isOpen={addCoffeeFormOpen}
          onClose={() => setAddCoffeeFormOpen(false)}
        />
      )}

      {addEquipmentFormOpen && (
        <AddEquipmentForm
          isOpen={addEquipmentFormOpen}
          onClose={() => setAddEquipmentFormOpen(false)}
        />
      )}
    </AnimatePresence>
  );
};

export default CreatePost;
