import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDuYwgDNDmrDbKIAYRNkkWoigiuTnF3d8o",
  authDomain: "coffeetalk-1e492.firebaseapp.com",
  databaseURL: "https://coffeetalk-1e492-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "coffeetalk-1e492",
  storageBucket: "coffeetalk-1e492.appspot.com",
  messagingSenderId: "115002489366",
  appId: "1:115002489366:web:1fbac765c5ba3cacdd5337",
  measurementId: "G-F7W3X7NZ9B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);
const functions = getFunctions(app);

export { app, auth, db, storage, analytics, functions }; 