import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  HStack,
  Text,
  IconButton,
  useColorModeValue,
  Collapse,
  Badge,
  Input,
  Select,
  NumberInput,
  NumberInputField,
  Button,
  useToast,
  Flex,
  Icon,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Tooltip,
  Avatar,
  Grid,
  SimpleGrid,
  Stat as ChakraStat,
} from '@chakra-ui/react';
import { 
  FaChevronDown, 
  FaChevronUp, 
  FaCoffee, 
  FaMugHot, 
  FaPlus, 
  FaStar, 
  FaThermometerHalf, 
  FaClock, 
  FaWeight,
  FaEdit,
  FaCheck,
  FaTrash,
  FaStore,
  FaDollarSign
} from 'react-icons/fa';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { useAuth } from '../contexts/AuthContext';
import { collection, query, where, getDocs, addDoc, orderBy, updateDoc, doc, increment, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import StarRating from './shared/StarRating';

// Custom Stat component
const Stat = ({ icon: Icon, label, value }) => (
  <Box p={3} borderWidth="1px" borderRadius="lg">
    <VStack spacing={1} align="center">
      <Icon size={16} />
      <Text fontSize="lg" fontWeight="bold">{value}</Text>
      <Text fontSize="xs" color="gray.500">{label}</Text>
    </VStack>
  </Box>
);

// Custom TasteSlider component
const TasteSlider = ({ label, value }) => (
  <VStack spacing={1} align="center">
    <Text fontSize="xs" color="gray.500">{label}</Text>
    <Box h="80px" w="20px" bg="gray.200" borderRadius="full" position="relative">
      <Box
        position="absolute"
        bottom="0"
        left="0"
        right="0"
        bg="blue.500"
        h={`${value * 10}%`}
        borderRadius="full"
        transition="height 0.2s"
      />
    </Box>
    <Text fontSize="xs" fontWeight="bold">{value}</Text>
  </VStack>
);

// Helper function to get icon for field
const getIconForField = (fieldName) => {
  switch (fieldName) {
    case 'dose':
      return FaWeight;
    case 'yield':
    case 'water':
      return FaMugHot;
    case 'time':
    case 'bloomTime':
    case 'drawdownTime':
    case 'steamTime':
    case 'totalTime':
      return FaClock;
    case 'temperature':
    case 'milkTemperature':
      return FaThermometerHalf;
    default:
      return FaCoffee;
  }
};

const brewTypes = {
  espresso: {
    methods: ['traditional', 'lever', 'lungo', 'ristretto'],
    fields: {
      dose: { label: 'Dose', unit: 'g', default: 18 },
      yield: { label: 'Yield', unit: 'g', default: 36 },
      grindSize: { label: 'Grind Size', unit: '', default: 15 },
      preInfusion: { label: 'Pre-Infusion', unit: 's', default: 0 },
      pressure: { label: 'Pressure', unit: 'bar', default: 9 },
      time: { label: 'Time', unit: 's', default: 25 },
      temperature: { label: 'Temperature', unit: '°C', default: 93 },
    }
  },
  filter: {
    methods: ['filter', 'v60', 'chemex', 'kalita', 'aeropress', 'french_press'],
    fields: {
      dose: { label: 'Dose', unit: 'g', default: 15 },
      water: { label: 'Water', unit: 'g', default: 250 },
      grindSize: { label: 'Grind Size', unit: '', default: 20 },
      bloomTime: { label: 'Bloom Time', unit: 's', default: 30 },
      bloomWater: { label: 'Bloom Water', unit: 'g', default: 30 },
      drawdownTime: { label: 'Drawdown Time', unit: 's', default: 180 },
      temperature: { label: 'Temperature', unit: '°C', default: 95 },
    }
  },
  milk_drink: {
    methods: ['cappuccino', 'latte', 'flat_white', 'cortado'],
    fields: {
      dose: { label: 'Dose', unit: 'g', default: 18 },
      yield: { label: 'Yield', unit: 'g', default: 36 },
      grindSize: { label: 'Grind Size', unit: '', default: 15 },
      milkAmount: { label: 'Milk Amount', unit: 'ml', default: 120 },
      milkTemperature: { label: 'Milk Temperature', unit: '°C', default: 65 },
      steamTime: { label: 'Steam Time', unit: 's', default: 20 },
      totalTime: { label: 'Total Time', unit: 's', default: 45 },
      temperature: { label: 'Coffee Temperature', unit: '°C', default: 93 },
    }
  }
};

const AddBrewDrawer = ({ isOpen, onClose, coffeeList, newBrew, setNewBrew, handleAddBrew }) => {
  const currentTypeFields = brewTypes[newBrew.type]?.fields || {};

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add New Brew</DrawerHeader>
        <DrawerBody>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Coffee</FormLabel>
              <Select
                value={newBrew.coffeeId}
                onChange={(e) => setNewBrew({ ...newBrew, coffeeId: e.target.value })}
              >
                <option value="">Select Coffee</option>
                {coffeeList.map((coffee) => (
                  <option key={coffee.id} value={coffee.id}>
                    {coffee.name}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Brew Type</FormLabel>
              <Select
                value={newBrew.type}
                onChange={(e) => {
                  const type = e.target.value;
                  setNewBrew({
                    ...newBrew,
                    type,
                    method: brewTypes[type].methods[0],
                    ...Object.fromEntries(
                      Object.entries(brewTypes[type].fields).map(([key, field]) => [key, field.default])
                    )
                  });
                }}
              >
                <option value="espresso">Espresso</option>
                <option value="filter">Filter</option>
                <option value="milk_drink">Milk Drink</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Method</FormLabel>
              <Select
                value={newBrew.method}
                onChange={(e) => setNewBrew({ ...newBrew, method: e.target.value })}
              >
                {brewTypes[newBrew.type].methods.map((method) => (
                  <option key={method} value={method}>
                    {method.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                  </option>
                ))}
              </Select>
            </FormControl>

            <SimpleGrid columns={2} spacing={4} width="100%">
              {Object.entries(currentTypeFields).map(([key, field]) => (
                <FormControl key={key}>
                  <FormLabel>{field.label} {field.unit && `(${field.unit})`}</FormLabel>
                  <NumberInput
                    value={newBrew[key]}
                    onChange={(value) => setNewBrew({ ...newBrew, [key]: parseFloat(value) })}
                    min={0}
                    precision={1}
                  >
                    <NumberInputField />
                  </NumberInput>
                </FormControl>
              ))}
            </SimpleGrid>

            <Box width="100%">
              <Text fontSize="sm" fontWeight="semibold" mb={2}>Taste Profile</Text>
              <SimpleGrid columns={2} spacing={4}>
                {Object.entries(newBrew.taste).map(([key, value]) => (
                  <FormControl key={key}>
                    <FormLabel>{key.charAt(0).toUpperCase() + key.slice(1)}</FormLabel>
                    <NumberInput
                      value={value}
                      onChange={(val) => setNewBrew({
                        ...newBrew,
                        taste: { ...newBrew.taste, [key]: parseInt(val) }
                      })}
                      min={1}
                      max={10}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </FormControl>
                ))}
              </SimpleGrid>
            </Box>

            <FormControl>
              <FormLabel>Rating</FormLabel>
              <StarRating
                rating={newBrew.rating || 0}
                onChange={(rating) => setNewBrew({ ...newBrew, rating })}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Notes</FormLabel>
              <Input
                value={newBrew.notes}
                onChange={(e) => setNewBrew({ ...newBrew, notes: e.target.value })}
                placeholder="Add notes about your brew..."
              />
            </FormControl>

            <Button
              colorScheme="blue"
              width="100%"
              onClick={() => {
                handleAddBrew();
                onClose();
              }}
            >
              Add Brew
            </Button>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const BrewLedger = () => {
  const { user } = useAuth();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [brews, setBrews] = useState([]);
  const [quickDrinks, setQuickDrinks] = useState([]);
  const [expandedId, setExpandedId] = useState(null);
  const [coffeeList, setCoffeeList] = useState([]);
  const [isAddingBrew, setIsAddingBrew] = useState(false);
  const toast = useToast();

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const accentColor = useColorModeValue('blue.500', 'blue.300');

  // Initialize newBrew state with default values
  const [newBrew, setNewBrew] = useState({
    coffeeId: '',
    type: 'espresso',
    method: 'traditional',
    dose: 18,
    yield: 36,
    grindSize: 15,
    temperature: 93,
    notes: '',
    rating: 0,
    taste: {
      acidity: 5,
      sweetness: 5,
      body: 5,
      balance: 5,
      aftertaste: 5
    }
  });

  useEffect(() => {
    if (user) {
      fetchBrews();
      fetchCoffeeList();
    }
  }, [user, selectedDate]);

  const fetchBrews = async () => {
    if (!user) return;

    try {
      const startOfDay = new Date(selectedDate);
      startOfDay.setHours(0, 0, 0, 0);
      
      const endOfDay = new Date(selectedDate);
      endOfDay.setHours(23, 59, 59, 999);

      // Fetch both regular brews and café visits
      const [brewsSnapshot, cafeVisitsSnapshot] = await Promise.all([
        // Regular brews
        getDocs(query(
          collection(db, 'brews'),
          where('userId', '==', user.uid),
          where('timestamp', '>=', startOfDay),
          where('timestamp', '<=', endOfDay),
          orderBy('timestamp', 'desc')
        )),
        // Café visits
        getDocs(query(
          collection(db, 'coffeeInventory'),
          where('userId', '==', user.uid),
          where('type', '==', 'quick'),
          where('createdAt', '>=', startOfDay),
          where('createdAt', '<=', endOfDay),
          orderBy('createdAt', 'desc')
        ))
      ]);

      const brewsData = brewsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate(),
      }));

      const cafeVisits = cafeVisitsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().createdAt?.toDate(),
        type: 'cafe'
      }));

      // Combine and sort all drinks by timestamp
      const allDrinks = [...brewsData, ...cafeVisits]
        .sort((a, b) => b.timestamp - a.timestamp);
      
      setBrews(allDrinks);

    } catch (error) {
      console.error('Error fetching brews:', error);
      toast({
        title: "Error fetching brews",
        description: error.message,
        status: "error",
        duration: 3000,
      });
    }
  };

  const fetchCoffeeList = async () => {
    if (!user) return;
    
    const coffeeQuery = query(
      collection(db, 'coffeeInventory'),
      where('userId', '==', user.uid)
    );
    
    const snapshot = await getDocs(coffeeQuery);
    setCoffeeList(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const handleAddBrew = async (brewData) => {
    try {
      await addDoc(collection(db, 'brews'), {
        ...brewData,
        userId: user.uid,
        timestamp: new Date(),
        isSetup: true
      });

      if (brewData.coffeeId) {
        const coffeeRef = doc(db, 'coffeeInventory', brewData.coffeeId);
        await updateDoc(coffeeRef, {
          remainingAmount: increment(-brewData.dose),
          totalBrews: increment(1)
        });
      }

      fetchBrews();
      toast({
        title: "Brew added",
        status: "success",
        duration: 2000,
      });
    } catch (error) {
      toast({
        title: "Error adding brew",
        description: error.message,
        status: "error",
        duration: 3000,
      });
    }
  };

  const brewMethods = {
    filter: ['v60', 'chemex', 'kalita', 'aeropress', 'french_press'],
    espresso: ['traditional', 'lever', 'lungo', 'ristretto'],
  };

  const handleUpdateBrew = async (brewId, updatedData, isQuickDrink = false) => {
    try {
      const brewRef = doc(db, 'brews', brewId);
      
      // If it's a quick drink being setup, mark it as setup
      if (isQuickDrink) {
        updatedData = {
          ...updatedData,
          isSetup: true,
          setupTimestamp: new Date()
        };
      }

      await updateDoc(brewRef, {
        ...updatedData,
        type: updatedData.type,
        method: updatedData.method,
        coffeeId: updatedData.coffeeId,
        rating: Number(updatedData.rating || 0),
        grindSize: Number(updatedData.grindSize || 0),
        time: Number(updatedData.time || 0),
        temperature: Number(updatedData.temperature || 0),
        lastUpdated: new Date()
      });

      // Refresh the brews list
      await fetchBrews();
    } catch (error) {
      console.error('Error updating brew:', error);
      throw error; // Re-throw to be caught by the handler in BrewRow
    }
  };

  const handleDeleteBrew = async (brewId) => {
    try {
      await deleteDoc(doc(db, 'brews', brewId));
      await fetchBrews();
      toast({
        title: "Brew deleted",
        status: "success",
        duration: 2000,
      });
    } catch (error) {
      console.error('Error deleting brew:', error);
      toast({
        title: "Error deleting brew",
        description: error.message,
        status: "error",
        duration: 3000,
      });
    }
  };

  const BrewRow = ({ brew }) => {
    const isExpanded = expandedId === brew.id;
    const coffee = coffeeList.find(c => c.id === brew.coffeeId);
    const [localBrew, setLocalBrew] = useState(brew);
    const [isEditing, setIsEditing] = useState(false);

    const bgColor = useColorModeValue('white', 'gray.800');
    const boxBg = useColorModeValue('gray.50', 'gray.700');
    const labelColor = useColorModeValue('gray.600', 'gray.400');
    const borderColor = useColorModeValue('gray.200', 'gray.700');

    const isCafeDrink = brew.type === 'cafe';
    const currentTypeFields = !isCafeDrink ? (brewTypes[localBrew.type || 'espresso']?.fields || {}) : {};

    return (
      <Box
        borderWidth="1px"
        borderRadius="lg"
        p={4}
        bg={bgColor}
        borderColor={isCafeDrink ? 'purple.300' : borderColor}
        mb={4}
        shadow="sm"
      >
        <Flex justify="space-between" align="center">
          <HStack spacing={3}>
            <Avatar
              size="sm"
              icon={isCafeDrink ? <FaStore /> : <FaCoffee />}
              bg={isCafeDrink ? 'purple.400' : 'blue.400'}
              color="white"
            />
            <VStack align="start" spacing={1}>
              <Text fontSize="md" fontWeight="bold">
                {isCafeDrink ? brew.name : (coffee?.name || 'Unknown Coffee')}
              </Text>
              <HStack spacing={2} fontSize="xs" color="gray.500">
                <Text>
                  {brew.timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                </Text>
                {isCafeDrink ? (
                  <Badge colorScheme="purple" px={2} py={1}>
                    {brew.location}
                  </Badge>
                ) : (
                  <Badge 
                    colorScheme={brew.type === 'espresso' ? 'orange' : 'blue'}
                    px={2}
                    py={1}
                  >
                    {brew.method || brew.type}
                  </Badge>
                )}
              </HStack>
            </VStack>
          </HStack>
          <HStack spacing={2}>
            <IconButton
              size="sm"
              icon={isEditing ? <FaCheck size={16} /> : <FaEdit size={16} />}
              variant="solid"
              colorScheme={isEditing ? "green" : "blue"}
              onClick={() => setIsEditing(!isEditing)}
              aria-label={isEditing ? "Save" : "Edit"}
            />
            <IconButton
              size="sm"
              icon={isExpanded ? <FaChevronUp size={12} /> : <FaChevronDown size={12} />}
              variant="ghost"
              onClick={() => setExpandedId(isExpanded ? null : brew.id)}
              aria-label="Toggle details"
            />
          </HStack>
        </Flex>

        <Collapse in={isExpanded}>
          <Box mt={6}>
            {!isEditing ? (
              <VStack spacing={6} align="stretch">
                {isCafeDrink ? (
                  <>
                    <Box bg={boxBg} p={4} borderRadius="md" borderWidth="1px">
                      <Text fontSize="sm" fontWeight="semibold" mb={3}>Details</Text>
                      <SimpleGrid columns={2} spacing={4}>
                        <HStack>
                          <Icon as={FaStore} />
                          <Text>Location:</Text>
                          <Text fontWeight="bold">{brew.location}</Text>
                        </HStack>
                        {brew.price && (
                          <HStack>
                            <Icon as={FaDollarSign} />
                            <Text>Price:</Text>
                            <Text fontWeight="bold">${brew.price}</Text>
                          </HStack>
                        )}
                      </SimpleGrid>
                    </Box>
                    
                    <Box bg={boxBg} p={4} borderRadius="md" borderWidth="1px">
                      <Text fontSize="sm" fontWeight="semibold" mb={2}>Rating</Text>
                      <StarRating rating={brew.rating || 0} isEditable={false} size={24} />
                    </Box>
                  </>
                ) : (
                  <Box bg={boxBg} p={4} borderRadius="md" borderWidth="1px">
                    <Text fontSize="sm" fontWeight="semibold" mb={3}>Brew Parameters</Text>
                    <Flex wrap="wrap" gap={4}>
                      {Object.entries(currentTypeFields)
                        .filter(([key]) => localBrew[key] != null)
                        .map(([key, field]) => (
                          <HStack key={key} spacing={1} fontSize="sm" color={labelColor}>
                            <Icon as={getIconForField(key)} boxSize={3} />
                            <Text>{field.label}:</Text>
                            <Text fontWeight="semibold">
                              {localBrew[key]}{field.unit}
                            </Text>
                          </HStack>
                        ))}
                    </Flex>
                  </Box>
                )}

                {/* Notes section - shown for both types */}
                {localBrew.notes && (
                  <Box bg={boxBg} p={4} borderRadius="md" borderWidth="1px">
                    <Text fontSize="sm" fontWeight="semibold" mb={2}>Notes</Text>
                    <Text fontSize="md">{localBrew.notes}</Text>
                  </Box>
                )}
              </VStack>
            ) : (
              <VStack spacing={6} align="stretch">
                {isCafeDrink ? (
                  <>
                    <FormControl>
                      <FormLabel>Coffee Name</FormLabel>
                      <Input
                        value={localBrew.name}
                        onChange={(e) => setLocalBrew({
                          ...localBrew,
                          name: e.target.value
                        })}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Location</FormLabel>
                      <Input
                        value={localBrew.location}
                        onChange={(e) => setLocalBrew({
                          ...localBrew,
                          location: e.target.value
                        })}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Price</FormLabel>
                      <NumberInput
                        value={localBrew.price || 0}
                        onChange={(value) => setLocalBrew({
                          ...localBrew,
                          price: Number(value)
                        })}
                        precision={2}
                      >
                        <NumberInputField />
                      </NumberInput>
                    </FormControl>
                  </>
                ) : (
                  <>
                    <SimpleGrid columns={2} spacing={4}>
                      <FormControl>
                        <FormLabel>Coffee</FormLabel>
                        <Select
                          value={localBrew.coffeeId}
                          onChange={(e) => setLocalBrew({
                            ...localBrew,
                            coffeeId: e.target.value
                          })}
                        >
                          {coffeeList.map((coffee) => (
                            <option key={coffee.id} value={coffee.id}>
                              {coffee.name}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Type</FormLabel>
                        <Select
                          value={localBrew.type}
                          onChange={(e) => {
                            const type = e.target.value;
                            setLocalBrew({
                              ...localBrew,
                              type,
                              method: brewTypes[type].methods[0]
                            });
                          }}
                        >
                          <option value="espresso">Espresso</option>
                          <option value="filter">Filter</option>
                          <option value="milk_drink">Milk Drink</option>
                        </Select>
                      </FormControl>
                    </SimpleGrid>
                    <Box>
                      <Text fontSize="sm" fontWeight="semibold" mb={3}>Brew Parameters</Text>
                      <SimpleGrid columns={2} spacing={4}>
                        {Object.entries(currentTypeFields).map(([key, field]) => (
                          <FormControl key={key}>
                            <FormLabel>{field.label}</FormLabel>
                            <NumberInput
                              value={localBrew[key] || field.default}
                              onChange={(value) => setLocalBrew({
                                ...localBrew,
                                [key]: Number(value)
                              })}
                            >
                              <NumberInputField />
                            </NumberInput>
                          </FormControl>
                        ))}
                      </SimpleGrid>
                    </Box>
                  </>
                )}
                
                <FormControl>
                  <FormLabel>Rating</FormLabel>
                  <StarRating
                    rating={localBrew.rating || 0}
                    onChange={(rating) => setLocalBrew({
                      ...localBrew,
                      rating
                    })}
                    size={24}
                    isEditable
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Notes</FormLabel>
                  <Input
                    value={localBrew.notes || ''}
                    onChange={(e) => setLocalBrew({
                      ...localBrew,
                      notes: e.target.value
                    })}
                    placeholder="Add notes..."
                  />
                </FormControl>
                
                <Button
                  colorScheme="red"
                  variant="ghost"
                  leftIcon={<FaTrash />}
                  onClick={() => handleDeleteBrew(brew.id)}
                >
                  Delete
                </Button>
              </VStack>
            )}
          </Box>
        </Collapse>
      </Box>
    );
  };

  return (
    <Box>
      <VStack spacing={4} p={4} align="stretch" maxW="container.md" mx="auto">
        <HStack justify="space-between" mb={4}>
          <Text fontSize="2xl" fontWeight="bold">Brew Journal</Text>
          <HStack>
            <Box w="200px">
              <SingleDatepicker
                name="date-input"
                date={selectedDate}
                onDateChange={setSelectedDate}
              />
            </Box>
            <IconButton
              icon={<FaPlus />}
              colorScheme="blue"
              onClick={() => setIsAddingBrew(true)}
              aria-label="Add brew"
            />
          </HStack>
        </HStack>

        {quickDrinks.length > 0 && (
          <Box>
            <Text fontSize="lg" fontWeight="semibold" mb={2}>
              Quick Drinks Needing Setup
            </Text>
            {quickDrinks.map(drink => (
              <BrewRow 
                key={drink.id} 
                brew={drink} 
                isQuickDrink={true}
              />
            ))}
          </Box>
        )}

        <Box>
          <Text fontSize="lg" fontWeight="semibold" mb={2}>
            Completed Brews
          </Text>
          {brews.map(brew => (
            <BrewRow 
              key={brew.id} 
              brew={brew}
            />
          ))}
        </Box>

        {brews.length === 0 && quickDrinks.length === 0 && (
          <VStack 
            py={8} 
            spacing={4}
            color="gray.500"
            bg={bgColor}
            borderRadius="xl"
            borderWidth="2px"
            borderStyle="dashed"
          >
            <FaCoffee size={32} />
            <Text>No brews recorded for this day</Text>
            <Button
              leftIcon={<FaPlus />}
              colorScheme="blue"
              variant="ghost"
              onClick={() => setIsAddingBrew(true)}
            >
              Add your first brew
            </Button>
          </VStack>
        )}
      </VStack>

      <AddBrewDrawer
        isOpen={isAddingBrew}
        onClose={() => setIsAddingBrew(false)}
        coffeeList={coffeeList}
        newBrew={newBrew}
        setNewBrew={setNewBrew}
        handleAddBrew={handleAddBrew}
      />
    </Box>
  );
};

export default BrewLedger; 