import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  Text,
  Heading,
  Avatar,
  Link,
  Flex,
  useColorModeValue,
  Badge,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { collection, query, where, getDocs, orderBy, limit, getDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import { formatTimeAgo } from '../../utils/formatTimeAgo';
import { motion } from 'framer-motion';
import ImageModal from '../shared/ImageModal';

const MotionBox = motion(Box);

const formatDate = (date) => {
  return new Date(date).toLocaleDateString('en-US', {
    weekday: 'long',
    month: 'short',
    day: 'numeric'
  }).toUpperCase();
};

const WinnerCard = ({ winner, date, index }) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [userData, setUserData] = useState(null);
  const bgColor = useColorModeValue('gray.700', 'gray.800');
  const textColor = useColorModeValue('gray.300', 'gray.400');

  useEffect(() => {
    const fetchUserData = async () => {
      if (winner?.userId) {
        try {
          const userDoc = await getDoc(doc(db, 'users', winner.userId));
          if (userDoc.exists()) {
            setUserData(userDoc.data());
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [winner?.userId]);

  if (!winner) {
    return (
      <MotionBox
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: index * 0.1 }}
        bg={bgColor}
        borderRadius="xl"
        p={4}
      >
        <Badge colorScheme="gray" px={3} py={1} borderRadius="full">
          {formatDate(date)}
        </Badge>
        <Text color={textColor} fontStyle="italic" mt={2}>
          No winner for this day
        </Text>
      </MotionBox>
    );
  }

  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1 }}
      bg={bgColor}
      borderRadius="xl"
      overflow="hidden"
    >
      {/* Image Container */}
      <Box position="relative">
        {/* Image */}
        <Box
          as="img"
          src={winner.imageUrl || winner.photoURL}
          alt="Winning coffee"
          width="100%"
          height="auto"
          objectFit="cover"
          cursor="pointer"
          onClick={() => setShowImageModal(true)}
          transition="transform 0.2s"
          _hover={{ transform: 'scale(1.02)' }}
        />

        {/* Overlay with User Info */}
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          p={4}
          background="linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)"
        >
          <Flex justify="space-between" align="center">
            <Flex align="center" gap={3}>
              <Link
                as={RouterLink}
                to={`/profile/${winner.userId}`}
                _hover={{ textDecoration: 'none' }}
              >
                <Avatar
                  size="md"
                  src={userData?.photoURL || winner.userPhotoURL}
                  name={userData?.displayName || winner.username || winner.userName}
                  border="2px solid white"
                />
              </Link>
              <VStack align="start" spacing={0}>
                <Link
                  as={RouterLink}
                  to={`/profile/${winner.userId}`}
                  color="white"
                  fontWeight="bold"
                  _hover={{ color: 'blue.300' }}
                >
                  {userData?.displayName || winner.username || winner.userName}
                </Link>
                <Badge colorScheme="green" variant="solid">
                  {formatDate(date)}
                </Badge>
              </VStack>
            </Flex>
            <Badge colorScheme="purple" variant="solid">
              {winner.likes || 0} likes
            </Badge>
          </Flex>
        </Box>
      </Box>

      {/* Description if exists */}
      {winner.content && (
        <Box p={4}>
          <Text color={textColor}>{winner.content}</Text>
        </Box>
      )}

      {/* Image Modal */}
      <ImageModal
        isOpen={showImageModal}
        onClose={() => setShowImageModal(false)}
        imageUrl={winner.imageUrl || winner.photoURL}
      />
    </MotionBox>
  );
};

const PreviousWinners = () => {
  const [winners, setWinners] = useState([]);
  const [loading, setLoading] = useState(true);
  const bgColor = useColorModeValue('gray.700', 'gray.800');
  const textColor = useColorModeValue('gray.300', 'gray.400');

  useEffect(() => {
    const fetchWinners = async () => {
      try {
        // Get the last 7 days
        const dates = Array.from({ length: 7 }, (_, i) => {
          const date = new Date();
          date.setDate(date.getDate() - i);
          return date;
        });

        const winnersData = await Promise.all(
          dates.map(async (date) => {
            const startOfDay = new Date(date.setHours(0, 0, 0, 0));
            const endOfDay = new Date(date.setHours(23, 59, 59, 999));

            const q = query(
              collection(db, 'posts'),
              where('type', '==', 'coffeeOfTheDay'),
              where('timestamp', '>=', startOfDay),
              where('timestamp', '<=', endOfDay),
              orderBy('timestamp', 'desc'),
              limit(1)
            );

            const snapshot = await getDocs(q);
            
            if (snapshot.empty) {
              return {
                date: startOfDay,
                winner: null
              };
            }

            const winnerDoc = snapshot.docs[0];
            const winnerData = winnerDoc.data();

            console.log(`Winner for ${startOfDay.toDateString()}:`, winnerData);

            return {
              date: startOfDay,
              winner: {
                id: winnerDoc.id,
                ...winnerData,
                likes: winnerData.likes || 0
              }
            };
          })
        );

        console.log('All winners data:', winnersData);
        setWinners(winnersData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching winners:', error);
        setLoading(false);
      }
    };

    fetchWinners();
  }, []);

  if (loading) {
    return <Text color={textColor}>Loading previous winners...</Text>;
  }

  return (
    <Box>
      <Heading size="lg" mb={6} color="white">
        Previous Winners
      </Heading>

      <VStack spacing={4} align="stretch">
        {winners.map((data, index) => (
          <WinnerCard
            key={data.date.toString()}
            winner={data.winner}
            date={data.date}
            index={index}
          />
        ))}
      </VStack>
    </Box>
  );
};

export default PreviousWinners; 