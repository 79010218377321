import React, { createContext, useContext, useState, useEffect } from 'react';
import { useColorMode } from '@chakra-ui/react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const { colorMode } = useColorMode();
  const [mode, setMode] = useState(colorMode);
  const [colors, setColors] = useState({
    background: mode === 'dark' ? '#1A202C' : '#F7FAFC',
    text: mode === 'dark' ? '#FFFFFF' : '#2D3748',
    // ... other colors
  });

  useEffect(() => {
    setMode(colorMode);
    setColors({
      background: colorMode === 'dark' ? '#1A202C' : '#F7FAFC',
      text: colorMode === 'dark' ? '#FFFFFF' : '#2D3748',
      // ... update other colors
    });
  }, [colorMode]);

  return (
    <ThemeContext.Provider value={{ mode, colors }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
