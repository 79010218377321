import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  Text,
  Image,
  Link,
  Button,
  useColorModeValue,
  Skeleton,
  SkeletonText,
  IconButton,
  Flex,
  useDisclosure,
  Input,
  HStack,
  Avatar,
  useToast,
} from '@chakra-ui/react';
import { FaHeart, FaRegHeart, FaComment, FaPlus } from 'react-icons/fa';
import { collection, query, orderBy, limit, getDocs, updateDoc, doc, arrayUnion, increment, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import DOMPurify from 'dompurify';
import { formatTimeAgo } from '../utils/timeAgo';
import NewsForm from './forms/NewsForm';

const NewsCard = ({ news, onLike, onComment, user }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [comment, setComment] = useState('');
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const toast = useToast();

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    if (!user) {
      toast({
        title: 'Please login to comment',
        status: 'warning',
        duration: 3000,
      });
      return;
    }
    onComment(news.id, comment);
    setComment('');
  };

  const isLiked = user && news.likedBy?.includes(user.uid);

  // Function to truncate text and add ellipsis
  const truncateText = (text, maxLength = 280) => {
    if (!text) return '';
    const cleanText = text.replace(/<[^>]*>/g, ''); // Remove HTML tags
    if (cleanText.length <= maxLength) return cleanText;
    return cleanText.substr(0, maxLength).trim() + '...';
  };

  return (
    <Box
      bg={bgColor}
      borderWidth="1px"
      borderColor={borderColor}
      borderRadius="lg"
      overflow="hidden"
      transition="all 0.2s"
    >
      {news.imageUrl && (
        <Image
          src={news.imageUrl}
          alt={news.title}
          objectFit="cover"
          width="100%"
          height="300px"
        />
      )}
      <Box p={6}>
        <HStack spacing={4} mb={4}>
          {news.sourceImage && (
            <Image
              src={news.sourceImage}
              alt={news.source}
              height="24px"
              objectFit="contain"
            />
          )}
          <Text color="gray.500" fontSize="sm">
            {formatTimeAgo(news.timestamp?.toDate())}
          </Text>
        </HStack>

        <Text fontSize="xl" fontWeight="bold" mb={4}>
          {news.title}
        </Text>

        <Box mb={4}>
          <Text 
            color="gray.600"
            dangerouslySetInnerHTML={{ 
              __html: DOMPurify.sanitize(
                isExpanded ? news.content : truncateText(news.content)
              )
            }}
          />
          {news.content && news.content.length > 280 && (
            <Button
              variant="link"
              colorScheme="blue"
              onClick={() => setIsExpanded(!isExpanded)}
              mt={2}
              size="sm"
            >
              {isExpanded ? 'Show less' : 'Read more'}
            </Button>
          )}
        </Box>

        {news.sourceUrl && (
          <Link href={news.sourceUrl} isExternal color="blue.500" mb={4} display="inline-block">
            Read full article
          </Link>
        )}

        <Flex justify="space-between" align="center" mt={4}>
          <HStack spacing={4}>
            <IconButton
              icon={isLiked ? <FaHeart /> : <FaRegHeart />}
              onClick={() => onLike(news.id)}
              variant="ghost"
              colorScheme={isLiked ? "red" : "gray"}
              isDisabled={!user}
              aria-label="Like"
            />
            <Text>{news.likes || 0}</Text>
          </HStack>
        </Flex>

        <VStack align="stretch" spacing={4} mt={6}>
          {news.comments?.map((comment, index) => (
            <Box key={index} p={4} bg={useColorModeValue('gray.50', 'gray.700')} borderRadius="md">
              <HStack spacing={3} mb={2}>
                <Avatar size="sm" src={comment.userPhotoURL} name={comment.userName} />
                <VStack align="start" spacing={0}>
                  <Text fontWeight="bold">{comment.userName}</Text>
                  <Text fontSize="xs" color="gray.500">
                    {typeof comment.timestamp === 'string' 
                      ? formatTimeAgo(new Date(comment.timestamp))
                      : comment.timestamp?.toDate 
                        ? formatTimeAgo(comment.timestamp.toDate()) 
                        : 'Just now'}
                  </Text>
                </VStack>
              </HStack>
              <Text>{comment.text}</Text>
            </Box>
          ))}

          <form onSubmit={handleCommentSubmit}>
            <HStack>
              <Input
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Add a comment..."
                disabled={!user}
              />
              <Button type="submit" disabled={!user || !comment}>
                Post
              </Button>
            </HStack>
          </form>
        </VStack>
      </Box>
    </Box>
  );
};

const News = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastUpdate, setLastUpdate] = useState(null);
  const { user } = useAuth();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bgColor = useColorModeValue('white', 'gray.800');

  useEffect(() => {
    fetchNews();
    checkLastUpdate();
  }, []);

  const checkLastUpdate = async () => {
    try {
      const today = new Date().toISOString().split('T')[0];
      const updateDoc = await getDoc(doc(db, 'newsUpdates', today));
      if (updateDoc.exists()) {
        setLastUpdate(updateDoc.data().lastUpdate?.toDate());
      }
    } catch (error) {
      console.error('Error checking last update:', error);
    }
  };

  const fetchNews = async () => {
    try {
      const newsQuery = query(
        collection(db, 'news'),
        orderBy('fetchedAt', 'desc'),
        orderBy('timestamp', 'desc'),
        limit(20)
      );
      const snapshot = await getDocs(newsQuery);
      const newsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setNews(newsData);
    } catch (error) {
      console.error('Error fetching news:', error);
      toast({
        title: 'Error loading news',
        description: 'Please try again later',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleLike = async (newsId) => {
    if (!user) {
      toast({
        title: 'Please login to like news',
        status: 'warning',
        duration: 3000,
      });
      return;
    }

    try {
      const newsRef = doc(db, 'news', newsId);
      await updateDoc(newsRef, {
        likes: increment(1),
        likedBy: arrayUnion(user.uid)
      });
      fetchNews(); // Refresh news
    } catch (error) {
      console.error('Error liking news:', error);
    }
  };

  const handleComment = async (newsId, commentText) => {
    try {
      const newsRef = doc(db, 'news', newsId);
      await updateDoc(newsRef, {
        comments: arrayUnion({
          userId: user.uid,
          userName: user.displayName || 'Anonymous',
          text: commentText,
          timestamp: new Date().toISOString()
        })
      });
      fetchNews(); // Refresh news
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  return (
    <Box p={4}>
      <VStack spacing={6}>
        {/* Add News Button */}
        {user && (
          <Button
            leftIcon={<FaPlus />}
            colorScheme="blue"
            onClick={onOpen}
            width="full"
            size="lg"
            mb={2}
          >
            Write News Article
          </Button>
        )}

        {lastUpdate && (
          <Text fontSize="sm" color="gray.500">
            Last updated: {lastUpdate.toLocaleString()}
          </Text>
        )}
        
        {loading ? (
          <VStack spacing={4} w="100%">
            {[...Array(3)].map((_, i) => (
              <Skeleton key={i} height="200px" width="100%" />
            ))}
          </VStack>
        ) : news.length === 0 ? (
          <Box textAlign="center" py={10}>
            <Text>No news available at the moment</Text>
          </Box>
        ) : (
          news.map((item) => (
            <NewsCard
              key={item.id}
              news={item}
              onLike={handleLike}
              onComment={handleComment}
              user={user}
            />
          ))
        )}

        {/* News Form Modal */}
        {isOpen && (
          <NewsForm onClose={onClose} />
        )}
      </VStack>
    </Box>
  );
};

export default News;
