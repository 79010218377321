import React from 'react';
import {
  SimpleGrid,
  Text,
  useColorModeValue,
  VStack,
  Icon,
} from '@chakra-ui/react';
import { 
  FaCoffee, 
  FaStar,
  FaUserFriends,
} from 'react-icons/fa';

const StatItem = ({ label, value }) => {
  const textColor = useColorModeValue('gray.400', 'gray.400');
  const valueColor = useColorModeValue('white', 'white');

  return (
    <VStack spacing={1} align="center">
      <Text fontSize="xl" fontWeight="bold" color={valueColor}>
        {value || 0}
      </Text>
      <Text fontSize="sm" color={textColor}>
        {label}
      </Text>
    </VStack>
  );
};

const ProfileStats = ({ stats = {} }) => {
  const statItems = [
    { label: 'Posts', value: stats?.posts || 0 },
    { label: 'Reviews', value: stats?.reviews || 0 },
    { label: 'Following', value: stats?.following || 0 },
  ];

  return (
    <SimpleGrid columns={3} spacing={8} px={4} py={2}>
      {statItems.map((item) => (
        <StatItem
          key={item.label}
          label={item.label}
          value={item.value}
        />
      ))}
    </SimpleGrid>
  );
};

export default ProfileStats; 