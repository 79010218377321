import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  HStack,
  Image,
  Button,
  Input,
  Text,
  Heading,
  useToast,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter, 
  ModalBody,
  Switch,
  FormControl,
  FormLabel,
  Textarea,
  useColorModeValue,
  Badge,
  Select,
  Icon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { 
  collection, 
  addDoc, 
  getDocs, 
  doc, 
  updateDoc, 
  deleteDoc,
  getDoc 
} from 'firebase/firestore';
import { storage, db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { QRCodeSVG } from 'qrcode.react';
import imageCompression from 'browser-image-compression';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { FaCamera, FaImage, FaPrint } from 'react-icons/fa';
import { useTheme } from '../contexts/ThemeContext';
import BadgeCard from './badges/BadgeCard';
import PrintTemplate from './badges/PrintTemplate';
import { renderToString } from 'react-dom/server';

// Print styles component
const PrintStyles = () => (
  <style>
    {`
      @media print {
        body * {
          visibility: hidden;
        }
        #print-area, #print-area * {
          visibility: visible;
        }
        #print-area {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
        }
        .no-print {
          display: none !important;
        }
      }
    `}
  </style>
);

const BadgeManagement = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [isBadgeManager, setIsBadgeManager] = useState(false);
  const { mode, colors = {} } = useTheme();

  // Add fallback colors
  const defaultColors = {
    background: mode === 'dark' ? 'gray.900' : 'gray.50',
    card: mode === 'dark' ? 'gray.800' : 'white',
    text: mode === 'dark' ? 'white' : 'gray.800',
    subtext: mode === 'dark' ? 'gray.400' : 'gray.600',
    border: mode === 'dark' ? 'gray.700' : 'gray.200'
  };

  // Merge theme colors with defaults
  const themeColors = {
    background: colors.background || defaultColors.background,
    card: colors.card || defaultColors.card,
    text: colors.text || defaultColors.text,
    subtext: colors.subtext || defaultColors.subtext,
    border: colors.border || defaultColors.border
  };

  // Check if user is badge manager
  useEffect(() => {
    const checkManagerStatus = async () => {
      if (!user) {
        navigate('/');
        return;
      }

      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const userData = userDoc.data();
      const isManager = userData?.isBadgeManager || false;
      setIsBadgeManager(isManager);

      if (!isManager) {
        toast({
          title: "Access Denied",
          description: "You need badge manager privileges to access this area",
          status: "error",
          duration: 3000,
        });
        navigate('/');
      }
      setIsLoading(false);
    };

    checkManagerStatus();
  }, [user, navigate]);

  const [badgeImage, setBadgeImage] = useState(null);
  const [badgeName, setBadgeName] = useState('');
  const [badgeDescription, setBadgeDescription] = useState('');
  const [printStyle, setPrintStyle] = useState('qr-only'); // 'qr-only' or 'full-page'
  const [badges, setBadges] = useState([]);
  const [selectedBadge, setSelectedBadge] = useState(null);
  const [isQRModalOpen, setIsQRModalOpen] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [storageUrl, setStorageUrl] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingBadge, setEditingBadge] = useState(null);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [badgeToDelete, setBadgeToDelete] = useState(null);
  const cancelRef = React.useRef();

  useEffect(() => {
    fetchBadges();
  }, [user]);

  const fetchBadges = async () => {
    if (!user) return;
    const badgesSnapshot = await getDocs(collection(db, 'badges'));
    const badgesList = badgesSnapshot.docs
      .map(doc => ({ id: doc.id, ...doc.data() }))
      .filter(badge => badge.createdBy === user.uid);
    setBadges(badgesList);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.webp']
    },
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles[0]) {
        try {
          const compressedImage = await imageCompression(acceptedFiles[0], {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920
          });
          setBadgeImage(compressedImage);
        } catch (error) {
          console.error("Error compressing image:", error);
          toast({
            title: "Error",
            description: "Failed to process image",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      }
    }
  });

  const uploadBadge = async () => {
    if (!badgeImage || !badgeName) {
      toast({
        title: "Error",
        description: "Please provide both an image and a name for the badge.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const uniqueFileName = `${Date.now()}-${badgeImage.name}`;
      const badgeRef = ref(storage, `badges/${uniqueFileName}`);
      
      // Upload the image
      const snapshot = await uploadBytes(badgeRef, badgeImage);
      const downloadURL = await getDownloadURL(snapshot.ref);
      
      // Create the storage URL
      const storageUrl = `gs://coffeetalk-1e492.appspot.com/badges/${uniqueFileName}`;
      setStorageUrl(storageUrl);  // Save the storage URL

      // Create badge document
      const badgeDoc = await addDoc(collection(db, 'badges'), {
        name: badgeName,
        description: badgeDescription,
        imageUrl: downloadURL,
        storageUrl: storageUrl,  // Save the storage URL in the document
        createdBy: user.uid,
        createdAt: new Date(),
        status: 'active'
      });

      setUploaded(true);
      fetchBadges();
      toast({
        title: "Badge created",
        description: "Your badge has been created successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      // Clear form
      setBadgeName('');
      setBadgeDescription('');
      setBadgeImage(null);
    } catch (error) {
      console.error("Error uploading badge:", error);
      toast({
        title: "Error",
        description: "Failed to upload badge.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleToggle = async (badgeId, currentStatus) => {
    const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
    await updateDoc(doc(db, 'badges', badgeId), { status: newStatus });
    fetchBadges();
  };

  const handleDelete = async (badgeId, imageUrl) => {
    try {
      await deleteDoc(doc(db, 'badges', badgeId));
      const imageRef = ref(storage, imageUrl);
      await deleteObject(imageRef);
      fetchBadges();
      toast({
        title: "Badge deleted",
        description: "The badge has been deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting badge:", error);
      toast({
        title: "Error",
        description: "Failed to delete badge.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleShowQR = (badge) => {
    setSelectedBadge(badge);
    setIsQRModalOpen(true);
  };

  const generateClaimUrl = (badgeId) => {
    return `https://beanscene.de/badge/claim/${badgeId}`;
  };

  const handlePrint = (badge, type) => {
    const claimUrl = generateClaimUrl(badge.id);
    const printContent = `
      <!DOCTYPE html>
      <html>
        <head>
          <title>${badge.name} - BeanScene Badge</title>
          <style>
            @media print {
              body {
                margin: 0;
                padding: 20px;
                font-family: Arial, sans-serif;
                color: #2D3748;
              }
              .container {
                text-align: center;
                max-width: 600px;
                margin: 0 auto;
                padding: 20px;
              }
              .logo {
                width: 200px;
                margin-bottom: 10px;
              }
              .badge-image {
                max-width: 150px;
                margin: 20px auto;
                border-radius: 10px;
                box-shadow: 0 4px 6px rgba(0,0,0,0.1);
              }
              .qr-code {
                margin: 20px auto;
                padding: 15px;
                background: white;
                border-radius: 10px;
                box-shadow: 0 4px 6px rgba(0,0,0,0.1);
                width: fit-content;
              }
              .title {
                font-size: 24px;
                font-weight: bold;
                margin: 15px 0;
                color: #1A202C;
              }
              .description {
                font-size: 16px;
                line-height: 1.6;
                margin: 15px auto;
                max-width: 500px;
                color: #4A5568;
              }
              .badge-description {
                font-style: italic;
                color: #718096;
                margin: 10px 0;
              }
              .footer {
                margin-top: 20px;
                font-size: 12px;
                color: #718096;
              }
              .website {
                margin-top: 10px;
                font-size: 14px;
                color: #4A5568;
                font-weight: bold;
              }
            }
          </style>
        </head>
        <body>
          <div class="container">
            <img src="/beanscenelogo.png" alt="BeanScene" class="logo"/>
            ${type === 'full-page' ? `
              <div class="title">Collect Your Coffee Badge!</div>
              <p class="description">
                Join the coffee community and start your coffee journey! 
                Scan this QR code to collect this unique badge and showcase your coffee adventures. 
                Discover new cafes, connect with fellow coffee lovers, and build your collection of 
                achievements in the world of coffee.
              </p>
              <img src="${badge.imageUrl}" class="badge-image" alt="${badge.name}"/>
              <h2>${badge.name}</h2>
              <p class="badge-description">${badge.description || 'A special coffee achievement awaits!'}</p>
            ` : ''}
            <div class="qr-code">
              ${document.getElementById('qr-code-for-print')?.innerHTML || ''}
            </div>
            ${type === 'full-page' ? `
              <p class="footer">
                Start collecting badges from your favorite coffee spots!
                Join thousands of coffee enthusiasts in sharing their passion for great coffee.
              </p>
            ` : ''}
            <p class="website">www.beanscene.de</p>
            <p class="claim-url">${claimUrl}</p>
          </div>
          <script>
            window.onload = () => {
              setTimeout(() => {
                window.print();
                window.close();
              }, 1000);
            };
          </script>
        </body>
      </html>
    `;

    const printWindow = window.open('', '_blank');
    printWindow.document.write(printContent);
    printWindow.document.close();
  };

  const handleEdit = (badge) => {
    setEditingBadge(badge);
    setIsEditModalOpen(true);
  };

  const handleEditSubmit = async (updatedBadge) => {
    try {
      await updateDoc(doc(db, 'badges', updatedBadge.id), {
        name: updatedBadge.name,
        description: updatedBadge.description,
      });
      
      toast({
        title: "Badge updated",
        status: "success",
        duration: 3000,
      });
      
      setIsEditModalOpen(false);
      fetchBadges();
    } catch (error) {
      toast({
        title: "Error updating badge",
        description: error.message,
        status: "error",
        duration: 3000,
      });
    }
  };

  const handleDeleteClick = (badge) => {
    setBadgeToDelete(badge);
    setIsDeleteAlertOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!badgeToDelete) return;
    
    try {
      await deleteDoc(doc(db, 'badges', badgeToDelete.id));
      const imageRef = ref(storage, badgeToDelete.imageUrl);
      await deleteObject(imageRef);
      
      toast({
        title: "Badge deleted",
        description: "The badge has been deleted successfully.",
        status: "success",
        duration: 3000,
      });
      
      setIsDeleteAlertOpen(false);
      setBadgeToDelete(null);
      fetchBadges();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete badge.",
        status: "error",
        duration: 3000,
      });
    }
  };

  if (isLoading) {
    return <Box>Loading...</Box>;
  }

  if (!isBadgeManager) {
    return null;
  }

  return (
    <Box p={4} bg={themeColors.background}>
      <PrintStyles />
      <Tabs variant="enclosed">
        <TabList>
          <Tab color={themeColors.text}>Create Badge</Tab>
          <Tab color={themeColors.text}>Manage Badges</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VStack spacing={6} align="stretch">
              <Heading size="md" color={themeColors.text}>Create a New Badge</Heading>
              
              {/* Image Upload Section */}
              <Box
                {...getRootProps()}
                p={6}
                bg={themeColors.card}
                borderRadius="md"
                borderWidth={2}
                borderStyle="dashed"
                borderColor={themeColors.border}
                cursor="pointer"
                transition="all 0.2s"
                _hover={{
                  borderColor: mode === 'dark' ? 'blue.400' : 'blue.500',
                  bg: mode === 'dark' ? 'gray.700' : 'gray.50'
                }}
              >
                <input {...getInputProps()} />
                <VStack spacing={2} align="center">
                  {badgeImage ? (
                    <Box position="relative" width="full">
                      <Image
                        src={URL.createObjectURL(badgeImage)}
                        alt="Badge preview"
                        maxH="200px"
                        mx="auto"
                        objectFit="contain"
                        borderRadius="md"
                      />
                      <Text mt={2} fontSize="sm" color="gray.500" textAlign="center">
                        Click or drag to replace image
                      </Text>
                    </Box>
                  ) : (
                    <>
                      <Icon as={FaImage} boxSize={8} color="gray.400" />
                      <Text color="gray.500" textAlign="center">
                        Drop your badge image here, or click to select
                      </Text>
                      <Text fontSize="sm" color="gray.400">
                        Supports JPG, PNG, WEBP
                      </Text>
                    </>
                  )}
                </VStack>
              </Box>

              <Input
                placeholder="Badge Name"
                value={badgeName}
                onChange={(e) => setBadgeName(e.target.value)}
                size="lg"
              />
              
              <Textarea
                placeholder="Badge Description"
                value={badgeDescription}
                onChange={(e) => setBadgeDescription(e.target.value)}
                size="lg"
                resize="vertical"
                minH="100px"
              />

              <Button 
                onClick={uploadBadge} 
                colorScheme="blue"
                size="lg"
                isDisabled={!badgeImage || !badgeName.trim()}
                leftIcon={<FaCamera />}
              >
                Create Badge
              </Button>
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack spacing={4} align="stretch">
              <Heading size="md" color={themeColors.text}>Your Badges</Heading>
              {badges.map((badge, index) => (
                <BadgeCard
                  key={badge.id}
                  badge={badge}
                  onToggle={handleToggle}
                  onDelete={handleDeleteClick}
                  onShowQR={handleShowQR}
                  onEdit={handleEdit}
                  isFirst={index === 0}
                />
              ))}
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Modal isOpen={isQRModalOpen} onClose={() => setIsQRModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedBadge?.name} QR Code</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="center">
              <div id="qr-code-for-print">
                <QRCodeSVG 
                  value={selectedBadge ? generateClaimUrl(selectedBadge.id) : ''}
                  size={256}
                  level="H"
                  includeMargin={true}
                />
              </div>
              
              <FormControl display="flex" alignItems="center" mb={4}>
                <FormLabel mb="0">
                  Print Style:
                </FormLabel>
                <Select
                  value={printStyle}
                  onChange={(e) => setPrintStyle(e.target.value)}
                  className="no-print"
                >
                  <option value="qr-only">QR Code Only</option>
                  <option value="full-page">Full Page with Details</option>
                </Select>
              </FormControl>

              <Button 
                onClick={() => handlePrint(selectedBadge, printStyle)}
                colorScheme="blue"
                leftIcon={<FaPrint />}
                className="no-print"
              >
                Print Badge
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Edit Modal */}
      <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Badge</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <Input
                placeholder="Badge Name"
                value={editingBadge?.name || ''}
                onChange={(e) => setEditingBadge({
                  ...editingBadge,
                  name: e.target.value
                })}
              />
              <Textarea
                placeholder="Badge Description"
                value={editingBadge?.description || ''}
                onChange={(e) => setEditingBadge({
                  ...editingBadge,
                  description: e.target.value
                })}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={() => setIsEditModalOpen(false)}>
              Cancel
            </Button>
            <Button 
              colorScheme="blue" 
              onClick={() => handleEditSubmit(editingBadge)}
            >
              Save Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Delete Confirmation */}
      <AlertDialog
        isOpen={isDeleteAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Delete Badge</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure? This action cannot be undone.
              All QR codes for this badge will stop working.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDeleteAlertOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeleteConfirm} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default BadgeManagement;
