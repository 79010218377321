import { 
  collection, 
  doc,
  query,
  where,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  setDoc,
  arrayUnion,
  arrayRemove,
  increment,
  GeoPoint,
  startAfter,
  limit,
  orderBy,
  Timestamp
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase';
import { config } from '../config';
import { searchNearbyPlaces } from './locationIQ';

// Stories Functions
export const addStory = async (userId, storyData) => {
  try {
    const storyRef = await addDoc(collection(db, 'stories'), {
      userId,
      createdAt: new Date(),
      expiresAt: new Date(Date.now() + 24 * 60 * 60 * 1000), // 24 hours
      views: [],
      ...storyData
    });
    return storyRef.id;
  } catch (error) {
    console.error('Error adding story:', error);
    throw error;
  }
};

export const getStoriesForUser = async (userId) => {
  try {
    const q = query(
      collection(db, 'stories'),
      where('expiresAt', '>', new Date()),
      where('userId', '==', userId),
      orderBy('expiresAt'),
      orderBy('createdAt', 'desc')
    );
    
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error getting stories:', error);
    throw error;
  }
};

export const getUserData = async (userId) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      return {
        uid: userDoc.id,
        ...userDoc.data()
      };
    }
    return null;
  } catch (error) {
    console.error('Error getting user data:', error);
    return null;
  }
};

export const getStoriesForTimeline = async (userId, following = []) => {
  try {
    // Ensure following is an array
    const followingArray = Array.isArray(following) ? following : [];
    
    // Get stories from followed users and the user's own stories
    const storyQuery = query(
      collection(db, 'stories'),
      where('userId', 'in', [userId, ...followingArray]),
      where('expiresAt', '>', new Date()),
      orderBy('expiresAt', 'desc'),
      limit(20)
    );

    const storiesSnapshot = await getDocs(storyQuery);
    const stories = [];

    for (const storyDoc of storiesSnapshot.docs) {
      const storyData = storyDoc.data();
      
      // Get user data for each story
      const userDocRef = doc(db, 'users', storyData.userId);
      const userDoc = await getDoc(userDocRef);
      
      if (userDoc.exists()) {
        stories.push({
          id: storyDoc.id,
          ...storyData,
          user: {
            id: userDoc.id,
            ...userDoc.data()
          }
        });
      }
    }

    // Group stories by user
    const groupedStories = stories.reduce((acc, story) => {
      const userId = story.userId;
      if (!acc[userId]) {
        acc[userId] = {
          user: story.user,
          stories: []
        };
      }
      acc[userId].stories.push(story);
      return acc;
    }, {});

    return Object.values(groupedStories);
  } catch (error) {
    console.error('Error getting timeline stories:', error);
    throw error;
  }
};

export const viewStory = async (storyId, userId) => {
  try {
    const storyRef = doc(db, 'stories', storyId);
    await updateDoc(storyRef, {
      views: arrayUnion({
        userId,
        viewedAt: new Date()
      })
    });
  } catch (error) {
    console.error('Error marking story as viewed:', error);
    throw error;
  }
};

// Chat Functions
export const createChat = async (currentUser, otherUser) => {
  const chatId = [currentUser.uid, otherUser.uid].sort().join('_');
  
  try {
    const chatRef = doc(db, 'chats', chatId);
    const chatDoc = await getDoc(chatRef);
    
    if (!chatDoc.exists()) {
      await setDoc(chatRef, {
        participants: [currentUser.uid, otherUser.uid],
        participantsData: {
          [currentUser.uid]: {
            displayName: currentUser.displayName,
            photoURL: currentUser.photoURL
          },
          [otherUser.uid]: {
            displayName: otherUser.displayName,
            photoURL: otherUser.photoURL
          }
        },
        lastMessage: null,
        lastMessageTime: new Date(),
        createdAt: new Date()
      });
    }
    
    return chatId;
  } catch (error) {
    console.error('Error creating chat:', error);
    throw error;
  }
};

export const sendMessage = async (chatId, senderId, text) => {
  try {
    const messageRef = await addDoc(collection(db, 'messages'), {
      chatId,
      senderId,
      text,
      timestamp: new Date(),
      read: false
    });

    const chatRef = doc(db, 'chats', chatId);
    await updateDoc(chatRef, {
      lastMessage: {
        text,
        senderId
      },
      lastMessageTime: new Date()
    });

    return messageRef.id;
  } catch (error) {
    console.error('Error sending message:', error);
    throw error;
  }
};

// Coffee Places Functions
export const addCoffeePlace = async (placeData, userId) => {
  try {
    if (!placeData.lat || !placeData.lng) {
      throw new Error('Invalid coordinates');
    }

    const location = new GeoPoint(placeData.lat, placeData.lng);
    const placeRef = await addDoc(collection(db, 'coffeePlaces'), {
      ...placeData,
      addedBy: userId,
      createdAt: new Date(),
      lastUpdated: new Date(),
      verified: false,
      ratings: [],
      reviews: [],
      likes: 0,
      location,
      lat: placeData.lat,
      lng: placeData.lng
    });

    return placeRef.id;
  } catch (error) {
    console.error('Error adding coffee place:', error);
    throw error;
  }
};

export const searchCoffeePlaces = async ({
  searchTerm = '',
  filters = {},
  location,
  lastVisible = null,
  pageSize = 20
}) => {
  try {
    if (!location?.lat || !location?.lng) {
      throw new Error('Valid location coordinates required');
    }

    const places = await searchNearbyPlaces({
      lat: location.lat,
      lng: location.lng,
      searchTerm
    });

    return {
      places,
      lastVisible: null,
      hasMore: false
    };
  } catch (error) {
    console.error('Error searching coffee places:', error);
    throw error;
  }
};

export const addReview = async (placeId, reviewData, userId) => {
  if (!placeId || !userId || !reviewData) {
    throw new Error('Place ID, User ID, and review data are required');
  }

  try {
    const placeRef = doc(db, 'places', placeId);
    const userRef = doc(db, 'users', userId);
    const timestamp = new Date();

    const review = {
      userId,
      text: reviewData.text,
      rating: reviewData.rating,
      timestamp,
      likes: 0,
      likedBy: []
    };

    // Update place reviews
    await updateDoc(placeRef, {
      reviews: arrayUnion(review),
      reviewCount: increment(1),
      lastReviewed: timestamp
    });

    // Update user's reviews
    await updateDoc(userRef, {
      [`reviews.${placeId}`]: review
    });

    return review;
  } catch (error) {
    console.error('Error adding review:', error);
    throw error;
  }
};

export const checkInToPlace = async (placeId, userId, checkInData = {}) => {
  try {
    const placeRef = doc(db, 'coffeePlaces', placeId);
    const userRef = doc(db, 'users', userId);
    const timestamp = new Date();

    const checkIn = {
      userId,
      timestamp,
      ...checkInData
    };

    await Promise.all([
      updateDoc(placeRef, {
        checkIns: arrayUnion(checkIn),
        checkInCount: increment(1),
        lastCheckIn: timestamp
      }),
      updateDoc(userRef, {
        checkedInPlaces: arrayUnion(placeId),
        checkInCount: increment(1)
      })
    ]);

    return checkIn;
  } catch (error) {
    console.error('Error checking in:', error);
    throw error;
  }
};

export const addTipToPlace = async (placeId, userId, tipData) => {
  try {
    const placeRef = doc(db, 'coffeePlaces', placeId);
    const userRef = doc(db, 'users', userId);

    const tip = {
      userId,
      text: tipData.text,
      timestamp: new Date(),
      likes: 0,
      likedBy: []
    };

    await Promise.all([
      updateDoc(placeRef, {
        tips: arrayUnion(tip)
      }),
      updateDoc(userRef, {
        tipCount: increment(1)
      })
    ]);

    return tip;
  } catch (error) {
    console.error('Error adding tip:', error);
    throw error;
  }
};

export const toggleTipLike = async (placeId, tipId, userId) => {
  try {
    const placeRef = doc(db, 'coffeePlaces', placeId);
    const placeDoc = await getDoc(placeRef);
    const placeData = placeDoc.data();

    const tipIndex = placeData.tips.findIndex(t => t.id === tipId);
    if (tipIndex === -1) throw new Error('Tip not found');

    const tip = placeData.tips[tipIndex];
    const isLiked = tip.likedBy.includes(userId);

    const updatedTip = {
      ...tip,
      likes: isLiked ? tip.likes - 1 : tip.likes + 1,
      likedBy: isLiked 
        ? tip.likedBy.filter(id => id !== userId)
        : [...tip.likedBy, userId]
    };

    const updatedTips = [...placeData.tips];
    updatedTips[tipIndex] = updatedTip;

    await updateDoc(placeRef, {
      tips: updatedTips
    });

    return !isLiked;
  } catch (error) {
    console.error('Error toggling tip like:', error);
    throw error;
  }
};

export const addCheckIn = async (placeId, userId) => {
  if (!placeId || !userId) {
    throw new Error('Place ID and User ID are required');
  }

  try {
    // First ensure the place exists
    const placeRef = doc(db, 'places', placeId);
    const placeDoc = await getDoc(placeRef);
    
    if (!placeDoc.exists()) {
      throw new Error('Place not found - please try adding the place first');
    }

    const checkIn = {
      userId,
      timestamp: new Date(),
    };

    // Update place check-ins
    await updateDoc(placeRef, {
      checkIns: arrayUnion(checkIn),
      checkInCount: increment(1)
    });

    // Update user's check-ins
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      [`checkIns.${placeId}`]: checkIn
    });

    return checkIn;
  } catch (error) {
    console.error('Error adding check-in:', error);
    throw error;
  }
};

export const addRating = async (placeId, rating, userId) => {
  try {
    const placeRef = doc(db, 'coffeePlaces', placeId);
    const userRef = doc(db, 'users', userId);
    const timestamp = new Date();

    // Get current place data
    const placeDoc = await getDoc(placeRef);
    if (!placeDoc.exists()) {
      throw new Error('Place not found');
    }

    const placeData = placeDoc.data();
    const ratings = placeData.ratings || [];
    
    // Remove user's previous rating if exists
    const filteredRatings = ratings.filter(r => r.userId !== userId);
    
    // Add new rating
    const newRating = {
      userId,
      rating,
      timestamp
    };
    
    // Calculate new average rating
    const newRatings = [...filteredRatings, newRating];
    const avgRating = newRatings.reduce((acc, r) => acc + r.rating, 0) / newRatings.length;

    // Update place ratings
    await updateDoc(placeRef, {
      ratings: newRatings,
      averageRating: avgRating,
      ratingCount: newRatings.length,
      lastRated: timestamp
    });

    // Update user's ratings
    await updateDoc(userRef, {
      [`ratings.${placeId}`]: {
        rating,
        timestamp
      }
    });

    return {
      rating,
      averageRating: avgRating,
      ratingCount: newRatings.length
    };
  } catch (error) {
    console.error('Error adding rating:', error);
    throw error;
  }
};

export const getUserPlaceData = async (placeId, userId) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      return null;
    }

    const userData = userDoc.data();
    return {
      isCheckedIn: userData.checkedInPlaces?.includes(placeId) || false,
      rating: userData.ratings?.[placeId]?.rating || 0,
      lastCheckIn: userData.lastCheckIn?.placeId === placeId ? userData.lastCheckIn.timestamp : null
    };
  } catch (error) {
    console.error('Error getting user place data:', error);
    throw error;
  }
};

export const addPlace = async (placeData) => {
  if (!placeData?.placeId) {
    throw new Error('Place data is required');
  }

  try {
    const placeRef = doc(db, 'places', placeData.placeId);
    
    // Filter out undefined values and create clean place document
    const placeDoc = {
      placeId: placeData.placeId,
      name: placeData.name || '',
      address: placeData.address || '',
      location: new GeoPoint(
        placeData.location?.lat || placeData.lat || 0,
        placeData.location?.lng || placeData.lng || 0
      ),
      photos: placeData.photos || [],
      image: placeData.image || null,
      rating: placeData.rating || 0,
      userRatingsTotal: placeData.userRatingsTotal || 0,
      openingHours: placeData.openingHours || null,
      website: placeData.website || null,
      phone: placeData.phone || null,
      checkIns: [],
      ratings: [],
      reviews: [],
      createdAt: new Date(),
      updatedAt: new Date()
    };

    // Only add priceLevel if it exists
    if (typeof placeData.priceLevel === 'number') {
      placeDoc.priceLevel = placeData.priceLevel;
    }

    await setDoc(placeRef, placeDoc, { merge: true });
    return placeRef.id;
  } catch (error) {
    console.error('Error adding place:', error);
    throw error;
  }
};

export const toggleFavorite = async (placeId, userId) => {
  if (!placeId || !userId) {
    throw new Error('Place ID and User ID are required');
  }

  try {
    const placeRef = doc(db, 'places', placeId);
    const placeDoc = await getDoc(placeRef);
    
    if (!placeDoc.exists()) {
      throw new Error('Place not found');
    }

    const currentLikedBy = placeDoc.data().likedBy || [];
    const isCurrentlyLiked = currentLikedBy.includes(userId);

    if (isCurrentlyLiked) {
      // Remove from favorites
      await updateDoc(placeRef, {
        likedBy: arrayRemove(userId)
      });
    } else {
      // Add to favorites
      await updateDoc(placeRef, {
        likedBy: arrayUnion(userId)
      });
    }

    return !isCurrentlyLiked;
  } catch (error) {
    console.error('Error toggling favorite:', error);
    throw error;
  }
};
