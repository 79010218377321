import React from 'react';
import {
  Box,
  VStack,
  HStack,
  Text,
  Image,
  Badge,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaCoffee, FaStar } from 'react-icons/fa';
import StarRating from './StarRating';

const MotionBox = motion(Box);

const CoffeeCard = ({ coffee, onClick }) => {
  const cardBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const textColor = useColorModeValue('gray.600', 'gray.400');

  return (
    <Box 
      p={4} 
      borderRadius="lg" 
      borderWidth="1px"
      onClick={onClick}
      cursor="pointer"
      transition="all 0.2s"
      _hover={{ transform: 'translateY(-2px)', shadow: 'md' }}
    >
      <VStack align="stretch" spacing={2}>
        {coffee.photoURL && (
          <Image 
            src={coffee.photoURL} 
            alt={coffee.name}
            borderRadius="md"
            objectFit="cover"
            height="150px"
          />
        )}
        <Text fontWeight="bold">{coffee.name}</Text>
        <Text color="gray.500">{coffee.roaster}</Text>
        <StarRating rating={coffee.rating || 0} isEditable={false} size={20} />

        <HStack justify="space-between">
          <Text fontWeight="bold" fontSize="lg">{coffee.name}</Text>
          {coffee.rating >= 4 && (
            <Icon as={FaStar} color="yellow.400" boxSize={5} />
          )}
        </HStack>

        <Text color={textColor} fontSize="sm">{coffee.roaster}</Text>

        <HStack spacing={2} flexWrap="wrap">
          <Badge colorScheme="purple">{coffee.roastLevel}</Badge>
          {coffee.origin && (
            <Badge colorScheme="green">{coffee.origin}</Badge>
          )}
          {coffee.isEspresso && (
            <Badge colorScheme="orange">Espresso</Badge>
          )}
        </HStack>

        {coffee.tasting_notes?.length > 0 && (
          <HStack spacing={2} flexWrap="wrap">
            {coffee.tasting_notes.slice(0, 3).map((note, index) => (
              <Badge
                key={index}
                colorScheme="blue"
                variant="subtle"
                fontSize="xs"
              >
                {note}
              </Badge>
            ))}
            {coffee.tasting_notes.length > 3 && (
              <Badge colorScheme="gray" fontSize="xs">
                +{coffee.tasting_notes.length - 3}
              </Badge>
            )}
          </HStack>
        )}


        {coffee.status === 'finished' && (
          <Text fontSize="sm" color="gray.500">
            Finished {new Date(coffee.finishedDate?.seconds * 1000).toLocaleDateString()}
          </Text>
        )}

        <HStack justify="space-between" mt={2}>
          <Text fontSize="sm" color={textColor}>
            Remaining: {coffee.remainingGrams ? `${coffee.remainingGrams}g` : 'Not set'}
          </Text>
          {coffee.remainingGrams && coffee.remainingGrams < 50 && (
            <Badge colorScheme="red">Low</Badge>
          )}
        </HStack>
      </VStack>
    </Box>
  );
};

export default CoffeeCard; 