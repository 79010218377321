import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Image,
  Box,
} from '@chakra-ui/react';

const ImageModal = ({ isOpen, onClose, imageUrl }) => {
  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose} 
      size="6xl"
      isCentered
    >
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent bg="transparent" boxShadow="none">
        <ModalCloseButton color="white" />
        <ModalBody p={0}>
          <Box
            maxH="90vh"
            overflow="hidden"
            borderRadius="lg"
          >
            <Image
              src={imageUrl}
              alt="Enlarged view"
              w="100%"
              h="100%"
              objectFit="contain"
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ImageModal;