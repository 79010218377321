import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  HStack,
  Text,
  Button,
  Input,
  Select,
  Textarea,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Image,
  SimpleGrid,
  Heading,
  Badge,
  Icon,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
} from '@chakra-ui/react';
import { FaCoffee, FaSearch, FaCalendar, FaStar } from 'react-icons/fa';
import { collection, addDoc, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { css } from '@emotion/react';
import { motion, useAnimation } from 'framer-motion';
import { useBreakpointValue } from '@chakra-ui/react';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';

const calendarStyles = css`
  .react-calendar {
    width: 100%;
    max-width: 100%;
    background: var(--chakra-colors-gray-800);
    border: 1px solid var(--chakra-colors-gray-700);
    border-radius: var(--chakra-radii-lg);
    padding: 1rem;
    color: var(--chakra-colors-gray-100);
  }

  .react-calendar__navigation {
    margin-bottom: 1rem;
  }

  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    color: var(--chakra-colors-gray-100);
    font-size: 16px;
    margin-top: 8px;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: var(--chakra-colors-gray-700);
    border-radius: var(--chakra-radii-md);
  }

  .react-calendar__navigation button[disabled] {
    background-color: var(--chakra-colors-gray-600);
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75rem;
    color: var(--chakra-colors-blue-400);
  }

  .react-calendar__month-view__days__day {
    color: var(--chakra-colors-gray-100);
    padding: 8px;
  }

  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6px;
    background: none;
    text-align: center;
    line-height: 16px;
    border-radius: var(--chakra-radii-md);
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: var(--chakra-colors-gray-700);
  }

  .react-calendar__tile--now {
    background: var(--chakra-colors-blue-600) !important;
    color: white;
  }

  .react-calendar__tile--active {
    background: var(--chakra-colors-blue-500) !important;
    color: white;
  }

  .react-calendar__tile--hasEntry {
    background-color: var(--chakra-colors-gray-700);
  }

  .dot {
    height: 6px;
    width: 6px;
    background-color: var(--chakra-colors-blue-400);
    border-radius: 50%;
    display: block;
    margin: 2px auto 0;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: var(--chakra-colors-gray-500);
  }
`;

const MotionDrawerContent = motion(DrawerContent);

const EspressoJournal = () => {
  const { user } = useAuth();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [mode, setMode] = useState('espresso');
  const [coffeeInventory, setCoffeeInventory] = useState([]);
  const [selectedBean, setSelectedBean] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [journalEntries, setJournalEntries] = useState([]);
  const [suggestions, setSuggestions] = useState(null);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const {
    isOpen: isHistoryOpen,
    onOpen: onOpenHistory,
    onClose: onCloseHistory
  } = useDisclosure();

  const [formData, setFormData] = useState({
    grindSetting: '',
    dose: '',
    yield: '',
    time: '',
    temperature: '',
    pressure: '',
    notes: '',
    rating: 5, // Default rating
  });

  useEffect(() => {
    if (user) {
      loadCoffeeInventory();
    }
  }, [user]);

  const loadCoffeeInventory = async () => {
    try {
      const q = query(collection(db, 'coffeeInventory'), where('userId', '==', user.uid));
      const querySnapshot = await getDocs(q);
      const coffees = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCoffeeInventory(coffees);
    } catch (error) {
      console.error('Error loading coffee inventory:', error);
      toast({
        title: 'Error loading coffee inventory',
        status: 'error',
        duration: 3000,
      });
    }
  };

  const handleBeanSelect = (bean) => {
    setSelectedBean(bean);
    onClose();
  };

  const handleChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      setFormData(prev => ({ ...prev, [name]: value }));
    } else {
      const { name, value } = e;
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSave = async () => {
    if (!selectedBean) {
      toast({
        title: 'Please select a coffee bean',
        status: 'warning',
        duration: 3000,
      });
      return;
    }

    try {
      const journalEntry = {
        ...formData,
        beanId: selectedBean.id,
        beanName: selectedBean.name,
        mode,
        userId: user.uid,
        createdAt: new Date(),
      };

      await addDoc(collection(db, 'brewJournal'), journalEntry);
      
      toast({
        title: 'Entry saved successfully!',
        status: 'success',
        duration: 3000,
      });

      // Reset form
      setFormData({
        grindSetting: '',
        dose: '',
        yield: '',
        time: '',
        temperature: '',
        pressure: '',
        notes: '',
        rating: 0,
      });
    } catch (error) {
      toast({
        title: 'Error saving entry',
        description: error.message,
        status: 'error',
        duration: 3000,
      });
    }
  };

  const SelectedCoffeeCard = () => (
    selectedBean && (
      <Box 
        borderWidth="1px" 
        borderRadius="lg" 
        p={4} 
        bg="gray.800"
        borderColor="gray.700"
        mb={4}
      >
        <HStack spacing={4}>
          {selectedBean.photoURL ? (
            <Image
              src={selectedBean.photoURL}
              alt={selectedBean.name}
              boxSize="60px"
              objectFit="cover"
              borderRadius="md"
            />
          ) : (
            <Icon as={FaCoffee} boxSize="60px" color="gray.500" />
          )}
          <VStack align="start" spacing={1} flex={1}>
            <Text fontSize="xl" fontWeight="bold" color="white">{selectedBean.name}</Text>
            <Text color="gray.400">{selectedBean.roaster}</Text>
            <HStack spacing={2}>
              <Badge colorScheme="blue">{selectedBean.roastLevel}</Badge>
              {selectedBean.origin && (
                <Badge colorScheme="green">{selectedBean.origin}</Badge>
              )}
            </HStack>
          </VStack>
        </HStack>
      </Box>
    )
  );

  // Load journal entries for selected date
  useEffect(() => {
    if (user) {
      loadJournalEntries();
    }
  }, [user, selectedDate]);

  const loadJournalEntries = async () => {
    try {
      // Get start and end of month
      const start = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
      const end = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0, 23, 59, 59);

      const q = query(
        collection(db, 'brewJournal'),
        where('userId', '==', user.uid),
        where('createdAt', '>=', start),
        where('createdAt', '<=', end),
        orderBy('createdAt', 'desc')
      );

      const querySnapshot = await getDocs(q);
      const entries = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setJournalEntries(entries);
    } catch (error) {
      console.error('Error loading journal entries:', error);
    }
  };

  // Get suggestions based on past successful brews
  const getSuggestions = async (beanId) => {
    try {
      const q = query(
        collection(db, 'brewJournal'),
        where('userId', '==', user.uid),
        where('beanId', '==', beanId),
        where('rating', '>=', 8), // Only get highly rated brews
        orderBy('rating', 'desc'),
        orderBy('createdAt', 'desc')
      );

      const querySnapshot = await getDocs(q);
      const goodBrews = querySnapshot.docs.map(doc => doc.data());

      if (goodBrews.length > 0) {
        // Calculate average values from successful brews
        const avgSuggestions = {
          grindSetting: average(goodBrews.map(b => Number(b.grindSetting))),
          dose: average(goodBrews.map(b => Number(b.dose))),
          yield: average(goodBrews.map(b => Number(b.yield))),
          temperature: average(goodBrews.map(b => Number(b.temperature))),
          pressure: average(goodBrews.map(b => Number(b.pressure))),
          time: average(goodBrews.map(b => Number(b.time))),
        };
        setSuggestions(avgSuggestions);
      }
    } catch (error) {
      console.error('Error getting suggestions:', error);
    }
  };

  const average = (arr) => {
    return Math.round((arr.reduce((a, b) => a + b, 0) / arr.length) * 10) / 10;
  };

  // History drawer content
  const HistoryDrawer = () => {
    const controls = useAnimation();

    const handleDragEnd = (_, info) => {
      if (info.offset.x > 100) {
        onCloseHistory();
      } else {
        controls.start({ x: 0 });
      }
    };

    const tileContent = ({ date }) => {
      // Convert date to midnight for comparison
      const dateString = date.toDateString();
      const hasEntry = journalEntries.some(entry => {
        const entryDate = entry.createdAt?.toDate?.();
        return entryDate && entryDate.toDateString() === dateString;
      });

      return hasEntry ? <div className="dot" /> : null;
    };

    return (
      <Drawer
        isOpen={isHistoryOpen}
        placement="right"
        onClose={onCloseHistory}
        size={isMobile ? "full" : "md"}
      >
        <DrawerOverlay />
        <MotionDrawerContent
          bg="gray.900"
          drag={isMobile ? "x" : false}
          dragConstraints={{ left: 0, right: 100 }}
          dragElastic={0.7}
          onDragEnd={handleDragEnd}
          animate={controls}
          initial={{ x: 0 }}
        >
          <DrawerCloseButton color="gray.100" />
          <DrawerHeader color="gray.100">Brew History</DrawerHeader>
          <DrawerBody>
            <VStack spacing={4} align="stretch">
              <Box css={calendarStyles}>
                <Calendar
                  onChange={setSelectedDate}
                  value={selectedDate}
                  tileContent={tileContent}
                  tileClassName={({ date }) => {
                    const dateString = date.toDateString();
                    const hasEntry = journalEntries.some(entry => {
                      const entryDate = entry.createdAt?.toDate?.();
                      return entryDate && entryDate.toDateString() === dateString;
                    });
                    return hasEntry ? 'tile--hasEntry' : null;
                  }}
                />
              </Box>

              {/* Show entries for selected date */}
              {journalEntries
                .filter(entry => {
                  const entryDate = entry.createdAt?.toDate?.();
                  return entryDate && entryDate.toDateString() === selectedDate.toDateString();
                })
                .map((entry) => (
                  <Box
                    key={entry.id}
                    p={4}
                    borderWidth="1px"
                    borderRadius="lg"
                    borderColor="gray.700"
                    bg="gray.800"
                    shadow="dark-lg"
                    w="100%"
                    maxW="100%"
                  >
                    <VStack align="stretch" spacing={2}>
                      <HStack justify="space-between">
                        <Text fontWeight="bold" color="gray.100">{entry.beanName}</Text>
                        <Badge colorScheme={entry.rating >= 8 ? 'green' : 'gray'}>
                          {entry.rating}/10
                        </Badge>
                      </HStack>
                      <SimpleGrid columns={2} spacing={2}>
                        <Stat size="sm">
                          <StatLabel color="gray.400">Grind</StatLabel>
                          <StatNumber color="gray.100">{entry.grindSetting}</StatNumber>
                        </Stat>
                        <Stat size="sm">
                          <StatLabel color="gray.400">Dose</StatLabel>
                          <StatNumber color="gray.100">{entry.dose}g</StatNumber>
                        </Stat>
                        <Stat size="sm">
                          <StatLabel color="gray.400">Yield</StatLabel>
                          <StatNumber color="gray.100">{entry.yield}g</StatNumber>
                        </Stat>
                        <Stat size="sm">
                          <StatLabel color="gray.400">Time</StatLabel>
                          <StatNumber color="gray.100">{entry.time}s</StatNumber>
                        </Stat>
                      </SimpleGrid>
                      {entry.notes && (
                        <Text fontSize="sm" color="gray.400">
                          {entry.notes}
                        </Text>
                      )}
                    </VStack>
                  </Box>
                ))}
            </VStack>
          </DrawerBody>
        </MotionDrawerContent>
      </Drawer>
    );
  };

  return (
    <Box p={4} maxW="100%" mx="auto">
      <VStack spacing={6} align="stretch" w="100%">
        <HStack justify="space-between" w="100%" flexWrap="wrap" gap={2}>
          <Heading size="lg">Brew Journal</Heading>
          <HStack spacing={2} flexWrap="wrap">
            <Button
              leftIcon={<FaCalendar />}
              onClick={onOpenHistory}
              variant="outline"
              size={isMobile ? "sm" : "md"}
            >
              History
            </Button>
            <Button
              onClick={() => setMode('espresso')}
              colorScheme={mode === 'espresso' ? 'blue' : 'gray'}
              variant={mode === 'espresso' ? 'solid' : 'outline'}
              size={isMobile ? "sm" : "md"}
            >
              Espresso
            </Button>
            <Button
              onClick={() => setMode('filter')}
              colorScheme={mode === 'filter' ? 'blue' : 'gray'}
              variant={mode === 'filter' ? 'solid' : 'outline'}
              size={isMobile ? "sm" : "md"}
            >
              Filter
            </Button>
          </HStack>
        </HStack>

        <Box borderWidth="1px" borderRadius="lg" p={4} w="100%">
          <VStack spacing={4} align="stretch" w="100%">
            <HStack>
              <Button
                leftIcon={<FaSearch />}
                onClick={onOpen}
                colorScheme="blue"
                flex="1"
              >
                Select Coffee Bean
              </Button>
            </HStack>

            <SelectedCoffeeCard />

            {mode === 'espresso' ? (
              <>
                <SimpleGrid columns={2} spacing={4}>
                  <Box>
                    <Text mb={2}>Grind Setting</Text>
                    <NumberInput 
                      min={0} 
                      max={50}
                      value={formData.grindSetting}
                      onChange={(value) => handleChange({ name: 'grindSetting', value })}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Box>
                  <Box>
                    <Text mb={2}>Dose (g)</Text>
                    <NumberInput 
                      min={0} 
                      max={30} 
                      step={0.1}
                      value={formData.dose}
                      onChange={(value) => handleChange({ name: 'dose', value })}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Box>
                  <Box>
                    <Text mb={2}>Yield (g)</Text>
                    <NumberInput min={0} max={100} step={0.1}>
                      <NumberInputField
                        name="yield"
                        value={formData.yield}
                        onChange={(value) => handleChange({ target: { name: 'yield', value } })}
                      />
                    </NumberInput>
                  </Box>
                  <Box>
                    <Text mb={2}>Time (s)</Text>
                    <NumberInput min={0} max={60}>
                      <NumberInputField
                        name="time"
                        value={formData.time}
                        onChange={(value) => handleChange({ target: { name: 'time', value } })}
                      />
                    </NumberInput>
                  </Box>
                  <Box>
                    <Text mb={2}>Temperature (°C)</Text>
                    <NumberInput min={0} max={100}>
                      <NumberInputField
                        name="temperature"
                        value={formData.temperature}
                        onChange={(value) => handleChange({ target: { name: 'temperature', value } })}
                      />
                    </NumberInput>
                  </Box>
                  <Box>
                    <Text mb={2}>Pressure (bar)</Text>
                    <NumberInput min={0} max={15} step={0.1}>
                      <NumberInputField
                        name="pressure"
                        value={formData.pressure}
                        onChange={(value) => handleChange({ target: { name: 'pressure', value } })}
                      />
                    </NumberInput>
                  </Box>
                </SimpleGrid>
              </>
            ) : (
              <>
                <SimpleGrid columns={2} spacing={4}>
                  <Box>
                    <Text mb={2}>Grind Setting</Text>
                    <NumberInput min={0} max={50}>
                      <NumberInputField
                        name="grindSetting"
                        value={formData.grindSetting}
                        onChange={(value) => handleChange({ target: { name: 'grindSetting', value } })}
                      />
                    </NumberInput>
                  </Box>
                  <Box>
                    <Text mb={2}>Coffee (g)</Text>
                    <NumberInput min={0} max={100} step={0.1}>
                      <NumberInputField
                        name="dose"
                        value={formData.dose}
                        onChange={(value) => handleChange({ target: { name: 'dose', value } })}
                      />
                    </NumberInput>
                  </Box>
                  <Box>
                    <Text mb={2}>Water (ml)</Text>
                    <NumberInput min={0} max={1000}>
                      <NumberInputField
                        name="yield"
                        value={formData.yield}
                        onChange={(value) => handleChange({ target: { name: 'yield', value } })}
                      />
                    </NumberInput>
                  </Box>
                  <Box>
                    <Text mb={2}>Time (min:sec)</Text>
                    <Input
                      name="time"
                      value={formData.time}
                      onChange={handleChange}
                      placeholder="2:30"
                    />
                  </Box>
                  <Box>
                    <Text mb={2}>Temperature (°C)</Text>
                    <NumberInput min={0} max={100}>
                      <NumberInputField
                        name="temperature"
                        value={formData.temperature}
                        onChange={(value) => handleChange({ target: { name: 'temperature', value } })}
                      />
                    </NumberInput>
                  </Box>
                </SimpleGrid>
              </>
            )}

            <Box>
              <Text mb={2}>Notes</Text>
              <Textarea
                name="notes"
                value={formData.notes}
                onChange={handleChange}
                placeholder="Add your tasting notes, observations, or adjustments..."
              />
            </Box>

            <Box>
              <Text mb={2} color="white">Rating</Text>
              <Box pt={8} pb={4} position="relative" px={6}>
                <Slider
                  aria-label="rating"
                  defaultValue={5}
                  min={0}
                  max={10}
                  step={1}
                  value={formData.rating}
                  onChange={(value) => handleChange({ name: 'rating', value })}
                >
                  <SliderMark 
                    value={0} 
                    mt={3}
                    transform="translateX(-50%)"
                    fontSize="sm"
                    color="gray.400"
                  >
                    0
                  </SliderMark>
                  <SliderMark 
                    value={5} 
                    mt={3}
                    transform="translateX(-50%)"
                    fontSize="sm"
                    color="gray.400"
                  >
                    5
                  </SliderMark>
                  <SliderMark 
                    value={10} 
                    mt={3}
                    transform="translateX(-50%)"
                    fontSize="sm"
                    color="gray.400"
                  >
                    10
                  </SliderMark>
                  <SliderMark
                    value={formData.rating}
                    textAlign="center"
                    bg="blue.500"
                    color="white"
                    mt="-10"
                    w="8"
                    fontSize="sm"
                    borderRadius="md"
                    transform="translateX(-50%)"
                    position="absolute"
                    top="-4"
                  >
                    {formData.rating}
                  </SliderMark>
                  <SliderTrack bg="gray.700">
                    <SliderFilledTrack bg="blue.500" />
                  </SliderTrack>
                  <SliderThumb boxSize={4} />
                </Slider>
              </Box>
            </Box>

            <Button colorScheme="blue" onClick={handleSave}>
              Save Entry
            </Button>
          </VStack>
        </Box>

        {suggestions && (
          <Box p={4} bg="blue.50" borderRadius="md">
            <Text fontWeight="bold" mb={2}>Suggested Settings (Based on past success)</Text>
            <SimpleGrid columns={2} spacing={4}>
              <Text>Grind: {suggestions.grindSetting}</Text>
              <Text>Dose: {suggestions.dose}g</Text>
              <Text>Yield: {suggestions.yield}g</Text>
              <Text>Time: {suggestions.time}s</Text>
              <Text>Temp: {suggestions.temperature}°C</Text>
              <Text>Pressure: {suggestions.pressure}bar</Text>
            </SimpleGrid>
          </Box>
        )}

        <HistoryDrawer />
      </VStack>

      {/* Bean Selection Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Coffee Bean</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid columns={2} spacing={4} pb={4}>
              {coffeeInventory.map(bean => (
                <Box
                  key={bean.id}
                  p={4}
                  borderWidth="1px"
                  borderRadius="lg"
                  cursor="pointer"
                  onClick={() => handleBeanSelect(bean)}
                  _hover={{ bg: 'gray.50' }}
                >
                  <HStack>
                    {bean.photoURL ? (
                      <Image
                        src={bean.photoURL}
                        alt={bean.name}
                        boxSize="50px"
                        objectFit="cover"
                        borderRadius="md"
                      />
                    ) : (
                      <Icon as={FaCoffee} boxSize="50px" color="gray.300" />
                    )}
                    <VStack align="start" spacing={1}>
                      <Text fontWeight="bold">{bean.name}</Text>
                      <Text fontSize="sm" color="gray.500">
                        {bean.roaster}
                      </Text>
                      <Badge colorScheme="blue">{bean.roastLevel}</Badge>
                    </VStack>
                  </HStack>
                </Box>
              ))}
            </SimpleGrid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default EspressoJournal; 