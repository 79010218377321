import React, { useState } from 'react';
import { HStack, Icon } from '@chakra-ui/react';
import { FaStar } from 'react-icons/fa';

const StarRating = ({ rating, onChange, size = 24, isEditable = true }) => {
  const [hoverRating, setHoverRating] = useState(0);

  const handleClick = (index) => {
    if (isEditable && onChange) {
      onChange(index + 1);
    }
  };

  return (
    <HStack spacing={1}>
      {[...Array(5)].map((_, index) => (
        <Icon
          key={index}
          as={FaStar}
          boxSize={`${size}px`}
          color={index < (hoverRating || rating) ? "yellow.400" : "gray.300"}
          cursor={isEditable ? "pointer" : "default"}
          onClick={() => handleClick(index)}
          onMouseEnter={() => isEditable && setHoverRating(index + 1)}
          onMouseLeave={() => isEditable && setHoverRating(0)}
        />
      ))}
    </HStack>
  );
};

export default StarRating;
