import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { 
  Box, 
  Flex, 
  IconButton,
  Container,
  useColorMode,
  useBreakpointValue,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  VStack,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  HStack,
} from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import { FaHeart, FaSignOutAlt, FaUser, FaSignInAlt } from 'react-icons/fa';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import { useSidebar } from '../contexts/SidebarContext';
import { useAuth } from '../contexts/AuthContext';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useToast } from '@chakra-ui/react';
import LoginModal from './auth/LoginModal';

const Header = () => {
  const { user } = useAuth();
  const [scrollDirection, setScrollDirection] = useState('up');
  const [prevScroll, setPrevScroll] = useState(0);
  const { colorMode, toggleColorMode } = useColorMode();
  const { setIsSidebarOpen } = useSidebar();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const location = useLocation();
  const { isOpen: isLoginOpen, onOpen: onLoginOpen, onClose: onLoginClose } = useDisclosure();

  // Handle scroll behavior
  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll <= 0) {
        setScrollDirection('up');
        return;
      }

      if (Math.abs(currentScroll - prevScroll) < 10) {
        return;
      }

      if (currentScroll > prevScroll) {
        setScrollDirection('down');
      } else {
        setScrollDirection('up');
      }

      setPrevScroll(currentScroll);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScroll]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleProfileClick = () => {
    if (location.pathname === `/profile/${user.uid}`) {
      // If we're already on the profile page, just scroll to top
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate(`/profile/${user.uid}`);
    }
  };

  const handleDonate = () => {
    onOpen();
  };

  return (
    <>
      <Box 
        as="header" 
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex={1000}
        transform={{
          base: scrollDirection === 'down' ? 'translateY(-100%)' : 'translateY(0)',
          md: 'translateY(0)'
        }}
        transition="transform 0.3s ease"
        height={{ base: "calc(env(safe-area-inset-top) + 56px)", md: "72px" }}
        bg={colorMode === 'light' ? 'rgba(255, 255, 255, 0.9)' : 'rgba(26, 32, 44, 0.9)'}
        backdropFilter="blur(10px)"
        boxShadow="sm"
      >
        <Container 
          maxW="container.xl" 
          height="100%"
          pt={{ base: 'env(safe-area-inset-top)', md: 0 }}
          px={{ base: 3, md: 6 }}
        >
          <Flex alignItems="center" justifyContent="space-between" height="100%">
            {/* Logo Section */}
            <Link to="/" onClick={(e) => {
              if (location.pathname === '/') {
                e.preventDefault();
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }
            }}>
              <Box display="flex" alignItems="center">
                <img 
                  src="/beanscenelogo.png" 
                  alt="BeanScene Logo" 
                  style={{
                    height: isMobile ? '28px' : '35px',
                    width: 'auto',
                    objectFit: 'contain'
                  }}
                />
              </Box>
            </Link>

            {/* Right Section */}
            <HStack spacing={2}>
              <Button
                leftIcon={<FaHeart />}
                variant="ghost"
                size={isMobile ? "sm" : "md"}
                onClick={handleDonate}
                colorScheme="pink"
              >
                Donate
              </Button>

             
              {user ? (
                <Menu>
                  <MenuButton
                    as={IconButton}
                    icon={<FaUser />}
                    variant="ghost"
                    size={isMobile ? "sm" : "md"}
                  />
                  <MenuList zIndex={2000}>
                    <MenuItem 
                      icon={<FaUser />}
                      onClick={handleProfileClick}
                    >
                      Profile
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem 
                      icon={<FaSignOutAlt />}
                      onClick={handleLogout}
                    >
                      Sign Out
                    </MenuItem>
                  </MenuList>
                </Menu>
              ) : (
                <Button
                  leftIcon={<FaSignInAlt />}
                  onClick={onLoginOpen}
                  colorScheme="blue"
                  size={isMobile ? "sm" : "md"}
                  variant="ghost"
                >
                </Button>
              )}
            </HStack>
          </Flex>
        </Container>
      </Box>

      <LoginModal isOpen={isLoginOpen} onClose={onLoginClose} />

      {/* Donation Modal */}
      <Modal 
        isOpen={isOpen} 
        onClose={onClose}
        isCentered
      >
        <ModalOverlay backdropFilter="blur(10px)" />
        <ModalContent mx={4}>
          <ModalHeader>Support BeanScene</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack spacing={4} align="stretch">
              <Text>
                Help us keep BeanScene running and ad-free! Your support means the world to us.
              </Text>
              <Button
                as="a"
                href="https://www.paypal.com/paypalme/kaidetmers"
                target="_blank"
                rel="noopener noreferrer"
                colorScheme="blue"
                leftIcon={<FaHeart />}
                size="lg"
              >
                Donate via PayPal
              </Button>
              <Text fontSize="sm" color="gray.500">
                Your donation helps us maintain and improve BeanScene for the coffee community.
              </Text>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Header;
