import React, { useState, useEffect } from 'react';
import {
  Box,
  SimpleGrid,
  useColorModeValue,
  VStack,
  Text,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { collection, query, where, onSnapshot, orderBy } from 'firebase/firestore';
import { db } from '../firebase';
import CoffeeCard from './shared/CoffeeCard';
import AddCoffeeForm from './forms/AddCoffeeForm';
import StarRating from './shared/StarRating';

const BeanInventory = ({ userId, showAddButton = false, onCoffeeClick }) => {
  const [coffees, setCoffees] = useState([]);
  const [selectedCoffee, setSelectedCoffee] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bgColor = useColorModeValue('gray.50', 'gray.900');

  useEffect(() => {
    if (!userId) return;

    const q = query(
      collection(db, 'coffeeInventory'),
      where('userId', '==', userId),
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const coffeeData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      const activeCoffees = coffeeData.filter(coffee => 
        coffee.status === 'active' || !coffee.status
      );
      
      setCoffees(activeCoffees);
    }, (error) => {
      console.error("Error fetching coffees:", error);
    });

    return () => unsubscribe();
  }, [userId]);

  const handleCoffeeClick = (coffee) => {
    onCoffeeClick?.(coffee);
  };

  const handleFormClose = () => {
    setSelectedCoffee(null);
    onClose();
  };

  const handleFormSuccess = () => {
    handleFormClose();
  };

  return (
    <VStack 
      spacing={4} 
      align="stretch"
      bg="inherit"
      borderRadius="lg"
    >
      <Box bg={bgColor}>
        <VStack spacing={4} align="stretch">
          {showAddButton && (
            <Button
              onClick={() => {
                setSelectedCoffee(null);
                onOpen();
              }}
              colorScheme="blue"
              size="lg"
              mb={4}
            >
              Add New Coffee
            </Button>
          )}

          {coffees.length === 0 ? (
            <Text textAlign="center" color="gray.500">
              No coffees in your inventory yet.
            </Text>
          ) : (
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
              {coffees.map((coffee) => (
                <CoffeeCard
                  key={coffee.id}
                  coffee={coffee}
                  onClick={() => handleCoffeeClick(coffee)}
                />
              ))}
            </SimpleGrid>
          )}

          <AddCoffeeForm
            isOpen={isOpen}
            onClose={handleFormClose}
            onSuccess={handleFormSuccess}
            editCoffee={selectedCoffee}
          />
        </VStack>
      </Box>
    </VStack>
  );
};

export default BeanInventory;
