import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Image,
  useColorModeValue,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';

const CodeOfConduct = () => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <Container maxW="container.md" py={8}>
      <VStack spacing={8} align="stretch">
        <Box 
          bg={bgColor} 
          p={8} 
          borderRadius="xl" 
          boxShadow="sm"
        >
          <VStack spacing={6} align="stretch">
            <Image
              src="/beanscenelogo.png"
              alt="BeanScene Logo"
              height="60px"
              objectFit="contain"
              mx="auto"
              mb={4}
            />
            
            <Heading size="lg" textAlign="center">Community Guidelines</Heading>
            
            <Text color={textColor}>
              At BeanScene, we're committed to fostering a welcoming, inclusive, and 
              respectful community for all coffee enthusiasts. These guidelines help 
              ensure everyone can participate in meaningful discussions and share their 
              coffee journey safely.
            </Text>

            <Heading size="md">Core Values</Heading>
            <UnorderedList spacing={2} color={textColor}>
              <ListItem>Be respectful and inclusive of all community members</ListItem>
              <ListItem>Share authentic and honest experiences</ListItem>
              <ListItem>Support and encourage fellow coffee enthusiasts</ListItem>
              <ListItem>Contribute to constructive discussions</ListItem>
            </UnorderedList>

            <Heading size="md">Unacceptable Behavior</Heading>
            <UnorderedList spacing={2} color={textColor}>
              <ListItem>Harassment or discrimination of any kind</ListItem>
              <ListItem>Hate speech or offensive content</ListItem>
              <ListItem>Spam or self-promotion without community value</ListItem>
              <ListItem>Intentionally false or misleading information</ListItem>
              <ListItem>Personal attacks or trolling</ListItem>
            </UnorderedList>

            <Heading size="md">Content Guidelines</Heading>
            <UnorderedList spacing={2} color={textColor}>
              <ListItem>Share genuine experiences and reviews</ListItem>
              <ListItem>Respect intellectual property rights</ListItem>
              <ListItem>Keep discussions coffee-related and on-topic</ListItem>
              <ListItem>Use appropriate language and tone</ListItem>
              <ListItem>Credit sources when sharing others' content</ListItem>
            </UnorderedList>

            <Heading size="md">Enforcement</Heading>
            <Text color={textColor}>
              Violations of these guidelines may result in content removal, temporary 
              suspension, or permanent account termination. We review each case 
              individually and take appropriate action based on the severity and 
              frequency of violations.
            </Text>

            <Heading size="md">Reporting</Heading>
            <Text color={textColor}>
              If you encounter content or behavior that violates these guidelines, 
              please use the report function or contact us directly. We take all 
              reports seriously and will investigate promptly while maintaining 
              reporter confidentiality.
            </Text>

            <Text fontSize="sm" color={textColor} fontStyle="italic">
              These guidelines may be updated periodically. Users will be notified 
              of significant changes. Continued use of BeanScene constitutes 
              acceptance of these guidelines.
            </Text>
          </VStack>
        </Box>
      </VStack>
    </Container>
  );
};

export default CodeOfConduct; 