import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  Text,
  Image,
  Badge,
  useColorModeValue,
  Flex,
  useToast,
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import confetti from 'canvas-confetti';

const MotionBox = motion(Box);

// Define the glow animation using emotion's css
const glowAnimation = css`
  @keyframes glow {
    0% { box-shadow: 0 0 10px #4299E1, 0 0 20px #4299E1, 0 0 30px #4299E1; }
    50% { box-shadow: 0 0 20px #4299E1, 0 0 30px #4299E1, 0 0 40px #4299E1; }
    100% { box-shadow: 0 0 10px #4299E1, 0 0 20px #4299E1, 0 0 30px #4299E1; }
  }
  animation: glow 2s infinite;
`;

const BadgeClaim = () => {
  const { badgeId } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const [badge, setBadge] = useState(null);
  const [claimed, setClaimed] = useState(false);
  const bgColor = useColorModeValue('white', 'gray.800');

  useEffect(() => {
    const fetchBadge = async () => {
      try {
        const badgeDoc = await getDoc(doc(db, 'badges', badgeId));
        if (badgeDoc.exists()) {
          setBadge(badgeDoc.data());
          // Check if user already has this badge
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          const userData = userDoc.data();
          if (userData?.badges?.includes(badgeId)) {
            setClaimed(true);
          } else {
            // Claim the badge
            await claimBadge();
          }
        } else {
          toast({
            title: "Badge not found",
            status: "error",
            duration: 3000,
          });
          setTimeout(() => navigate('/'), 3000);
        }
      } catch (error) {
        console.error("Error fetching badge:", error);
        toast({
          title: "Error fetching badge",
          status: "error",
          duration: 3000,
        });
      }
    };

    if (user && badgeId) {
      fetchBadge();
    }
  }, [user, badgeId]);

  const claimBadge = async () => {
    try {
      await updateDoc(doc(db, 'users', user.uid), {
        badges: arrayUnion(badgeId)
      });
      setClaimed(true);
      
      // Trigger confetti effect
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
      });

      toast({
        title: "Badge Claimed!",
        description: "New badge added to your collection!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Redirect to profile after delay
      setTimeout(() => {
        navigate(`/profile/${user.uid}`);
      }, 3000);
    } catch (error) {
      console.error("Error claiming badge:", error);
      toast({
        title: "Error claiming badge",
        status: "error",
        duration: 3000,
      });
    }
  };

  if (!badge) return null;

  return (
    <Flex
      minH="100vh"
      align="center"
      justify="center"
      p={4}
      bg={useColorModeValue('gray.50', 'gray.900')}
    >
      <MotionBox
        initial={{ scale: 0.5, opacity: 0 }}
        animate={{ 
          scale: 1, 
          opacity: 1,
          transition: { 
            type: "spring",
            stiffness: 260,
            damping: 20 
          }
        }}
        bg={bgColor}
        p={8}
        borderRadius="xl"
        boxShadow="xl"
        maxW="sm"
        w="full"
        textAlign="center"
        position="relative"
        css={glowAnimation}
      >
        <VStack spacing={6}>
          <MotionBox
            animate={{
              scale: [1, 1.1, 1],
              rotate: [0, 10, -10, 0],
            }}
            transition={{
              duration: 0.5,
              ease: "easeInOut",
              times: [0, 0.5, 1],
              repeat: 0,
            }}
          >
            <Image
              src={badge.imageUrl}
              alt={badge.name}
              boxSize="150px"
              objectFit="contain"
              borderRadius="full"
              border="4px solid"
              borderColor="blue.400"
            />
          </MotionBox>

          <Badge
            colorScheme="green"
            fontSize="lg"
            p={2}
            borderRadius="full"
            textTransform="uppercase"
            letterSpacing="wider"
          >
            {claimed ? "Badge Claimed!" : "Claiming Badge..."}
          </Badge>

          <Text fontSize="2xl" fontWeight="bold">
            {badge.name}
          </Text>

          {badge.description && (
            <Text color="gray.500">
              {badge.description}
            </Text>
          )}

          <Text fontSize="sm" color="blue.500" fontWeight="medium">
            Added to your collection!
          </Text>
        </VStack>
      </MotionBox>
    </Flex>
  );
};

export default BadgeClaim;
