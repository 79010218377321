import React from 'react';
import {
  Box,
  Skeleton,
  SkeletonCircle,
  VStack,
  HStack,
  useColorModeValue,
} from '@chakra-ui/react';

const ProfileSkeleton = () => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <Box py={8}>
      <Box
        bg={bgColor}
        borderRadius="xl"
        borderWidth="1px"
        borderColor={borderColor}
        overflow="hidden"
      >
        {/* Cover Photo Skeleton */}
        <Skeleton height="200px" />

        {/* Profile Info */}
        <Box p={6} position="relative">
          {/* Avatar */}
          <SkeletonCircle 
            size="24" 
            position="absolute"
            top="-48px"
            left={{ base: '50%', md: '8' }}
            transform={{ base: 'translateX(-50%)', md: 'translateX(0)' }}
          />

          {/* User Info */}
          <VStack
            align={{ base: 'center', md: 'start' }}
            spacing={4}
            mt={{ base: 12, md: 0 }}
            ml={{ base: 0, md: '160px' }}
          >
            <Skeleton height="24px" width="200px" />
            <Skeleton height="16px" width="150px" />
            <Skeleton height="40px" width="300px" />
          </VStack>
        </Box>

        {/* Stats */}
        <HStack justify="center" spacing={8} p={4} borderTopWidth="1px" borderColor={borderColor}>
          {[...Array(3)].map((_, i) => (
            <VStack key={i} spacing={1}>
              <Skeleton height="24px" width="40px" />
              <Skeleton height="16px" width="60px" />
            </VStack>
          ))}
        </HStack>
      </Box>

      {/* Content Skeleton */}
      <VStack spacing={4} mt={6} px={4}>
        {[...Array(3)].map((_, i) => (
          <Skeleton key={i} height="200px" width="100%" borderRadius="xl" />
        ))}
      </VStack>
    </Box>
  );
};

export default ProfileSkeleton; 