import React, { useState } from 'react';
import {
  Box,
  Flex,
  Avatar,
  Text,
  IconButton,
  useColorModeValue,
  Button,
  VStack,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
} from '@chakra-ui/react';
import { FaCamera, FaCog, FaUserEdit, FaSignOutAlt, FaCoffee, FaStar, FaUserFriends } from 'react-icons/fa';
import { motion } from 'framer-motion';
import ImageUpload from '../../shared/ImageUpload';
import { useNavigate } from 'react-router-dom';

const MotionBox = motion(Box);

const StatsDisplay = ({ stats }) => {
  const statItems = [
    { label: 'Posts', value: stats?.posts || 0, icon: FaCoffee },
    { label: 'Reviews', value: stats?.reviews || 0, icon: FaStar },
    { label: 'Following', value: stats?.following || 0, icon: FaUserFriends },
  ];

  return (
    <HStack spacing={8} mt={4} justify="center">
      {statItems.map((item) => (
        <VStack 
          key={item.label} 
          spacing={0} 
          align="center"
          minW="60px"
        >
          <Text fontWeight="bold" fontSize="lg" color="white">
            {item.value}
          </Text>
          <Text color="gray.400" fontSize="sm">
            {item.label}
          </Text>
        </VStack>
      ))}
    </HStack>
  );
};

const ProfileHeader = ({
  user,
  currentUser,
  isOwnProfile,
  onImageUpdate,
  stats,
  onEdit,
}) => {
  const [isEditingCover, setIsEditingCover] = useState(false);
  const bgColor = useColorModeValue('gray.900', 'gray.900');
  const navigate = useNavigate();

  const handleCoverPhotoUpdate = async (files) => {
    if (!onImageUpdate || !files || !files.length) return;
    
    try {
      await onImageUpdate(files[0].file, 'cover');
      setIsEditingCover(false);
    } catch (error) {
      console.error('Error updating cover photo:', error);
    }
  };

  const handleAvatarUpdate = async (event) => {
    const file = event.target.files?.[0];
    if (!file || !onImageUpdate) return;

    try {
      await onImageUpdate(file, 'avatar');
    } catch (error) {
      console.error('Error updating avatar:', error);
    }
  };

  const handleEditClick = () => {
    if (isOwnProfile) {
      navigate('/edit-profile');
    }
  };

  return (
    <Box position="relative" bg={bgColor}>
      {/* Cover Photo */}
      <Box
        h="150px"
        bg="orange.600"
        position="relative"
        overflow="hidden"
      >
        {user?.coverPhotoURL && (
          <Box
            as="img"
            src={user.coverPhotoURL}
            alt="Cover"
            w="100%"
            h="100%"
            objectFit="cover"
            objectPosition="center 30%"
            transform="scale(1.2)"
            style={{
              transformOrigin: 'center center'
            }}
          />
        )}
        {isOwnProfile && (
          <IconButton
            icon={<FaCamera />}
            position="absolute"
            top={4}
            right={4}
            colorScheme="whiteAlpha"
            size="sm"
            aria-label="Change cover photo"
            onClick={() => setIsEditingCover(true)}
          />
        )}
      </Box>

      {/* Profile Info */}
      <Box px={6} pb={6}>
        {/* Avatar */}
        <Box position="relative" mt="-50px" mb={4}>
          <Avatar
            size="xl"
            src={user?.photoURL}
            name={user?.displayName}
            border="4px solid"
            borderColor={bgColor}
          />
          {isOwnProfile && (
            <IconButton
              icon={<FaCamera />}
              size="sm"
              colorScheme="gray"
              rounded="full"
              position="absolute"
              bottom={0}
              right={0}
              onClick={() => document.getElementById('avatarInput').click()}
            />
          )}
          <input
            id="avatarInput"
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={(e) => handleAvatarUpdate(e)}
          />
        </Box>

        {/* User Info */}
        <VStack align="start" spacing={1}>
          <Flex w="100%" justify="space-between" align="center">
            <Text fontSize="2xl" fontWeight="bold" color="white">
              {user?.displayName}
            </Text>
            {isOwnProfile && (
              <Button
                leftIcon={<FaUserEdit />}
                variant="outline"
                size="sm"
                onClick={handleEditClick}
                colorScheme="blue"
              >
                Edit Profile
              </Button>
            )}
          </Flex>
          <Text color="gray.400">@{user?.username}</Text>
        </VStack>

        {/* Stats */}
        <StatsDisplay stats={stats} />
      </Box>

      {/* Image Upload Modal for Cover Photo */}
      {isEditingCover && (
        <Box
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg="rgba(0,0,0,0.8)"
          zIndex={1000}
          p={4}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box maxW="md" w="100%">
            <ImageUpload
              onUpload={handleCoverPhotoUpdate}
              onCancel={() => setIsEditingCover(false)}
              ratio={16/6}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ProfileHeader; 