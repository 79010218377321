import React, { useState, useEffect } from 'react';
import {
  SimpleGrid,
  Box,
  Image,
  Text,
  VStack,
  useColorModeValue,
  Skeleton,
} from '@chakra-ui/react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../../firebase';

export const BadgesTab = ({ profileUser }) => {
  const [badges, setBadges] = useState([]);
  const [loading, setLoading] = useState(true);
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  useEffect(() => {
    const fetchBadges = async () => {
      if (!profileUser?.badges?.length) {
        setLoading(false);
        return;
      }

      try {
        const badgePromises = profileUser.badges.map(async (badgeId) => {
          const badgeDoc = await getDoc(doc(db, 'badges', badgeId));
          if (badgeDoc.exists()) {
            return {
              id: badgeDoc.id,
              ...badgeDoc.data(),
              earnedDate: profileUser.badgeEarnDates?.[badgeId] || null
            };
          }
          return null;
        });

        const fetchedBadges = (await Promise.all(badgePromises))
          .filter(badge => badge !== null);
        setBadges(fetchedBadges);
      } catch (error) {
        console.error('Error fetching badges:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBadges();
  }, [profileUser]);

  if (loading) {
    return (
      <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} spacing={4}>
        {[1, 2, 3, 4].map((i) => (
          <Skeleton key={i} height="200px" borderRadius="lg" />
        ))}
      </SimpleGrid>
    );
  }

  if (!badges.length) {
    return (
      <Box 
        textAlign="center" 
        p={8} 
        bg={bgColor} 
        borderRadius="lg"
        borderWidth="1px"
        borderColor={borderColor}
      >
        <Text color="gray.500">No badges earned yet</Text>
      </Box>
    );
  }

  return (
    <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} spacing={4}>
      {badges.map((badge) => (
        <Box
          key={badge.id}
          p={4}
          bg={bgColor}
          borderRadius="lg"
          borderWidth="1px"
          borderColor={borderColor}
          textAlign="center"
        >
          <VStack spacing={3}>
            <Image
              src={badge.imageUrl}
              alt={badge.name}
              boxSize="80px"
              objectFit="contain"
              fallbackSrc="/default-badge.png"
            />
            <Text fontWeight="bold">{badge.name}</Text>
            <Text fontSize="sm" color="gray.500">
              {badge.description}
            </Text>
            {badge.earnedDate && (
              <Text fontSize="xs" color="gray.500">
                Earned {new Date(badge.earnedDate).toLocaleDateString()}
              </Text>
            )}
          </VStack>
        </Box>
      ))}
    </SimpleGrid>
  );
}; 