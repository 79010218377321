import React from 'react';
import { 
  VStack, 
  Text, 
  useColorModeValue,
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import CoffeePost from '../../../CoffeePost';

const MotionVStack = motion(VStack);

const ReviewsTab = ({ posts = [], isOwnProfile }) => {
  const emptyStateColor = useColorModeValue('gray.500', 'gray.400');

  if (!posts?.length) {
    return (
      <Text 
        color={emptyStateColor} 
        textAlign="center" 
        py={8}
        fontStyle="italic"
      >
        {isOwnProfile 
          ? "You haven't written any reviews yet." 
          : "No reviews yet."}
      </Text>
    );
  }

  return (
    <AnimatePresence>
      <MotionVStack 
        spacing={6} 
        align="stretch"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {posts.map((review, index) => (
          <motion.div
            key={review.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <CoffeePost post={review} />
          </motion.div>
        ))}
      </MotionVStack>
    </AnimatePresence>
  );
};

export default ReviewsTab; 