import React, { createContext, useContext, useState } from 'react';
import { useAuth } from './AuthContext';

const ProfileContext = createContext();

export function ProfileProvider({ children }) {
  const { user } = useAuth();
  const [viewMode, setViewMode] = useState('grid');
  const [isPublicView, setIsPublicView] = useState(false);

  const isOwnProfile = (profileId) => {
    return user?.uid === profileId;
  };

  const getProfileAccess = (profileId) => {
    if (!user) return 'public';
    if (isOwnProfile(profileId)) return 'owner';
    return 'user';
  };

  const value = {
    viewMode,
    setViewMode,
    isPublicView,
    setIsPublicView,
    isOwnProfile,
    getProfileAccess,
  };

  return (
    <ProfileContext.Provider value={value}>
      {children}
    </ProfileContext.Provider>
  );
}

export function useProfile() {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
} 