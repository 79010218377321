import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  HStack,
  Button,
  Text,
  Box,
  Image,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  useToast,
  IconButton,
  useColorModeValue,
  Flex,
  Center,
  Icon,
} from '@chakra-ui/react';
import { FaEdit, FaTrash, FaCamera } from 'react-icons/fa';
import { doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase';
import { ImageUpload } from './shared/ImageUpload';

const EQUIPMENT_TYPES = {
  brewing: [
    'Espresso Machine',
    'Coffee Maker',
    'French Press',
    'Pour Over',
    'AeroPress',
    'Moka Pot',
    'Cold Brew',
    'Siphon',
    'Other'
  ],
  accessories: [
    'Grinder',
    'Scale',
    'Kettle',
    'Filter',
    'Tamper',
    'Milk Pitcher',
    'Other'
  ]
};

const EquipmentDetails = ({ equipment, isOpen, onClose, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editedEquipment, setEditedEquipment] = useState(equipment);
  const toast = useToast();
  const bgColor = useColorModeValue('white', 'gray.800');

  const handleImageUpload = async (file) => {
    try {
      setLoading(true);
      const storageRef = ref(storage, `equipment/${equipment.id}/${Date.now()}_${file.name}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      
      await updateDoc(doc(db, 'equipmentInventory', equipment.id), {
        photoURL: url
      });

      setEditedEquipment(prev => ({ ...prev, photoURL: url }));
      onUpdate();
      
      toast({
        title: 'Image updated',
        status: 'success',
        duration: 2000,
      });
    } catch (error) {
      console.error('Error uploading image:', error);
      toast({
        title: 'Error uploading image',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await updateDoc(doc(db, 'equipmentInventory', equipment.id), {
        ...editedEquipment,
        updatedAt: new Date()
      });

      toast({
        title: 'Equipment updated',
        status: 'success',
        duration: 2000,
      });

      onUpdate();
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating equipment:', error);
      toast({
        title: 'Error updating equipment',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this equipment?')) {
      try {
        await deleteDoc(doc(db, 'equipmentInventory', equipment.id));
        toast({
          title: 'Equipment deleted',
          status: 'success',
          duration: 2000,
        });
        onClose();
        onUpdate();
      } catch (error) {
        console.error('Error deleting equipment:', error);
        toast({
          title: 'Error deleting equipment',
          status: 'error',
          duration: 3000,
        });
      }
    }
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      size="full"
      motionPreset="slideInBottom"
    >
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent 
        margin={0} 
        rounded="none"
        height="100vh"
        overflow="auto"
        position="fixed"
        top={0}
        right={0}
        bottom={0}
        left={0}
      >
        <ModalHeader
          borderBottom="1px solid"
          borderColor="whiteAlpha.200"
          py={4}
          position="sticky"
          top={0}
          bg={bgColor}
          zIndex={10}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {isEditing ? (
            <>
              <Button variant="ghost" onClick={() => setIsEditing(false)}>
                Cancel
              </Button>
              <Text fontWeight="semibold">Edit Equipment</Text>
              <Button
                colorScheme="blue"
                onClick={handleSave}
                isLoading={loading}
              >
                Save
              </Button>
            </>
          ) : (
            <>
              <Button variant="ghost" onClick={onClose}>
                Close
              </Button>
              <Text fontWeight="semibold">{equipment.name}</Text>
              <HStack spacing={2}>
                <IconButton
                  icon={<FaEdit />}
                  onClick={() => setIsEditing(true)}
                  variant="ghost"
                  aria-label="Edit equipment"
                />
                <IconButton
                  icon={<FaTrash />}
                  onClick={handleDelete}
                  colorScheme="red"
                  variant="ghost"
                  aria-label="Delete equipment"
                />
              </HStack>
            </>
          )}
        </ModalHeader>

        <ModalBody p={0}>
          <VStack spacing={6} w="full" p={4} pb={20}>
            {/* Equipment Image */}
            <Box 
              w="full" 
              position="relative"
              borderRadius="xl"
              overflow="hidden"
              bg="gray.900"
            >
              {editedEquipment.photoURL ? (
                <Image
                  src={editedEquipment.photoURL}
                  alt={editedEquipment.name}
                  w="full"
                  h="250px"
                  objectFit="cover"
                  borderRadius="xl"
                />
              ) : (
                <Center 
                  h="250px" 
                  bg="gray.800" 
                  borderRadius="xl"
                  flexDirection="column"
                  gap={2}
                >
                  <Icon as={FaCamera} boxSize={8} color="gray.500" />
                  <Text color="gray.500" fontSize="sm">No image available</Text>
                </Center>
              )}

              {isEditing && (
                <Button
                  position="absolute"
                  top={4}
                  right={4}
                  size="sm"
                  colorScheme="blackAlpha"
                  bg="blackAlpha.600"
                  _hover={{ bg: 'blackAlpha.700' }}
                  leftIcon={<FaCamera />}
                  onClick={() => document.getElementById('equipment-photo').click()}
                >
                  Change Photo
                </Button>
              )}
              <input
                id="equipment-photo"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ display: 'none' }}
              />
            </Box>

            {isEditing ? (
              <VStack spacing={4} width="100%">
                <HStack width="full" spacing={4}>
                  <FormControl isRequired flex={1}>
                    <FormLabel>Name</FormLabel>
                    <Input
                      value={editedEquipment.name}
                      onChange={(e) => setEditedEquipment(prev => ({
                        ...prev,
                        name: e.target.value
                      }))}
                    />
                  </FormControl>

                  <FormControl isRequired flex={1}>
                    <FormLabel>Manufacturer</FormLabel>
                    <Input
                      value={editedEquipment.manufacturer}
                      onChange={(e) => setEditedEquipment(prev => ({
                        ...prev,
                        manufacturer: e.target.value
                      }))}
                    />
                  </FormControl>
                </HStack>

                <HStack width="full" spacing={4}>
                  <FormControl flex={1}>
                    <FormLabel>Category</FormLabel>
                    <Select
                      value={editedEquipment.category}
                      onChange={(e) => setEditedEquipment(prev => ({
                        ...prev,
                        category: e.target.value,
                        type: ''
                      }))}
                    >
                      <option value="brewing">Brewing Equipment</option>
                      <option value="accessories">Accessories</option>
                    </Select>
                  </FormControl>

                  <FormControl flex={1}>
                    <FormLabel>Type</FormLabel>
                    <Select
                      value={editedEquipment.type}
                      onChange={(e) => setEditedEquipment(prev => ({
                        ...prev,
                        type: e.target.value
                      }))}
                    >
                      <option value="">Select type</option>
                      {EQUIPMENT_TYPES[editedEquipment.category]?.map(type => (
                        <option key={type} value={type}>{type}</option>
                      ))}
                    </Select>
                  </FormControl>
                </HStack>

                <HStack width="full" spacing={4}>
                  <FormControl flex={1}>
                    <FormLabel>Purchase Date</FormLabel>
                    <Input
                      type="date"
                      value={editedEquipment.purchaseDate}
                      onChange={(e) => setEditedEquipment(prev => ({
                        ...prev,
                        purchaseDate: e.target.value
                      }))}
                    />
                  </FormControl>

                  <FormControl flex={1}>
                    <FormLabel>Price</FormLabel>
                    <Input
                      type="number"
                      value={editedEquipment.price}
                      onChange={(e) => setEditedEquipment(prev => ({
                        ...prev,
                        price: e.target.value
                      }))}
                    />
                  </FormControl>
                </HStack>

                <FormControl>
                  <FormLabel>Notes</FormLabel>
                  <Textarea
                    value={editedEquipment.notes}
                    onChange={(e) => setEditedEquipment(prev => ({
                      ...prev,
                      notes: e.target.value
                    }))}
                    rows={4}
                  />
                </FormControl>
              </VStack>
            ) : (
              <VStack align="stretch" spacing={6} width="100%">
                <HStack justify="space-between">
                  <Box>
                    <Text color="gray.500" fontSize="sm">Manufacturer</Text>
                    <Text fontSize="lg">{equipment.manufacturer}</Text>
                  </Box>
                  <Box textAlign="right">
                    <Text color="gray.500" fontSize="sm">Type</Text>
                    <Text fontSize="lg">{equipment.type}</Text>
                  </Box>
                </HStack>

                <HStack justify="space-between">
                  <Box>
                    <Text color="gray.500" fontSize="sm">Purchase Date</Text>
                    <Text fontSize="lg">
                      {new Date(equipment.purchaseDate).toLocaleDateString()}
                    </Text>
                  </Box>
                  <Box textAlign="right">
                    <Text color="gray.500" fontSize="sm">Price</Text>
                    <Text fontSize="lg">${equipment.price}</Text>
                  </Box>
                </HStack>

                {equipment.notes && (
                  <Box>
                    <Text color="gray.500" fontSize="sm" mb={2}>Notes</Text>
                    <Box 
                      p={4} 
                      bg="gray.50" 
                      _dark={{ bg: 'gray.800' }} 
                      borderRadius="lg"
                    >
                      <Text>{equipment.notes}</Text>
                    </Box>
                  </Box>
                )}
              </VStack>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EquipmentDetails;
