import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { db } from '../firebase';
import { useToast } from '@chakra-ui/react';

export const useBrewCheck = (user) => {
  const [todaysPosts, setTodaysPosts] = useState([]);
  const [yesterdayWinner, setYesterdayWinner] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasPostedToday, setHasPostedToday] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const toast = useToast();

  // Get today's start and end timestamps
  const getTodayRange = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return { today, tomorrow };
  };

  // Get yesterday's start and end timestamps
  const getYesterdayRange = () => {
    const { today } = getTodayRange();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    return { yesterday, today };
  };

  // Fetch today's posts with proper caching
  const fetchTodaysPosts = async () => {
    try {
      const { today, tomorrow } = getTodayRange();
      
      const q = query(
        collection(db, 'posts'),
        where('type', '==', 'coffeeOfTheDay'),
        where('timestamp', '>=', today),
        where('timestamp', '<', tomorrow),
        orderBy('timestamp', 'desc')
      );

      const snapshot = await getDocs(q);
      const posts = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate()
      }));

      setTodaysPosts(posts);
      setHasPostedToday(user ? posts.some(post => post.userId === user.uid) : false);
    } catch (error) {
      console.error('Error fetching posts:', error);
      toast({
        title: 'Error fetching posts',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  // Fetch yesterday's winner with caching
  const fetchYesterdayWinner = async () => {
    try {
      const { yesterday, today } = getYesterdayRange();
      
      const q = query(
        collection(db, 'posts'),
        where('type', '==', 'coffeeOfTheDay'),
        where('timestamp', '>=', yesterday),
        where('timestamp', '<', today),
        orderBy('votes', 'desc'),
        limit(1)
      );

      const snapshot = await getDocs(q);
      if (!snapshot.empty) {
        const winnerData = {
          id: snapshot.docs[0].id,
          ...snapshot.docs[0].data(),
          timestamp: snapshot.docs[0].data().timestamp?.toDate()
        };
        setYesterdayWinner(winnerData);
      }
    } catch (error) {
      console.error('Error fetching yesterday\'s winner:', error);
    }
  };

  // Update time left
  const updateTimeLeft = () => {
    const now = new Date();
    const endOfDay = new Date();
    endOfDay.setHours(23, 59, 59, 999);
    
    const diff = endOfDay - now;
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    
    setTimeLeft({ hours, minutes });
  };

  // Initialize timers and fetch data
  useEffect(() => {
    fetchTodaysPosts();
    fetchYesterdayWinner();
    updateTimeLeft();

    const timeInterval = setInterval(updateTimeLeft, 60000);
    const fetchInterval = setInterval(fetchTodaysPosts, 300000); // Refresh every 5 minutes

    return () => {
      clearInterval(timeInterval);
      clearInterval(fetchInterval);
    };
  }, [user]);

  return {
    todaysPosts,
    yesterdayWinner,
    loading,
    hasPostedToday,
    timeLeft,
    refetchPosts: fetchTodaysPosts
  };
}; 