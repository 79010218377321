import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Image,
  useColorModeValue,
  UnorderedList,
  ListItem,
  Link,
} from '@chakra-ui/react';

const Privacy = () => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <Container maxW="container.md" py={8}>
      <VStack spacing={8} align="stretch">
        <Box 
          bg={bgColor} 
          p={8} 
          borderRadius="xl" 
          boxShadow="sm"
        >
          <VStack spacing={6} align="stretch">
            <Image
              src="/beanscenelogo.png"
              alt="BeanScene Logo"
              height="60px"
              objectFit="contain"
              mx="auto"
              mb={4}
            />
            
            <Heading size="lg" textAlign="center">Privacy Policy</Heading>
            
            <Text color={textColor}>
              Last updated: {new Date().toLocaleDateString()}
            </Text>

            <Text color={textColor}>
              At BeanScene, we take your privacy seriously. This policy outlines how 
              we collect, use, and protect your personal information when you use 
              our platform.
            </Text>

            <Heading size="md">Information We Collect</Heading>
            <UnorderedList spacing={2} color={textColor}>
              <ListItem>Account information (name, email, profile picture)</ListItem>
              <ListItem>User-generated content (posts, reviews, comments)</ListItem>
              <ListItem>Usage data (interactions, preferences)</ListItem>
              <ListItem>Device information (browser type, IP address)</ListItem>
              <ListItem>Location data (if permitted)</ListItem>
            </UnorderedList>

            <Heading size="md">How We Use Your Information</Heading>
            <UnorderedList spacing={2} color={textColor}>
              <ListItem>Provide and improve our services</ListItem>
              <ListItem>Personalize your experience</ListItem>
              <ListItem>Process your transactions</ListItem>
              <ListItem>Send service updates and notifications</ListItem>
              <ListItem>Analyze usage patterns and trends</ListItem>
              <ListItem>Prevent fraud and ensure security</ListItem>
            </UnorderedList>

            <Heading size="md">Data Storage and Security</Heading>
            <Text color={textColor}>
              We use industry-standard security measures to protect your data. Your 
              information is stored on secure servers and we regularly review our 
              security practices. We use Firebase for data storage and authentication, 
              which provides additional security measures.
            </Text>

            <Heading size="md">Third-Party Services</Heading>
            <Text color={textColor}>
              We use trusted third-party services for specific functions:
            </Text>
            <UnorderedList spacing={2} color={textColor}>
              <ListItem>Google Firebase (authentication and data storage)</ListItem>
              <ListItem>Google Analytics (usage analysis)</ListItem>
              <ListItem>Payment processors (when applicable)</ListItem>
            </UnorderedList>

            <Heading size="md">Your Rights</Heading>
            <Text color={textColor}>
              You have the right to:
            </Text>
            <UnorderedList spacing={2} color={textColor}>
              <ListItem>Access your personal data</ListItem>
              <ListItem>Correct inaccurate data</ListItem>
              <ListItem>Request data deletion</ListItem>
              <ListItem>Opt-out of marketing communications</ListItem>
              <ListItem>Export your data</ListItem>
            </UnorderedList>

            <Heading size="md">Cookies and Tracking</Heading>
            <Text color={textColor}>
              We use cookies and similar technologies to improve user experience and 
              collect usage data. You can control cookie settings through your browser 
              preferences.
            </Text>

            <Heading size="md">Children's Privacy</Heading>
            <Text color={textColor}>
              BeanScene is not intended for users under 13 years of age. We do not 
              knowingly collect information from children under 13.
            </Text>

            <Heading size="md">Contact Us</Heading>
            <Text color={textColor}>
              If you have questions about this privacy policy or your data, please 
              contact us at:{' '}
              <Link href="mailto:privacy@beanscene.de" color="blue.500">
                privacy@beanscene.de
              </Link>
            </Text>

            <Text fontSize="sm" color={textColor} fontStyle="italic">
              This privacy policy may be updated periodically. We will notify users 
              of significant changes via email or app notifications.
            </Text>
          </VStack>
        </Box>
      </VStack>
    </Container>
  );
};

export default Privacy; 