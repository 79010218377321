import React, { useState } from 'react';
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  arrayUnion,
  getDoc,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import {
  useToast,
  VStack,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useBreakpointValue,
  Box,
  Text,
  HStack,
  IconButton,
  Tag,
  TagLabel,
  TagCloseButton,
  useColorModeValue,
  Image,
  AspectRatio,
  Switch,
} from '@chakra-ui/react';
import { FaCamera, FaPlus } from 'react-icons/fa';
import { db, storage } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Capacitor } from '@capacitor/core';
import StarRating from '../shared/StarRating';
import { debounce } from 'lodash';

const AddEquipmentForm = ({ isOpen, onClose, onSuccess, editingEquipment = null }) => {
  const { user } = useAuth();
  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const bgColor = useColorModeValue('white', 'gray.800');

  const [formData, setFormData] = useState({
    name: editingEquipment?.name || '',
    manufacturer: editingEquipment?.manufacturer || '',
    category: editingEquipment?.category || 'Brewing Equipment',
    model: editingEquipment?.model || '',
    serialNumber: editingEquipment?.serialNumber || '',
    purchaseDate: editingEquipment?.purchaseDate || '',
    purchasePrice: editingEquipment?.purchasePrice || '',
    purchaseLocation: editingEquipment?.purchaseLocation || '',
    warranty: editingEquipment?.warranty || '',
    maintenanceSchedule: editingEquipment?.maintenanceSchedule || '',
    notes: editingEquipment?.notes || '',
    status: editingEquipment?.status || 'Active',
    photo: null,
    photoURL: editingEquipment?.photoURL || null,
    manualUrl: editingEquipment?.manualUrl || '',
    tags: editingEquipment?.tags || [],
    rating: editingEquipment?.rating || 0,
  });

  const [loading, setLoading] = useState(false);
  const [newTag, setNewTag] = useState('');

  const searchExistingEquipment = debounce(async (name) => {
    if (!name) return;
    
    try {
      const q = query(
        collection(db, 'equipmentInventory'),
        where('name', '>=', name),
        where('name', '<=', name + '\uf8ff')
      );
      
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const existingEquipment = querySnapshot.docs[0].data();
        toast({
          title: 'Equipment found',
          description: 'Pre-filling details from existing equipment',
          status: 'info',
          duration: 3000,
        });
        
        setFormData(prev => ({
          ...prev,
          manufacturer: existingEquipment.manufacturer || prev.manufacturer,
          category: existingEquipment.category || prev.category,
          model: existingEquipment.model || prev.model,
          tags: existingEquipment.tags || prev.tags,
        }));
      }
    } catch (error) {
      console.error('Error searching equipment:', error);
    }
  }, 500);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));

    // Search for existing equipment when name changes
    if (name === 'name' && value.length > 2) {
      searchExistingEquipment(value);
    }
  };

  const handlePhotoSelect = async () => {
    try {
      if (Capacitor.isNativePlatform()) {
        const { Camera } = await import('@capacitor/camera');
        const image = await Camera.getPhoto({
          quality: 90,
          allowEditing: true,
          resultType: 'base64',
          source: 'PROMPT'
        });

        const response = await fetch(`data:image/jpeg;base64,${image.base64String}`);
        const blob = await response.blob();
        
        setFormData(prev => ({
          ...prev,
          photo: blob,
          photoURL: `data:image/jpeg;base64,${image.base64String}`
        }));
      } else {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = async (e) => {
          const file = e.target.files[0];
          if (file) {
            setFormData(prev => ({
              ...prev,
              photo: file,
              photoURL: URL.createObjectURL(file)
            }));
          }
        };
        input.click();
      }
    } catch (error) {
      console.error('Error selecting photo:', error);
      toast({
        title: 'Error selecting photo',
        status: 'error',
        duration: 3000,
      });
    }
  };

  const handleAddTag = () => {
    if (newTag.trim() && !formData.tags.includes(newTag.trim())) {
      setFormData(prev => ({
        ...prev,
        tags: [...prev.tags, newTag.trim()]
      }));
      setNewTag('');
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setFormData(prev => ({
      ...prev,
      tags: prev.tags.filter(tag => tag !== tagToRemove)
    }));
  };

  const handleRatingChange = (newRating) => {
    setFormData(prev => ({
      ...prev,
      rating: newRating
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      if (!formData.name || !formData.manufacturer) {
        toast({
          title: 'Required fields missing',
          description: 'Please fill in equipment name and manufacturer',
          status: 'warning',
          duration: 3000,
        });
        return;
      }

      let photoURL = formData.photoURL;
      if (formData.photo) {
        const photoRef = ref(storage, `equipment-photos/${user.uid}/${Date.now()}`);
        await uploadBytes(photoRef, formData.photo);
        photoURL = await getDownloadURL(photoRef);
      }

      const equipmentData = {
        ...formData,
        userId: user.uid,
        userName: user.displayName || 'Anonymous',
        userPhotoURL: user.photoURL,
        photoURL,
        updatedAt: new Date(),
      };
      delete equipmentData.photo;

      if (editingEquipment) {
        await updateDoc(doc(db, 'equipmentInventory', editingEquipment.id), equipmentData);
      } else {
        const equipmentRef = await addDoc(collection(db, 'equipmentInventory'), {
          ...equipmentData,
          createdAt: new Date(),
        });

        // Update user's equipment inventory
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        
        if (userDoc.exists()) {
          await updateDoc(userRef, {
            equipmentInventory: arrayUnion(equipmentRef.id)
          });
        } else {
          await updateDoc(userRef, {
            equipmentInventory: [equipmentRef.id]
          });
        }
      }

      // Trigger haptic feedback on mobile
      if (Capacitor.isNativePlatform()) {
        try {
          const { Haptics } = await import('@capacitor/haptics');
          await Haptics.impact({ style: 'medium' });
        } catch (error) {
          console.debug('Haptics not available:', error);
        }
      }

      toast({
        title: `Equipment ${editingEquipment ? 'updated' : 'added'} successfully`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      onSuccess?.();
      onClose();
    } catch (error) {
      console.error('Error saving equipment:', error);
      toast({
        title: 'Error saving equipment',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      size={isMobile ? "full" : "xl"}
      motionPreset="slideInBottom"
    >
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent 
        bg={bgColor}
        margin={isMobile ? 0 : undefined}
        borderRadius={isMobile ? "0" : undefined}
      >
        <ModalHeader>{editingEquipment ? 'Edit Equipment' : 'Add Equipment'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <VStack spacing={4} align="stretch">
            {/* Photo Upload Section */}
            <Box
              position="relative"
              borderRadius="lg"
              overflow="hidden"
              borderWidth={1}
              borderStyle="dashed"
              cursor="pointer"
              onClick={handlePhotoSelect}
              _hover={{ borderColor: 'blue.500' }}
            >
              {formData.photoURL ? (
                <AspectRatio ratio={16/9}>
                  <Image
                    src={formData.photoURL}
                    alt="Equipment"
                    objectFit="cover"
                  />
                </AspectRatio>
              ) : (
                <VStack py={8} spacing={2}>
                  <FaCamera size={24} />
                  <Text>Add Photo</Text>
                </VStack>
              )}
            </Box>

            {/* Basic Info */}
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
              <FormControl isRequired>
                <FormLabel>Equipment Name</FormLabel>
                <Input
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="e.g., V60 Dripper"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Manufacturer</FormLabel>
                <Input
                  name="manufacturer"
                  value={formData.manufacturer}
                  onChange={handleInputChange}
                  placeholder="e.g., Hario"
                />
              </FormControl>
            </SimpleGrid>

            {/* Category and Model */}
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
              <FormControl>
                <FormLabel>Category</FormLabel>
                <Select
                  name="category"
                  value={formData.category}
                  onChange={handleInputChange}
                >
                  <option>Brewing Equipment</option>
                  <option>Grinder</option>
                  <option>Kettle</option>
                  <option>Scale</option>
                  <option>Filter</option>
                  <option>Storage</option>
                  <option>Cleaning</option>
                  <option>Other</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Model</FormLabel>
                <Input
                  name="model"
                  value={formData.model}
                  onChange={handleInputChange}
                  placeholder="e.g., VDXB-02"
                />
              </FormControl>
            </SimpleGrid>

            {/* Purchase Info */}
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
              <FormControl>
                <FormLabel>Purchase Date</FormLabel>
                <Input
                  name="purchaseDate"
                  type="date"
                  value={formData.purchaseDate}
                  onChange={handleInputChange}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Purchase Price</FormLabel>
                <Input
                  name="purchasePrice"
                  type="number"
                  value={formData.purchasePrice}
                  onChange={handleInputChange}
                  placeholder="0.00"
                />
              </FormControl>
            </SimpleGrid>

            {/* Additional Info */}
            <FormControl>
              <FormLabel>Purchase Location</FormLabel>
              <Input
                name="purchaseLocation"
                value={formData.purchaseLocation}
                onChange={handleInputChange}
                placeholder="e.g., Local Coffee Shop"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Warranty Information</FormLabel>
              <Textarea
                name="warranty"
                value={formData.warranty}
                onChange={handleInputChange}
                placeholder="Warranty details..."
                rows={2}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Maintenance Schedule</FormLabel>
              <Textarea
                name="maintenanceSchedule"
                value={formData.maintenanceSchedule}
                onChange={handleInputChange}
                placeholder="Maintenance notes..."
                rows={2}
              />
            </FormControl>

            {/* Tags */}
            <FormControl>
              <FormLabel>Tags</FormLabel>
              <HStack mb={2}>
                <Input
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  placeholder="e.g., Pour Over, Manual"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleAddTag();
                    }
                  }}
                />
                <IconButton
                  icon={<FaPlus />}
                  onClick={handleAddTag}
                  aria-label="Add tag"
                />
              </HStack>
              <Box minH="40px">
                <HStack spacing={2} flexWrap="wrap">
                  {formData.tags.map((tag, index) => (
                    <Tag
                      key={index}
                      size={isMobile ? "lg" : "md"}
                      borderRadius="full"
                      variant="solid"
                      colorScheme="blue"
                    >
                      <TagLabel>{tag}</TagLabel>
                      <TagCloseButton onClick={() => handleRemoveTag(tag)} />
                    </Tag>
                  ))}
                </HStack>
              </Box>
            </FormControl>

            {/* Status and Manual URL */}
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
              <FormControl>
                <FormLabel>Status</FormLabel>
                <Select
                  name="status"
                  value={formData.status}
                  onChange={handleInputChange}
                >
                  <option>Active</option>
                  <option>In Storage</option>
                  <option>Needs Repair</option>
                  <option>Retired</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Manual URL</FormLabel>
                <Input
                  name="manualUrl"
                  value={formData.manualUrl}
                  onChange={handleInputChange}
                  placeholder="Link to manual..."
                />
              </FormControl>
            </SimpleGrid>

            {/* Notes */}
            <FormControl>
              <FormLabel>Notes</FormLabel>
              <Textarea
                name="notes"
                value={formData.notes}
                onChange={handleInputChange}
                placeholder="Any additional notes..."
                rows={3}
              />
            </FormControl>

            {/* Rating */}
            <FormControl>
              <FormLabel>Rating</FormLabel>
              <StarRating
                rating={formData.rating}
                onChange={handleRatingChange}
                size={isMobile ? 32 : 24}
                isEditable
              />
            </FormControl>

            {/* Submit Button */}
            <Button
              colorScheme="blue"
              size={isMobile ? "lg" : "md"}
              onClick={handleSubmit}
              isLoading={loading}
              py={isMobile ? 7 : undefined}
              position={isMobile ? "fixed" : "relative"}
              bottom={isMobile ? 0 : undefined}
              left={isMobile ? 0 : undefined}
              right={isMobile ? 0 : undefined}
              borderRadius={isMobile ? 0 : undefined}
              mb={isMobile ? 0 : 4}
              mx={isMobile ? 0 : undefined}
              zIndex={2}
            >
              {editingEquipment ? 'Update Equipment' : 'Add to Inventory'}
            </Button>
            {isMobile && <Box h="60px" />} {/* Spacer for fixed button */}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddEquipmentForm;
