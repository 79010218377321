import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  Text,
  Grid,
  Flex,
  Image,
  Icon,
  HStack,
  VStack,
  Button,
  useDisclosure,
  Heading,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaCompass, FaUsers, FaNewspaper, FaBook, FaMugHot, FaMedal, FaMapMarkerAlt, FaStar, FaInfoCircle, FaEnvelope, FaShieldAlt, FaLock, FaFileContract, FaCoffee, FaTrophy } from 'react-icons/fa';
import { MdCollections, MdRateReview, MdGroups } from 'react-icons/md';
import { useAuth } from '../contexts/AuthContext';
import { useTheme } from '../contexts/ThemeContext';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';

const FEATURES = [
  {
    type: 'hero',
    title: 'Discover & Connect',
    description: 'Find great spots and connect with coffee lovers',
    features: [
      {
        title: 'Places',
        icon: FaCompass,
        description: 'Find great coffee spots',
        path: '/places',
        color: '#D4A017',
        image: 'https://images.unsplash.com/photo-1453614512568-c4024d13c247?auto=format&fit=crop&q=80&w=1200',
      },
      {
        title: 'Meetups',
        icon: MdGroups,
        description: 'Connect with coffee lovers',
        path: '/meetups',
        color: '#A0522D',
        image: 'https://images.unsplash.com/photo-1517048676732-d65bc937f952?auto=format&fit=crop&q=80&w=1200',
      },
    ]
  },
  {
    type: 'hero',
    title: 'Perfect Your Craft',
    description: 'Master your coffee making skills',
    features: [
 
      {
        title: 'Brew Journal',
        icon: FaMugHot,
        description: 'Track your daily brews',
        path: '/brew-journal',
        color: '#8B4513',
        image: 'https://images.unsplash.com/photo-1495474472287-4d71bcdd2085?auto=format&fit=crop&q=80&w=1200',
      },
      {
        title: 'Brew Check',
        icon: FaTrophy,
        description: 'Share your best coffee shots',
        path: '/brew-check',
        color: '#FFD700',
        image: 'https://images.unsplash.com/photo-1541167760496-1628856ab772?auto=format&fit=crop&q=80&w=1200',
      },
    ]
  }
];

const SECONDARY_FEATURES = [
  {
    title: 'News',
    icon: FaNewspaper,
    description: 'Latest in coffee',
    path: '/news',
    color: '#4299E1' // Brighter blue for better contrast
  },
  {
    title: 'Collections',
    icon: MdCollections,
    description: 'Curated coffee experiences',
    path: '/collections',
    color: '#F6AD55' // Brighter orange
  },
  {
    title: 'People',
    icon: FaUsers,
    description: 'Find coffee enthusiasts',
    path: '/people',
    color: '#68D391' // Brighter green
  },
  {
    title: 'Database',
    icon: FaBook,
    description: 'Coffee knowledge base',
    path: '/database',
    color: '#9F7AEA' // Brighter purple
  },
];

const INFO_OPTIONS = [
  {
    id: 'info',
    label: 'Information',
    description: 'Learn more about BeanScene',
    items: [
      {
        id: 'about',
        icon: FaInfoCircle,
        label: 'About',
        description: 'Learn about BeanScene',
        color: '#4299E1',
        gradient: 'linear-gradient(135deg, #4299E1 0%, #3182CE 100%)',
        path: '/about'
      },
      {
        id: 'contact',
        icon: FaEnvelope,
        label: 'Contact',
        description: 'Get in touch with us',
        color: '#48BB78',
        gradient: 'linear-gradient(135deg, #48BB78 0%, #38A169 100%)',
        path: '/contact'
      },
      {
        id: 'codeOfConduct',
        icon: FaShieldAlt,
        label: 'Code of Conduct',
        description: 'Our community guidelines',
        color: '#ED8936',
        gradient: 'linear-gradient(135deg, #ED8936 0%, #DD6B20 100%)',
        path: '/code-of-conduct'
      },
      {
        id: 'privacy',
        icon: FaLock,
        label: 'Privacy Policy',
        description: 'How we handle your data',
        color: '#9F7AEA',
        gradient: 'linear-gradient(135deg, #9F7AEA 0%, #805AD5 100%)',
        path: '/privacy'
      },
      {
        id: 'terms',
        icon: FaFileContract,
        label: 'Terms of Use',
        description: 'Rules and regulations',
        color: '#F687B3',
        gradient: 'linear-gradient(135deg, #F687B3 0%, #ED64A6 100%)',
        path: '/terms'
      }
    ]
  }
];

const FeatureSection = ({ section }) => {
  const { mode } = useTheme();
  const bgGradient = mode === 'dark' 
    ? 'linear(to-b, gray.900, gray.800)'
    : 'linear(to-b, white, gray.50)';

  return (
    <VStack spacing={8} width="100%" align="stretch">
      <Box px={4}>
        <Heading size="lg" mb={2}>{section.title}</Heading>
        <Text color="gray.500">{section.description}</Text>
      </Box>

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6} px={4}>
        {section.features.map((feature) => (
          <FeatureCard key={feature.path} feature={feature} />
        ))}
      </SimpleGrid>
    </VStack>
  );
};

const FeatureCard = ({ feature }) => {
  const navigate = useNavigate();
  const { mode } = useTheme();
  
  return (
    <Box
      as={motion.div}
      whileHover={{ y: -5 }}
      position="relative"
      height="200px"
      borderRadius="2xl"
      overflow="hidden"
      cursor="pointer"
      onClick={() => navigate(feature.path)}
      boxShadow="xl"
    >
      {/* Background Image with Gradient Overlay */}
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bgImage={`url(${feature.image})`}
        bgSize="cover"
        bgPosition="center"
        _after={{
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bg: mode === 'dark' 
            ? 'linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.8) 100%)'
            : 'linear-gradient(180deg, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.8) 100%)',
        }}
      />

      {/* Content */}
      <VStack
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        p={6}
        align="start"
        spacing={2}
      >
        <HStack spacing={3}>
          <Icon 
            as={feature.icon} 
            boxSize={6} 
            color="white" 
          />
          <Heading 
            size="lg" 
            color="white"
            textShadow="0 2px 4px rgba(0,0,0,0.3)"
          >
            {feature.title}
          </Heading>
        </HStack>
        <Text 
          color="white" 
          fontSize="md"
          textShadow="0 1px 2px rgba(0,0,0,0.5)"
        >
          {feature.description}
        </Text>
      </VStack>
    </Box>
  );
};

const Search = () => {
  const { isOpen: isInfoOpen, onOpen: onOpenInfo, onClose: onCloseInfo } = useDisclosure();

  return (
    <Box 
      minH="100vh" 
      py={6}
      bg={useColorModeValue('gray.50', 'gray.900')}
    >
      <Container maxW="container.xl">
        <VStack spacing={8} align="stretch">
          {FEATURES.map((section, index) => (
            <FeatureSection key={index} section={section} />
          ))}
          
          {/* Quick Access Section */}
          <Box px={4}>
            <Heading size="lg" mb={4}>Quick Access</Heading>
            <SimpleGrid 
              columns={{ base: 2, md: 3, lg: 4 }} 
              spacing={4}
            >
              {SECONDARY_FEATURES.map((feature) => (
                <QuickAccessCard key={feature.path} feature={feature} />
              ))}
              
              {/* Info Button */}
              <Box
                as={motion.div}
                whileHover={{ y: -3 }}
                p={4}
                bg={useColorModeValue('white', 'gray.800')}
                borderRadius="xl"
                boxShadow="md"
                cursor="pointer"
                onClick={onOpenInfo}
              >
                <VStack spacing={3}>
                  <Icon 
                    as={FaInfoCircle} 
                    boxSize={8} 
                    color="blue.400" 
                  />
                  <VStack spacing={1}>
                    <Text fontWeight="bold">Information</Text>
                    <Text 
                      fontSize="sm" 
                      color="gray.500" 
                      textAlign="center"
                    >
                      About, Contact & More
                    </Text>
                  </VStack>
                </VStack>
              </Box>
            </SimpleGrid>
          </Box>
        </VStack>
      </Container>

      {/* Info Modal */}
      {isInfoOpen && (
        <>
          <Box
            position="fixed"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bg="rgba(0, 0, 0, 0.8)"
            backdropFilter="blur(10px)"
            onClick={onCloseInfo}
            zIndex={1300}
          />
          
          <Box
            bg="gray.900"
            borderRadius="2xl"
            position="fixed"
            bottom={0}
            left="50%"
            transform="translateX(-50%)"
            width="90%"
            maxW="400px"
            maxH="85vh"
            overflowY="auto"
            p={4}
            pb={6}
            zIndex={1400}
            boxShadow="dark-lg"
          >
            {INFO_OPTIONS.map((group) => (
              <Box key={group.id} mb={4}>
                <Text 
                  fontSize="md" 
                  fontWeight="bold"
                  color="white" 
                  mb={1} 
                  px={1}
                >
                  {group.label}
                </Text>
                <Text 
                  fontSize="xs" 
                  color="whiteAlpha.700" 
                  mb={3} 
                  px={1}
                >
                  {group.description}
                </Text>
                <VStack spacing={2}>
                  {group.items.map((option) => (
                    <Button
                      key={option.id}
                      variant="unstyled"
                      display="flex"
                      alignItems="center"
                      width="full"
                      minH="64px"
                      p={4}
                      onClick={() => {
                        onCloseInfo();
                        navigate(option.path);
                      }}
                      position="relative"
                      overflow="hidden"
                      role="group"
                      borderRadius="lg"
                      bg="whiteAlpha.50"
                      _hover={{ bg: 'whiteAlpha.100' }}
                    >
                      <Box
                        p={2.5}
                        borderRadius="lg"
                        background={option.gradient}
                        mr={3}
                        display="inline-flex"
                        flexShrink={0}
                      >
                        <Icon 
                          as={option.icon} 
                          boxSize={5}
                          color="white"
                        />
                      </Box>
                      <Box flex="1" textAlign="left">
                        <Text 
                          fontSize="sm"
                          fontWeight="semibold"
                          color="white"
                          mb={0.5}
                          lineHeight="1.2"
                        >
                          {option.label}
                        </Text>
                        <Text
                          fontSize="xs"
                          color="whiteAlpha.700"
                          lineHeight="1.4"
                        >
                          {option.description}
                        </Text>
                      </Box>
                    </Button>
                  ))}
                </VStack>
              </Box>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

const QuickAccessCard = ({ feature }) => {
  const navigate = useNavigate();
  const cardBg = useColorModeValue('white', 'gray.800');
  
  return (
    <Box
      as={motion.div}
      whileHover={{ y: -3 }}
      p={4}
      bg={cardBg}
      borderRadius="xl"
      boxShadow="md"
      cursor="pointer"
      onClick={() => navigate(feature.path)}
    >
      <VStack spacing={3}>
        <Icon 
          as={feature.icon} 
          boxSize={8} 
          color={feature.color} 
        />
        <VStack spacing={1}>
          <Text fontWeight="bold">{feature.title}</Text>
          <Text 
            fontSize="sm" 
            color="gray.500" 
            textAlign="center"
          >
            {feature.description}
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
};

export default Search;
