import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  VStack,
  Input,
  Button,
  Flex,
  Text,
  Avatar,
} from '@chakra-ui/react';
import { collection, addDoc, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { db, auth } from '../firebase';

const ChatWindow = ({ recipientId, recipientData }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (!recipientId) return;

    // Create a unique chat ID (sorted user IDs to ensure consistency)
    const chatId = [auth.currentUser.uid, recipientId].sort().join('_');
    
    const messagesRef = collection(db, 'messages');
    const q = query(
      messagesRef,
      where('chatId', '==', chatId),
      orderBy('timestamp', 'asc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newMessages = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setMessages(newMessages);
      scrollToBottom();
    });

    return () => unsubscribe();
  }, [recipientId]);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    const chatId = [auth.currentUser.uid, recipientId].sort().join('_');

    try {
      await addDoc(collection(db, 'messages'), {
        chatId,
        text: newMessage,
        senderId: auth.currentUser.uid,
        recipientId,
        timestamp: new Date(),
      });

      // Update or create chat document
      // This could be used for the recent chats list
      await addDoc(collection(db, 'chats'), {
        id: chatId,
        participants: [auth.currentUser.uid, recipientId],
        lastMessage: {
          text: newMessage,
          timestamp: new Date()
        },
        lastMessageTime: new Date()
      });

      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <Box h="full">
      <VStack h="full" spacing={4}>
        <Box flex="1" w="full" overflowY="auto" p={4}>
          {messages.map((message) => (
            <Flex
              key={message.id}
              justify={message.senderId === auth.currentUser.uid ? 'flex-end' : 'flex-start'}
              mb={4}
            >
              <Box
                maxW="70%"
                bg={message.senderId === auth.currentUser.uid ? 'blue.500' : 'gray.100'}
                color={message.senderId === auth.currentUser.uid ? 'white' : 'black'}
                p={3}
                borderRadius="lg"
              >
                <Text>{message.text}</Text>
              </Box>
            </Flex>
          ))}
          <div ref={messagesEndRef} />
        </Box>

        <form onSubmit={sendMessage} style={{ width: '100%' }}>
          <Flex p={4}>
            <Input
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Type a message..."
              mr={2}
            />
            <Button type="submit" colorScheme="blue">
              Send
            </Button>
          </Flex>
        </form>
      </VStack>
    </Box>
  );
};

export default ChatWindow;
ChatWindow