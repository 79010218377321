import { config } from '../config';

export const initGoogleMaps = () => {
  return new Promise((resolve, reject) => {
    try {
      console.log('Starting to load Google Maps script...');
      
      // Set up the callback
      window.onGoogleMapsLoaded = () => {
        console.log('Google Maps loaded successfully');
        resolve();
      };

      // Create and append the script
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}&libraries=places&callback=onGoogleMapsLoaded`;
      script.async = true;
      script.defer = true;
      script.onerror = (error) => {
        console.error('Failed to load Google Maps:', error);
        reject(error);
      };
      
      document.head.appendChild(script);
    } catch (error) {
      console.error('Error initializing Google Maps:', error);
      reject(error);
    }
  });
};

export const isMapsLoaded = () => {
  return window.google && window.google.maps;
}; 