import React, { memo } from 'react';
import { Box } from '@chakra-ui/react';
import DOMPurify from 'dompurify';

const PostContent = memo(({ content, themeColors }) => {
  if (!content) return null;

  const sanitizedContent = DOMPurify.sanitize(content, {
    ALLOWED_TAGS: ['p', 'br', 'strong', 'em', 'u', 'a', 'ul', 'ol', 'li'],
    ALLOWED_ATTR: ['href', 'target']
  });

  return (
    <Box
      className="post-content"
      color={themeColors.text}
      mb={4}
      sx={{
        '& a': {
          color: 'blue.400',
          _hover: { textDecoration: 'underline' }
        },
        '& p': {
          mb: 2
        }
      }}
      dangerouslySetInnerHTML={{ __html: sanitizedContent }}
    />
  );
});

PostContent.displayName = 'PostContent';
export default PostContent; 